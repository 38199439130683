import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input, Divider, message } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Attribute from "./attribute/attribute";
import instanceOfAxios from "../../../../configAxios";

export interface AttributeObject {
  id: string;
  text: string;
  type: string;
}
export interface CollectionAttributesProps {
  mandatoryArray: AttributeObject[];
  optionalArray: AttributeObject[];
  getMandatoryAttributes: (e: any) => void;
  getSuggestedAttributes: (e: any) => void;
}

const CollectionAttributes = ({
  mandatoryArray,
  optionalArray,
  getMandatoryAttributes,
  getSuggestedAttributes,
}: CollectionAttributesProps) => {
  const { t } = useTranslation();
  const [mandatoryAttributes, setMandatoryAttributes] = useState<
    AttributeObject[]
  >([]);
  const [suggestedAttributes, setSuggestedAttributes] = useState<
    AttributeObject[]
  >([]);
  const [customAttributes, setCustomAttributes] = useState([
    {
      id: `c-0`,
      text: "Keyword",
      type: "popular",
    },
    {
      id: `c-1`,
      text: "country of creation ",
      type: "popular",
    },
    {
      id: `c-2`,
      text: "Style",
      type: "popular",
    },
  ]);
  const [mandatoryInputVisible, setMandatoryInputVisible] = useState(false);
  const [mandatoryValue, setMandatoryValue] = useState("");

  const [suggestedInputVisible, setSuggestedInputVisible] = useState(false);
  const [suggestedValue, setSuggestedValue] = useState("");

  useEffect(() => {
    instanceOfAxios
      .get(`popular_attributes?first=12`)
      .then((res) => {
        let popAtt = [];
        for (let i = 0; i < res?.data?.nodes.length; i++) {
          popAtt.push({
            id: `c-${i}`,
            text: res?.data?.nodes[i].key,
            type: "popular",
          });
        }
        setCustomAttributes(popAtt);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    if (mandatoryArray?.length > 0) {
      setMandatoryAttributes(mandatoryArray);
    }
    if (optionalArray?.length > 0) {
      setSuggestedAttributes(optionalArray);
    }
  }, [mandatoryArray, optionalArray]);

  // send the mandatory attributs to parent component when the state change
  useEffect(() => {
    getMandatoryAttributes(mandatoryAttributes);
  }, [mandatoryAttributes]);
  // end
  // send the optional attributs to parent component when the state change
  useEffect(() => {
    getSuggestedAttributes(suggestedAttributes);
  }, [suggestedAttributes]);
  // end

  const onCloseSuggestedAttribute = (index: number) => {
    if (suggestedAttributes[index].type === "popular") {
      setCustomAttributes([...customAttributes, suggestedAttributes[index]]);
    }
    let suggestedCopy = suggestedAttributes.filter((val, i) => {
      return i !== index;
    });
    setSuggestedAttributes(suggestedCopy);
  };

  const onCloseMandatoryAttribute = (index: number) => {
    if (mandatoryAttributes[index].type === "popular") {
      setCustomAttributes([...customAttributes, mandatoryAttributes[index]]);
    }
    let mandatoryCopy = mandatoryAttributes.filter((val, i) => {
      return i !== index;
    });
    setMandatoryAttributes(mandatoryCopy);
  };

  const checkDuplicated = (newValue: string) => {
    return (
      mandatoryAttributes?.some(
        (attribute: any) => attribute?.text === newValue
      ) ||
      suggestedAttributes?.some(
        (attribute: any) => attribute?.text === newValue
      )
    );
  };

  const mandatoryInputConfirm = (e: any) => {
    if (e.target.value !== "") {
      let newMand = e.target.value.trim();
      if (checkDuplicated(newMand)) {
        message.warning({
          content: t("collection-page.attribute-name-exists"),
          style: {
            marginTop: "80vh",
          },
        });
      } else {
        setMandatoryAttributes([
          ...mandatoryAttributes,
          {
            id: `m-${mandatoryAttributes.length + 1}`,
            text: newMand,
            type: "mandatory",
          },
        ]);
      }
    }
    setMandatoryInputVisible(false);
    setMandatoryValue("");
  };

  const suggestedInputConfirm = (e: any) => {
    if (e.target.value !== "") {
      let newSug = e.target.value.trim();
      if (checkDuplicated(newSug)) {
        message.warning({
          content: t("collection-page.attribute-name-exists"),
          style: {
            marginTop: "80vh",
          },
        });
      } else {
        setSuggestedAttributes([
          ...suggestedAttributes,
          {
            id: `s-${suggestedAttributes.length + 1}`,
            text: newSug,
            type: "suggested",
          },
        ]);
      }
    }
    setSuggestedInputVisible(false);
    setSuggestedValue("");
  };

  //////////// DRAG & DROP functions ///////////////

  const reorder = (list: string, startIndex: number, endIndex: number) => {
    let result: any[] = [];

    switch (list) {
      case "custom":
        result = customAttributes;
        break;

      case "mandatory":
        result = mandatoryAttributes;
        break;
      case "suggested":
        result = suggestedAttributes;
        break;

      default:
        break;
    }
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (
    source: string,
    destination: string,
    droppableSource: any,
    droppableDestination: any
  ) => {
    let sourceClone: any[] = [];
    let destClone: any[] = [];
    switch (source) {
      case "custom":
        sourceClone = customAttributes;
        break;

      case "mandatory":
        sourceClone = mandatoryAttributes;
        break;

      case "suggested":
        sourceClone = suggestedAttributes;
        break;

      default:
        break;
    }

    switch (destination) {
      case "custom":
        destClone = customAttributes;
        break;

      case "mandatory":
        destClone = mandatoryAttributes;
        break;

      case "suggested":
        destClone = suggestedAttributes;
        break;

      default:
        break;
    }

    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        source.droppableId,
        source.index,
        destination.index
      );

      if (source.droppableId === "custom") {
        setCustomAttributes(items);
      } else if (source.droppableId === "mandatory") {
        setMandatoryAttributes(items);
      } else if (source.droppableId === "suggested") {
        setSuggestedAttributes(items);
      }
    } else {
      const result = move(
        source.droppableId,
        destination.droppableId,
        source,
        destination
      );

      setMandatoryAttributes(result.mandatory || mandatoryAttributes);
      setCustomAttributes(result.custom || customAttributes);
      setSuggestedAttributes(result.suggested || suggestedAttributes);

      getMandatoryAttributes(result.mandatory || mandatoryAttributes);
      getSuggestedAttributes(result.suggested || suggestedAttributes);
    }
  };

  const onAttributeChange = (e: any, type: string) => {
    switch (type) {
      case "mandatory":
        setMandatoryValue(e.target?.value);
        break;

      case "optional":
        setSuggestedValue(e.target?.value);
        break;

      default:
        break;
    }
  };

  return (
    <div className="collection-attributes-space">
      <div>
        <h5>
          {`${t("collection-page.collection-attributes")}`}{" "}
          <span className="required-field">*</span>:
        </h5>
        <h5 className="text-regular-font">
          {t("collection-page.collection-attributes-description")}
        </h5>
      </div>
      <Divider />
      <div>
        <h5>{`${t("collection-page.mandatory-attributes")} :`}</h5>
        <h5 className="text-regular-font">
          {t("collection-page.mandatory-attributes-description")}
        </h5>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="mandatory">
          {(provided) => (
            <ul
              className="attributes-container red-border"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {mandatoryAttributes.map((attribute, index) => {
                return (
                  <li key={index}>
                    <Attribute
                      text={attribute.text}
                      closable={true}
                      onClose={() => onCloseMandatoryAttribute(index)}
                      mandatory={true}
                    />
                  </li>
                );
              })}
              {mandatoryInputVisible ? (
                <Input
                  autoFocus
                  type="text"
                  value={mandatoryValue}
                  onChange={(e) => onAttributeChange(e, "mandatory")}
                  onBlur={mandatoryInputConfirm}
                  onPressEnter={mandatoryInputConfirm}
                  style={{ width: "150px", height: "40px" }}
                />
              ) : (
                <div
                  className="add-attribute-holder"
                  onClick={() => setMandatoryInputVisible(true)}
                  style={{ backgroundColor: "#FFD6D6" }}
                >
                  <PlusCircleFilled className="add-attribute-icon red-color" />
                  <h5 className="text-regular-font red-color">
                    {t("collection-page.collection-add-attribute")}
                  </h5>
                </div>
              )}
            </ul>
          )}
        </Droppable>

        <div>
          <h5>{`${t("collection-page.optional-attributes")} :`}</h5>
          <h5 className="text-regular-font">
            {t("collection-page.optional-attributes-description")}{" "}
          </h5>
        </div>
                {/* Start optional attibutes bloack */}
        <Droppable droppableId="suggested">
          {(provided) => (
            <ul
              className="attributes-container green-border"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {suggestedAttributes.map((attribute, index) => {
                return (
                  <li key={index}>
                    <Attribute
                      text={attribute.text}
                      closable={true}
                      onClose={() => onCloseSuggestedAttribute(index)}
                      suggested={true}
                    />
                  </li>
                );
              })}
              {suggestedInputVisible ? (
                <Input
                  autoFocus
                  type="text"
                  value={suggestedValue}
                  onChange={(e) => onAttributeChange(e, "optional")}
                  onBlur={suggestedInputConfirm}
                  onPressEnter={suggestedInputConfirm}
                  style={{ width: "150px", height: "40px" }}
                />
              ) : (
                <div
                  className="add-attribute-holder"
                  onClick={() => setSuggestedInputVisible(true)}
                  style={{ backgroundColor: "#DDFFF4" }}
                >
                  <PlusCircleFilled className="add-attribute-icon green-color" />
                  <h5 className="text-regular-font green-color">
                    {t("collection-page.collection-add-attribute")}
                  </h5>
                </div>
              )}
            </ul>
          )}
        </Droppable>
                {/* end optional attibutes bloack */}
        <div>
          <h5>{`${t("collection-page.popular-attributes")} :`}</h5>
          <h5 className="text-regular-font">
            {t("collection-page.popular-attributes-description")}{" "}
          </h5>
        </div>

        <Droppable droppableId="custom">
          {(provided) => (
            <ul
              className="attributes-container primary-border"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {customAttributes.map((attribute, index) => {
                return (
                  <Draggable
                    key={attribute.id}
                    draggableId={attribute.id}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Attribute text={attribute.text} popular={true} />
                      </li>
                    )}
                  </Draggable>
                );
              })}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CollectionAttributes;
