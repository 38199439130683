import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./components/footer";
import MobileNavbar from "./components/mobileNavbar/mobileNavbar";
import Navbar from "./components/navbar";
import "./layout.less";
import { withoutLayout } from "../../../App";

const Layout = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [position, setPosition] = useState(location.pathname);
  const [without, setWithout] = useState<string[]>([]);
  const [landingWithout, setLandingWithout] = useState<string[]>([]);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    let temp: string[] = [];
    withoutLayout.map((route) => {
      temp.push(route?.path);
      setWithout(temp);
      return true;
    });

    return () => {
      // Cleanup on component unmount if needed
      window.onscroll = null;
    };
  }, []); // Empty dependency array to ensure this effect runs only once on mount

  useEffect(() => {
    // This will be called whenever the route changes
    console.log("Route changed to", location.pathname);

    // You can also perform any other logic based on the route change

    // For example, if you want to scroll to the top of the page on route change
    window.scrollTo(0, 0);

    // Update the position state
    setPosition(location.pathname);
  }, [location.pathname]);

  return (
    <>
      {without.includes(position) ||
      landingWithout.some((path) => position.includes(path)) ? (
        <div>{children}</div>
      ) : (
        <div>
          <Navbar scrolled={scrolled} />
          <MobileNavbar scrolled={scrolled} />
          <div className="main-body">{children}</div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Layout;
