import React, { useEffect, useState } from "react";
import {
  Menu,
  Dropdown,
  Upload,
  message,
  Button,
  Tooltip,
  Row,
  Col,
  Image,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import CollectionBanner from "../upload image/collection banner/collectionBanner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EditFilled, DeleteFilled, DislikeFilled } from "@ant-design/icons";
import reportIcon from "../../../../../assets/images/report.svg";
import shareIcon from "../../../../../assets/images/share.svg";
import homeIcon from "../../../../../assets/images/home-icon.svg";
import editIcon from "../../../../../assets/images/edit icon.svg";
import CopyIconProfile from "../../../../../assets/images/copy.png";
import flagIcon from "../../../../../assets/images/flag icon.svg";
import "./collectionHeader.less";
import IconButton from "../../../../components/buttons/IconButton/IconButton";
import TransparentButton from "../../../../components/buttons/transparent button/transparentButton";
import instanceOfAxios from "../../../../../configAxios";
import { useContext } from "../../../../context";
import PictureFile from "../../../../../assets/images/file-picture.svg";
import CustomModal from "../../../../components/modal/customModal";
import useImageDisplay from "../../../../helpers/useImageDisplay";
import coverProp1 from "../../../../../assets/images/cover_photo.webp";
import ConfirmModal from "../../../../components/modal/confirm modal/confirmModal";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../components/toasts/openToast";
import { getItem, setItem } from "../../../../storage/session";
import useUserNaming from "../../../../helpers/useUserNaming";
import TextAreaModal from "../../../../components/modal/textAreaModal/textAreaModal";
import { Helmet } from "react-helmet";
import { getItemFromLocalStorage } from "../../../../helpers/useLocalStorage";


export interface CollectionHeaderProps {
  owner?: boolean;
  profile?: boolean;
  infosProfile?: any;
  checked?: any;
  collection?: any;
  setExtbanner?: any;
  extbanner?: any;
  creator?: any;
  colDetails?: any;
  idCol?: any;
  localBanner?: string;
  setLocalBanner?: any;
}

const CollectionHeader = ({
  collection,
  owner,
  profile,
  infosProfile,
  localBanner,
  setLocalBanner,
  setExtbanner,
  extbanner
}: CollectionHeaderProps) => {

  const { t } = useTranslation();
  const  navigate = useNavigate();
  const { address } = useContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visbleModal, setVisiblemodal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [dislikeVisible, setDislikeVisible] = useState(false);
  const [visibilityModalProfileBanner, setVisibilityModalProfileBanner] = useState(false);
  const [timeStampProfileBanner, setTimeStampProfileBanner] = useState(new Date().getTime())
  const [timeStampCollectionBanner, setTimeStampCollectionBanner] = useState(new Date().getTime())
  const [isPorfileBannerLoading, setIsPorfileBannerLoading] = useState(false);
  const [isCollectionBannerLoading, setIsCollectionBannerLoading] = useState(false);


  const profilePhoto = useImageDisplay(
    infosProfile?.avatar,
    "__b" + infosProfile?.avatar_ext
  );
  const logoImage = useImageDisplay(
    collection?.logo_image,
    "__m" + collection?.logo_image_ext
  );
  const addressDisplay = useUserNaming({
    erd_address: infosProfile?.erd_address,
  });
  const cover1S3 = "static/media/cover_photo.webp";
  const [collectionBanner, setCollectionBanner] = useState<any>(
    process.env.REACT_APP_S3_ENABLED === "true" ? process.env.REACT_APP_SERVER_IMAGE_S3_BASE_URL  + cover1S3 : coverProp1
  ); 
  let uploadedDisplay = useImageDisplay(`${localBanner}` , "__ba" + extbanner );

  const [idProfile, setIdProfile] = useState(
    JSON.parse(localStorage.getItem("user") || "{}")?.id
  );

  const cancelImage = () => {
    setVisiblemodal(false);
  };

  const handelOpen = () => {
    setVisiblemodal(true);
  };
  const closeModal = () => {
    setVisiblemodal(false);
  };

  const handleOpenModalProfileBanner = () => {
    setVisibilityModalProfileBanner(true);
  };
  const closeModalProfileBanner = () => {
      setVisibilityModalProfileBanner(false);

  };

  const editBanner = () => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    }
    let values: any = {};
    values.erd_address = address;
    values.default_banner = typeof collectionBanner === "string";
    let formData = new FormData();
    formData.append("erd_address", address);
    formData.append("default_banner", values.default_banner);
    if (typeof collectionBanner === "string") {
      formData.append("banner_image", collectionBanner);
      formData.append(
        "banner_image_metadata",
        collection?.banner_image_metadata
      );
    } else {
      formData.append("banner_image", collectionBanner);
      if (typeof collectionBanner !== "string") {
        formData.append(
          "banner_image_metadata",
          JSON.stringify({
            name: collectionBanner?.name,
            size: collectionBanner?.size,
            type: collectionBanner?.type,
          })
        );
      }
    }
    setIsCollectionBannerLoading(true)
    instanceOfAxios
      .put("collections/update_banner/" + collection?.id, formData,{
        headers: {
          "x-access-token": getItemFromLocalStorage("token"),
        },        
      })
      .then((res: any) => {
        setLocalBanner(res?.data?.banner_image);
        setExtbanner(res?.data?.banner_image_ext);
        setTimeStampCollectionBanner(new Date().getTime())
        setVisiblemodal(false);
    setIsCollectionBannerLoading(false)
      })
      .catch((err: any) => {
        if(err?.response.status===401){
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-unauthorized-user"),
            3
          );                    
        }else{
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        } 
    setIsCollectionBannerLoading(false)
      });
  };
  const editProfileBanner = () => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    }
    let values: any = {};
    values.erd_address = address;
    values.default_banner = typeof collectionBanner === "string";
    let formData = new FormData();
    formData.append("erd_address", address);
    formData.append("default_banner", values.default_banner);
    formData.append("banner_image", collectionBanner);

    setIsPorfileBannerLoading(true)
    instanceOfAxios
      .put(`profile/update_banner/${address}` ,formData, {
        headers: {
          "x-access-token": getItemFromLocalStorage("token"),
        },        
      })
      .then((res: any) => {
        setLocalBanner(res?.data?.banner_image);
        setExtbanner(res?.data?.banner_image_ext);
        setTimeStampProfileBanner(new Date().getTime())
        window.location.reload()
        setVisibilityModalProfileBanner(false);
        setIsPorfileBannerLoading(false)

      })
      .catch((err: any) => {
        if(err?.response.status===401){
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-unauthorized-user"),
            3
          );                    
        }else{
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        }   
        setIsPorfileBannerLoading(false)
      });
  };

  const showModal = () => {
    if (address) {
      setIsModalVisible(true);
    } else {
      setItem("goToUrl", window.location.href);
      navigate("/auth");
    }
  };

  const showDislikeModal = () => {
    if (address) {
      setDislikeVisible(true);
    } else {
      setItem("goToUrl", window.location.href);
      navigate("/auth");
    }
  };

  const handleOk = (status: string, value: string) => {
    if (address) {
      instanceOfAxios
        .post(
          `report_collection/collection/${collection?.id}/address/${address}`,
          {
            report: value,
            dislike: status !== "report",
            reports: status === "report",
          }
        )
        .then((res: any) => {
          if (res?.data) {
            OpenSuccessNotification(
              t("profile.success"),
              status === "report"
                ? t("collection-page.report-success")
                : t("collection-page.dislike-success"),
              3
            );
          }
          setIsModalVisible(false);
          setDislikeVisible(false);
        })
        .catch((err: any) => {
          OpenErrorNotification(
            t("profile.error"),
            status === "report"
              ? t("collection-page.report-already")
              : t("collection-page.dislike-collection-already"),
            3
          );
          setIsModalVisible(false);
          setDislikeVisible(false);
        });
    } else {
      setItem("goToUrl", window.location.href);
      navigate("/auth");
    }
  };

  const menu = (
    <Menu>
      {!owner && !infosProfile && (
        <>
          <Menu.Item key="0">
            <div className="collection-profile-menu-item" onClick={showModal}>
              <img alt="flagIcon" src={flagIcon} className="flag-icon" />
              <h6>{t("collection-page.report-page")}</h6>
            </div>
          </Menu.Item>
          <Menu.Item key="1">
            <div
              className="collection-profile-menu-item"
              onClick={showDislikeModal}
            >
              <DislikeFilled style={{ margin: "0 10px" }} />
              <h6>{t("collection-page.dislike-collection")}</h6>
            </div>
          </Menu.Item>
        </>
      )}
      {owner && !infosProfile && (
        <a
          href={`/edit-collection/${collection?.homepage}`}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Menu.Item
            key="2"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(`/edit-collection/${collection?.homepage}`);
            }}
          >
            <h5 className="menu-row">
              {" "}
              <EditFilled className="item-menu-icon" />
              {t("common.edit")}
            </h5>
          </Menu.Item>
        </a>
      )}
      {(owner ||
        JSON.parse(localStorage.getItem("user") || "{}")?.role === "admin") &&
        collection?.countItems === 0 &&
        !infosProfile && (
          <Menu.Item key="3" onClick={() => setDeleteConfirm(true)}>
            <h5 className="menu-row red-color">
              <DeleteFilled className="item-menu-icon" />
              {t("common.delete")}
            </h5>
          </Menu.Item>
        )}
    </Menu>
  );

  const deleteCollection = () => {
    instanceOfAxios
      .delete(`collection/${collection?.id}/address/${address}`)
      .then((res) => {
        OpenSuccessNotification(
          t("profile.success"),
          t("profile.success-desc"),
          3
        );
        window.scrollTo(0, 0);
        navigate(`/collection`);
      })
      .catch((e) => {
        OpenErrorNotification(t("profile.error"), t("profile.error-desc"), 3);
      });
  };

  const linkCopied = () => {
    message.success("collection link is copied");
  };
  const linkprofilecopied = (e: any) => {
    copy(e);
    message.success(t("collection-page.erd-copied"));
  };

  const chnageBanner = (
    <>
      <h3> {`${t("collection-page.collection-banner")}`}</h3>

      <p> {t("collection-page.collection-banner-description")}</p>
      <CollectionBanner
        bannerext={extbanner}
        image={uploadedDisplay+"?"+timeStampCollectionBanner}
        setCollectionBanner={setCollectionBanner}
      />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
        <Col>
          <Button onClick={cancelImage} className="cancelBanner">{t("common.cancel")}</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={editBanner}>
            {t("collection-page.confirm")}
          </Button>
        </Col>
      </Row>
    </>
  );
  const changeProfileBanner = (
    <>
      <h3>{t("collection-page.profile-banner")}</h3>

      <p> {t("collection-page.collection-banner-description")}</p>
      <CollectionBanner
        bannerext={extbanner}
        image={  infosProfile?.banner_image!=="" 
              ?uploadedDisplay+ "?"+timeStampProfileBanner : `${localBanner}` } 
        setCollectionBanner={setCollectionBanner}
      />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
        <Col>
          <Button onClick={cancelImage} className="cancelBanner">{t("common.cancel")}</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={editProfileBanner}>
            {t("collection-page.confirm")}
          </Button>
        </Col>
      </Row>
    </>
  );  

  return (
    <div>
      <Helmet>
          <meta
          content={infosProfile ? profilePhoto : logoImage}
          property="og:image"
        />
           <meta
          content={infosProfile ? profilePhoto : logoImage}
          property="twitter:image"
        />
      </Helmet>
      <CustomModal
        children={chnageBanner}
        width={""}
        visible={visbleModal}
        closable={true}
        handleOk={handelOpen}
        handleCancel={closeModal}
      />
      {/* Profile Banner Modal */}
      <CustomModal
        children={changeProfileBanner}
        width={"700px"}
        visible={visibilityModalProfileBanner}
        closable={true}
        handleOk={handleOpenModalProfileBanner}
        handleCancel={closeModalProfileBanner}
      />
      {isModalVisible && (
        <TextAreaModal
          visible={isModalVisible}
          title={t("collection-page.report-collection")}
          description={t(
            "collection-page.why-do-you-think-this-collection-should-be-removed-from-the-marketplace"
          )}
          label={t("collection-page.refuse-message-title")}
          placeholder={t("collection-page.report-placeholder")}
          okButton={t("collection-page.report-ok-button")}
          handleOk={(val) => handleOk("report", val)}
          handleCancel={() => setIsModalVisible(false)}
        />
      )}

      <TextAreaModal
        visible={dislikeVisible}
        title={t("collection-page.dislike-collection")}
        description={t(
          "collection-page.why-do-you-think-this-collection-should-be-removed-from-the-marketplace"
        )}
        label={t("collection-page.refuse-message-title")}
        placeholder={t("collection-page.report-placeholder")}
        okButton={t("collection-page.report-ok-button")}
        handleOk={(val) => handleOk("dislike", val)}
        handleCancel={() => setDislikeVisible(false)}
      />
      <div className={"collection-show-cover-container"}>
        <>{
          isCollectionBannerLoading?
          <Spin/>:
      collection&&  <Image
         alt={`${collection?.collection_name}`
        }
          src={
            infosProfile 
              ? coverProp1
              : uploadedDisplay.includes("static/media")?
              uploadedDisplay.replace(process.env.REACT_APP_SERVER_IMAGE_S3_BASE_URL||"",""): uploadedDisplay  + "?" + timeStampCollectionBanner
          }
          preview={false}
          placeholder={
            <Spin/>
          }  
          className="collection-show-cover-photo"
        />
        }
        </>
        <>
        { isPorfileBannerLoading?
          <Spin/>:
      infosProfile&&  <Image
         alt={`${infosProfile?.nickname}-banner`
        }
          src={infosProfile?.banner_image!=="" 
              ? uploadedDisplay.includes("static/media")?
              uploadedDisplay.replace(process.env.REACT_APP_SERVER_IMAGE_S3_BASE_URL||"","") 
              :uploadedDisplay + "?" +timeStampProfileBanner
              : coverProp1
          }
          preview={false}
          placeholder={        
            <Spin/>
          }        
          className="collection-show-cover-photo"
        />
        }
        </>
        {owner && (
          <div className="edit-collection-buttons">
            {!infosProfile ? (
              <TransparentButton
                icon={profile ? "" : PictureFile}
                text={
                  profile ? "" : t("collection-page.edit-collection-banner")
                }
                onClick={() => (profile ? "" : handelOpen())}
              />
            ) : (
              ""
            )}
            {address && owner && infosProfile?.id === idProfile ? (
              <>
                  {profile &&<TransparentButton
                  icon={PictureFile}
                  text={t("collection-page.edit-profile-banner")} 
                  onClick={() => handleOpenModalProfileBanner()
                  }
                />}
                <TransparentButton
                  icon={editIcon}
                  text={profile ? "Edit Profile" : "Edit collection"}
                  onClick={() =>
                    profile
                      ? navigate("/my-profile")
                      : navigate(`/edit-collection/${collection?.homepage}`)
                  }
                />           
              </>
              
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <div className="collection-header-description">
        <div className="collection-header-body">
          <div className="collection-show-photo-holder">
            <div className="collection-show-photo-container">
              <img
                alt={
                  infosProfile
                    ? `${infosProfile?.nickname ||
                        infosProfile?.erd_address}`
                    : `${collection?.collection_name}`
                }
                src={infosProfile ? profilePhoto : logoImage}
                className="collection-show-photo"
              />
            </div>
          </div>

          <div className="collection-options-container">
            <div className="collection-options">
              {!infosProfile && collection?.homepage2 && (
                <Tooltip title={t("collection-page.project-link-tooltip")}>
                  <a target="_blank" href={collection?.homepage2}>
                    <IconButton icon={homeIcon} />
                  </a>
                </Tooltip>
              )}
              {!infosProfile && (
                <Tooltip title={t("collection-page.copy-to-share")}>
                  {" "}
                  <CopyToClipboard
                    text={`${window.location.origin}/${collection?.homepage}`}
                    onCopy={linkCopied}
                  >
                    <IconButton icon={shareIcon} />
                  </CopyToClipboard>
                </Tooltip>
              )}
              {!infosProfile ? (
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <IconButton icon={reportIcon} />
                </Dropdown>
              ) : (
                ""
              )}
              <ConfirmModal
                modalVisible={deleteConfirm}
                confirm={deleteCollection}
                cancel={() => setDeleteConfirm(false)}
                title={t("collection-page.confirm-delete-collection-title")}
                description={t("collection-page.confirm-delete-collection")}
              />
            </div>
          </div>
          <div className="desc-space">
            <div className="desc-limit">
              <h1>
                {infosProfile?.nickname
                  ? infosProfile.nickname
                  : collection?.collection_name}
              </h1>
              {infosProfile ? (
                <div className="copier-link">
                  <div>{addressDisplay}</div>
                  <CopyToClipboard
                    text={window.location.href}
                    onCopy={() =>
                      linkprofilecopied(
                        infosProfile?.erd_address
                          ? infosProfile.erd_address
                          : ""
                      )
                    }
                  >
                    <IconButton icon={CopyIconProfile} />
                  </CopyToClipboard>
                </div>
              ) : (
                ""
              )}
              <h5 className="text-regular-font">
                {infosProfile ? (
                  infosProfile.bio
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: profile
                        ? infosProfile.bio
                        : collection?.description,
                    }}
                  ></div>
                )}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionHeader;
