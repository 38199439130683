import { useEffect, useState } from "react";
import Message from "../../../../../assets/images/message 01.svg";
import NotifImg from "../../../../../assets/images/notification 01.svg";
import Notification from "../../../home/components/notification/notification";
import { isValidURL } from "../../../../helpers/validateUrl";
import io from "socket.io-client";
import instanceOfAxios from "../../../../../configAxios";
import { useContext } from "../../../../context";
import { useNavigate } from "react-router-dom";
import { setItem } from "../../../../storage/session";
import { Badge } from "antd";

const NotifIcons = () => {
  const [countnotif, setCountnotif] = useState<number>(0);
  const [countnotifMsg, setCountnotifMsg] = useState<number>(0);
  const [newnotifprofile, setNewnotifprofile] = useState<any>({});
  const [newnotifitem, setNewnotifitem] = useState<any>({});
  const [newnotifcollection, setNewnotifcollection] = useState<any>({});
  const [newnotifoffer, setNewnotifoffer] = useState<any>({});
  const [datanotif, setNotif] = useState<any[]>([]);
  const [dataLoad, setDataload] = useState<boolean>(false);
  const [dataLoadMsg, setDataloadMsg] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [dataMsg, setDataMsg] = useState<any[]>([]);
  const [offsetMsg, setOffsetMsg] = useState<number>(0);
  const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL;
  const serverAPIEndpoint = process.env.REACT_APP_SERVER_IMAGE_BASE_URL;
  const enbaleSocketIo = process.env.REACT_APP_ENABLE_SOCKET_IO;
  
  const { address } = useContext();
  const  navigate = useNavigate();
  
  if(enbaleSocketIo === 'true'){
    if (!serverBaseURL) {
      throw new Error();
    }
    if (!serverAPIEndpoint) {
      throw new Error();
    }
    const SOCKET_URL = serverBaseURL + serverAPIEndpoint;
    if (!isValidURL(SOCKET_URL)) {
      throw new Error();
    }
    var socket:any
    setTimeout(() => {
       socket = io(SOCKET_URL);
    },60000)
  }
  if(enbaleSocketIo === 'true'){
    useEffect(() => {
      setCountnotif(countnotif + 1);
      setNotif([newnotifprofile, ...datanotif]);
    }, [newnotifprofile]);
  
    useEffect(() => {
      setCountnotif(countnotif + 1);
      setNotif([newnotifitem, ...datanotif]);
    }, [newnotifitem]);
  
    useEffect(() => {
      setCountnotif(countnotif + 1);
      setNotif([newnotifcollection, ...datanotif]);
    }, [newnotifcollection]);
  
    useEffect(() => {
      setCountnotifMsg(countnotifMsg + 1);
  
      setDataMsg([newnotifoffer, ...dataMsg]);
    }, [newnotifoffer]);
  }
  
  useEffect(() => {
    
  
     // get all notifications user

    fetchDataNotif(true);
    fetchDataMsg(true);
    if(enbaleSocketIo === 'true'){
      setTimeout(() => {
      // socket user profile
      
      socket.on("connect", function() {
        socket.emit("join", { erd_address: address });
      });
     },60000)
  
      const timer = setInterval(() => {
  
      socket.on("profile", (data: any) => {
        setNewnotifprofile(data);
      });
  
      // socket items
  
      socket.on("item", (data: any) => {
        setNewnotifitem(data);
      });
  
      // socket offers
  
      socket.on("offers", (data: any) => {
        setNewnotifoffer(data);
      });
  
      // socket gifts
      socket.on("gift", (data: any) => {
        setNewnotifoffer(data);
      });
  
      // socket collection
  
      socket.on("collection", (data: any) => {
        setNewnotifcollection(data);
      }); 
      
      }, 60000);
      
      setCountnotif(0);
      setCountnotifMsg(0);
      return () => {
        clearTimeout(timer);
        socket.close();
      }
    }

    
  }, []);

  const fetchDataNotif = (filter: boolean) => {
    if (filter === false) {
      setOffset(10);
    }
    if (!address) {
      setItem("goToUrl", window.location.href);
      navigate("/auth");
    } else {
      instanceOfAxios
        .get(`events/${address}?first=10&offset=${filter ? offset : 0}`)
        .then(function(res) {
          let objData = datanotif;
          res.data.result.forEach((element: any) => {
            objData.push(element);
          });

          if (filter === true) {
            setNotif([...objData]);
          } else {
            setNotif(res.data.result);
          }

          setDataload(res.data.pageInfo.hasNextPage);

          if (res.data.pageInfo.hasNextPage === true && filter === true) {
            setOffset(offset + 10);
          }
        });
    }
  };

  const fetchDataMsg = (filter: boolean) => {
    if (filter === false) {
      setOffset(10);
    }
    if (!address) {
      setItem("goToUrl", window.location.href);
      navigate("/auth");
    } else {
      instanceOfAxios
        .get(`messages/${address}?first=10&offset=${filter ? offsetMsg : 0}`)
        .then(function(res) {
          let objData = dataMsg;

          res.data.result.forEach((element: any) => {
            objData.push(element);
          });

          if (filter === true) {
            setDataMsg([...objData]);
          } else {
            setDataMsg(res.data.result);
          }

          setDataloadMsg(res.data.pageInfo.hasNextPage);
          if (res.data.pageInfo.hasNextPage === true && filter === true) {
            setOffsetMsg(offsetMsg + 10);
          }
        });
    }
  };

  const SocialIconLink = (icon: string, type: string) => {
    if (type === "msg") {
      return (
        <Notification
          datanotif={dataMsg}
          notifType={type}
          dataLoad={dataLoadMsg}
          DataNotif={fetchDataMsg}>
          <div className="msg-notif-navbar" onClick={() => setCountnotifMsg(0)}>
            <Badge size="small" count={countnotifMsg}>
              <img alt="notifIcon" src={icon} className="icon-notif-msg" />
            </Badge>
          </div>
        </Notification>
      );
    } else {
      return (
        <>
          <Notification
            datanotif={datanotif}
            notifType={type}
            dataLoad={dataLoad}
            DataNotif={fetchDataNotif}>
            <div className="msg-notif-navbar" onClick={() => setCountnotif(0)}>
              <Badge size="small" count={countnotif}>
                <img alt="iconMsg" src={icon} className="icon-notif-msg" />
              </Badge>
            </div>
          </Notification>
        </>
      );
    }
  };

  return (
    <div className="icons-navbar-msg" style={{ cursor: "pointer" }}>
      {/* <Tooltip title={t("common.coming-soon")}> */}
      {SocialIconLink(Message, "msg")}
      {/* </Tooltip> */}
      {SocialIconLink(NotifImg, "notif")}
    </div>
  );
};

export default NotifIcons;
