import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import likeButton from '../../../../assets/images/filledLikeButton.svg';
import emptyHeart from '../../../../assets/images/emptyHeart.svg';
import instanceOfAxios from '../../../../configAxios';
import { useContext, useDispatch } from '../../../context';
import { setItem } from '../../../storage/session';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useGlobalContext } from '../../../context/globalContext';
import { getItemFromLocalStorage } from '../../../helpers/useLocalStorage';
import { OpenErrorNotification } from '../../../components/toasts/openToast';
import { useTranslation } from 'react-i18next';

export interface LikeButtonProps {
	liked?: boolean;
	itemId: number;
	className: string;
}

const LikeButton = observer(({ liked, itemId, className }: LikeButtonProps) => {
	const [ likedItem, setLikedItem ] = useState(liked);
	const { address } = useContext();
	const { likesStore } = useGlobalContext();
	const  navigate = useNavigate();
	const dispatch = useDispatch();
	const [ likeLoading, setLikeLoading ] = useState(false);
	const { t } = useTranslation();

	useEffect(
		() => {
			setLikedItem(liked);
		},
		[ liked ]
	);

	const likeItem = () => {
		if (address) {
			setLikeLoading(true);
			instanceOfAxios
				.post(`favorite_items/item/${itemId}/address/${address}`,{},{
					headers: {
					  "x-access-token": getItemFromLocalStorage("token"),
					},
				  })
				.then((res: any) => {
					dispatch({ type: 'actionLike', idItem: itemId });
					likesStore.setIsLiked(!likesStore.isLiked);
					setLikedItem(true);
					setLikeLoading(false);
				})
				.catch((err: any) => {
					if(err?.response.status===401){
						OpenErrorNotification(
						  t("profile.error"),
						  t("profile.error-unauthorized-user"),
						  3
						);                    
					  }else{
						OpenErrorNotification(
						  t("profile.error"),
						  t("profile.error-desc"),
						  3
						);
					  }
					setLikeLoading(false);
				});
		} else {
			setItem('goToUrl', window.location.href);
			navigate('/auth');
		}
	};

	const dislike = () => {
		if (address) {
			setLikeLoading(true);
			instanceOfAxios
				.delete(`favorite_items/item/${itemId}/address/${address}`,{
					headers: {
					  "x-access-token": getItemFromLocalStorage("token"),
					},
				  })
				.then((res: any) => {
					dispatch({ type: 'actionLike', idItem: itemId });
					likesStore.setIsLiked(!likesStore.isLiked);
					setLikedItem(false);
					setLikeLoading(false);
				})
				.catch((err: any) => {
					if(err?.response.status===401){
						OpenErrorNotification(
						  t("profile.error"),
						  t("profile.error-unauthorized-user"),
						  3
						);                    
					  }else{
						OpenErrorNotification(
						  t("profile.error"),
						  t("profile.error-desc"),
						  3
						);
					  }
					setLikeLoading(false);
				});
		} else {
			setItem('goToUrl', window.location.href);
			navigate('/auth');
		}
	};

	return (
		<div className={className} onClick={likedItem ? dislike : likeItem}>
			{likeLoading ? (
				<div className="filled-heart">
					<div className="empty-container" />
					<Spin className="empty-heart" />
				</div>
			) : likedItem ? (
				<img alt="likeIcon" src={likeButton} className="filled-heart" />
			) : (
				<div className="filled-heart">
					<div className="empty-container" />
					<img alt="emptyHeart" src={emptyHeart} className="empty-heart" />
				</div>
			)}
		</div>
	);
});

export default LikeButton;
