import useImageDisplay from "../../helpers/useImageDisplay";

interface CustomImageProps {
    image: string
    extensionImage: any
    className: string
    id?:any
}

const CustomImage = ({ image, className, id, extensionImage }: CustomImageProps) => {
    const displayImg = useImageDisplay(image, '__t'+extensionImage);
    if (image)
        return (
            <img src={displayImg} className={className} id={id} alt="avatar"/>
        ); else
        return <></>
}

export default CustomImage;