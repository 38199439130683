import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface MenuObject {
  name: string;
  link: string;
  isReady: boolean;
}

export interface FooterMenuProps {
  title?: string;
  menu: MenuObject[];
}

const FooterMenu = ({ title, menu }: FooterMenuProps) => {
  const { t } = useTranslation();

  return (
    <div className="footer-menu-holder">
      <h4 className="footer-title">{title}</h4>
      <ul className="footer-menu">
        {menu.map((el, key: number) => {
          if (el.isReady) {
            return (
              <li key={key}>
                <Link className="footer-menu-item" to={el.link}>
                  {el.name}
                </Link>
              </li>
            );
          } else {
            return (
              <Tooltip
                key={key + 1}
                title={t("common.coming-soon")}
                align={{ offset: [0, 15] }}
                placement="topLeft"
              >
                <li>
                  <Link className="footer-menu-item disabled-link" to="/">
                    {el.name}
                  </Link>
                </li>
              </Tooltip>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default FooterMenu;
