import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import "./userDesc.less";

export interface UserDescProps {
  avatar: string;
  title: string;
  username: string;
  userId: number;
}

const UserDesc = ({ avatar, title, username, userId }: UserDescProps) => {
  const navigate = useNavigate();

  const redirectToProfile = () => {
    navigate(`/profile-details/${userId}`);
  };

  return (
    <div onClick={redirectToProfile}>
      <a
        href={`/profile-details/${userId}`}
        onClick={(e) => {
          e.preventDefault();
        }}
        className="user-container"
      >
        <Avatar icon={<img alt={`${username}`} src={avatar} />} />
        <div className="user-container-text">
          <h6 className="text-regular-font">{title}</h6>
          <h6 className="primary-color">{username}</h6>
        </div>
      </a>
    </div>
  );
};

export default UserDesc;
