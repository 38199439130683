import * as React from "react";
import { Skeleton } from "antd";
import "./recentItem.less";



const LoadingItem = () => {
  return (

    <div className="item-container">
      <div className="loading-item-image-container">
        <Skeleton.Image
          style={{ width: "250px", height: "calc(200px + 15vh)" }}
        />
      </div>

      <div className="loading-item-description">
        <Skeleton avatar active paragraph={{ rows: 1 }} />
      </div>
    </div>
    
    
  );
};

export default LoadingItem;
