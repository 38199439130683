import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  sendTransactions,
} from "@multiversx/sdk-dapp/services";
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account'
import { useGetAccountInfo, useGetIsLoggedIn, useGetSuccessfulTransactions } from '@multiversx/sdk-dapp/hooks'
import { logout } from '@multiversx/sdk-dapp/utils'
import { useNavigate } from "react-router-dom";
import brandImage from "../../../../assets/images/cryptoAnimalBrand.png";
import rarityLogo from "../../../../assets/images/rarityLogo.svg";
import "./cryptoAnimal.less";
import { useContext, useDispatch } from "../../../context";
import { setItem } from "../../../storage/session";
import { message, Slider } from "antd";
import instanceOfAxios from "../../../../configAxios";
import LandingButton from "../../../components/buttons/landingButton";
import { getTransactionsByTxHash } from "../../elrond transactions/apiRequests";
import { network } from "../../../config/configGlobal";
import { saveTransactionHistoryApiCall } from "../../elrond transactions/transactionsServices";
import { ITransactionHistory } from "../../elrond transactions/transaction.interface";
import { OpenErrorNotification } from "../../../components/toasts/openToast";

interface ICustomTransaction {
  receiver: any,
  gasLimit: any,
  value: number,
  data: any,
}


const CryptoAnimal = () => {
  const { t } = useTranslation();
  const { address } = useContext();
  const dispatch = useDispatch();
  const  navigate = useNavigate();
  const /*transactionSessionId*/[, setTransactionSessionId] = useState<
    string | null
  >(null);
  // const { sendTransactions } = transactionServices;
  const {
    successfulTransactionsArray,
  } = useGetSuccessfulTransactions();
  const { account } = useGetAccountInfo();
  const isLoggedIn = useGetIsLoggedIn();
  const [customtransaction, setCustomtransaction] = useState<ICustomTransaction>({
    receiver: null,
    gasLimit: null,
    value: 0,
    data: null,
  });
  const [price, setPrice] = useState(0.5);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1)

  useEffect(() => {
    if (isLoggedIn) {
      instanceOfAxios
        .get("generic_transaction/100")
        .then(async (res: any) => {
          setPrice(res?.data?.value);
          setLoading(true)
          setCustomtransaction({
            receiver: res?.data?.receiver,
            gasLimit: res?.data?.gas_limit,
            value: res?.data?.value,
            data: res?.data?.data_example,
          });
          setLoading(false)

        })
        .catch((err: any) => {
          if (err?.response.status === 401) {
            OpenErrorNotification(
              t("profile.error"),
              t("profile.error-unauthorized-user"),
              3
            );
          } else {
            OpenErrorNotification(
              t("profile.error"),
              t("profile.error-desc"),
              3
            );
          }
          return "failed call for register grant";
        });
    }
  }, [isLoggedIn]);

  const connect = () => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    } else {
      logout(`${window.location.origin}${window.location.pathname}`);
      localStorage.clear()
    }
  };

  const mint = async () => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    } else {
      if (Number(account?.balance) < (quantity * price)) {
        message.error(t("action-box.not-enough-balance"));
      } else {
        await refreshAccount();
        const { sessionId /*, error*/ } = await sendTransactions({
          transactions: customtransaction,
          transactionsDisplayInfo: {
            processingMessage: t("action-box.processing-cybears-transaction"),
            errorMessage: t("action-box.cybears-transaction-error"),
            successMessage: t("action-box.cybears-transaction-success"),
          },
          redirectAfterSign: false,
        });
        if (sessionId !== null) {
          setTransactionSessionId(sessionId);
          localStorage.setItem("mint-crypton-sessionId", sessionId);
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("mint-crypton-sessionId")) {
      successfulTransactionsArray.map((item) => {
        if (item[0] === localStorage.getItem("mint-crypton-sessionId")) {
          if (item[1]?.transactions[0]?.hash) {
            saveInTrasnactionHistory(item[1]?.transactions[0]?.hash);
            return;
          }
        }
      });
    }
  }, [successfulTransactionsArray]);

  const saveInTrasnactionHistory = async (hash: string) => {
    localStorage.removeItem("mint-crypton-sessionId");
    const responseTx = await getTransactionsByTxHash(
      network.gatewayAddress,
      hash
    );

    let transaction: ITransactionHistory = {
      address,
      hash,
      data: responseTx?.data?.data?.transaction.data,
      gasLimit: responseTx?.data?.data?.transaction.gasLimit,
      gasPrice: responseTx?.data?.data?.transaction.gasPrice,
      nonce: responseTx?.data?.data?.transaction.nonce,
      reciever: responseTx?.data?.data?.transaction.receiver,
      status: responseTx?.data?.data?.transaction.status,
      value: responseTx?.data?.data?.transaction.value,
      smartContractResults1_data: responseTx?.data?.data?.transaction.data,
    };
    await saveTransactionHistoryApiCall(transaction);
  };

  const getQuantity = (value: any) => {
    setQuantity(value)
    customtransaction.value = price * value;
  }

  return (
    <div>
      <div className="crypto-animal-container">
        <div className="landing-btn-container">
          <LandingButton
            text={
              address
                ? t("action-box.disconnect")
                : t("action-box.connect-back")
            }
            onClick={connect}
          />
        </div>
        <div className="crypto-animal-content ">
          <div className="crypto-animal-part">
            <img className="brand-img" src={brandImage} alt="CyBears Logo" />
          </div>

          <div className="crypto-animal-part">
            <div className="card custom-crypto-animal-card">
              <div className="card-body">
                <div>
                  <h4
                    className="card-title"
                    style={{
                      color: "white",
                      paddingBottom: "20px",
                    }}>
                    {t("landing-pages.crypto-animal.title")}
                  </h4>
                </div>
                <h5 className=" text-regular-font custom-a-styling">
                  {t("landing-pages.crypto-animal.desc-1")}{" "}
                  <a
                    className="crypto-animal-link"
                    href="https://crypto-animals.io/">
                    crypto-animals.io!
                  </a>
                </h5>

                <h5 className=" text-regular-font custom-a-styling">
                  {t("landing-pages.crypto-animal.desc-2")}{" "}
                  <a href="https://rarity.market/cybears">
                    <img
                      className=" align-middle"
                      src={rarityLogo}
                      alt="Icon"
                      style={{ height: "20px" }}
                    />
                  </a>
                  &nbsp;: 1.000
                </h5>

                <h5 className=" text-regular-font">
                  {t("landing-pages.crypto-animal.desc-3")} 10.000
                </h5>

                <h5 className=" text-regular-font">
                  {`${t("landing-pages.crypto-animal.desc-4")} ${price}`} EGLD
                </h5>
                <div className="slider-mint">
                  <div>
                    <Slider
                      className="slider"
                      onAfterChange={(value) => getQuantity(value)}
                      defaultValue={1}
                      max={50}
                      min={1}
                      step={1}
                      disabled={loading}
                    />
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span style={{ fontSize: "10px" }}>1</span>
                    <span style={{ fontSize: "10px" }}>50</span>
                  </div>
                </div>
                <div className="crypto-animal-btn-container custom-a-styling">
                  <a
                    href="#"
                    className="btn btn-hero crypto-animals-btn"
                    style={{ color: "white" }}
                    onClick={mint}>
                    {address
                      ? t("landing-pages.crypto-animal.button", { quantity })
                      : t("landing-pages.crypto-animal.button-not-connected")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoAnimal;
