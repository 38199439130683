import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabel from "./table";
import "./activity.less";
import instanceOfAxios from "../../../configAxios";
import { useContext } from "../../context";
import CustomImage from "../../components/image/customImage";
import OptionSelect from "../../components/option/optionSelect";
import { useGlobalContext } from "../../context/globalContext";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

const Activity=observer(()=> {
  const pageItemsNumber = 20;
  const { t } = useTranslation();
  const [offset, setOffset] = useState<number>(0);
  const [offsetCollection, setOffsetcollection] = useState<number>(0);
  const { address } = useContext();
  const { activityStore } = useGlobalContext();

  const assert = (text: any, count: any) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  };

  const handleShowMore = (
    filter: boolean,
    Collections: string,
    events: string,
    usersFilter: any
  ) => {
    instanceOfAxios
      .post(
        `activity${
          address ? `?erd_address=${address}&` : "?"
        }first=${pageItemsNumber}&offset=${filter ? offset : 0}${
          activityStore.valueCollection !== "" && activityStore.valueCollection !== null ? Collections : ""
        }`,
        {
          users: [usersFilter],
          event: events,
        }
      )
      .then(function(res) {
        let result = res.data.nodes;

        if (!filter) {
          activityStore.setData(result);
        } else {
          let concatArray:any[]
          concatArray=  activityStore.data.concat(result);        
          activityStore.setData(concatArray)          
        }
        activityStore.setDataload(res.data.pageInfo.hasNextPage);

        if (res.data.pageInfo.hasNextPage && filter) {
          setOffset(offset + pageItemsNumber);
        } else {
          setOffset(pageItemsNumber);
        }
      });
  };

  const handleGetCollections = (filter: boolean) => {
    instanceOfAxios
      .get(
        `/filter-collection${
          address ? `?erd_address=${address}&` : "?"
        }&first=${pageItemsNumber}&offset=${filter ? offsetCollection : 0}`
      )
      .then(function(res) {
        let result = res.data.nodes;

        let objData = activityStore.dataCollection;

        result.forEach((element: any) => {
          let structureResult = {
            label: (
              <>
                <CustomImage
                  image={element?.logo_image}
                  extensionImage={element?.logo_image_ext}
                  id={element?.id}
                  className={"img-collection"}
                />
                <span className="collection-text">
                  {assert(element.collection_name, 13)}
                </span>
              </>
            ),
            value: element.id,
          };

          objData.push(structureResult);
        });

        if (filter) {
          activityStore.setDataCollection(objData);
        } else {
          activityStore.setDataCollection([...objData]);
        }
        activityStore.setDataloadcollection(res.data.pageInfo.hasNextPage);
        if (res.data.pageInfo.hasNextPage === true) {
          setOffsetcollection(offsetCollection + pageItemsNumber);
        } else {
          setOffsetcollection(pageItemsNumber);
        }
      });
  };

  useEffect(() => {
    if(activityStore.data.length===0){
      handleShowMore(false, activityStore.valueCollection, activityStore.eventFilter, activityStore.listUsers);
    }  
    if(activityStore.dataCollection.length===0){
      handleGetCollections(false);
    }
  }, []);  

  useEffect(() => {
    if (activityStore.listColllection !== null && activityStore.listColllection !== "") {
      let col = activityStore.listColllection.map((el: any) => {
        return `&collection=${el}`;
      });
      activityStore.setValueCollection(col.join(""));
    }
  }, [activityStore.listColllection]);

  useEffect(() => {
    if (activityStore.isNotCalled) {
      handleShowMore(false, activityStore.valueCollection, activityStore.eventFilter, activityStore.listUsers);
    activityStore.setIsNotCalled(false);

    }
  }, [activityStore.isNotCalled]);


  // scroll function event listner
  const handleScroll = () => {
    const position = window.pageYOffset;
    activityStore.setScrollPosition(position);
  };


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activityStore.scrollPosition]);
  
  useEffect(() => {
    window.scrollTo(0,activityStore.scrollPosition)
    }, [activityStore.scrollPosition])

  const users = [
    {
      label: t("All"),
      value: t("All"),
    },
    {
      label: t("activity.me"),
      value: "me",
    },
    // {
    //   label:t("activity.friends"),
    //   value: "friends",
    // },
    // {
    //   label:t("activity.follower"),
    //   value: "follower",
    // },
  ];

  const events = [
    {
      label: t("activity.sales"),
      value: "sales",
    },
    {
      label: t("activity.bids"),
      value: "bids",
    },
    {
      label: t("activity.list"),
      value: "list",
    },
    {
      label: t("activity.offers"),
      value: "offers",
    },
    {
      label: t("activity.transfers"),
      value: "transfers",
    },
    {
      label: t("activity.create"),
      value: "create",
    },
  ];

  return (
    <>
     <Helmet>
      <title>Rarity.market | Activity</title>
      <meta
          name="keywords"
          content="NFT, marketplace, activity, bid, creation, auction, item, art, digital, elrond"
        />
         <meta
          name="description"
          content="Browse current activity on the Rarity marketplace"
        />
          <meta content="Rarity.market | Activity" property="og:title" />
          <meta property="og:url" content="https://rarity.market/activity" />
          <meta
          content="Browse current activity on the Rarity marketplace"
          property="og:description"
        />
         <meta
          content="https://rarity.market/static/media/rarityLogo.6556aa1d.svg"
          property="og:image"
        />
             <meta content="Rarity.market | Activity" property="twitter:title" />
             <meta property="twitter:url" content="https://rarity.market/activity" />
             <meta
          content="Browse current activity on the Rarity marketplace"
          property="twitter:description"
        />
           <meta
          content="https://rarity.market/static/media/rarityLogo.6556aa1d.svg"
          property="twitter:image"
        />
     </Helmet>
      <div>
        <style>
          {
            "\
        .ant-select-dropdown {\
          position: fixed!important;\
          z-index: 10 !important;\
        }\
        "
          }
        </style>

        <div className="activity-main ">
          <div className="activity-filter">
            <div style={{ display: "grid" }}>
              <div>
                {" "}
                <span className="link-home-activity ">
                  <a href="/" className="breadcrumbPath">
                    {t("Home")}
                  </a>{" "}
                  {">"}
                  <span className="primary-color link-home-activity ">
                    {t("common.activity")}
                  </span>{" "}
                </span>
              </div>
              <div className="title-block-activity-page">
                <h1>{t("activity.Current market activity")}</h1>{" "}
              </div>
            </div>

            <div className="selectors-select">
              <div className="optionSelect">
                <span className="activity-select">
                  {t("activity.select-title-events")}
                </span>
                {/* event Filters */}
                <OptionSelect
                  data={events}
                  multiple={true}
                  keyTraduction="activity"
                  filterOption={activityStore.eventFilter}
                  setFilterOption={activityStore.setEventFilter}
                />
              </div>
              <div className="optionSelect">
                <span className="activity-select">
                  {t("common.collection")}
                </span>
                <OptionSelect
                  data={activityStore.dataCollection}
                  multiple={true}
                  keyTraduction="activity"
                  loadMore={handleGetCollections}
                  moreData={activityStore.dataLoadCollection}
                  filterOption={activityStore.listColllection}
                  setFilterOption={activityStore.setListColllection}
                  isNotUsingTranslation={true}
                />
              </div>
              {address ? (
                <div className="optionSelect">
                  <span className="activity-select">
                    {t("activity.select-title-users")}
                  </span>
                  <OptionSelect
                    data={users}
                    multiple={false}
                    keyTraduction="activity"
                    filterOption={activityStore.listUsers}
                    setFilterOption={activityStore.setListUsers}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div>
          <Tabel
            data={activityStore.data}
            valueCol={activityStore.valueCollection}
            eventFilter={activityStore.eventFilter}
            listUsers={activityStore.listUsers}
            handleShowMore={handleShowMore}
            dataLoad={activityStore.dataLoad}
          />
        </div>
      </div>
    </>
  );
});
export default Activity;
