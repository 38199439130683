import { useEffect, useState } from "react";
import { Select, Space } from "antd";
import { Checkbox } from "antd";
import { Radio } from 'antd';
import { useTranslation } from "react-i18next";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;

interface Props {
  data: any[];
  multiple: boolean;
  filterOption:any
  setFilterOption: any;
  handleGetCollections?: any;
  dataLoadCollection?: any;
  selectCollection?: any;

}

const OptionSelect = ({ data, filterOption, setFilterOption, multiple }: Props) => {
  const { t } = useTranslation();
  const [checkedListCol, setCheckedListCol] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const onChangeCheckbox = (e: any) => {
    setFilterOption("");
    setCheckedListCol(
      e.target.checked ? data.map((el) => (el.value ? el.value : null)) : []
    );

    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChange = async (list: any) => {
    setCheckedListCol(list);
    setIndeterminate(!!list.length && list.length < data.length);
    setCheckAll(list.length === data.length);
  };

  const onChangeRadio = (list: any) => {
    if (list.target.value !== `${t("All")}`) {
      setFilterOption(list.target.value);
    } else {
      setFilterOption("");
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Select
        mode="multiple"
        placeholder={t("All")}
        value={ (filterOption !== "")&&(filterOption !== null) ?  t(`message.${filterOption}`) : [] }
        maxTagCount="responsive"
        showArrow={true}
        className="checkbox-activity"
        showSearch={false}
      >
        {(multiple === true) ?
          <>
            <Option value=''>
              <Checkbox
                value={t("All")}
                indeterminate={indeterminate}
                onChange={onChangeCheckbox}
                checked={checkAll}
              >
                {t("All")}
              </Checkbox>
            </Option>
            <Option value="">
              <CheckboxGroup
                options={data}
                value={checkedListCol}
                onChange={onChange}
              />
            </Option>
          </>
          : <Option value=""  >
              <RadioGroup name="radiogroup" defaultValue={"All"} onChange={onChangeRadio}>
                <Space direction="vertical">
                  {
                    data.map((el) => {
                      return <Radio value={el.value}>{el.label}</Radio>
                    })
                  }
                </Space>
              </RadioGroup>
            </Option>
        }

      </Select>
    </Space>
  );
};

export default OptionSelect;
