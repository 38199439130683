import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { DeleteFilled } from "@ant-design/icons";
import {
    Modal,
    Spin,
    Tooltip,
    message,
    Button,
    Radio,
    RadioChangeEvent,
    Space,
} from "antd";
import screenfull from "screenfull";

import ICAUDIO from "../../../../../assets/images/ic-add-audio.svg";
import ICUNLOCkAUDIO from "../../../../../assets/images/ic-unlock-audio.svg";
import ICVIDEO from "../../../../../assets/images/ic-add-video.svg";
import ICUNLOCKVIDEO from "../../../../../assets/images/ic-unlock-video.svg";
import IC3D from "../../../../../assets/images/ic-add-3d.svg";
import ICUNLOCK3D from "../../../../../assets/images/ic-unlock-3d.svg";
import ICPHOTO from "../../../../../assets/images/ic-image.svg";
import ICLOCK from "../../../../../assets/images/ic-lock.svg";
import ICPLAY from "../../../../../assets/images/ic-audio.png";
import ICPAUSE from "../../../../../assets/images/ic-pause-button.png";

import LikeButton from "../../../home/components/likeButton";
import ImageUpload from "../upload image/imageUpload/imageUpload";
import useImageDisplay from "../../../../helpers/useImageDisplay";
import instanceOfAxios from "../../../../../configAxios";
import { useContext } from "../../../../context";
import ZoomableImage from "../zoomableImage/zoomableImage";
import { useGlobalContext } from "../../../../context/globalContext";
import ItemExtraFile from "../itemExtraFile/itemExtraFile";
import "./itemPhotos.less";
import { observer } from "mobx-react-lite";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { useTranslation } from "react-i18next";
import { refreshAccount } from "@multiversx/sdk-dapp/utils/account";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account";
import { useGetSuccessfulTransactions } from "@multiversx/sdk-dapp/hooks/transactions";
import {
    OpenErrorNotification,
    OpenSuccessNotification,
} from "../../../../components/toasts/openToast";
import { ITransactionHistory } from "../../../elrond transactions/transaction.interface";
import { getTransactionsByTxHash } from "../../../elrond transactions/apiRequests";
import { network } from "../../../../config/configGlobal";
import { saveTransactionHistoryApiCall } from "../../../elrond transactions/transactionsServices";
import ReactPlayer from "react-player";
import PrivewItemDetails from "../itemExtraFile/privewItemDetails";
import { getItemFromLocalStorage } from "../../../../helpers/useLocalStorage";

interface ItemPhotosProps {
    display: boolean;
    itemsDetails?: any;
    photosArray: any[];
    setPhotosArray?: any;
    croppedArray?: any;
    setCropped?: any;
    originalFiles?: any;
    setOriginalFiles?: any;
    setPhotosChanged?: any;
    setButtonDisabled?: any;
}

const ItemPhotos = observer(
    ({
        display,
        itemsDetails,
        photosArray,
        setPhotosArray,
        croppedArray,
        setCropped,
        originalFiles,
        setOriginalFiles,
        setPhotosChanged,
        setButtonDisabled,
    }: ItemPhotosProps) => {
        const { address } = useContext();
        const { itemFormStore } = useGlobalContext();
        const [displayPhoto, setDisplayPhoto] = useState("");
        const [displayKeepRelation, setDisplayKeepRelation] = useState(false);
        const [newClicked, setNewClicked] = useState(true);
        const [canFetchOriginal, setCanFetchOriginal] = useState(false);
        const [metaDataArray, setMetaDataArray] = useState<string[]>([]);
        const [photosLoading, setPhotosLoading] = useState(false);
        const [openUnlockModal, setOpenUnlockModal] = useState(false);
        const [openUnlockImagesModal, setOpenUnlockImagesModal] =
            useState(false);
        const [itemIcon, setItemIcon] = useState<any>(ICPHOTO);
        const [openDiscardModal, setOpenDiscardModal] = useState(false);
        const [extraFile, setExtraFile] = useState<any>(null);
        const [dataExtraFile, setDataExtraFile] = useState<any>({});
        const { itemDetailsStore } = useGlobalContext();
        const { t } = useTranslation();
        const [sessionIdTransaction, setSessionIdTransaction] = useState(0);
        const audioRef = useRef<any>(null);
        const videoPlayerRef = useRef<any>(null);
        const [isPlayed, setIsPlayed] = useState(false);
        const [playVideo, setPlayVideo] = useState<boolean>(false);
        const [value, setValue] = useState(3);
        const [loadEnd, setLoadEnd] = useState<boolean>(true);
        const { successfulTransactionsArray } = useGetSuccessfulTransactions();
        const { account } = useGetAccountInfo();

        const uri0 = useImageDisplay(
            itemsDetails?.uri,
            "__m" + itemsDetails?.uri_ext
        );
        const uri1 = useImageDisplay(
            itemsDetails?.uri1,
            "__m" + itemsDetails?.uri1_ext
        );
        const uri2 = useImageDisplay(
            itemsDetails?.uri2,
            "__m" + itemsDetails?.uri2_ext
        );
        const uri3 = useImageDisplay(
            itemsDetails?.uri3,
            "__m" + itemsDetails?.uri3_ext
        );

        const convertToFile = async (
            url: string,
            name: string,
            type: string
        ) => {
            const response = await fetch(url);

            const data = await response.blob();

            const f = new File([data], name, {
                type: response.headers.get("content-type") || type,
            });

            return f;
        };

        // get the config transaction for extra feature files

        const getConfigTransaction = (idExtraFeature: number) => {
            instanceOfAxios
                .get(`generic_transaction/1?extra_feature=${idExtraFeature}`)
                .then((res: any) => {
                    setDataExtraFile(res?.data);
                })
                .catch((err: any) => {
                    return "failed call for register grant";
                });
        };

        const saveInTrasnactionHistory = async (hash: string) => {
            localStorage.removeItem("extra-file-sessionId");
            localStorage.removeItem("extra-file-id");
            const responseTx = await getTransactionsByTxHash(
                network.gatewayAddress,
                hash
            );

            let transaction: ITransactionHistory = {
                address,
                hash,
                data: responseTx?.data?.data?.transaction.data,
                gasLimit: responseTx?.data?.data?.transaction.gasLimit,
                gasPrice: responseTx?.data?.data?.transaction.gasPrice,
                nonce: responseTx?.data?.data?.transaction.nonce,
                reciever: responseTx?.data?.data?.transaction.receiver,
                status: responseTx?.data?.data?.transaction.status,
                value: responseTx?.data?.data?.transaction.value,
                smartContractResults1_data:
                    responseTx?.data?.data?.transaction.data,
            };
            await saveTransactionHistoryApiCall(transaction);
        };
        const unlockExtraFeature = async (
            fee: number,
            feeUnit: string,
            feeTx: string,
            profileId: number,
            extraFeatureId: number,
            collectionId: number
        ) => {
            instanceOfAxios
                .post(
                    `extra_feature_booking/address/${address}`,
                    {
                        fee,
                        fee_unit: feeUnit,
                        fee_tx: feeTx,
                        profile_id: profileId,
                        extra_feature_id: extraFeatureId,
                        object: collectionId,
                    },
                    {
                        headers: {
                            "x-access-token": getItemFromLocalStorage("token"),
                        },
                    }
                )
                .then((res: any) => {
                    saveInTrasnactionHistory(feeTx);
                    instanceOfAxios
                        .get(
                            `collections/${
                                itemFormStore.collectionData.id
                            }?profile_id=${
                                JSON.parse(localStorage.getItem("user") || "{}")
                                    ?.id
                            }`
                        )
                        .then((res) => {
                            itemFormStore.setCollectionData(res?.data);
                        })
                        .catch((error) => {
                            return error;
                        });
                    OpenSuccessNotification(
                        t("profile.success"),
                        t(
                            "transaction-message.processing-transaction-extra-success"
                        ),
                        3
                    );
                })
                .catch((err: any) => {
                    OpenErrorNotification(
                        t("profile.error"),
                        t("profile.error-desc"),
                        3
                    );
                });
        };
        useEffect(() => {
            //this useEffect is responsible for converting the src of the images to files
            //so we will have an array of the cropped files
            if (!display && uri0) {
                setDisplayPhoto(uri0);
                setNewClicked(false);

                let array: any[] = [];
                setCanFetchOriginal(true);
                const uri0promise = new Promise(async (resolve, reject) => {
                    console.log("uri0 ========>", uri0);
                    await convertToFile(
                        uri0,
                        metaDataArray[0]
                            ? JSON.parse(metaDataArray[0])?.name
                            : "uri",
                        metaDataArray[0]
                            ? JSON.parse(metaDataArray[0])?.type
                            : "image/jpeg"
                    ).then((value: any) => {
                        //necessary for the metadata safe saving
                        //we read it from the metadata from the api result
                        value.imgWidth = JSON.parse(metaDataArray[0])?.width;
                        value.imgHeight = JSON.parse(metaDataArray[0])?.height;
                        array.push(value);
                    });
                    resolve(array);
                });
                uri0promise.then((res: any) => {
                    if (uri1) {
                        const uri1promise = new Promise(
                            async (resolve, reject) => {
                                await convertToFile(
                                    uri1,
                                    metaDataArray[1]
                                        ? JSON.parse(metaDataArray[1])?.name
                                        : "uri1",
                                    metaDataArray[1]
                                        ? JSON.parse(metaDataArray[1])?.type
                                        : "image/jpeg"
                                ).then((value: any) => {
                                    value.imgWidth = JSON.parse(
                                        metaDataArray[1]
                                    )?.width;
                                    value.imgHeight = JSON.parse(
                                        metaDataArray[1]
                                    )?.height;
                                    res.push(value);
                                });
                                resolve(res);
                            }
                        );
                        uri1promise.then((res: any) => {
                            if (uri2) {
                                const uri2promise = new Promise(
                                    async (resolve, reject) => {
                                        await convertToFile(
                                            uri2,
                                            metaDataArray[2]
                                                ? JSON.parse(metaDataArray[2])
                                                      ?.name
                                                : "uri2",
                                            metaDataArray[2]
                                                ? JSON.parse(metaDataArray[2])
                                                      ?.type
                                                : "image/jpeg"
                                        ).then((value: any) => {
                                            value.imgWidth = JSON.parse(
                                                metaDataArray[2]
                                            )?.width;
                                            value.imgHeight = JSON.parse(
                                                metaDataArray[2]
                                            )?.height;
                                            res.push(value);
                                        });
                                        resolve(res);
                                    }
                                );
                                uri2promise.then((res: any) => {
                                    if (uri3) {
                                        const uri3promise = new Promise(
                                            async (resolve, reject) => {
                                                await convertToFile(
                                                    uri3,
                                                    metaDataArray[3]
                                                        ? JSON.parse(
                                                              metaDataArray[3]
                                                          )?.name
                                                        : "uri3",
                                                    metaDataArray[3]
                                                        ? JSON.parse(
                                                              metaDataArray[3]
                                                          )?.type
                                                        : "image/jpeg"
                                                ).then((value: any) => {
                                                    value.imgWidth = JSON.parse(
                                                        metaDataArray[3]
                                                    )?.width;
                                                    value.imgHeight =
                                                        JSON.parse(
                                                            metaDataArray[3]
                                                        )?.height;
                                                    res.push(value);
                                                });
                                                resolve(res);
                                            }
                                        );
                                        uri3promise.then((res: any) => {
                                            console.log(
                                                "res 1 ==========>",
                                                res
                                            );
                                            setCropped(res);
                                            setCanFetchOriginal(true);
                                        });
                                    } else {
                                        console.log("res 2 ==========>", res);
                                        setCropped(res);
                                        setCanFetchOriginal(true);
                                    }
                                });
                            } else {
                                console.log("res 3 ==========>", res);
                                setCropped(res);
                                setCanFetchOriginal(true);
                            }
                        });
                    } else {
                        //we save the array resulted from the promises => array of cropped files of the images
                        console.log("res 4 ==========>", res);
                        setCropped(res);
                        setCanFetchOriginal(true);
                    }
                });

                if (uri1 && uri2 && uri3) {
                    setPhotosArray([uri0, uri1, uri2, uri3]);
                } else if (uri1 && uri2) {
                    setPhotosArray([uri0, uri1, uri2]);
                } else if (uri1) {
                    setPhotosArray([uri0, uri1]);
                } else {
                    setPhotosArray([uri0]);
                }
            }
        }, [uri0]);

        useEffect(() => {
            if (canFetchOriginal === true) {
                //*****here the part for retrieving the original files => edit item
                instanceOfAxios
                    .get(
                        `generate_secret_link/item/${itemsDetails?.id}/address/${address}`,
                        {
                            headers: {
                                "x-access-token":
                                    getItemFromLocalStorage("token"),
                            },
                        }
                    )
                    .then((res: any) => {
                        setOriginalFiles([]);
                        //******************************************************************************** */
                        //******************************************************************************** */
                        let array: any[] = [];
                        const uri0promise = new Promise(
                            async (resolve, reject) => {
                                if (res?.data?.uri) {
                                    const response = fetch(
                                        `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_SERVER_API_ENDPOINT}/secret_file?token=${res?.data?.uri}`
                                    ).then(async (response) => {
                                        await setPhotosLoading(true);
                                        await setButtonDisabled(true);

                                        const data = await response.blob();
                                        const f = new File(
                                            [data],
                                            croppedArray[0]?.name,
                                            {
                                                type:
                                                    response.headers.get(
                                                        "content-type"
                                                    ) || res?.data?.type,
                                            }
                                        );
                                        await setOriginalFiles(
                                            (originalFiles: any) => [
                                                ...originalFiles,
                                                f,
                                            ]
                                        );
                                        console.log(
                                            "originalFiles     =>",
                                            originalFiles
                                        );
                                        array.push(f);
                                        resolve(array);
                                        await setPhotosLoading(true);
                                        await setButtonDisabled(true);
                                    });
                                } else {
                                    await setPhotosLoading(false);
                                    await setButtonDisabled(false);
                                    resolve(array);
                                }
                            }
                        );
                        uri0promise.then(async (result: any) => {
                            if (res?.data?.uri1) {
                                const uri1promise = new Promise(
                                    async (resolve, reject) => {
                                        const response = fetch(
                                            `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_SERVER_API_ENDPOINT}/secret_file?token=${res?.data?.uri1}`
                                        ).then(async (response) => {
                                            const data = await response.blob();
                                            const f = new File(
                                                [data],
                                                croppedArray[1]?.name,
                                                {
                                                    type:
                                                        response.headers.get(
                                                            "content-type"
                                                        ) || res?.data?.type,
                                                }
                                            );
                                            await setOriginalFiles(
                                                (originalFiles: any) => [
                                                    ...originalFiles,
                                                    f,
                                                ]
                                            );
                                            array.push(f);
                                            resolve(result);
                                        });
                                    }
                                );
                                uri1promise.then(async (result: any) => {
                                    if (res?.data?.uri2) {
                                        const uri2promise = new Promise(
                                            async (resolve, reject) => {
                                                const response = fetch(
                                                    `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_SERVER_API_ENDPOINT}/secret_file?token=${res?.data?.uri2}`
                                                ).then(async (response) => {
                                                    const data =
                                                        await response.blob();
                                                    const f = new File(
                                                        [data],
                                                        croppedArray[2]?.name,
                                                        {
                                                            type:
                                                                response.headers.get(
                                                                    "content-type"
                                                                ) ||
                                                                res?.data?.type,
                                                        }
                                                    );
                                                    await setOriginalFiles(
                                                        (
                                                            originalFiles: any
                                                        ) => [
                                                            ...originalFiles,
                                                            f,
                                                        ]
                                                    );
                                                    array.push(f);
                                                    resolve(result);
                                                });
                                            }
                                        );
                                        uri2promise.then(
                                            async (result: any) => {
                                                if (res?.data?.uri3) {
                                                    const uri3promise =
                                                        new Promise(
                                                            async (
                                                                resolve,
                                                                reject
                                                            ) => {
                                                                const response =
                                                                    fetch(
                                                                        `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_SERVER_API_ENDPOINT}/secret_file?token=${res?.data?.uri3}`
                                                                    ).then(
                                                                        async (
                                                                            response
                                                                        ) => {
                                                                            const data =
                                                                                await response.blob();
                                                                            const f =
                                                                                new File(
                                                                                    [
                                                                                        data,
                                                                                    ],
                                                                                    croppedArray[3]?.name,
                                                                                    {
                                                                                        type:
                                                                                            response.headers.get(
                                                                                                "content-type"
                                                                                            ) ||
                                                                                            res
                                                                                                ?.data
                                                                                                ?.type,
                                                                                    }
                                                                                );
                                                                            await setOriginalFiles(
                                                                                (
                                                                                    originalFiles: any
                                                                                ) => [
                                                                                    ...originalFiles,
                                                                                    f,
                                                                                ]
                                                                            );
                                                                            array.push(
                                                                                f
                                                                            );
                                                                            resolve(
                                                                                result
                                                                            );
                                                                        }
                                                                    );
                                                            }
                                                        );
                                                    uri3promise.then(
                                                        async (result: any) => {
                                                            await setPhotosLoading(
                                                                false
                                                            );
                                                            await setButtonDisabled(
                                                                false
                                                            );
                                                        }
                                                    );
                                                } else {
                                                    await setPhotosLoading(
                                                        false
                                                    );
                                                    await setButtonDisabled(
                                                        false
                                                    );
                                                }
                                            }
                                        );
                                    } else {
                                        await setPhotosLoading(false);
                                        await setButtonDisabled(false);
                                    }
                                });
                            } else {
                                await setPhotosLoading(false);
                                await setButtonDisabled(false);
                            }
                        });
                    })
                    .catch((err: any) => {
                        if (err?.response.status === 401) {
                            OpenErrorNotification(
                                t("profile.error"),
                                t("profile.error-unauthorized-user"),
                                3
                            );
                        } else {
                            OpenErrorNotification(
                                t("profile.error"),
                                t("profile.error-desc"),
                                3
                            );
                        }
                        return "failed call for get secret files tokens";
                    });
            }
        }, [canFetchOriginal]);

        useEffect(() => {
            if (itemsDetails?.uri3_metadata) {
                setMetaDataArray([
                    itemsDetails?.uri_metadata,
                    itemsDetails?.uri1_metadata,
                    itemsDetails?.uri2_metadata,
                    itemsDetails?.uri3_metadata,
                ]);
            } else if (itemsDetails?.uri2_metadata) {
                setMetaDataArray([
                    itemsDetails?.uri_metadata,
                    itemsDetails?.uri1_metadata,
                    itemsDetails?.uri2_metadata,
                ]);
            } else if (itemsDetails?.uri1_metadata) {
                setMetaDataArray([
                    itemsDetails?.uri_metadata,
                    itemsDetails?.uri1_metadata,
                ]);
            } else {
                setMetaDataArray([itemsDetails?.uri_metadata]);
            }
        }, [itemsDetails]);

        useEffect(() => {
            if (display && photosArray?.length > 0) {
                setDisplayPhoto(photosArray[0]);
                itemDetailsStore.setIsItemPhotoLoading(false);
            }
            if (photosArray?.length === 0) {
                setDisplayPhoto("");
                setNewClicked(true);
                itemDetailsStore.setIsItemPhotoLoading(false);
            }
            if (photosArray?.length > 0) {
                itemDetailsStore.setIsItemPhotoLoading(false);
                if (
                    photosArray[0] !== null &&
                    (photosArray[0].includes(".glb") ||
                        photosArray[0].includes(".gltf") ||
                        photosArray[0].includes(".fbx") ||
                        photosArray[0].includes(".obj"))
                ) {
                    setItemIcon(ICPHOTO);
                } else if (itemFormStore.collectionData) {
                    if (
                        !itemFormStore.collectionData.extra_unlock_1image
                            .extra_unlock_1image &&
                        !itemFormStore.collectionData.extra_unlock_3_image
                            .extra_unlock_3_image
                    ) {
                        setItemIcon(ICLOCK);
                    } else {
                        setItemIcon(ICPHOTO);
                    }
                }
            } else {
                setItemIcon(ICPHOTO);
            }
        }, [photosArray]);

        useEffect(() => {
            //this function is to check if the new displayed photo have a keepRelation image or not
            let imageIndex = photosArray?.indexOf(displayPhoto);
            if (
                displayPhoto !== "" &&
                metaDataArray[0] &&
                imageIndex >= 0 &&
                imageIndex <= metaDataArray?.length + 1 &&
                metaDataArray[imageIndex]
            ) {
                let diplayedMetatag: any = JSON.parse(
                    metaDataArray[imageIndex]
                );
                if (
                    diplayedMetatag?.type !== "image/gif" &&
                    diplayedMetatag?.type !== "image/svg+xml" &&
                    diplayedMetatag?.width >=
                        1024 + (diplayedMetatag?.width * 20) / 100
                ) {
                    setDisplayKeepRelation(true);
                } else {
                    setDisplayKeepRelation(false);
                }
            }
        }, [displayPhoto]);

        const onClickUploadAudio = () => {
            // discard changes
            if (photosArray.length > 0) {
                setOpenDiscardModal(true);
            } else if (itemFormStore.extraFilePath !== null) {
                setOpenDiscardModal(true);
            } else if (
                !itemFormStore.collectionData.extra_unlock_audio
                    .extra_unlock_audio
            ) {
                getConfigTransaction(
                    itemFormStore.collectionData.extra_unlock_audio.id
                );
                localStorage.setItem(
                    "extra-file-id",
                    itemFormStore.collectionData.extra_unlock_audio.id
                );
                setOpenUnlockModal(true);
            } else {
                itemFormStore.setShowPhotosBlock(false);
                itemFormStore.setShowVideoBlock(false);
                itemFormStore.setShow3DBlock(false);
                itemFormStore.setShowAudioBlock(true);
                itemFormStore.setExtraFilePath(null);
                itemFormStore.setIsConfirmExtraFile(false);
            }
        };
        const onClickUploadVideo = () => {
            // discard changes
            if (photosArray.length > 0) {
                setOpenDiscardModal(true);
            } else if (itemFormStore.extraFilePath !== null) {
                setOpenDiscardModal(true);
            } else if (
                !itemFormStore.collectionData.extra_unlock_video
                    .extra_unlock_video
            ) {
                getConfigTransaction(
                    itemFormStore.collectionData.extra_unlock_video.id
                );
                localStorage.setItem(
                    "extra-file-id",
                    itemFormStore.collectionData.extra_unlock_video.id
                );
                setOpenUnlockModal(true);
            } else {
                itemFormStore.setShowPhotosBlock(false);
                itemFormStore.setShowAudioBlock(false);
                itemFormStore.setShow3DBlock(false);
                itemFormStore.setShowVideoBlock(true);
                itemFormStore.setExtraFilePath(null);
                itemFormStore.setIsConfirmExtraFile(false);
            }
        };
        const onClickUpload3DFile = () => {
            // discard changes
            if (photosArray.length > 0) {
                setOpenDiscardModal(true);
            } else if (itemFormStore.extraFilePath !== null) {
                setOpenDiscardModal(true);
            } else if (
                !itemFormStore.collectionData.extra_unlock_3D.extra_unlock_3D
            ) {
                getConfigTransaction(
                    itemFormStore.collectionData.extra_unlock_3D.id
                );
                localStorage.setItem(
                    "extra-file-id",
                    itemFormStore.collectionData.extra_unlock_3D.id
                );
                setOpenUnlockModal(true);
            } else {
                itemFormStore.setShowPhotosBlock(false);
                itemFormStore.setShowAudioBlock(false);
                itemFormStore.setShowVideoBlock(false);
                itemFormStore.setShow3DBlock(true);
                itemFormStore.setExtraFilePath(null);
                itemFormStore.setIsConfirmExtraFile(false);
            }
        };
        const onClickUploadImage = () => {
            if (!photosLoading) {
                if (
                    photosArray.length > 0 &&
                    ((itemFormStore.collectionData &&
                        itemFormStore.collectionData.extra_unlock_1image
                            .extra_unlock_1image) ||
                        itemFormStore.collectionData.extra_unlock_3_image
                            .extra_unlock_3_image)
                ) {
                    if (
                        photosArray[0].includes(".glb") ||
                        photosArray[0].includes(".gltf") ||
                        photosArray[0].includes(".fbx") ||
                        photosArray[0].includes(".obj") ||
                        (photosArray.length > 1 &&
                            (photosArray[1].includes(".mp4") ||
                                photosArray[1].includes(".webm") ||
                                photosArray[1].includes(".mp3") ||
                                photosArray[1].includes(".ogg") ||
                                photosArray[1].includes(".wav")))
                    ) {
                        setOpenDiscardModal(true);
                    } else {
                        setNewClicked(true);
                        itemFormStore.setShowAudioBlock(false);
                        itemFormStore.setShowVideoBlock(false);
                        itemFormStore.setShow3DBlock(false);
                        itemFormStore.setShowPhotosBlock(true);
                        itemFormStore.setExtraFilePath(null);
                        itemFormStore.setIsConfirmExtraFile(false);
                    }
                } else if (
                    itemFormStore.extraFilePath !== null &&
                    ((itemFormStore.collectionData &&
                        itemFormStore.collectionData.extra_unlock_1image
                            .extra_unlock_1image) ||
                        itemFormStore.collectionData.extra_unlock_3_image
                            .extra_unlock_3_image)
                ) {
                    setOpenDiscardModal(true);
                } else if (
                    itemIcon === ICLOCK &&
                    itemFormStore.collectionData &&
                    !itemFormStore.collectionData.extra_unlock_1image
                        .extra_unlock_1image
                ) {
                    getConfigTransaction(
                        itemFormStore.collectionData.extra_unlock_1image.id
                    );
                    localStorage.setItem(
                        "extra-file-id",
                        itemFormStore.collectionData.extra_unlock_1image.id
                    );
                    setOpenUnlockImagesModal(true);
                } else {
                    itemFormStore.setShowAudioBlock(false);
                    itemFormStore.setShowVideoBlock(false);
                    itemFormStore.setShow3DBlock(false);
                    itemFormStore.setShowPhotosBlock(true);
                    itemFormStore.setExtraFilePath(null);
                    itemFormStore.setIsConfirmExtraFile(false);
                }
            }
        };

        const onCloseDicardkModal = () => {
            setOpenDiscardModal(false);
        };
        const confiremDicardModal = () => {
            if (photosArray.length > 0) {
                setCropped([]);
                setOriginalFiles([]);
                setDisplayPhoto("");
                setPhotosArray([]);
                setOpenDiscardModal(false);
            }
            if (extraFile !== null) {
                itemFormStore.setExtraFilePath(null);
                itemFormStore.setIsConfirmExtraFile(false);
                setOpenDiscardModal(false);
                setExtraFile(null);
                itemFormStore.setShowPhotosBlock(false);
            }
        };
        const handleClickFullscreen = () => {
            if (screenfull.isEnabled) {
                screenfull.request(videoPlayerRef.current.wrapper);
            }
        };

        const onCloseUnlockModal = () => {
            setOpenUnlockModal(false);
        };
        const onCloseUnlockImagesModal = () => {
            setOpenUnlockImagesModal(false);
        };
        const confiremTransactionUnlockModal = async () => {
            if (
                Number(account.balance)<
                dataExtraFile?.value
            ) {
                message.error(t("action-box.not-enough-balance"));
            } else {
                console.log("dataExtraFile ===========>", dataExtraFile);
                await refreshAccount();
                const { sessionId /*, error*/ } = await sendTransactions({
                    transactions: {
                        receiver: dataExtraFile?.receiver,
                        gasLimit: dataExtraFile?.gas_limit,
                        value: dataExtraFile?.value,
                        data: "N/A",
                    },
                    transactionsDisplayInfo: {
                        processingMessage: t(
                            "transaction-message.processing-transaction-audio"
                        ),
                        errorMessage: t(
                            "transaction-message.processing-transaction-audio-error"
                        ),
                        successMessage: t(
                            "transaction-message.processing-transaction-audio-success"
                        ),
                    },
                    redirectAfterSign: false,
                });
                setOpenUnlockModal(false);
                if (sessionId !== null) {
                    localStorage.setItem("extra-file-sessionId", sessionId);
                }
            }
        };
        const confiremTransactionUnlockImagesModal = async () => {
            if (
                Number(account.balance) <
                dataExtraFile?.value
            ) {
                message.error(t("action-box.not-enough-balance"));
            } else {
                await refreshAccount();
                const { sessionId } = await sendTransactions({
                    transactions: {
                        receiver: dataExtraFile?.receiver,
                        gasLimit: dataExtraFile?.gas_limit,
                        value: dataExtraFile?.value ,
                        data: "N/A",
                    },
                    transactionsDisplayInfo: {
                        processingMessage: t(
                            "transaction-message.processing-transaction-audio"
                        ),
                        errorMessage: t(
                            "transaction-message.processing-transaction-audio-error"
                        ),
                        successMessage: t(
                            "transaction-message.processing-transaction-audio-success"
                        ),
                    },
                    redirectAfterSign: false,
                });
                setOpenUnlockModal(false);
                if (sessionId !== null) {
                    localStorage.setItem("extra-file-sessionId", sessionId);
                }
            }
        };

        useEffect(() => {
            successfulTransactionsArray.map((item) => {
                if (item[0] === localStorage.getItem("extra-file-sessionId")) {
                    if (item[1]?.transactions[0]?.hash) {
                        if (
                            sessionIdTransaction !== parseInt(item[0]) &&
                            itemFormStore.collectionData
                        ) {
                            unlockExtraFeature(
                                item[1]?.transactions[0]?.value,
                                "EGLD",
                                item[1]?.transactions[0]?.hash,
                                JSON.parse(localStorage.getItem("user") || "{}")
                                    ?.id,
                                parseInt(
                                    localStorage.getItem("extra-file-id") || ""
                                ),
                                itemFormStore.collectionData.id
                            );
                            setSessionIdTransaction(parseInt(item[0]));
                            itemFormStore.setIsExtraFeatureChanged(true);
                        }
                    }
                }
            });
        }, [successfulTransactionsArray]);

        const onPlayAudio = () => {
            if (audioRef && audioRef.current) {
                audioRef.current.play();
                setIsPlayed(true);
            }
        };
        const onPauseAudio = () => {
            if (audioRef && audioRef.current) {
                audioRef.current.pause();
                setIsPlayed(false);
            }
        };

        const onPlayVideo = () => {
            setPlayVideo(true);
        };
        const onPauseVideo = () => {
            setPlayVideo(false);
        };

        const onChangeExtraImages = (e: RadioChangeEvent) => {
            localStorage.setItem("extra-file-id", e.target.value);
            setValue(e.target.value);
            getConfigTransaction(e.target.value);
        };

        const clearExtraFileData = () => {
            setPhotosArray([]);
            setCropped([]);
            setOriginalFiles([]);
        };

        return (
            <>
                <div
                    className={
                        display && photosArray.length < 2
                            ? "item-photos-global-container-one-image"
                            : "item-photos-global-container"
                    }>
                    {!display && (
                        <div>
                            <div>
                                {/* display small photos menu */}
                                {(!display || photosArray.length > 1) &&
                                    photosArray.map((element, index) => {
                                        if (element !== "") {
                                            if (
                                                photosArray.length > 1 &&
                                                photosArray[1] !== null
                                            ) {
                                                return (
                                                    !photosArray[1].includes(
                                                        ".mp3"
                                                    ) &&
                                                    !photosArray[1].includes(
                                                        ".ogg"
                                                    ) &&
                                                    !photosArray[1].includes(
                                                        ".wav"
                                                    ) &&
                                                    !photosArray[1].includes(
                                                        ".mp4"
                                                    ) &&
                                                    !photosArray[1].includes(
                                                        ".webm"
                                                    ) && (
                                                        <div
                                                            key={index}
                                                            className={
                                                                displayPhoto ===
                                                                    element &&
                                                                !newClicked
                                                                    ? "mini-photos-container mini-photos-container-selected"
                                                                    : "mini-photos-container"
                                                            }
                                                            onClick={() => {
                                                                setNewClicked(
                                                                    false
                                                                );
                                                                setDisplayPhoto(
                                                                    element
                                                                );
                                                            }}>
                                                            {element.includes(
                                                                ".glb"
                                                            ) ||
                                                            element.includes(
                                                                ".gltf"
                                                            ) ||
                                                            element.includes(
                                                                ".fbx"
                                                            ) ||
                                                            element.includes(
                                                                ".obj"
                                                            ) ? (
                                                                <PrivewItemDetails
                                                                    path={
                                                                        element
                                                                    }
                                                                    fileType={
                                                                        element
                                                                    }
                                                                    className="item-details-left-item"
                                                                />
                                                            ) : (
                                                                <img
                                                                    alt="element"
                                                                    src={
                                                                        element
                                                                    }
                                                                    className="mini-photos-image"
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={
                                                            displayPhoto ===
                                                                element &&
                                                            !newClicked
                                                                ? "mini-photos-container mini-photos-container-selected"
                                                                : "mini-photos-container"
                                                        }
                                                        onClick={() => {
                                                            setNewClicked(
                                                                false
                                                            );
                                                            setDisplayPhoto(
                                                                element
                                                            );
                                                        }}>
                                                        {element.includes(
                                                            ".glb"
                                                        ) ||
                                                        element.includes(
                                                            ".gltf"
                                                        ) ||
                                                        element.includes(
                                                            ".fbx"
                                                        ) ||
                                                        element.includes(
                                                            ".obj"
                                                        ) ? (
                                                            <PrivewItemDetails
                                                                path={element}
                                                                fileType={
                                                                    element
                                                                }
                                                                className="item-details-left-item"
                                                            />
                                                        ) : (
                                                            <img
                                                                alt="element"
                                                                src={element}
                                                                className="mini-photos-image"
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            }
                                        }
                                    })}
                                {/* display for the add image button */}
                                {!display &&
                                    photosArray?.length <
                                        (itemFormStore.collectionData &&
                                        itemFormStore.collectionData.length >
                                            0 &&
                                        itemFormStore.collectionData
                                            .extra_unlock_3_image
                                            .extra_unlock_3_image
                                            ? 4
                                            : 2) &&
                                    (photosArray.length > 1 &&
                                    !photosArray[1].includes(".mp3") &&
                                    !photosArray[1].includes(".ogg") &&
                                    !photosArray[1].includes(".wav") &&
                                    !photosArray[1].includes(".mp4") &&
                                    !photosArray[1].includes(".webm") ? (
                                        <div
                                            className={
                                                photosArray?.length > 0
                                                    ? "add-item-photo-button add-item-photo-button-clickable"
                                                    : itemFormStore.showPhotosBlock &&
                                                      (!itemFormStore.showAudioBlock ||
                                                          !itemFormStore.showVideoBlock ||
                                                          !itemFormStore.show3DBlock)
                                                    ? "photo-item-photo-button mini-photos-container-selected"
                                                    : "photo-item-photo-button"
                                            }
                                            onClick={() =>
                                                itemFormStore.showAudioBlock ||
                                                itemFormStore.showVideoBlock ||
                                                itemFormStore.show3DBlock
                                                    ? onClickUploadImage()
                                                    : onClickUploadImage()
                                            }>
                                            <img
                                                alt="addIcon"
                                                src={itemIcon}
                                                className="lock-item-photo-button-icon"
                                            />
                                        </div>
                                    ) : (
                                        <Tooltip
                                            title={
                                                itemIcon === ICLOCK
                                                    ? t(
                                                          "collection-page.tooltip-images"
                                                      )
                                                    : ""
                                            }>
                                            <div
                                                className={
                                                    photosArray?.length > 0
                                                        ? "add-item-photo-button add-item-photo-button-clickable"
                                                        : itemFormStore.showPhotosBlock &&
                                                          (!itemFormStore.showAudioBlock ||
                                                              !itemFormStore.showVideoBlock ||
                                                              !itemFormStore.show3DBlock)
                                                        ? "photo-item-photo-button mini-photos-container-selected"
                                                        : "photo-item-photo-button"
                                                }
                                                onClick={() =>
                                                    itemFormStore.showAudioBlock ||
                                                    itemFormStore.showVideoBlock ||
                                                    itemFormStore.show3DBlock
                                                        ? onClickUploadImage()
                                                        : onClickUploadImage()
                                                }>
                                                <img
                                                    alt="addIcon"
                                                    src={itemIcon}
                                                    className="lock-item-photo-button-icon"
                                                />
                                            </div>
                                        </Tooltip>
                                    ))}
                            </div>
                            {/* Extra Files Upload Icons */}
                            {photosArray.length < 3 && (
                                <>
                                    {!display &&
                                        itemFormStore.collectionData && (
                                            <Tooltip
                                                title={
                                                    itemFormStore.collectionData
                                                        .extra_unlock_audio &&
                                                    itemFormStore.collectionData
                                                        .extra_unlock_audio
                                                        .extra_unlock_audio
                                                        ? ""
                                                        : t(
                                                              "collection-page.tooltip-unlock-extra-feature"
                                                          )
                                                }>
                                                <div
                                                    className={
                                                        itemFormStore.showAudioBlock
                                                            ? "lock-item-photo-button mini-photos-container-selected"
                                                            : "lock-item-photo-button"
                                                    }
                                                    onClick={
                                                        onClickUploadAudio
                                                    }>
                                                    <img
                                                        alt="ICAUDIO"
                                                        src={
                                                            itemFormStore
                                                                .collectionData
                                                                .extra_unlock_audio &&
                                                            itemFormStore
                                                                .collectionData
                                                                .extra_unlock_audio
                                                                .extra_unlock_audio
                                                                ? ICAUDIO
                                                                : ICUNLOCkAUDIO
                                                        }
                                                        className="lock-item-photo-button-icon"
                                                    />
                                                </div>
                                            </Tooltip>
                                        )}
                                    {!display &&
                                        itemFormStore.collectionData && (
                                            <Tooltip
                                                title={
                                                    itemFormStore.collectionData
                                                        .extra_unlock_video &&
                                                    itemFormStore.collectionData
                                                        .extra_unlock_video
                                                        .extra_unlock_video
                                                        ? ""
                                                        : t(
                                                              "collection-page.tooltip-unlock-extra-feature"
                                                          )
                                                }>
                                                <div
                                                    className={
                                                        itemFormStore.showVideoBlock
                                                            ? "lock-item-photo-button mini-photos-container-selected"
                                                            : "lock-item-photo-button"
                                                    }
                                                    onClick={
                                                        onClickUploadVideo
                                                    }>
                                                    <img
                                                        alt="ICVIDEO"
                                                        src={
                                                            itemFormStore
                                                                .collectionData
                                                                .extra_unlock_video &&
                                                            itemFormStore
                                                                .collectionData
                                                                .extra_unlock_video
                                                                ?.extra_unlock_video
                                                                ? ICVIDEO
                                                                : ICUNLOCKVIDEO
                                                        }
                                                        className="lock-item-photo-button-icon"
                                                    />
                                                </div>
                                            </Tooltip>
                                        )}
                                    {!display &&
                                        itemFormStore.collectionData && (
                                            <Tooltip
                                                title={
                                                    itemFormStore.collectionData
                                                        .extra_unlock_3D &&
                                                    itemFormStore.collectionData
                                                        .extra_unlock_3D
                                                        .extra_unlock_3D
                                                        ? ""
                                                        : t(
                                                              "collection-page.tooltip-unlock-extra-feature"
                                                          )
                                                }>
                                                <div
                                                    className={
                                                        itemFormStore.show3DBlock
                                                            ? "lock-item-photo-button mini-photos-container-selected"
                                                            : "lock-item-photo-button"
                                                    }
                                                    onClick={
                                                        onClickUpload3DFile
                                                    }>
                                                    <img
                                                        alt="IC3D"
                                                        src={
                                                            itemFormStore
                                                                .collectionData
                                                                .extra_unlock_3D
                                                                ?.extra_unlock_3D
                                                                ? IC3D
                                                                : ICUNLOCK3D
                                                        }
                                                        className="lock-item-photo-button-icon"
                                                    />
                                                </div>
                                            </Tooltip>
                                        )}
                                </>
                            )}
                        </div>
                    )}

                    {!display && newClicked ? (
                        <div className="item-upload-box-container">
                            {itemFormStore.showAudioBlock ||
                            itemFormStore.showVideoBlock ||
                            itemFormStore.show3DBlock ? (
                                <ItemExtraFile
                                    setExtraFile={(val: any) => {
                                        console.log("val extra ========>", val);
                                        setExtraFile(val);
                                        setCropped(val);
                                        setOriginalFiles(val);
                                    }}
                                    showAudioBlock={
                                        itemFormStore.showAudioBlock
                                    }
                                    showVideoBlock={
                                        itemFormStore.showVideoBlock
                                    }
                                    show3DBlock={itemFormStore.show3DBlock}
                                />
                            ) : (
                                <ImageUpload
                                    image={""}
                                    size={["100%", "100%"]}
                                    setCollectionImage={(val: any) => {
                                        setCropped([...croppedArray, val]);
                                    }}
                                    setOriginalImage={(val: any) => {
                                        setOriginalFiles([
                                            ...originalFiles,
                                            val,
                                        ]);
                                        setPhotosChanged(true);
                                    }}
                                    setPreview={(photo: string) => {
                                        setDisplayPhoto(photo);
                                        setPhotosArray([...photosArray, photo]);
                                        setNewClicked(false);
                                    }}
                                />
                            )}
                        </div>
                    ) : (
                        <div
                            className={
                                photosArray[1] !== undefined &&
                                (photosArray[1].includes(".mp4") ||
                                    photosArray[1].includes(".webm"))
                                    ? "item-details-video-container"
                                    : "item-details-image-container"
                            }>
                            {displayKeepRelation ? (
                                <ZoomableImage
                                    photoSrc={displayPhoto}
                                    customImageClassname="item-details-image"
                                />
                            ) : // item photo details
                            itemDetailsStore.isItemPhotoLoading ? (
                                <div className="photos-spinner">
                                    <Spin size="large" />
                                </div>
                            ) : // image display
                            photosArray[1] !== undefined &&
                              (photosArray[1].includes(".mp4") ||
                                  photosArray[1].includes(".webm")) ? (
                                <div>
                                    <ReactPlayer
                                        ref={videoPlayerRef}
                                        url={photosArray[1]}
                                        className="item-details-video"
                                        playing={playVideo}
                                        onPlay={onPlayVideo}
                                        onPause={onPauseVideo}
                                        config={{
                                            file: {
                                                attributes: {
                                                    onContextMenu: (e: any) =>
                                                        e.preventDefault(),
                                                },
                                            },
                                        }}
                                    />
                                    <Button
                                        className="responsive-btn-item-video"
                                        onClick={onPlayVideo}>
                                        {t("collection-page.play")}
                                    </Button>
                                    <Button
                                        className="item-video-button responsive-btn-item-video"
                                        onClick={onPauseVideo}>
                                        {t("collection-page.pause")}
                                    </Button>
                                    <Button
                                        className="item-video-button responsive-btn-item-video"
                                        onClick={handleClickFullscreen}>
                                        {t("collection-page.fullscreen")}
                                    </Button>
                                </div>
                            ) : photosArray[0] !== undefined &&
                              (photosArray[0].includes(".glb") ||
                                  photosArray[0].includes(".gltf") ||
                                  photosArray[0].includes(".fbx") ||
                                  photosArray[0].includes(".obj")) ? (
                                <PrivewItemDetails
                                    path={photosArray[0]}
                                    fileType={photosArray[0]}
                                />
                            ) : photosArray[1] !== undefined &&
                              (photosArray[1].includes(".mp3") ||
                                  photosArray[1].includes(".ogg") ||
                                  photosArray[1].includes(".wav")) ? (
                                <div className="play-block-item">
                                    <img
                                        alt={`${itemsDetails?.title}`}
                                        src={displayPhoto}
                                        onLoad={() => {
                                            itemDetailsStore.setIsItemPhotoLoading(
                                                false
                                            );
                                            setLoadEnd(false);
                                        }}
                                        className="item-details-image"
                                    />
                                    {photosArray?.length > 1 && (
                                        <>
                                            {photosArray[1] !== undefined &&
                                                (photosArray[1].includes(
                                                    ".mp3"
                                                ) ||
                                                    photosArray[1].includes(
                                                        ".ogg"
                                                    ) ||
                                                    photosArray[1].includes(
                                                        ".wav"
                                                    )) && (
                                                    <>
                                                        <audio
                                                            ref={audioRef}
                                                            src={photosArray[1]}
                                                        />
                                                        {!loadEnd && (
                                                            <>
                                                                <Tooltip
                                                                    title={
                                                                        !isPlayed
                                                                            ? "Click Play The Audio"
                                                                            : ""
                                                                    }>
                                                                    <img
                                                                        src={
                                                                            isPlayed
                                                                                ? ICPAUSE
                                                                                : ICPLAY
                                                                        }
                                                                        alt="ICPLAY"
                                                                        className="play-block-icon"
                                                                        onClick={() =>
                                                                            isPlayed
                                                                                ? onPauseAudio()
                                                                                : onPlayAudio()
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                                <div
                                                                    className={`circle ${
                                                                        isPlayed
                                                                            ? "delay1"
                                                                            : ""
                                                                    }`}></div>
                                                                <div
                                                                    className={`circle ${
                                                                        isPlayed
                                                                            ? "delay2"
                                                                            : ""
                                                                    }`}></div>
                                                                <div
                                                                    className={`circle ${
                                                                        isPlayed
                                                                            ? "delay3"
                                                                            : ""
                                                                    }`}></div>
                                                                <div
                                                                    className={`circle ${
                                                                        isPlayed
                                                                            ? "delay4"
                                                                            : ""
                                                                    }`}></div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <img
                                    alt={`${itemsDetails?.title}`}
                                    src={displayPhoto}
                                    onLoad={() => {
                                        itemDetailsStore.setIsItemPhotoLoading(
                                            false
                                        );
                                    }}
                                    className="item-details-image"
                                />
                            )}
                            {photosLoading && (
                                <div className="photos-spinner">
                                    <Spin size="large" />
                                </div>
                            )}
                            {display ? (
                                <LikeButton
                                    liked={itemsDetails?.isFavorite}
                                    itemId={itemsDetails?.id}
                                    className="like-button-container"
                                />
                            ) : (
                                <DeleteFilled
                                    className="red-color delete-image-button-container"
                                    onClick={() => {
                                        setNewClicked(true);
                                        setPhotosChanged(true);
                                        itemFormStore.setExtraFilePath(null);
                                        itemFormStore.setIsConfirmExtraFile(
                                            false
                                        );
                                        //for the backend : array of cropped files
                                        setCropped(
                                            croppedArray?.filter(
                                                (item: any, index: number) => {
                                                    return (
                                                        index !==
                                                        photosArray?.findIndex(
                                                            (element) =>
                                                                element ===
                                                                displayPhoto
                                                        )
                                                    );
                                                }
                                            )
                                        );
                                        //for the frontend : array of photos src (string)
                                        photosArray.length > 1 &&
                                        (photosArray[1].includes(".wav") ||
                                            photosArray[1].includes(".mp3") ||
                                            photosArray[1].includes(".ogg") ||
                                            photosArray[1].includes(".webm") ||
                                            photosArray[1].includes(".mp4"))
                                            ? clearExtraFileData()
                                            : setPhotosArray(
                                                  photosArray?.filter(
                                                      (item, index) => {
                                                          return (
                                                              index !==
                                                              photosArray?.findIndex(
                                                                  (element) =>
                                                                      element ===
                                                                      displayPhoto
                                                              )
                                                          );
                                                      }
                                                  )
                                              );
                                        //for the backend : array of original files
                                        photosArray.length > 1 &&
                                        (photosArray[1].includes(".wav") ||
                                            photosArray[1].includes(".mp3") ||
                                            photosArray[1].includes(".ogg") ||
                                            photosArray[1].includes(".webm") ||
                                            photosArray[1].includes(".mp4"))
                                            ? clearExtraFileData()
                                            : setOriginalFiles(
                                                  originalFiles?.filter(
                                                      (
                                                          item: any,
                                                          index: number
                                                      ) => {
                                                          return (
                                                              index !==
                                                              photosArray?.findIndex(
                                                                  (element) =>
                                                                      element ===
                                                                      displayPhoto
                                                              )
                                                          );
                                                      }
                                                  )
                                              );
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
                <Modal
                    title={t("collection-page.warning-discard-changes")}
                    visible={openDiscardModal}
                    onCancel={onCloseDicardkModal}
                    destroyOnClose={true}
                    onOk={confiremDicardModal}>
                    <div>
                        <h5>{t("collection-page.discard-changes")}</h5>
                    </div>
                </Modal>
                <Modal
                    title={t("collection-page.unlock-extra-feature")}
                    visible={openUnlockModal}
                    onCancel={onCloseUnlockModal}
                    destroyOnClose={true}
                    onOk={confiremTransactionUnlockModal}>
                    <div>
                        <h5>
                            {localStorage.getItem("extra-file-id") === "3"
                                ? t("collection-page.unlock-1image")
                                : localStorage.getItem("extra-file-id") === "4"
                                ? t("collection-page.unlock-3-image")
                                : localStorage.getItem("extra-file-id") === "5"
                                ? t("collection-page.unlock-audio")
                                : localStorage.getItem("extra-file-id") === "6"
                                ? t("collection-page.unlock-video")
                                : t("collection-page.unlock-3d")}
                        </h5>
                        {itemFormStore.collectionData && (
                            <h6 style={{ paddingTop: 5 }}>
                                {t("collection-page.extra-feature-price-msg", {
                                    price:
                                        localStorage.getItem(
                                            "extra-file-id"
                                        ) === "3"
                                            ? Number(
                                                  itemFormStore.collectionData
                                                      .extra_unlock_1image.price
                                              )/ 1000000000000000000
                                            : localStorage.getItem(
                                                  "extra-file-id"
                                              ) === "4"
                                            ? Number(
                                                  itemFormStore.collectionData
                                                      .extra_unlock_3_image
                                                      .price
                                              )/ 1000000000000000000
                                            : localStorage.getItem(
                                                  "extra-file-id"
                                              ) === "5"
                                            ? Number(
                                                  itemFormStore.collectionData
                                                      .extra_unlock_audio.price
                                              )/ 1000000000000000000
                                            : localStorage.getItem(
                                                  "extra-file-id"
                                              ) === "6"
                                            ? Number(
                                                  itemFormStore.collectionData
                                                      .extra_unlock_video.price
                                              )/ 1000000000000000000
                                            : Number(
                                                  itemFormStore.collectionData
                                                      .extra_unlock_3D.price
                                              )/ 1000000000000000000,
                                })}
                            </h6>
                        )}
                    </div>
                </Modal>
                <Modal
                    title={t("collection-page.unlock-images")}
                    visible={openUnlockImagesModal}
                    onCancel={onCloseUnlockImagesModal}
                    destroyOnClose={true}
                    onOk={confiremTransactionUnlockImagesModal}>
                    <div>
                        <Radio.Group
                            onChange={onChangeExtraImages}
                            value={value}>
                            <Space direction="vertical">
                                <Radio value={3}>
                                    {t("collection-page.unlock-1image")}
                                </Radio>
                                <Radio value={4}>
                                    {t("collection-page.unlock-3-image")}
                                </Radio>
                            </Space>
                        </Radio.Group>
                        {itemFormStore.collectionData && (
                            <h6
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: 25,
                                }}>
                                {t("collection-page.extra-feature-price-msg", {
                                    price:
                                        localStorage.getItem(
                                            "extra-file-id"
                                        ) === "3"
                                            ? Number(
                                                  itemFormStore.collectionData
                                                      .extra_unlock_1image.price
                                              )/ 1000000000000000000
                                            : localStorage.getItem(
                                                  "extra-file-id"
                                              ) === "4"
                                            ? Number(
                                                  itemFormStore.collectionData
                                                      .extra_unlock_3_image
                                                      .price
                                              )  / 1000000000000000000
                                            : "",
                                })}
                            </h6>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
);

export default ItemPhotos;
