import React from 'react';
import { Row, Col } from 'antd';
import image1 from '../../../../../assets/images/home_slider1.webp';
import image2 from '../../../../../assets/images/home_slider2.webp';
import image3 from '../../../../../assets/images/home_slider3.webp';
import image4 from '../../../../../assets/images/home_slider4.webp';
import './sliderImages.less';
import { useNavigate } from "react-router-dom";

const SliderImages = () => {
	const  navigate = useNavigate();

	const redirectPath=(path:string)=>{
		navigate({pathname:path})
	}
	return (
		<Row gutter={[ 24, 24 ]} className="slider-container">
			<Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<img alt="shadowed-1" src={image1} className="tall-image shadowed-1" />
			</Col>
			<Col span={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
				<img alt="shadowed-2" src={image2} className="normal-image shadowed-2" onClick={()=>redirectPath('/bettinas-art')}/>
			</Col>
			<Col
				span={12}
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					height: 'fit-content'
				}}>
				<img alt="shadowed-3" src={image3} className="normal-image shadowed-3" />
			</Col>
			<Col span={12}>
				<img alt="shadowed-4" src={image4} className="tall-image shadowed-4" onClick={()=>redirectPath('/locota-sculptural-art')} />
			</Col>
		</Row>
	);
};

export default SliderImages;
