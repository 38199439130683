import React from 'react';
import { Col, Row } from 'antd';
import arrow from '../../../../../assets/images/activeArrow.svg';

export interface IPaginationRelatedProps {
	pageInfos: number;
	pageSelected: number;
	goPreviousPage: () => void;
	goNextPage: () => void;
}

function PaginationRelatedItem(props: IPaginationRelatedProps) {
	const { pageInfos, pageSelected, goNextPage, goPreviousPage } = props;
	return (
		<Row>
			<Col span={24} className="pagination-butons-container">
				<div
					className={pageSelected > 0 ? 'arrow-container' : 'arrow-container low-opacity'}
					onClick={pageSelected > 0 ? goPreviousPage : () => {}}>
					<img
						alt="arrow"
						src={arrow}
						className={localStorage.getItem('language') === 'ar' ? '' : 'reverse-arrow'}
					/>
				</div>
				<div
					className={
						pageInfos > 1 && pageInfos - 1 > pageSelected ? (
							'arrow-container'
						) : (
							'arrow-container low-opacity'
						)
					}
					onClick={pageInfos > 1 && pageInfos - 1 > pageSelected ? goNextPage : () => {}}>
					<img
						alt="arrow"
						src={arrow}
						className={localStorage.getItem('language') !== 'ar' ? '' : 'reverse-arrow'}
					/>
				</div>
			</Col>
		</Row>
	);
}

export default PaginationRelatedItem;
