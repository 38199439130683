import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "antd/dist/antd.css";
import { ContextProvider } from "./main/context";
import "./App.less";
import Layout from "./main/modules/layout/layout";

import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal,
} from "@multiversx/sdk-dapp/UI";
import { routes, i18nLayouts } from "./routes";
import TransactionsProcessor from "./main/modules/elrond transactions/transactionsProcessor";
import { GlobalContextProvider } from "./main/context/globalContext";
import { useEffect } from "react";
import { checkExistingUrl } from "./main/helpers/communFunctions";
import {
  apiTimeout,
  walletConnectV2ProjectId,
} from "./main/config/configGlobal";
import { DappProvider } from "./main/components/Unlock/sdkDappComponents";
const withLayout = Object.values(routes).filter((e: any) => e.withLayout);
export const withoutLayout = Object.values(routes).filter(
  (e: any) => !e.withLayout
);

const environment =
  process.env.REACT_APP_SERVER_ELROND === "mainnet"
    ? "mainnet"
    : process.env.REACT_APP_SERVER_ELROND === "testnet"
    ? "testnet"
    : "devnet";

const App = () => {
  useEffect(() => {
    checkExistingUrl(i18nLayouts);
  }, []);

  return (
    <ContextProvider>
      <GlobalContextProvider>
        <Router>
          <DappProvider
            environment={environment}
            customNetworkConfig={{
              name: "customConfig",
              apiTimeout,
              walletConnectV2ProjectId,
            }}
          >
            <Layout>
              <TransactionsToastList />
              <NotificationModal />
              <SignTransactionsModals className="custom-class-for-modals" />
              <TransactionsProcessor />
              <Routes>
                {withoutLayout?.map((route, index) => {
                  return (
                    <Route
                      path={route?.path}
                      key={index}
                      element={<route.component />}
                    />
                  );
                })}

                {withLayout?.map((route, index) => {
                  return (
                    <Route
                      path={route?.path}
                      key={index}
                      element={<route.component />}
                    />
                  );
                })}
              </Routes>
            </Layout>
          </DappProvider>
        </Router>
      </GlobalContextProvider>
    </ContextProvider>
  );
};

export default App;
