import * as React from "react";

import { useEffect, useState } from "react";

const useWorstDisplay = (text: string, limit: number) => {
  const [displayText, setDisplayText] = useState<string>("");

  useEffect(() => {
    if (text?.length > limit) {
      setDisplayText(text?.substring(0, limit) + "...");
    } else {
      setDisplayText(text);
    }
  }, [text]);

  return displayText;
};

export default useWorstDisplay;
