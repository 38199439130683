import React from "react";
import { Button } from "antd";
import "./buttons.less";

interface Props {
  children: any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
}

const LightButton = ({ children, onClick, disabled }: Props) => {
  return (
    <Button className="light-button" onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

export default LightButton;
