import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MobileTab from "../../../../components/mobileTab/mobileTab";
import FilterProfile from "./filterProfile";
import ProfileTab from "../../../collection/components/profile tab/profileTab";
import instanceOfAxios from "../../../../../configAxios";
import addIcon from "../../../../../assets/images/add.svg";
import RecentItem from "../../../home/components/recentItem/recentItem";
import FeatureCollection from "../../../home/components/featureCollection";
import { Col, Row, Spin } from "antd";
import { useContext } from "../../../../context";
import { useNavigate, useParams } from "react-router-dom";
import Nodata from "../../../../components/noData/nodata";
import CustomImage from "../../../../components/image/customImage";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks/account";
import { useGlobalContext } from "../../../../context/globalContext";
import { observer } from "mobx-react-lite";

export interface TabsCollectionProfileType {
  profileAddress: any;
  profileOwns: any;
}

const TabsCollectionProfile = observer(({
  profileAddress,
  profileOwns
}: TabsCollectionProfileType) => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const  navigate = useNavigate();
  const { address } = useContext();
  const [activeTab, setActiveTab] = useState(profileOwns > 0 ? "owns" : "collection");
  const [dataCollection, setDatacollection] = useState<any[]>([]);
  const [offsetCollection, setOffsetcollection] = useState<number>(0);
  const [dataLoadCollectionlist, setDataloadcollectionlist] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [dataItems, setDataitems] = useState<any[]>([]);
  const [dataDraft, setDatadraft] = useState<any[]>([]);
  const [dataFavorite, setDatafavorite] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [collectionsCount, setCollectionscount] = useState<number>(0);
  const [itemsCount, setItemscount] = useState<number>(0);
  const [draftCount, setDraftcount] = useState<number>(0);
  const [favoriteCount, setFavoritecount] = useState<number>(0);
  const [dataloadDraft, setDataloadDraft] = useState<boolean>(false);
  const [dataloadCollection, setDataloadCollection] = useState<boolean>(false);
  const [dataloadItems, setDataloadItems] = useState<boolean>(false);
  const [dataloadFavorite, setDataloadFavorite] = useState<boolean>(false);
  const [collectionsLoading, setCollectionsLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const { isLoggedIn } = useGetLoginInfo()
  const { likesStore } = useGlobalContext()

  const [body, setBody] = useState<any>({
    collection: "",
    type: "",
    sortby: "",
    statusAuction: "",
  });
  const menuTab = [
    {
      label: t("collection-page.collection"),
      value: "collection",
    },
    {
      label: t("collection-page.items"),
      value: "items",
    },
    {
      label: t("collection-page.favorite"),
      value: "favorite",
    },

    {
      label: t("collection-page.owns"),
      value: "owns",
    },
  ];

  const type = [
    {
      label: t("All"),
      value: "All",
    },
    {
      label: t("collection-page.nft"),
      value: "nft",
    },
    {
      label: t("collection-page.sft"),
      value: "sft",
    },
  ];
  const status = [
    {
      label: t("All"),
      value: "All",
    },
    {
      label: t("collection-page.on-auction"),
      value: "onAuction",
    },
    {
      label: t("collection-page.not-on-auction"),
      value: "notOnAuction",
    },
    {
      label: t("On-auction-with-bids"),
      value: "onAuctionBids",
    },
  ];

  const sortBy = [
    {
      label: t("Recently-Created"),
      value: "created_at",
    },
    {
      label: t("Recently-Sold"),
      value: "recently_sold",
    },
    {
      label: t("Auction-ends-soon"),
      value: "auction_soon",
    },
    {
      label: t("Price-Low-to-High"),
      value: "price",
    },
    {
      label: t("Price-High-to-Low"),
      value: "priceHigh",
    },
    {
      label: t("hightest-Last-Sale"),
      value: "highest_last_sale",
    },
    {
      label: t("Most-viewed"),
      value: "views",
    },
    {
      label: t("Most-Favorited"),
      value: "likes",
    },
    {
      label: t("Oldest"),
      value: "oldest",
    },
  ];

  useEffect(() => {
    if (
      window.location.hash !== "" &&
      menuTab.filter((menuItem) =>
        window.location.hash?.includes(menuItem?.value)
      )?.length > 0
    ) {
      setActiveTab(
        menuTab.filter((menuItem) =>
          window.location.hash?.includes(menuItem?.value)
        )[0]?.value
      );
    }
  }, []);

  const assert = (text: any, count: any) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  };

  const getCollection = (filter: boolean) => {
    setCollectionsLoading(true);
    instanceOfAxios
      .get(
        `collections${profileAddress ? `?erd_address=${profileAddress}&` : "?"
        }first=20&offset=${filter ? 0 : offset}${address ? `&current_address=${address}` : ""}`
      )
      .then(function (res) {
        let result = res.data.nodes;

        setCollectionscount(res.data.pageInfo.totalCount);
        let objData = data;

        result.forEach((element: any) => {
          objData.push(element);
        });

        if (filter) {
          setData(result);
        } else {
          setData([...objData]);
        }
        setDataloadCollection(res.data.pageInfo.hasNextPage);
        if (res.data.pageInfo.hasNextPage === true && filter === false) {
          setOffset(offset + 20);
        } else {
          setOffset(20);
        }
        setCollectionsLoading(false);
      })
      .catch((e) => {
        setCollectionsLoading(false);
      });
  };

  const fetchDataItems = (filter: boolean) => {
    setItemsLoading(true);
    instanceOfAxios
      .get(
        `items${profileAddress ? `?erd_address=${profileAddress}&` : "?"}${address ? `&current_user=${address}&` : ""
        }first=20&offset=${filter ? 0 : offset}${body.collection}${body.type}${body.sortby
        }${body.statusAuction}`
      )
      .then(function (res) {
        let result = res.data.nodes;

        setItemscount(res.data.pageInfo.totalCount);
        let objData = dataItems;

        result.forEach((element: any) => {
          objData.push(element);
        });

        if (filter) {
          setDataitems(result);
        } else {
          setDataitems([...objData]);
        }
        setDataloadItems(res.data.pageInfo.hasNextPage);
        if (res.data.pageInfo.hasNextPage === true && filter === false) {
          setOffset(offset + 20);
        } else {
          setOffset(20);
        }
        setItemsLoading(false);
      })
      .catch((e) => {
        setItemsLoading(false);
      });
  };

  const fetchDataOwns = (filter: boolean) => {
    setDraftLoading(true);
    instanceOfAxios
      .get(
        `items_owns${profileAddress ? `?erd_address=${profileAddress}&` : "?"
        }first=20&offset=${filter ? 0 : offset}${body.collection}${body.type}${body.sortby
        }${body.statusAuction}`
      )
      .then(function (res) {
        let result = res.data.nodes;
        setDraftcount(res.data.pageInfo.totalCount);

        let objData = dataDraft;

        result.forEach((element: any) => {
          objData.push(element);
        });

        if (filter) {
          setDatadraft(result);
        } else {
          setDatadraft([...objData]);
        }
        setDataloadDraft(res.data.pageInfo.hasNextPage);
        if (res.data.pageInfo.hasNextPage === true && filter === false) {
          setOffset(offset + 20);
        } else {
          setOffset(20);
        }

        setDraftLoading(false);
      })
      .catch((e) => {
        setDraftLoading(false);
      });
  };

  const fetchDataFavorite = (filter: boolean) => {
    setFavoriteLoading(true);
    instanceOfAxios
      .get(
        `favorite_items/address/${address}?first=20&offset=${filter ? 0 : offset
        }${body.collection}${body.type}${body.sortby}${body.statusAuction}&current_user=${profileAddress}`
      )
      .then(function (res) {
        let result = res.data.nodes;
        setFavoritecount(res.data.pageInfo.totalCount);

        let objData = dataFavorite;

        result.forEach((element: any) => {
          objData.push(element);
        });

        if (filter) {
          setDatafavorite(result);
        } else {
          setDatafavorite([...objData]);
        }
        setDataloadFavorite(res.data.pageInfo.hasNextPage);
        if (res.data.pageInfo.hasNextPage === true && filter === false) {
          setOffset(offset + 20);
        } else {
          setOffset(20);
        }
        setFavoriteLoading(false);
      })
      .catch((e) => {
        setFavoriteLoading(false);
      });
  };

  const handleGetCollections = (filter: boolean, activeTab: string) => {
    instanceOfAxios
      .get(`/list-collection?first=20&offset=${filter ? offsetCollection : 0}&filter=${activeTab === "owns" ? "collected" : activeTab}&profile=${activeTab === "favorite" ? JSON.parse(localStorage.getItem("user") || "{}")?.id : id}`)
      .then(function (res) {
        let result = res.data.nodes;
        let objData: any = [];
        if (result.length !== 0) {
          objData = dataCollection;
        } else {
          objData = [];
        }
        result.forEach((element: any) => {
          let structureResult = {
            label: (
              <>

                <CustomImage image={element.logo_image} extensionImage={element.logo_image_ext} className={"img-collection"} />

                <span className="collection-text">
                  {assert(element.collection_name, 13)}
                </span>
              </>
            ),
            value: element.collection_id,
          };

          objData.push(structureResult);
        });
        if (filter) {
          setDatacollection(objData);
        } else {
          setDatacollection([...objData]);
        }
        setDataloadcollectionlist(res.data.pageInfo.hasNextPage);
        if (res.data.pageInfo.hasNextPage === true) {
          setOffsetcollection(offsetCollection + 20);
        } else {
          setOffsetcollection(20);
        }
      });
  };

  const showMore = (etatBool: any, etat: any) => {
    switch (etat !== "") {
      case etat === "collection":
        getCollection(etatBool);
        break;
      case etat === "items":
        fetchDataItems(etatBool);
        break;
      case etat === "owns":
        fetchDataOwns(etatBool);
        break;
      case etat === "favorite":
        if (isLoggedIn) {
          fetchDataFavorite(etatBool);
        }
        break;
      default:
        getCollection(etatBool);
        fetchDataItems(etatBool);
        fetchDataOwns(etatBool);
        if (isLoggedIn) {
          fetchDataFavorite(etatBool);
        }
        break;
    }
  };

  useEffect(() => {
    showMore(true, activeTab);
  }, [body, likesStore.isLiked]);

  useEffect(() => {
    if (activeTab !== "collection") {
      handleGetCollections(false, activeTab);
      setDatacollection([])
    }
    setBody({ collection: "", type: "", sortby: "", statusAuction: "" });
    showMore(true, "activeTab");
  }, [profileAddress, activeTab]);

  const loadData = (el: any, type: any) => {
    if (type === "collection") {
      return (
        <Col span={9} className="col-style">
          <FeatureCollection collection={el} />
        </Col>
      );
    } else {
      return (
        <Col span={9} className="col-style">
          <RecentItem item={el} />
        </Col>
      );
    }
  };

  const onchangeActiveTab = (tab: any) => {
    setBody({ collection: "", type: "", sortby: "", statusAuction: "" });
    setActiveTab(tab);
    setDatacollection([])

  };

  return (
    <div className="profileDetails_tab">
      <ProfileTab
        profile={true}
        menu={menuTab}
        elementsCount={[
          collectionsCount,
          itemsCount,
          favoriteCount,
          draftCount,
        ]}
        activeTab={activeTab}
        setActiveTab={(tab) => onchangeActiveTab(tab)}
      />
      <div style={{ marginBottom: "30px" }}>
        <MobileTab
          tabs={menuTab}
          activeTab={t(`collection-page.${activeTab}`)}
          customHeaderClass="collection-profile-mobile-tab"
          onChangeTab={(tab) => onchangeActiveTab(tab)}
        />
      </div>
      {activeTab !== "collection" ? (
        <div className="collection filters">
          {activeTab !== "owns" ? (
            <FilterProfile
              data={type}
              title={"Type"}
              setOffset={setOffset}
              activeTab={activeTab}
              body={body}
              setBody={setBody}
            />
          ) : (
            <FilterProfile
              data={[]}
              title={"Type"}
              setOffset={setOffset}
              activeTab={activeTab}
              body={body}
              setBody={setBody}
            />
          )}
          <FilterProfile
            data={status}
            title={"Status"}
            setOffset={setOffset}
            activeTab={activeTab}
            body={body}
            setBody={setBody}
          />
          <FilterProfile
            data={sortBy}
            title={"Sort By"}
            setOffset={setOffset}
            activeTab={activeTab}
            body={body}
            setBody={setBody}
          />
          <FilterProfile
            data={dataCollection}
            title={"Collection"}
            setOffset={setOffset}
            activeTab={activeTab}
            dataLoadCollection={dataLoadCollectionlist}
            handleGetCollections={handleGetCollections}
            body={body}
            setBody={setBody}
          />
        </div>
      ) : (
        ""
      )}
      <Row className="rowCollection">
        {activeTab === "collection" ? (
          collectionsLoading ? (
            <div className="profile-tab-loading-content">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Col
                span={9}
                className="col-style col-add"
                onClick={() => navigate("/collection")}
              >
                <div>
                  <h5>{t("collection-page.add-collection")}</h5>
                  <img
                    alt="addIcon"
                    src={addIcon}
                    style={{ margin: "20px 0px" }}
                  ></img>
                </div>
              </Col>
              {data.map((el: any) => {
                return loadData(el, "collection");
              })}
            </>
          )
        ) : activeTab === "items" ? (
          itemsLoading ? (
            <div className="profile-tab-loading-content">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Col
                span={9}
                className="col-style col-add"
                onClick={() => navigate("/collection")}
              >
                <div>
                  <h5>{t("collection-page.add-new-item")}</h5>
                  <img
                    alt="addIcon"
                    src={addIcon}
                    style={{ margin: "20px 0px" }}
                  ></img>
                </div>
              </Col>
              {dataItems.map((el: any) => {
                return loadData(el, "items");
              })}
            </>
          )
        ) : activeTab === "owns" ? (

          draftLoading ? (
            <div className="profile-tab-loading-content">
              <Spin size="large" />
            </div>
          ) : (
            <>
              {dataDraft.length > 0 ? (
                dataDraft.map((el: any) => {
                  return loadData(el, "owns");
                })
              ) : (
                <Nodata />
              )}
            </>
          )
        ) : favoriteLoading ? (
          <div className="profile-tab-loading-content">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {dataFavorite.length > 0 ? (
              dataFavorite.map((el: any) => {
                return loadData(el, "favorite");
              })
            ) : (
              <Nodata />
            )}
          </>
        )}
      </Row>
      {dataloadFavorite && activeTab === "favorite" ? (
        <button
          className="button-seeMore ant-btn ant-btn-default collections-button"
          onClick={() => showMore(false, activeTab)}
        >
          {t("activity.see-more")}{" "}
        </button>
      ) : dataloadCollection && activeTab === "collection" ? (
        <button
          className="button-seeMore ant-btn ant-btn-default collections-button"
          onClick={() => showMore(false, activeTab)}
        >
          {t("activity.see-more")}{" "}
        </button>
      ) : dataloadItems && activeTab === "items" ? (
        <button
          className="button-seeMore ant-btn ant-btn-default collections-button"
          onClick={() => showMore(false, activeTab)}
        >
          {t("activity.see-more")}{" "}
        </button>
      ) : dataloadDraft && activeTab === "owns" ? (
        <button
          className="button-seeMore ant-btn ant-btn-default collections-button"
          onClick={() => showMore(false, activeTab)}
        >
          {t("activity.see-more")}{" "}
        </button>
      ) : (
        ""
      )}
    </div>
  );
});
export default TabsCollectionProfile;
