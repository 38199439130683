import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../../../../context";
import { setItem } from "../../../../../storage/session";
import MakeOffer from "../boxes/makeOffer";

interface MakeOfferDisplayProps {
  item: any;
  userCurrency: string;
}

const MakeOfferDisplay = ({ item, userCurrency }: MakeOfferDisplayProps) => {
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const { address } = useContext();
  const [makeOfferVisible, setMakeOfferVisible] = useState(false);

  const makeOfferTrigger = () => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    } else {
      setMakeOfferVisible(true);
    }
  };

  return (
    <div>
      <h5 className="action-description-text">
        {t("action-box.make-offer-display")}
      </h5>
      <Button type="primary" block onClick={makeOfferTrigger}>
        {t("action-box.make-offer")}
      </Button>
      <MakeOffer
        item={item}
        visible={makeOfferVisible}
        handleOk={() => setMakeOfferVisible(false)}
        handleCancel={() => setMakeOfferVisible(false)}
        blockchainCurrency={"EGLD"}
        userCurrency={userCurrency}
      />
    </div>
  );
};

export default MakeOfferDisplay;
