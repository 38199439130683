import { object, string, InferType } from "yup";

const elrondEnv = process.env.REACT_APP_SERVER_ELROND;

export const minDust: string = "5000000000000000"; // 0.005 EGLD
export const decimals: number = 2;
export const denomination: number = 18;
export const genesisTokenSuply: number = 20000000;
export const feesInEpoch: number = 0;
export const stakePerNode: number = 2500;
export const protocolSustainabilityRewards: number = 0.1;
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 50000;
export const gasPerDataByte = 1500;

export const elrondApiUrl =
  elrondEnv === "devnet"
    ? "https://devnet-api.multiversx.com/"
    : elrondEnv === "testnet"
    ? "https://testnet-api.multiversx.com/"
    : "https://api.multiversx.com/";

export const network: NetworkType =
  elrondEnv === "devnet"
    ? {
        id: "devnet",
        name: "Devnet",
        chainID: "D",
        egldLabel: "xEGLD",
        walletAddress: "https://devnet-wallet.multiversx.com/dapp/init",
        apiAddress: "https://devnet-api.multiversx.com",
        gatewayAddress: "https://devnet-notifier.fourtytwo.com:7079",
        explorerAddress: "https://devnet-explorer.multiversx.com/",
      }
    : elrondEnv === "testnet"
    ? {
        id: "testnet",
        name: "Testnet",
        chainID: "T",
        egldLabel: "xEGLD",
        walletAddress: "https://testnet-wallet.multiversx.com",
        apiAddress: "https://testnet-api.multiversx.com",
        gatewayAddress: "https://testnet-notifier.fourtytwo.com:7079",
        explorerAddress: "https://testnet-explorer.multiversx.com/",
      }
    : {
        id: "mainnet",
        name: "Mainnet",
        chainID: "1",
        egldLabel: "EGLD",
        walletAddress: "https://wallet.multiversx.com",
        apiAddress: "https://api.multiversx.com",
        gatewayAddress: 'https://notifier.fourtytwo.com:7079',
        explorerAddress: "https://explorer.multiversx.com/",
      };

const networkSchema = object({
  id: string()
    .defined()
    .required(),
  egldLabel: string()
    .defined()
    .required(),
  name: string()
    .defined()
    .required(),
  chainID: string()
    .defined()
    .required(),
  walletAddress: string(),
  apiAddress: string(),
  gatewayAddress: string(),
  explorerAddress: string(),
}).required();

export type NetworkType = InferType<typeof networkSchema>;

networkSchema.validate(network, { strict: true }).catch(({ errors }) => {
  console.error(`Config invalid format for ${network.id}`, errors);
});


//WalletConnect 2
export const walletConnectV2ProjectId = 'c6389a668a22dae94b3dff1d08a5fbf7';
export const apiTimeout = 6000;