import { useState } from "react";
import { Avatar, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "./recentItem.less";
import LikeButton from "../likeButton";
import EditIcon from "../../../../../assets/images/edit.svg";
import { useContext } from "../../../../context";
import useWorstDisplay from "../../../../helpers/useWorstDisplay";
import useImageDisplay from "../../../../helpers/useImageDisplay";
import useUserNaming from "../../../../helpers/useUserNaming";
import { useGlobalContext } from "../../../../context/globalContext";
import ICPLAY from "../../../../../assets/images/ic-play-button.png";
import ICAUDIO from "../../../../../assets/images/ic-audio.png";
import PrivewItemDetails from "../../../collection/components/itemExtraFile/privewItemDetails";

export interface ItemProps {
  draft?: boolean;
  edit?: boolean;
  item: any;
  itemprofile?: boolean;
  favorit?: boolean;
  activeTab?: any;
  erdAdressConnect?: any;
}

const RecentItem = observer(
  ({
    item,
    edit,
    itemprofile,
    favorit,
    activeTab,
    erdAdressConnect,
  }: ItemProps) => {
    const { t } = useTranslation();
    const { address } = useContext();
    const navigate = useNavigate();
    const { itemDetailsStore } = useGlobalContext();

    const [price, setPrice] = useState("200000");
    const itemTitle = useWorstDisplay(item?.title, 20);
    const collectionName = useWorstDisplay(
      item?.collection?.collection_name,
      20
    );
    const collectionImage = useImageDisplay(
      item?.collection?.logo_image,
      "__t" + item?.collection?.logo_image_ext
    );
    let ownerAvatar;
    if (item?.owner?.avatar_ext) {
      ownerAvatar = useImageDisplay(
        item?.owner?.avatar,
        "__t" + item?.owner?.avatar_ext
      );
    } else {
      ownerAvatar = useImageDisplay(item?.owner?.avatar, "");
    }

    const imageItem = useImageDisplay(item?.uri, "__m" + item?.uri_ext);
    const NameOwner = useUserNaming(item?.owner);

    const handleRedirect = () => {
      itemDetailsStore.setIsItemPhotoLoading(true);
      window.scrollTo(0, 0);
      if (item.type === "nft") {
        navigate(`/nft/${item?.token_identifier}${window.location.hash}`);
      } else {
        navigate(`/item-details/${item?.id}${window.location.hash}`);
      }
    };

    const handleRedirectItem = () => {
      window.scrollTo(0, 0);
      if (item.type === "nft") {
        navigate(`/nft/${item?.token_identifier}${window.location.hash}`);
      } else {
        navigate(`/item-details/${item?.id}${window.location.hash}`);
      }
    };

    return (
      <>
        {!item ? (
          ""
        ) : (
          <div className="item-container">
            <div className="item-image-container img-hover-zoom">
              <a
                href={
                  item.type === "nft"
                    ? `/nft/${item?.token_identifier}${window.location.hash}`
                    : `/item-details/${item?.id}${window.location.hash}`
                }
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="images-alt"
              >
                {imageItem !== undefined && imageItem !== null && (
                  <>
                    {imageItem.includes(".glb") ||
                    imageItem.includes(".gltf") ||
                    imageItem.includes(".obj") ||
                    imageItem.includes(".fbx") ? (
                      <PrivewItemDetails
                        path={imageItem}
                        fileType={imageItem}
                        pathRedirection={
                          item.type === "nft"
                            ? `/nft/${item?.token_identifier}${window.location.hash}`
                            : `/item-details/${item?.id}${window.location.hash}`
                        }
                      />
                    ) : (
                      <>
                        <img
                          alt={`${item?.title}`}
                          src={edit ? collectionImage : imageItem}
                          className="item-image"
                          onClick={handleRedirect}
                        />
                        {item.uri1 !== undefined && item.uri1 !== null && (
                          <>
                            {(item.uri1.includes(".mp3") ||
                              item.uri1.includes(".ogg") ||
                              item.uri1.includes(".wav") ||
                              item.uri1.includes(".mp4") ||
                              item.uri1.includes(".webm")) && (
                              <>
                                <img
                                  src={
                                    item.uri1.includes(".mp4") ||
                                    item.uri1.includes(".webm")
                                      ? ICPLAY
                                      : ICAUDIO
                                  }
                                  alt="ICPLAY"
                                  className="play-block-icon-recent-item"
                                />
                                <div className="circle-recent-item  delay-item1"></div>
                                <div className="circle-recent-item  delay-item2"></div>
                                <div className="circle-recent-item  delay-item3"></div>
                                <div className="circle-recent-item  delay-item4"></div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </a>
            </div>
            {activeTab === t("collection-page.items") &&
            address === erdAdressConnect ? (
              <div className="like-button-container">
                <a
                  href={`/edit-item/${item?.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="images-alt"
                >
                  <img
                    alt="icon-edit"
                    src={EditIcon}
                    width="35"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(`/edit-item/${item?.id}`, {
                        replace: true,
                      });
                    }}
                  />
                </a>
              </div>
            ) : activeTab === t("collection-page.draft") ? (
              ""
            ) : (
              <LikeButton
                liked={item?.isFavorite}
                itemId={item?.id}
                className="like-button-container-collection-item"
              />
            )}
            <div className="item-description">
              <div className="item-text-container">
                <a
                  href={
                    item.type === "nft"
                      ? `/nft/${item?.token_identifier}${window.location.hash}`
                      : `/item-details/${item?.id}${window.location.hash}`
                  }
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <h5
                    style={{ cursor: "pointer" }}
                    onClick={handleRedirectItem}
                  >
                    {useWorstDisplay(itemTitle, 15)}
                  </h5>
                </a>
                <Tooltip title={item?.collection?.collection_name}>
                  <a
                    href={`/${item?.collection?.homepage}`}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <h5
                      style={{
                        cursor: "pointer",
                        lineBreak: "anywhere",
                      }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(`/${item?.collection?.homepage}`, {
                          replace: true,
                        });
                      }}
                      className="text-regular-font"
                    >
                      {collectionName}
                    </h5>
                  </a>
                </Tooltip>
              </div>
              {activeTab === t("collection-page.draft") ? (
                ""
              ) : (
                <div className="price-holder">
                  {itemprofile || favorit ? (
                    <h6 className="price-tag ">{`${price} EGLD`}</h6>
                  ) : (
                    <h6 className="price-tag ">{`${item?.likes} ${t(
                      "likes"
                    )}`}</h6>
                  )}
                </div>
              )}
            </div>
            {activeTab === t("collection-page.draft") ? (
              ""
            ) : (
              <div className="item-description">
                <div
                  className="item-avatar-block"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(`/profile-details/${item?.owner.id}`, {
                      replace: true,
                    });
                  }}
                >
                  <a
                    className="item-avatar-block"
                    href={"profile-details/" + item?.owner?.id}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Avatar
                      style={{ cursor: "pointer" }}
                      src={ownerAvatar}
                      size="large"
                    />
                    <span className="nameOwner">{NameOwner}</span>
                  </a>
                </div>

                {itemprofile || favorit ? (
                  <h5>{item?.type}</h5>
                ) : (
                  <h5>{item?.type}</h5>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);

export default RecentItem;
