import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import OrangeCard from "../orangeCard/orangeCard";
import illustration1 from "../../../../../assets/images/illustration1.png";
import illustration2 from "../../../../../assets/images/illustration2.png";
import CustomDots from "./customDots/customDots";
import { useContext } from "../../../../context";
import { setItem } from "../../../../storage/session";

const CardsCarousel = () => {
  const { t } = useTranslation();
  const { address } = useContext();
  const  navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const redirect = () => {
    if (address) {
      window.scrollTo(0, 0);
      navigate("/collection");
    } else {
      setItem("goToUrl", window.location.href);
      navigate("/auth");
    }
  };
  return (
    <div className="caroussel">
      <Carousel
        partialVisbile={false}
        itemClass="image-item"
        deviceType={"desktop"}
        responsive={responsive}
        removeArrowOnDeviceType={["desktop"]}
        showDots={true}
        customDot={<CustomDots />}
        renderDotsOutside={true}
        dotListClass="custom-dot-list-style"
      >
        <OrangeCard
          image={illustration1}
          title={t("home.call-to-action0-headline")}
          description={t("home.call-to-action0-text")}
          buttonTitle={t("home.call-to-action0-button")}
          firstImage={true}
          onClick={redirect}
        />
        <OrangeCard
          image={illustration2}
          title={t("home.call-to-action1-headline")}
          description={t("home.call-to-action1-text")}
          buttonTitle={t("home.call-to-action2-text")}
          firstImage={false}
          onClick={redirect}
        />
      </Carousel>
    </div>
  );
};

export default CardsCarousel;
