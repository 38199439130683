import * as React from "react";
import { useTranslation } from "react-i18next";
import "./profileSider.less";
import { useEffect, useState } from "react";
import instanceOfAxios from "../../../../../configAxios";
import { Spin } from "antd";

export interface ProfileSiderProps {
  checked?: any;
  erdAdressConnect?: any;
}

const ProfileStatics = ({ checked, erdAdressConnect }: ProfileSiderProps) => {
  const { t } = useTranslation();
  const [statistics, setStatistcs] = useState<any>({});
  const [statsLoading, setStatsLoading] = useState(false);

  useEffect(() => {
    erdAdressConnect && setStatsLoading(true);
    instanceOfAxios
      .get("collection_statistics/address/" + erdAdressConnect)
      .then((res) => {
        setStatistcs(res?.data);
        setStatsLoading(false);
      })
      .catch((err: any) => {
        setStatsLoading(false);
      });
  }, [erdAdressConnect]);

  function intToString(value: number) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 !== 0) {
      shortValue = parseFloat(shortValue.toFixed(1));
    }
    return shortValue + suffixes[suffixNum];
  }
  return (
    <div className="profile-sider-container">
      <>
        <div className="profil-sider-row" style={{ paddingTop: "25px" }}>
          <h5 className="text-regular-font text-regular">
            {t("collection-page.statistics")}
          </h5>
        </div>

        {statsLoading ? (
          <div className="profile-stats-loading-container">
            <Spin />
          </div>
        ) : (
          <>
            {statistics?.collection_published > 0 ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.collections-created")}
                </h5>
                <h5 className="text-regular-font">
                  {intToString(statistics?.collection_published)}
                </h5>
              </div>
            ) : (
              ""
            )}
            {statistics?.nft_created > 0 ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.nfts-created")}
                </h5>
                <h5 className="text-regular-font">
                  {intToString(statistics?.nft_created)}
                </h5>
              </div>
            ) : (
              ""
            )}
            {statistics?.sft_created ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.sfts-created")}
                </h5>
                <h5 className="text-regular-font">
                  {intToString(statistics?.sft_created)}
                </h5>
              </div>
            ) : (
              ""
            )}
            {statistics?.nft_in_auction ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.nfts-inauction")}
                </h5>
                <h5 className="text-regular-font">
                  {intToString(statistics?.nft_in_auction)}
                </h5>
              </div>
            ) : (
              ""
            )}
            {statistics?.nft_and_sft_sold ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.nft-sft-Sold")}
                </h5>
                <h5 className="text-regular-font">
                  {intToString(statistics?.nft_and_sft_sold)}
                </h5>
              </div>
            ) : (
              ""
            )}
            {checked ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.sum-of-cost")}
                </h5>
                <h5 className="text-regular-font">
                  {intToString(statistics?.sum_of_cost)}
                </h5>
              </div>
            ) : (
              ""
            )}
            {checked ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.royality-fee-earned")}
                </h5>
                <div className="collection-profile-price-holder">
                  <h5 className="text-regular-font">
                    {intToString(statistics?.royalty_fee_earned)}
                  </h5>
                </div>
              </div>
            ) : (
              ""
            )}
            {statistics?.number_of_views > 0 ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.number-of-views")}
                </h5>
                <div className="collection-profile-price-holder">
                  <h5 className="text-regular-font">
                    {intToString(statistics?.number_of_views)}
                  </h5>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* {statistics?.trading_volume > 0 ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.trading-volume")}
                </h5>
                <div className="collection-profile-price-holder">
                  <h5 className="text-regular-font">
                    {intToString(parseInt(statistics?.trading_volume))}
                  </h5>
                </div>
              </div>
            ) : (
              ""
            )} */}
            {checked ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.auction-proceeds")}
                </h5>

                <div className="collection-profile-price-holder">
                  <h5 className="text-regular-font">
                    {intToString(parseInt(statistics?.auction_proceeds))}
                  </h5>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* {statistics?.ranking > 0 ? (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">{t("home.menu-rankings")}</h5>
                <div className="collection-profile-price-holder">
                  <h5 className="text-regular-font">
                    {intToString(statistics?.ranking)}
                  </h5>
                </div>
              </div>
            ) : (
              ""
            )} */}
          </>
        )}
      </>
    </div>
  );
};

export default ProfileStatics;
