import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import instanceOfAxios from "../../../../../../configAxios";
import { useContext } from "../../../../../context";
import { setItem } from "../../../../../storage/session";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../../components/toasts/openToast";
import { useEffect, useState } from "react";
import { getItemFromLocalStorage } from "../../../../../helpers/useLocalStorage";

interface AdminApproveDisplayProps {
  item: any;
}

const AdminApproveDisplay = ({ item }: AdminApproveDisplayProps) => {
  const { t } = useTranslation();
  const { address } = useContext();
  const  navigate = useNavigate();
  const [isNotApproved, setIsNotApproved] = useState(true);

  useEffect(() => {
    setIsNotApproved(true);
  }, [item]);

  const approveORdisapproveItem = (status: string) => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    } else {
      instanceOfAxios
        .post(
          `change_status_item/${item?.id}/address/${address}?status=${
            status === "approve" ? true : false
          }`,
          {},
          {
            headers: {
              "x-access-token": getItemFromLocalStorage("token"),
            },
          }
        )
        .then((res) => {
          switch (status) {
            case "approve":
              OpenSuccessNotification(
                t("profile.success"),
                t("action-box.approve-item-successfull"),
                3
              );
              break;

            case "decline":
              OpenSuccessNotification(
                t("profile.success"),
                t("action-box.decline-item-successfull"),
                3
              );
              break;

            default:
              break;
          }
          instanceOfAxios
            .get(`item_box_action/${item?.id}?erd_address=${address}`)
            .then((res) => {
              setIsNotApproved(false);
            })
            .catch((error) => {
              console.log("err", error);
              return error;
            });
        })
        .catch((error) => {
          console.log("err", error);
          if(error?.response.status===401){
            OpenErrorNotification(
              t("profile.error"),
              t("profile.error-unauthorized-user"),
              3
            );                    
          }else{
            OpenErrorNotification(
              t("profile.error"),
              t("profile.error-desc"),
              3
            );
          } 
          return error;
        });
    }
  };

  return (
    <>
      {isNotApproved && (
        <>
          <div className="auction-content">
            <h4 className="title-auction">
              {t("action-box.item-not-approved-yet")}
            </h4>
            <h6 className="desc-auction">
              {t("action-box.approve-item-question")}
            </h6>
          </div>
          <div className="approve-item-buttons-holder">
            <div
              className="admin-buttons admin-decline-button"
              onClick={() => approveORdisapproveItem("decline")}>
              {t("action-box.decline")}
            </div>
            <div
              className=" admin-buttons admin-approve-button"
              onClick={() => approveORdisapproveItem("approve")}>
              {t("action-box.approve")}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminApproveDisplay;
