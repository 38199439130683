import * as React from "react";
import { useEffect } from "react";
import { IntlProvider, FormattedNumber } from "react-intl";

export interface NumberDisplayi18nProps {
  currency: string;
  value: number;
  crypto: boolean;
}

const NumberDisplayi18n = ({
  currency,
  value,
  crypto,
}: NumberDisplayi18nProps) => {
  return (
    <IntlProvider
      locale={localStorage.getItem("language") || "en"}
      defaultLocale="en"
    >
      <FormattedNumber value={value} style="currency" currency={""} />
      {` ${currency}`}
    </IntlProvider>
  );
};

export default NumberDisplayi18n;
