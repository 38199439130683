import React from 'react';
import './transparentButton.less';

export interface TransparentButtonProps {
	icon?: string;
	text: string;
	onClick: () => void;
}

const TransparentButton = ({ icon, text, onClick }: TransparentButtonProps) => {
	return (
		<div className="transparent-button-holder" onClick={onClick}>
			<div className="empty-button-container">
				<h6 className="transparent-button-text">{text}</h6>
				{icon && <img alt="transparent icon" src={icon} className="transparent-button-icon" />}
			</div>
		</div>
	);
};

export default TransparentButton;
