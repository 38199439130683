import { Button, Modal, Result, Spin } from "antd";
import { useTranslation } from "react-i18next";
import auctionIcon from "../../../../../../assets/images/auction.svg";

interface LoadingModalProps {
  visible: boolean;
  title: string;
  message: string;
  auctionSetup?: boolean;
  actionTitle?: string;
  setActionAllowed?: (ok: boolean) => void;
}

const LoadingModal = ({
  visible,
  title,
  message,
  auctionSetup,
  actionTitle,
  setActionAllowed,
}: LoadingModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      footer={null}
      destroyOnClose
      maskClosable={false}
      closable={false}
      keyboard={false}
    >
      <div className="transaction-modal-container">
        <h3 className="primary-color">{title}</h3>

        <h5 className="transaction-wait-message">{message}</h5>
        {auctionSetup && (
          <Result
            icon={<img src={auctionIcon} style={{ width: "60px" }} />}
            title={<h3>{t("action-box.auction-wait-message")}</h3>}
            subTitle={<h5>{t("action-box.auction-wait-message-desc")}</h5>}
          />
        )}
        {!setActionAllowed && <Spin size="large" />}

        {/* This code is for the case if we need a button in the loading modal */}
        {setActionAllowed && (
          <Button type="primary" onClick={() => setActionAllowed(true)}>
            {actionTitle}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default LoadingModal;
