import React from 'react';
import { Tooltip } from 'antd';
import './iconButton.less';

export interface IconButtonProps {
	title?: string;
	icon: string;
	onClick?: () => void;
	disabled?: boolean;
	disabledIcon?: string;
	disabledTitle?: string;
}

const IconButton = ({ title, icon, onClick, disabled, disabledIcon, disabledTitle }: IconButtonProps) => {
	return (
		<Tooltip title={disabled ? disabledTitle || '' : title || ''}>
			<div
				className={disabled ? 'icon-button-container icon-disabled' : 'icon-button-container icon-enabled'}
				onClick={disabled ? () => {} : onClick}>
				<img alt="icon button" className="icon-button" src={disabled ? disabledIcon : icon} />
			</div>
		</Tooltip>
	);
};

export default IconButton;
