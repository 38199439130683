import { useNavigate } from "react-router-dom";
import { Col, Row, Tooltip } from "antd";
import Creators from "./creators";
import "../home.less";
import useWorstDisplay from "../../../helpers/useWorstDisplay";
import useUserNaming from "../../../helpers/useUserNaming";
import useImageDisplay from "../../../helpers/useImageDisplay";
import PrivewItemDetails from "../../collection/components/itemExtraFile/privewItemDetails";

export interface CollectionProps {
  collection: any;
  profile?: boolean;
}

const FeatureCollection = ({ collection, profile }: CollectionProps) => {
  const  navigate = useNavigate();
  const creatorDisplay = useUserNaming(collection?.creator);
  const imageItem_0 = useImageDisplay(collection?.items[0]?.uri,'__m'+collection?.items[0]?.uri_ext);
  const imageItem_1 = useImageDisplay(collection?.items[1]?.uri,'__m'+collection?.items[1]?.uri_ext);
  const imageItem_2 = useImageDisplay(collection?.items[2]?.uri,'__m'+collection?.items[2]?.uri_ext);
  const titleImage = useImageDisplay(collection?.logo_image,'__m'+collection?.logo_image_ext);
    

  const handleRedirect=()=>{
    window.scrollTo(0, 0);
    if(collection?.items[0]?.type==="nft"){
      navigate(`/nft/${collection?.items[0]?.token_identifier}`);
    }else {
      navigate(`/item-details/${collection?.items[0]?.id}`);
    }
  }

  const handleRedirectToItem=()=>{
    window.scrollTo(0, 0);
    if(collection?.items[1]?.type==="nft"){
      navigate(`/nft/${collection?.items[1]?.token_identifier}`);
    }else {
      navigate(`/item-details/${collection?.items[1]?.id}`);
    }
  }
  const handleRedirectToNft=()=>{
    window.scrollTo(0, 0);
    if(collection?.items[2]?.type==="nft"){
      navigate(`/nft/${collection?.items[2]?.token_identifier}`);
    }else {
      navigate(`/item-details/${collection?.items[2]?.id}`);
    }
  }
  

  return (
    <div className="feature-collection">
      <div className="big-image-container img-hover-zoom">
      <a
          href={`/${collection?.homepage}`}
          onClick={(e) => {
            e.preventDefault();
          }}>
            <img
              style={{ cursor: "pointer" }}
              alt={`${collection?.collection_name}`}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/${collection?.homepage}`);
              }}
              src={titleImage}
              className="big-image"
            />
          </a>
      </div>
      {collection?.items.lenght === 0 ? (
        ""
      ) : (
        <Row gutter={[16, 16]} className="small-images-container">
          {collection?.items[0] ? (
            <Col span={8}>
              {" "}
              <div
                className={
                  profile
                    ? "small-image-container img-margin img-hover-zoom image-collection-profile"
                    : "small-image-container img-margin img-hover-zoom"
                }
              >
              <a
                  href={collection?.items[0]?.type==="nft"?`/nft/${collection?.items[0]?.token_identifier}`
                  :`/item-details/${collection?.items[0]?.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}>
                    {(imageItem_0.includes(".glb") || imageItem_0.includes(".gltf") || imageItem_0.includes(".fbx") ||  imageItem_0.includes(".obj"))
                    ?<PrivewItemDetails path={imageItem_0} fileType={imageItem_0} className="item-details-left-item" pathRedirection={collection?.items[0]?.type==="nft"?`/nft/${collection?.items[0]?.token_identifier}`
                    :`/item-details/${collection?.items[0]?.id}`}/>
                    :
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={handleRedirect}
                      alt={`${collection?.items[0]?.title}`}
                      src={imageItem_0}
                      className="small-image"
                    />
                    
                    }
                  </a>
              </div>
            </Col>
          ) : (
            ""
          )}
          {collection?.items[1] ? (
            <Col span={8}>
              <div
                className={
                  profile
                    ? "small-image-container img-margin img-hover-zoom image-collection-profile"
                    : "small-image-container img-margin img-hover-zoom"
                }
              >
                <a
                  href={collection?.items[1]?.type==="nft"?`/nft/${collection?.items[1]?.token_identifier}`
                  :`/item-details/${collection?.items[1]?.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}>
                    {(imageItem_1.includes(".glb") || imageItem_1.includes(".gltf") || imageItem_1.includes(".fbx") ||  imageItem_1.includes(".obj"))
                    ?<PrivewItemDetails path={imageItem_1} fileType={imageItem_1} className="item-details-left-item" pathRedirection={collection?.items[1]?.type==="nft"?`/nft/${collection?.items[1]?.token_identifier}`
                    :`/item-details/${collection?.items[1]?.id}`} />
                    :
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={handleRedirectToItem}
                      alt={`${collection?.items[1]?.title}`}
                      src={imageItem_1}
                      className="small-image"
                    />
                    }
                  </a>
              </div>
            </Col>
          ) : (
            ""
          )}
          {collection?.items[2] ? (
            <Col span={8}>
              {" "}
              <div
                className={
                  profile
                    ? "small-image-container img-margin img-hover-zoom image-collection-profile"
                    : "small-image-container img-margin img-hover-zoom"
                }
              >
                <a
                  href={collection?.items[2]?.type==="nft"?`/nft/${collection?.items[2]?.token_identifier}`
                  :`/item-details/${collection?.items[2]?.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}>
                    {(imageItem_2.includes(".glb") || imageItem_2.includes(".gltf") || imageItem_2.includes(".fbx") ||  imageItem_2.includes(".obj"))
                    ?<PrivewItemDetails path={imageItem_2} fileType={imageItem_2} className="item-details-left-item" pathRedirection={collection?.items[2]?.type==="nft"?`/nft/${collection?.items[2]?.token_identifier}`
                    :`/item-details/${collection?.items[2]?.id}`} />
                    :
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={handleRedirectToNft}
                      alt={`${collection?.items[2]?.title}`}
                      src={imageItem_2}
                      className="small-image"
                    />
                    }
                  </a>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      )}
      <Tooltip title={collection?.collection_name} placement="bottom">
        <a
          href={`/${collection?.homepage}`}
          onClick={(e) => {
            e.preventDefault();
          }}>
            <h3
              style={{ marginBottom: "0", cursor: "pointer" }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/${collection?.homepage}`);
              }}
            >{`${useWorstDisplay(collection?.collection_name, 27)}`}</h3>
          </a>
      </Tooltip>
      <div className="collection-owner-holder">
          <a
          className="collection-owner-holder"
            href={`/profile-details/${collection?.creator?.id}`}
            onClick={(e) => {
              e.preventDefault();
            }}>
              <Creators creators={[collection?.creator]} />
              <h4
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(`/profile-details/${collection?.creator?.id}`);
                }}
                className="text-regular-font"
                style={{ padding: "0 10px", cursor: "pointer" }}
              >
                {creatorDisplay}
              </h4>
            </a>
      </div>
    </div>
  );
};

export default FeatureCollection;
