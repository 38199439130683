import { Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/rarityLogo.svg";
import LightButton from "../../../components/buttons/lightButton";
import Search from "../../../components/input/search";
import CustomMenu from "./menu";
import ProfileButton from "./profile button/profileButton";
import NotifIcons from "./notif icons/notifIcons";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";


const Navbar = ({ scrolled }: { scrolled: boolean }) => {
  const { t } = useTranslation();
  const  navigate = useNavigate();
	const {  address } = useGetAccountInfo();  

  return (
    <Row>
      <Col
        span={24}
        // style={{background : `${localStorage.getItem("theme") !== 'dark' ? '#ffffff' : '#181818' }`}}
        className={
          scrolled ? "navbar-container floatingNav" : "navbar-container"
        }
      >
        <div className="navbar-first-part">
          <img
            alt="logo"
            className="logo-img"
            src={logo}
            style={{ width: "6.5vw" }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/");
            }}
          />
          <div className="nav-search-container">
            <Search />
          </div>
        </div>
        <div className="navbar-second-part">
          <CustomMenu mobile={false} />
          <div className="button-holder">
          <a
              href={`/collection`}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <LightButton
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/collection");
                }}
              >
                {t("home.menu-create")}
              </LightButton>
            </a>
          </div>
          {address &&
          JSON.parse(localStorage.getItem("user") || "{}") ? (
            <>
              <NotifIcons />
              <ProfileButton />
            </>
          ) : (
            <>
              <div className="button-holder">
                <a
                    href={`/auth`}
                    onClick={(e) => {
                      e.preventDefault();
                    }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate("/auth");
                      }}
                      style={{ width: "100%" }}
                      loading={
                        window.location.search !== "" &&
                        window.location.pathname === "/"
                      }
                    >
                      {t("home.menu-connect")}
                    </Button>
                    </a>
              </div>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Navbar;
