import { useTranslation } from "react-i18next";
import { Menu, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { logout } from "@multiversx/sdk-dapp/utils";
import profileIcon from "../../../../../assets/images/profile button icon.svg";
import logoutIcon from "../../../../../assets/images/logout icon orange.svg";
import userIcon from "../../../../../assets/images/user profile.svg";
import egldIcon from "../../../../../assets/images/EGLD.svg";
import "./profileButton.less";
import { getItem } from "../../../../storage/session";
import useUserNaming from "../../../../helpers/useUserNaming";
import useImageDisplay from "../../../../helpers/useImageDisplay";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../../context/globalContext";
import { observer } from "mobx-react-lite";

const ProfileButton = observer(({ setMenuVisible }: { setMenuVisible?: any }) => {
  const { account } = useGetAccountInfo();
  const { t } = useTranslation();
  const { profieInfoStore, notificationsStore } = useGlobalContext()
  const  navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("user") || "{}")
  );
  const userDisplay = useUserNaming(userProfile);

  // let avatarDisplay = useImageDisplay(userProfile?.avatar, `__m${userProfile?.avatar_ext}`) + ` ${userProfile?.avatar?.includes("gravatar") ? "" : "?" + new Date().getTime()}`
  let avatarDisplay = useImageDisplay(
    userProfile?.avatar,
    `__t${userProfile?.avatar_ext}`
  );

  useEffect(() => {
    if (
      !JSON.parse(localStorage.getItem("user") || "{}")?.id &&
      getItem("user")?.id
    ) {
      localStorage.setItem("user", JSON.stringify(getItem("user")));
      if (!localStorage.getItem("address") && getItem("address")) {
        localStorage.setItem("address", getItem("address") || "");
      }
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      if (userProfile?.avatar_ext !== JSON.parse(localStorage.getItem("user") || "")) {
        setUserProfile(JSON.parse(localStorage.getItem("user") || ""))
      }
    }



  }, [profieInfoStore.isPorifleImageUpdated])


  const logOut = () => {
    logout(`${window.location.origin}${window.location.pathname}`);
    localStorage.clear()
    notificationsStore.setMenuVisible(false);
  };

  const menu = (
    <Menu>
      <div className="profile-dropdown-header">
        <h6 className="text-regular-font tiny-font">
          {t("profile.your-balance")}
        </h6>
        <h6 className="tiny-font">
          {`${(Number(account?.balance) / 1000000000000000000).toFixed(4) ||
            0.0}`}  
          <img alt="EGLD" src={egldIcon} className="egld-icon" />
        </h6>
      </div>
      <Menu.Divider />
      <Menu.Item key="0" className="profile-dropdown-menu-item">
        <div
          onClick={() => {
            notificationsStore.setMenuVisible(false);
            window.scrollTo(0, 0);
            navigate(`/profile-details/${userProfile?.id}`);
          }}
        >
          <a
            href={`/profile-details/${userProfile?.id}`}
            onClick={(e) => {
              e.preventDefault();
            }}
            className="profile-dropdown-item"
          >
            <img alt="userIcon" src={userIcon} className="profile-dropdown-item-icon" />
            <h6>{t("home.menu-profile")}</h6>
          </a>
        </div>
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => logOut()}
        className="profile-dropdown-menu-item"
      >
        <div className="profile-dropdown-item">
          <img alt="logoutIcon" src={logoutIcon} className="profile-dropdown-item-icon" />
          <h6>{t("profile.menu-logout")}</h6>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight" arrow>
      <div className="profile-button-container">
        <div className="navbar-avatar-image-container">
          <img alt="avatarIcon" src={userProfile?.avatar_ext ? avatarDisplay + "?" + profieInfoStore.isPorifleImageUpdated : avatarDisplay} className="navbar-avatar-image" />
        </div>
        <div className="profile-button-text-container">
          <h6>{userDisplay}</h6>
        </div>
        <div>
          <img alt="profileIcon" src={profileIcon} />
        </div>
      </div>
    </Dropdown>
  );
});

export default ProfileButton;
