import ImgCrop from "antd-img-crop";
import { useTranslation } from "react-i18next";
import { beforeUpload } from "../../helpers/uploadFunctions";

interface CropImageProps {
  children: any;
  title: string;
  shape: string;
  width: number;
  height: number;
  grid: boolean;
  setOriginalFile: (original: File) => void;
  setCroppedObject: (cropped: any) => void;
}

const CropImage = ({
  children,
  title,
  width,
  height,
  grid,
  setOriginalFile,
  setCroppedObject,
}: CropImageProps) => {
  let mymidth = 0;
  let myheight = 0;
  let originalPhoto = "";
  const { t } = useTranslation();

  const getImageSize = async (e: any) => {
    let src = e?.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    }
    const image = new Image();
    image.src = src;
    originalPhoto = src;
    image.onload = () => {
      mymidth = image.width;
      myheight = image.height;
    };
  };

  const getok = async (e: any, o: string, width: any, height: any) => {
    let src = e?.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);

        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    e.imgWidth = width;
    e.imgHeight = height;
    setCroppedObject({
      originalFile: e,
      previewUrl: e?.type === "image/svg+xml" ? o : src,
    });
  };

  return (
    <ImgCrop
      showGrid={grid}
      aspect={width || 1 / height || 1}
      modalOk={t("common.save")}
      modalCancel={t("common.cancel")}
      modalTitle={title}
      beforeCrop={(e: any) => {
        if(beforeUpload(
          e,
          t("profile.image-type-error", {
            supported_image_types: "Png, Jpg, gif or svg",
          }),
          t("profile.image-size-error")
        )){
          setOriginalFile(e);
          getImageSize(e);
          return true;
        }else{
          return false;
        }
      }}
      onModalOk={(e: any) => getok(e, originalPhoto, mymidth, myheight)}
    >
      {children}
    </ImgCrop>
  );
};

export default CropImage;
