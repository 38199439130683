import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import { FileImageOutlined, DeleteFilled } from "@ant-design/icons";
import CropImage from "../../../../../components/crop image/cropImage";
import { beforeUpload } from "../../../../../helpers/uploadFunctions";
import { useGlobalContext } from "../../../../../context/globalContext";
import { observer } from "mobx-react-lite";

interface ImageUploadProps {
  image: string;
  size: string[];
  setCollectionImage: (e: any) => void;
  setOriginalImage?: (e: any) => void;
  setPreview?: any;
}

const ImageUpload = observer(({
  image,
  size,
  setCollectionImage,
  setOriginalImage,
  setPreview,
}: ImageUploadProps) => {
  const { Dragger } = Upload;
  const { t } = useTranslation();
  const [imagePreview, setImagePreview] = useState("");
  let originalFile: any = {};
  const { itemDetailsStore } = useGlobalContext()


  useEffect(() => {
    if (image !== "") {
      setImagePreview(image);
      setPreview && setPreview(image);
    }
  }, [image]);

  const props = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  };

  return (
    <div style={{ width: size[0], height: size[1] }}>
      {imagePreview ? (
        <div className="item-image-preview-container">
          <img alt="item preview" src={imagePreview} className="item-image-preview" />
          <DeleteFilled
            className="red-color delete-image-button-container"
            onClick={() => {
              setOriginalImage && setOriginalImage("");
              setCollectionImage("");
              setImagePreview("");
              setPreview && setPreview("");
            }}
          />
        </div>
      ) : (
        <CropImage
          title={t("collection-page.crop-modal-title")}
          shape="rect"
          width={1}
          height={1}
          grid={true}
          setOriginalFile={(e: File) => {
            //we don't save in state because when we save it's final, in this case if the user cancelled, it would be a prb
            //so we save in the state in the next function because there we are sure that the user didn't cancel
            originalFile = e;
          }}
          setCroppedObject={(e: any) => {
            //this is for original file saving, in this case we're sure the user didn't cancel before crop
            setOriginalImage && setOriginalImage(originalFile);
            if (
              originalFile?.type === "image/gif" ||
              originalFile?.type === "image/svg+xml"
            ) {
              setCollectionImage(originalFile);
            }

            //here the cropped ones :
            if (
              e?.originalFile?.type !== "image/gif" &&
              e?.originalFile?.type !== "image/svg+xml"
            ) {
              setCollectionImage(e?.originalFile);
            }
            setImagePreview(e?.previewUrl);
            setPreview && setPreview(e?.previewUrl);
      

          }}
        >
          <Dragger
            showUploadList={false}
            onChange={()=>itemDetailsStore.setIsItemPhotoLoading(false)}
            beforeUpload={(file: any) =>
              beforeUpload(
                file,
                t("profile.image-type-error", {
                  supported_image_types: "Png, Jpg, gif or svg",
                }),
                t("profile.image-size-error")
              )
            }
            accept=".jpg, .jpeg, .png, .gif, .svg"
            {...props}
          >
            <p className="ant-upload-drag-icon">
              <FileImageOutlined />
            </p>
            <p className="ant-upload-text">
              {t("collection-page.collection-upload-title")}
            </p>
            <p className="ant-upload-hint">
              {t("collection-page.collection-upload-description")}
            </p>
          </Dragger>
        </CropImage>
      )}
    </div>
  );
});

export default ImageUpload;
