import React, { useEffect, useState } from "react";
import { Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import searchIcon from "../../../assets/images/search icon.svg";
import "./input.less";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/globalContext";
import { observer } from "mobx-react-lite";

const Search =observer(({ onSearch }: { onSearch?: any }) => {
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const {exploreStore}=useGlobalContext()

  const search = (search: any) => {
    navigate(`/explore?search=${search.target.value}`);  
    onSearch && onSearch();
  };

  const onSetSearch=(event:any)=>{
    exploreStore.setFilterSearchKeyWord(event.target.value)
    exploreStore.setSearchLengthKeys(event.target.value.length)
  }

  return (
    <>
      <Input
        onPressEnter={(event) => search(event)}
        onChange={(event)=>onSetSearch(event)}
        placeholder={t("home.menu-search")}
        size="large"
        prefix={<img alt="searchIcon" src={searchIcon} style={{ marginRight: "10px" }} />}
        className="search-input"
      />
    </>
  );
});

export default Search;
