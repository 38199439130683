import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Card } from "antd";
import TableGenesis from "./tableGenesis";
import instanceOfAxios from "../../../configAxios";
import { useGlobalContext } from "../../context/globalContext";
import "./genesis.less";
import { OpenErrorNotification } from "../../components/toasts/openToast";
import backIcon from "../../../assets/images/backIcon.svg";

export interface INodesGenesis {
  artistNft: [];
  brand_creation: string;
  collection: any;
  contributor_invitation: any;
  countGenesisNft: number;
  countLikes: number;
  countNft: number;
  created_at: string;
  genesisNft: [];
  id: number;
  private_access: any;
  profile: any;
  tradingVolume: number;
  updated_at: string;
  podcast: string;
  spotlight: string;
}

const Genesis = observer(() => {
  const { genesisCollection } = useGlobalContext();
  const { t } = useTranslation();

  const getGeneisCollection = async () => {
    genesisCollection.setIsAPICalled(true);
    instanceOfAxios
      .get("genesis_collection")
      .then((response: any) => {
        genesisCollection.setIsDataReady(true);
        genesisCollection.setNodesJoined(response.data.joinned.nodesJoined);
        genesisCollection.setNodesNomminated(
          response.data.nomminated.nodesNominated
        );
      })
      .catch((err: any) => {
        OpenErrorNotification(t("profile.error"), t("profile.error-desc"), 3);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!genesisCollection.isAPICalled) {
      getGeneisCollection();
    }
  }, [genesisCollection.isAPICalled]);

  const getBack = () => {
    history.go(-1);
  };

  return (
    <>
      <div className="ContentPage">
        <div className="back-button-genesis" onClick={getBack}>
          <img src={backIcon} alt="backIcon" className="back-icon-genesis" />
          <h4 className="back-text-genesis">{t("auth.go-back")}</h4>
        </div>
        <h3 className="titre">{t("genesis-collection.countdown")}</h3>
        <h1 className="sousTitre" style={{ textTransform: "uppercase" }}>
          {t("genesis-collection.genesis")}
        </h1>
        <div className="placeLeft">
          <div>{t("genesis-collection.places-left")} </div> &nbsp;{" "}
          {genesisCollection.isDataReady && (
            <div>{100 - genesisCollection.nodesJoined.length}</div>
          )}
        </div>
        <Card>
          <div className="discover">
            <h2 className="discoverTitre">
              {t("genesis-collection.discover-genesis")}
            </h2>
            <h4>{t("genesis-collection.handpicked")}</h4>
            <h5>{t("genesis-collection.genesis-description")}</h5>
          </div>
          <div>
            <TableGenesis
              data={genesisCollection.nodesJoined}
              timeFilter={[]}
              categoryFilter={[]}
              dataLoad={false}
            />
          </div>
          <div className="nominated-list-bloc">
            <h6>{t("genesis-collection.nominated-artists")}</h6>
            <TableGenesis
              data={genesisCollection.nodesNomminated}
              timeFilter={[]}
              categoryFilter={[]}
              dataLoad={false}
            />
          </div>
        </Card>
      </div>
    </>
  );
});
export default Genesis;
