import React from 'react';
import arrow from '../../../../assets/images/activeArrow.svg';
import infoIcon from '../../../../assets/images/icon information.svg';
import './infoNotif.less';

export interface InfoNotifProps {
	description: string;
	buttonText: string;
	onClick?: () => void;
}

const InfoNotif = ({ description, buttonText, onClick }: InfoNotifProps) => {
	return (
		<div className="notif-holder">
			<div className="notif-icon-holder">
				<img alt="infoIcon" src={infoIcon} className="notif-icon" />
			</div>
			<div>
				<h6 className="text-regular-font">{description}</h6>
				<h6 className="primary-color notif-text-button" onClick={onClick}>
					{buttonText}
					<img alt="arrow" src={arrow} className="notif-arrow" />{' '}
				</h6>
			</div>
		</div>
	);
};

export default InfoNotif;
