import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate , useParams } from 'react-router-dom';
import './collectionSuccess.less';

const CollectionSuccess = (props: any) => {
	const { t } = useTranslation();
	const  navigate = useNavigate();
	const  params  = useParams();

	return (
		<div className="collection-message-page">
			<div className="collection-message-container">
				<h2>{t('collection-page.collection-success-message')} </h2>
				<a
					href={`/create-item/${params.id}`}
					onClick={(e) => {
						e.preventDefault();
					}}>
					<Button type="primary" onClick={() => navigate(`/create-item/${params.id}`)}>
						{t('collection-page.collection-success-button')}{' '}
					</Button>
				</a>
			</div>
		</div>
	);
};

export default CollectionSuccess;
