import { action, makeAutoObservable, observable } from 'mobx';

class ItemDetailsStore {
	first = 8;
	@observable offset: number = -this.first;
	@observable isItemPhotoLoading: boolean = true;
	@observable role: any = JSON.parse(localStorage.getItem("user") || "{}")?.role
	@observable pageNumber: number = 0;
	@observable isRelatedItemLoading: boolean = true;
	@observable idItem: string = "";
	@observable itemsRelated: any[] = [];
	@observable pageInfos: any = {
		hasNextPage: false,
		hasPreviousPage: false,
		totalCount: 0,
	}
	@observable isAuctionBoxStatusChange: boolean = false;
	
	constructor() {
		makeAutoObservable(this);
	}
	@action
	setOffset(value: number) {
		this.offset = value;
	}
	@action
	setIsItemPhotoLoading(value: boolean) {
		this.isItemPhotoLoading = value;
	}
	@action
	setPageNumber(value: number) {
		this.pageNumber = value;
	}
	@action
	setIsRelatedItemLoading(value: boolean) {
		this.isRelatedItemLoading = value;
	}
	@action
	setIdItem(value: string) {
		this.idItem = value;
	}
	@action
	setItemsRelated(value: any[]) {
		this.itemsRelated = value;
	}
	@action
	setPageInfos(value: any) {
		this.pageInfos = value;
	}
	@action
	setIsAuctionBoxStatusChange(value: boolean) {
		this.isAuctionBoxStatusChange = value;
	}
}

export default new ItemDetailsStore();
