import React, { useState, useEffect } from "react";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { Address } from "@multiversx/sdk-core/out";
import { useGetIsLoggedIn } from "@multiversx/sdk-dapp/hooks";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account";
import { Spin, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import backIcon from "../../../assets/images/backIcon.svg";
import walletIcon from "../../../assets/images/EGLD.svg";
import ledgerIcon from "../../../assets/images/ledger.png";
import maiarIcon from "../../../assets/images/maiar.png";
import i18n from "../../../i18n";
import OptionButton from "../../components/buttons/optionButton";
import State from "../../components/State";
import { useContext, useDispatch } from "../../context";
import { removeItem } from "../../storage/session";
import "./auth.less";
import { Unlock } from "../../components/Unlock";
const Auth = () => {
  const { error } = useContext();
  const [language] = useState("");
  const pageCase = window.location.pathname;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = React.useRef(null);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useGetIsLoggedIn();
  const { account, address } = useGetAccountInfo();
  const [loginLoading, setLoginLoading] = useState(false);


  const loginProcess = (address: string) => {
    setLoginLoading(true);
    removeItem("wallet_login");
    dispatch({ type: "login", address: address });
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams as any);
    const addressFromSearch = params?.address;

    if (addressFromSearch !== undefined && new Address(params.address)) {
      loginProcess(addressFromSearch);
    } else if (address && new Address(address)) {
      loginProcess(address);
    } else if (isLoggedIn && account) {
      if (account?.address === "") {
        setLoginLoading(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        loginProcess(account?.address);
      }
    }

    removeItem("wallet_login");
    dispatch({ type: "loading", loading: false });

    if (language) {
      i18n.changeLanguage(language);
      localStorage.setItem("language", language);
    }
  }, [isLoggedIn, pageCase, language]);

  const redirect = () => {
    if (pageCase.includes("create")) {
      window.scrollTo(0, 0);
      navigate("/auth");
    } else {
      window.scrollTo(0, 0);
      navigate("/create");
    }
  };

  const getBack = () => {
    navigate("/");
  };

  return (
    <div ref={ref}>
      {error ? (
        <State
          icon={faBan}
          iconClass="text-primary"
          title="Something went wrong"
          description="If the problem persists please contact support."
        />
      ) : loginLoading ? (
        <div className="login-loading-container">
          <div>
            <Spin size="large" />
            <h3>{t("auth.connecting-loading")}</h3>
          </div>
        </div>
      ) : (
        <div className="auth-page-container">
          <Row>
            <Col span="24">
              <div onClick={getBack} className="back-button">
                <img
                  src={backIcon}
                  alt="backIcon"
                  className={
                    localStorage.getItem("language") === "ar"
                      ? "reverse-arrow back-icon"
                      : "back-icon"
                  }
                />
                <h4 className="back-text">{t("auth.go-back")}</h4>
              </div>
            </Col>
          </Row>
          <div className="login-part">
            <div className="login-container">
              <div className="login-welcome-text">
                {pageCase.includes("create") ? (
                  <h1> </h1>
                ) : (
                  <h1>{t("auth.welcome")}</h1>
                )}
                <h1 className="title-2">
                  {pageCase.includes("create")
                    ? t("auth.create-title")
                    : t("auth.connect-title")}
                </h1>
                <h4 className="text-regular-font">
                  {pageCase.includes("create")
                    ? t("auth.create-description")
                    : t("auth.connect-description")}
                </h4>
              </div>
              {pageCase.includes("create") ? (
                <div className="buttons-container">
                  <OptionButton
                    icon={maiarIcon}
                    option="xPortal Wallet App"
                    description={t("auth.xportal-description")}
                    link="https://xport.al/referral/e7a2n8vbpl"
                    linkName={t("auth.create-one")}
                    onClick={() =>
                      window.location.assign(
                        "https://xport.al/referral/e7a2n8vbpl"
                      )
                    }
                  />
                  <OptionButton
                    icon={maiarIcon}
                    option="MultiversX DeFi Wallet"
                    description={t("auth.defi-description")}
                    link="https://docs.multiversx.com/wallet/wallet-extension/"
                    linkName={t("auth.create-one")}
                    onClick={() =>
                      window.location.assign(
                        "https://docs.multiversx.com/wallet/wallet-extension/"
                      )
                    }
                  />
                  <OptionButton
                    icon={walletIcon}
                    option="Elrond Web Wallet"
                    description={t("auth.wallet-description")}
                    link="https://wallet.multiversx.com/create"
                    linkName={t("auth.create-one")}
                    onClick={() =>
                      window.location.assign(
                        "https://wallet.multiversx.com/create"
                      )
                    }
                  />
                  <OptionButton
                    icon={ledgerIcon}
                    option={t("auth.ledger")}
                    description={t("auth.ledger-description")}
                    link="https://shop.ledger.com?r=402456732958&tracker=Rarity.market"
                    linkName={t("auth.buy-one")}
                    onClick={() =>
                      window.location.assign(
                        "https://shop.ledger.com?r=402456732958&tracker=Rarity.market"
                      )
                    }
                  />
                </div>
              ) : (
                <div className="auth-buttons-container">
                 <Unlock/>
                </div>
              )}
              <h4 className="text-regular-font">
                {pageCase.includes("create")
                  ? t("auth.create-second-option-description")
                  : t("auth.connect-second-option-description")}
              </h4>
              <div className="other-option-button">
                <h3 className="other-option-link" onClick={redirect}>
                  {pageCase.includes("create")
                    ? t("auth.create-second-option-button")
                    : t("auth.connect-second-option-button")}
                </h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Auth;
