import React from 'react';
import noDataIcon from '../../../assets/images/noData.png';
import './nodata.less';

export interface NoDataProps {}

const Nodata = ({  }: NoDataProps) => {
	return (
		<div className="nodata">
			<img alt="nodata" src={noDataIcon} className="" />
		</div>
	);
};

export default Nodata;
