import { useTranslation } from "react-i18next";
import { Col, Row } from "antd"
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const DataProtection = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  

  return (
    <>
      <Helmet>
        <title>Rarity.market | Privacy Policy</title>
      </Helmet>
      <div className="title-static-pages faq-body-container">
        <Row>
          <Col>
            <a href="/" className="breadcrumbPath">
              {t("Home")}
            </a>
            <span>{">"}</span>
          </Col>
          <Col>
            {" "}
            <span className="primary-color">{t("home.Data-protection")}</span>
          </Col>
        </Row>
        <div style={{ marginTop: "40px" }}>
          <h4>{t("home.Data-protection")}</h4>

          <h4 style={{paddingTop:20}}>Datenschutzhinweise</h4>
          <div className="privacy-policy-page-description">
          <p>
          Der Schutz Ihrer Daten ist uns sehr wichtig. Für eine transparente Zusammenarbeit weisen wir Sie auf wichtige Verarbeitungstätigkeiten und Besonderheiten hin.
          </p>
          <h5>Zweck und Rechtsgrundlage der Datenverarbeitung</h5>
          <p>
          Die Datenverarbeitung in der Fourty Two AG erfolgt zur Vertragsabwicklung nach Art. 6 Abs. 1 lit. b DSGVO und, sofern der Forderung kein Vertrag zugrunde liegt, im Rahmen des Interessenabgleichs nach Art. 6 Abs. 1 lit. f DSGVO, wobei das berechtigte Interesse im Zusammenhang steht mit:
          </p>
          <ul>
            <li>Der Beantwortung von Anfragen</li>
            <li>Der Verwaltung und Administration</li>
          </ul>
          <p>
          Als Unternehmen unterliegen wir verschiedenen rechtlichen Verpflichtungen. Zur Erfüllung dieser Verpflichtungen kann eine Verarbeitung von personenbezogenen Daten, nach Art. 6 Abs.1c DSGVO rechtlicher Verpflichtungen oder nach Art.6 Abs.1e DSGVO im öffentlichen Interesse, erforderlich sein.
          </p>
          <ul>
            <li>Kontroll- und Meldepflichten</li>
            <li>Kreditwürdigkeits-, Alters- und Identitätsprüfungen</li>
            <li>Prävention / Abwehr strafbarer Handlungen</li>
          </ul>
          <p>
          Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO)
          </p>
          <p>
          Fourty Two AG<br />
          c/o Do Well GmbH<br />
          Gewerbestrasse 5<br />
          6330 Cham<br />
          <br />
          Switzerland<br /><br />
          <a href="https://fourtytwo.com/" target="_blank">https://fourtytwo.com</a> - Discover our new product. With <a href="https://fourtytwo.com/white-label-dapp/">Whitel Label Dapp</a> as a service (SaaS) we can help your project connect to MultiversX. <br />
          </p>
          <p>
          Wenn Sie Fragen zum Thema Datenschutz haben, schreiben Sie uns eine E-Mail an privacy @ <img src="/static/media/rarityLogo.6556aa1d.svg" height="25" alt="logo"></img> .market.
          </p>
          <h5>Geltungsbereich</h5>
          <p>
          Diese Datenschutzhinweise gelten für folgende Angebote:
          </p>
          <ul>
            <li>unsere Internetseite rarity.market</li>
            <li>immer dann, wenn sonst aus einem unserer Angebote auf diese Datenschutzhinweise verwiesen wird, unabhängig davon, auf welchem Weg Sie es aufrufen oder verwenden.</li>
          </ul> 
          <p>
          <h5>Erhebung personenbezogener Daten bei Kontaktaufnahme Ihrerseits</h5>
          <p>Wenn Sie uns eine E-Mail schreiben per Chat oder telefonisch kontaktieren, erheben, bearbeiten und speichern wir folgende Daten von Ihnen und nutzen diese zum Zwecke der Beantwortung Ihrer Anfrage.
          </p>
          <ul>
            <li>Vorname, Name</li>
            <li>Festnetz- / Mobil-Telefonnummer</li>
            <li>E-Mail-Adresse</li>
          </ul>
          <h5>Erhebung personenbezogener Daten bei Besuch unserer Website</h5>
          <p>
          Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten,erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO): 
          </p>
          <ul>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time(GMT)</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Zugriffsstatus / HTTP-Statuscode</li>
            <li>jeweils übertragene Datenmenge</li>
            <li>Website, von der die Anforderung kommt</li>
            <li>Browser</li>
            <li>Betriebssystem und dessen Oberfläche</li>
            <li>Sprache und Version der Browsersoftware</li>
            <li>Crypto Wallet (Adresse mit Guthaben, Währungen und Sammlerstücken)</li>
          </ul>
          <p>
          Die Informationen in Ihrem Crypto Wallet sind, wie später beschrieben, auch ohne unsere Dienste öffentlich einsehbar.
          </p>
          <h5>Einsatz von Cookies</h5>
          <p>Wir wenden für unsere Website technisch notwendige Cookies und sogenannte Session-Cookies. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet, gespeichert werden und durch welche der Stelle, die den Cookie setzt, bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu gestalten. Transiente Cookies (Session-Cookies) werden automatisiert gelöscht, wenn Sie den Browser schließen. Session-Cookies speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen. Rechtsgrundlage für die Verwendung von Cookies ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Falls Sie uns für das Setzen von Cookies eine Einwilligung erteilt haben, so ist Art. 6 Abs. 1 S. 1 lit. a DSGVO zusätzliche Rechtsgrundlage für die Verwendung von Cookies.
          </p>    
          <p>Wir verwenden keine weiteren Tracking -Technologien, außer den Analysedienst Matomo. Die Beschreibung erfolgt weiter unten. 
          Beim Besuch unserer Website findet keine automatisierte Entscheidungsfindung, das sogenannte Profiling, statt.
          Wir verarbeiten Ihre personenbezogenen Daten nur zu dem hier angegeben Zweck. Jede weitere Verarbeitung zu anderen Zwecken bedarf Ihrer vorherigen Einwilligung.
          </p>
          <h5>Registrierung in unserem Log-In Bereich</h5>
          <p>
          Soweit Sie unseren Log-In Bereich nutzen möchten, müssen Sie sich mittels Angabe Ihrer Crypto- Wallet registrieren. Das Anmelden und Verbinden mit den Crypto-Wallet und deren Adresse ist ist verpflichtend, alle weiteren Informationen können Sie freiwillig durch Nutzung unseres Marktplatzes bereitstellen.
          Wenn Sie unseren Marktplatz nutzen, speichern wir Ihre zur Vertragserfüllung erforderlichen Daten, bis Sie Ihren Zugang endgültig löschen. Alle Angaben können Sie im geschützten Kundenbereich verwalten, ändern und auch löschen. Rechtsgrundlage erfolgt gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
          </p>
          <h5>Besonderheiten bei Nutzung unserer Website</h5>
          <p>
          Alle Blockchain Transaktionen können öffentlich unter diesen Adressen eingesehen werden:
          </p>
          <ul>
            <li><a href="https://explorer.multiversx.com/" target="_blank">https://explorer.multiversx.com/</a></li>
            <li><a href="https://elrondscan.com/" target="_blank">https://elrondscan.com/</a></li>
            <li><a href="https://elrondmonitor.com/" target="_blank">https://elrondmonitor.com/</a></li>
          </ul>
          <p>
          Wir generieren eine transparente Übersicht über den Handelsverlauf von NFTs und eine quantitative Übersicht an erstellten und gehandelten NFTs. Des weiteren sind Beziehungen von Benutzern ersichtlich, gekennzeichnet durch  "Follower" und "Friends".
          Weiterhin führen wir Statistiken und Ranglisten und zeigen den durchschnittlichen Wert der NFTs und das Handelsvolumen pro Benutzer als auch pro Sammlung an.
          </p>
          <h5>Übermittlung von Daten</h5>
          <p>
          Es erhalten unser Hosting-Anbieter Linode.com in Philadelphia, PA, USA und diejenigen Abteilungen und Mitarbeiter der Fourty Two AG Ihre Daten, die diese zur Erfüllung der vertraglichen und gesetzlichen Pflichten sowie berechtigten Interessen benötigen. 
          Darüber hinaus erhalten von uns beauftragte Auftragsverarbeiter (insb. IT-Dienstleister) Ihre Daten, sofern und soweit diese die Daten zur Erfüllung ihrer jeweiligen Leistungen benötigen. Bei Vorliegen gesetzlicher Verpflichtungen oder berechtigter Interessen können öffentliche Stellen (z.B. Gerichte, Behörden) Empfänger Ihrer personenbezogenen Daten sein. Empfänger können auch Dritte sein, an die wir zu den o.g. Zwecken Ihre Daten übermitteln. Ihre Daten werden ausschließlich auf Grundlage der oben genannten Rechtsgrundlagen an Dritte weitergegeben. Weiterhin werden keine Daten an Drittländer weitergegeben.
          </p>
          <h5>Empfänger der personenbezogenen Daten</h5>
          <p>IT-Sicherheitsdienstleister</p>
          <p>
          Betroffene Daten: Zugriffsdaten Rechtsgrundlage: Art. 6 Abs. 1 Buchst. f) DSGVO<br />
          Berechtigtes Interesse: Verhinderung von Angriffen durch Ausnutzen von Sicherheitslücken / Schwachstellen<br />
          </p>
          <h5>Einsatz Analysedienst „Matomo“</h5>
          <p>
          Diese Website nutzt den Webanalysedienst Matomo, um die Nutzung unserer Website analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Rechtsgrundlage für die Nutzung von Matomo ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Beim ersten Besuch unserer Website können Sie dazu Ihre Einwilligung abgegeben, in dem Sie das Häckchen bei …. setzen. 
          Für diese Auswertung werden die bereits erwähnten Session-Cookies auf Ihrem Computer gespeichert. Die so erhobenen Informationen speichern wir ausschließlich auf seinem Server in Frankfurt am Main, Deutschland. Die Auswertung können Sie unterbinden durch Löschung vorhandener Cookies und die Verhinderung der Speicherung von Cookies. Wenn Sie die Speicherung der Cookies verhindern, weisen wir darauf hin, dass Sie gegebenenfalls diese Website nicht vollumfänglich nutzen können. Die Verhinderung der Speicherung von Cookies ist durch die Einstellung in ihrem Browser möglich. 
          Diese Website verwendet Matomo mit der Erweiterung „AnonymizeIP“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine direkte Personenbeziehbarkeit kann damit ausgeschlossen werden. Die mittels Matomo von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen von uns erhobenen Daten zusammengeführt.
          Das Programm Matomo ist ein Open-Source-Projekt. Informationen des Drittanbieters zum Datenschutz erhalten Sie unter <a href="https://matomo.org/docs/privacy/">https://matomo.org/docs/privacy/</a>.
          </p>
          <h5>Keine Verpflichtung zur Bereitstellung & Folgen der Nichtbereitstellung</h5>
          <p>
          Die Bereitstellung der personenbezogenen Daten ist nicht gesetzlich oder vertraglich vorgeschrieben und Sie sind nicht verpflichtet, Daten bereitzustellen. Wir informieren Sie im Rahmen des Eingabeprozesses, wenn die Bereitstellung personenbezogener Daten für den jeweiligen Service erforderlich ist (z.B. durch die Bezeichnung als „Pflichtfeld“). Bei erforderlichen Daten hat die Nichtbereitstellung zur Folge, dass der betreffende Service nicht erbracht werden kann.
          </p>
          <h5>Speicherdauer Ihrer Daten</h5>
          <p>
          Wir verarbeiten Ihre personenbezogenen Daten, soweit erforderlich, für die Dauer der Geschäftsbeziehung, bzw. solange es für die genannten Zwecke erforderlich ist sowie darüber hinaus gemäß den gesetzlichen Aufbewahrungs- und Dokumentationspflichten, die sich insbesondere aus der Abgabenordnung und dem Handelsgesetzbuch ergeben und i.d.R. 10 Jahre betragen. Darüber hinaus können personenbezogene Daten solange gespeichert und aufbewahrt werden, als die Daten für ein anhängiges gerichtliches oder behördliches Verfahren, in dem der Verantwortliche Parteistellung hat, von Bedeutung sind.
          </p>
          <p>
          Ihre Rechte
          </p>
          <p>
          Sie haben uns gegenüber folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
          </p>
          <ul>
            <li>Recht auf Auskunft,</li>
            <li>Recht auf Berichtigung oder Löschung,</li>
            <li>Recht auf Einschränkung der Verarbeitung,</li>
            <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
            <li>Recht auf Datenübertragbarkeit.</li>
          </ul>
          </p>
          <p>    
          Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns, zu beschweren. Diese Beschwerde richten Sie bitte an die zuständige Aufsichtsbehörde unter <a href="https://www.lda.bayern.de">www.lda.bayern.de</a>
          </p>
          <p>
          Benötigen Sie Hilfe bei der Ausübung Ihrer Rechte, dann wenden Sie sich gern an uns. 
          </p>
          <h5>Widerspruch</h5>
          <p>
          Sie haben jederzeit das Recht gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen. Richten Sie diesen bitte schriftlich per E-Mail oder per Post an die Adresse der Fourty Two AG.
          </p>
          <p>
          Stand Januar 2023 <br />
          Erstellt durch die <a href="https://www.datenschutz-werk.de/" target="_blank" >Werk3 für Datenschutz GmbH</a> aus Berlin
          </p>
            
          </div>
        </div>
        </div>
    </>
  )

}
export default DataProtection
