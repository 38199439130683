import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Select } from "antd";
import "./itemAttributes.less";
import Attribute from "../attribute/attribute";
import AddAttribute from "./addAttribute";
import { customAttributeObject } from "../itemForm";
import instanceOfAxios from "../../../../../configAxios";

const { Option } = Select;

export interface ItemAttributesProps {
  customArray: customAttributeObject[];
  setCustomArray: (array: customAttributeObject[]) => void;
  collectionAttributes: any;
  itemAttributes: any;
  setItemAttributes: (obj: any) => void;
  collectionId: string;
}

const ItemAttributes = ({
  customArray,
  setCustomArray,
  collectionAttributes,
  itemAttributes,
  setItemAttributes,
  collectionId,
}: ItemAttributesProps) => {
  const { t } = useTranslation();
  const [topValues, setTopValues] = useState<any[]>([]);

  useEffect(() => {
    collectionAttributes?.mandatory?.length &&
      instanceOfAxios
        .get(`top_attribute_value/collection/${collectionId}`)
        .then((res) => {
          setTopValues(res?.data);
        });
  }, [collectionAttributes]);

  const extractValues = (array: any[], id: number) => {
    let tempArr: any[] = [];
    array
      .filter((el: any) => el.collection_attribute_id === id)
      .map((el: any) => {
        tempArr.push(el.value);
      });
    return tempArr;
  };

  const handleChange = (id: number, value: any, type: string, name: string) => {
    switch (type) {
      case "mandatory":
        if (value?.length > 0) {
          let manAtt = itemAttributes?.mandatory;
          for (let i = 0; i < value.length; i++) {
            //here the case if the change is "value added"
            if (
              !manAtt.some(
                (el: any) =>
                  el.collection_attribute_id === id && el.value === value[i]
              )
            ) {
              //here we considere the useless spaces before and after the text
              let newMan = value[i].trim();
              manAtt.push({
                value: newMan,
                collection_attribute_id: id,
              });
            }
            //here the case if the change is "value removed"
            manAtt = manAtt.filter((el: any) => {
              return (
                el.collection_attribute_id !== id ||
                value.some((val: any) => val === el.value)
              );
            });
          }

          setItemAttributes({ ...itemAttributes, mandatory: manAtt });
        } else {
          let empty_Man = itemAttributes?.mandatory?.filter((el: any) => {
            return (
              el.collection_attribute_id !== id ||
              value.some((val: any) => val === el.value)
            );
          });

          setItemAttributes({
            ...itemAttributes,
            mandatory: empty_Man,
          });
        }
        break;

      case "optional":
        if (value?.length > 0) {
          let optAtt = itemAttributes?.optional;
          for (let i = 0; i < value.length; i++) {
            //here the case if the change is "value added"
            if (
              !optAtt.some(
                (el: any) =>
                  el.collection_attribute_id === id && el.value === value[i]
              )
            ) {
              //here we considere the useless spaces before and after the text
              let newOpt = value[i].trim();
              optAtt.push({
                value: newOpt,
                collection_attribute_id: id,
              });
            }
            //here the case if the change is "value removed"
            optAtt = optAtt.filter((el: any) => {
              return (
                el.collection_attribute_id !== id ||
                value.some((val: any) => val === el.value)
              );
            });
          }

          setItemAttributes({ ...itemAttributes, optional: optAtt });
        } else {
          let empty_opt = itemAttributes?.optional?.filter((el: any) => {
            return (
              el.collection_attribute_id !== id ||
              value.some((val: any) => val === el.value)
            );
          });
          setItemAttributes({ ...itemAttributes, optional: empty_opt });
        }
        break;

      default:
        break;
    }
  };

  const handleCustomChange = async (value: any, key: number, type: string) => {
    switch (type) {
      case "attribute":
        let cusObj = customArray;
        if (cusObj[key] === undefined) {
          cusObj[key] = {
            attribute: "",
            values: [],
          };
        }
        //considering the useless spaces
        cusObj[key].attribute = value?.trim();
        setCustomArray(cusObj);
        break;

      case "values":
        let cusObj2 = customArray;
        if (cusObj2[key] === undefined) {
          cusObj2[key] = {
            attribute: "",
            values: [],
          };
        }
        //considering the useless spaces
        let valuesWithoutSpaces = await value?.map((val: string) => {
          return val?.trim();
        });
        cusObj2[key].values = valuesWithoutSpaces;
        setCustomArray(cusObj2);
        break;

      default:
        break;
    }
  };

  const removeCustomRow = (key: number) => {
    let cusObj = customArray;
    cusObj[key] = {
      attribute: "",
      values: [],
    };
    setCustomArray(cusObj);
  };

  function MandatorytagRender(props: any) {
    const { value, onClose } = props;
    return (
      <Attribute
        text={value}
        closable={true}
        onClose={onClose}
        mandatory={true}
      />
    );
  }

  function OptionaltagRender(props: any) {
    const { value, onClose } = props;
    return (
      <Attribute
        text={value}
        closable={true}
        onClose={onClose}
        suggested={true}
      />
    );
  }

  return (
    <div>
      <h5>
        {`${t("collection-page.item-attributes")}`}
        <span className="required-field">*</span>:
      </h5>
      <Divider />
      <h5 className="red-color">{`${t(
        "collection-page.mandatory-attributes"
      )} :`}</h5>
      {collectionAttributes?.mandatory?.map((el: any,key:number) => {
        return (
          <div className="attribute-space" key={key}>
            <h5 className="text-regular-font">{el?.attribute?.key}</h5>
            <Select
              dropdownClassName="attribute"
              mode="tags"
              allowClear
              style={{ width: "100%" }}
              placeholder={`${t("collection-page.enter")} ${
                el?.attribute?.key
              }`}
              onChange={(value) => {
                handleChange(el?.id, value, "mandatory", el?.attribute?.key);
              }}
              tagRender={MandatorytagRender}
              value={extractValues(itemAttributes?.mandatory, el?.id)}>
              {topValues
                ?.filter(
                  (obj: any) =>
                    obj?.result[0]?.collection_attribute_attribute_id ===
                    el?.attribute?.id
                )[0]
                ?.result?.map((option: any) => {
                  return (
                    <Option
                      key={option?.itemAttribute_value}
                      value={option?.itemAttribute_value}>
                      {`${option?.itemAttribute_value} `}
                      <span className="small-percentage-mandatory">{`${option?.percentage}%`}</span>
                    </Option>
                  );
                })}
            </Select>
          </div>
        );
      })}
      {collectionAttributes?.optional.length !== 0 && (
        <>
          <Divider />
          <h5 className="green-color">{`${t(
            "collection-page.optional-attributes"
          )} :`}</h5>
          {collectionAttributes?.optional?.map((el: any,key:number) => {
            return (
              <div className="attribute-space" key={key}>
                <h5 className="text-regular-font">{el?.attribute?.key}</h5>
                <Select
                  dropdownClassName="attribute"
                  mode="tags"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={`${t("collection-page.enter")} ${
                    el?.attribute?.key
                  }`}
                  defaultValue={[]}
                  onChange={(value) => {
                    handleChange(el?.id, value, "optional", el?.attribute?.key);
                  }}
                  tagRender={OptionaltagRender}
                  tokenSeparators={[","]}
                  value={extractValues(itemAttributes?.optional, el?.id)}>
                  {topValues
                    ?.filter(
                      (obj: any) =>
                        obj?.result[0]?.collection_attribute_attribute_id ===
                        el?.attribute?.id
                    )[0]
                    ?.result?.map((option: any) => {
                      return (
                        <Option
                          key={option?.itemAttribute_value}
                          value={option?.itemAttribute_value}>
                          {`${option?.itemAttribute_value} `}
                          <span className="small-percentage-optional">{`${option?.percentage}%`}</span>
                        </Option>
                      );
                    })}
                </Select>
              </div>
            );
          })}
        </>
      )}
      {/* <Divider />
      <h5 className="primary-color">{`${t(
        "collection-page.custom-attributes"
      )} :`}</h5>
      <AddAttribute
        customAttributes={customArray}
        handleCustomChange={handleCustomChange}
        removeCustomRow={removeCustomRow}
      /> */}
    </div>
  );
};

export default ItemAttributes;
