import { Input, Form, Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import closeIcon from "../../../../assets/images/close.svg";
import instanceOfAxios from "../../../../configAxios";
import { useContext, useDispatch } from "../../../context";
import { getItemFromLocalStorage } from "../../../helpers/useLocalStorage";
import { getItem } from "../../../storage/session";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../toasts/openToast";
import "./emailConfirm.less";

interface EmailConfirmProps {
  confirmVisible: boolean;
  emailExist?: boolean;
  handleClose: () => void;
  email: string;
  emailInput: boolean;
  closable: boolean;
}

const EmailConfirm = ({
  confirmVisible,
  emailExist,
  handleClose,
  email,
  emailInput,
  closable,
}: EmailConfirmProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { address } = useContext();
  const [userEmail, setUserEmail] = useState(email);
  const [confirmSent, setConfirmSent] = useState(!emailInput);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [exist, setExist] = useState(emailExist || false);

  useEffect(() => {
    setUserEmail(email);
    form.setFieldsValue({ email: email });
  }, [email]);

  const sendConfirmEmail = (values: any) => {
    setConfirmLoading(true);
    instanceOfAxios
      .put("profile/" + address, {
        email: values?.email || userEmail,
        language: JSON.parse(localStorage.getItem("user") || "{}")?.language,
      },{
        headers: {
          "x-access-token": getItemFromLocalStorage("token"),
        },
      })
      .then((res: any) => {
        if (res?.data?.code === 1) {
          OpenErrorNotification(t("profile.error"), t("profile.email-used"), 3);
          setExist(true);
          setConfirmLoading(false);
        } else {
          setExist(false);
          setUserEmail(res?.data?.email);
          setConfirmSent(true);
          setConfirmLoading(false);
          dispatch({
            type: "setProfile",
            user: res?.data,
          });
          OpenSuccessNotification(
            t("profile.success"),
            t("profile.confirm-email-sent"),
            3
          );
        }
      })
      .catch((err: any) => {
        if(err?.response.status===401){
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-unauthorized-user"),
            3
          );                    
        }else{
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        } 
      });
  };

  return (
    <Modal
      centered={true}
      title={null}
      visible={confirmVisible}
      footer={null}
      width={"450px"}
      onOk={handleClose}
      onCancel={handleClose}
      closeIcon={
        <img alt="Close Modal" src={closeIcon} style={{ width: "15px" }} />
      }
      destroyOnClose
      closable={true}
      maskClosable={true}
    >
      <div>
        <div>
          {exist && (
            <h4 className="email-modal-description red-color">
              {t("profile.email-exist", {
                email: userEmail,
              })}
            </h4>
          )}
          {!confirmSent ? (
            <Form form={form} onFinish={sendConfirmEmail}>
              {!exist && (
                <div>
                  <h3 className="primary-color">{t("profile.hey")}</h3>
                  <h4 className="email-modal-description">
                    {t("profile.ask-email-for-item")}
                  </h4>
                </div>
              )}
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: t("profile.valid-email-required"),
                  },
                ]}
              >
                <Input placeholder={t("profile.email-placeholder")} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  {t("profile.confirm-email")}
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <div className="confirm-email-centered">
              <h4>
                {t("profile.please-confirm-your-email", { email: userEmail })}
              </h4>
              <Button
                type="link"
                loading={confirmLoading}
                onClick={() => sendConfirmEmail(form?.getFieldsValue)}
              >
                {t("profile.resend-confirm-email")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EmailConfirm;
