import { useEffect, useState } from "react";
import { useContext } from "../../../../context";
import ProfileStatics from "../../../collection/components/profile sider/profileStatics";
import CollectionHeader from "../../../collection/components/collection header/collectionHeader";
import instanceOfAxios from "../../../../../configAxios";
import ProfileSider from "../../../collection/components/profile sider/profileSider";
import TabsCollectionProfile from "./tabsCollectionProfile";
import { Col, Row, Spin } from "antd";

import "./collectionProfile.less";
import ScrollToTop from "../../../explore/components/scrollToTop";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getItemFromLocalStorage } from "../../../../helpers/useLocalStorage";

const ProfileDetails = (props: any) => {
  const { id } = useParams<any>();

  const { address } = useContext();
  const [profile, setProfile] = useState<any>();
  const [localBanner, setLocalBanner] = useState(profile?.banner_image);
  const [extbanner, setExtbanner] = useState(null);

  const [userOrProfile, setUserOrprofile] = useState(Boolean);

  useEffect(() => {
    if (id) {
      instanceOfAxios.get("/profileById/" + id,{
        headers: {
          "x-access-token": getItemFromLocalStorage("token")?getItemFromLocalStorage("token"):"",
        },
      }).then((res) => {
        setProfile(res?.data);
        setLocalBanner(res?.data?.banner_image)
        setExtbanner(res?.data?.banner_image_ext)
      });
    }
  }, [id]);
  

  return (
    <>
     <Helmet>
     <title>Rarity.market - Rarity user</title>
     <meta name="description" content={profile?.bio} />
        <meta
          name="keywords"
          content="NFT, artist, user, profile, digital, elrond"
        />
        <meta content="Rarity user" property="og:title" />
        <meta content={profile?.bio} property="og:description" />
        <meta
          property="og:url"
          content={`https://rarity.market/profile-details/${profile?.id}`}
        />
        <meta
          content="https://rarity.market/static/media/rarityLogo.6556aa1d.svg"
          property="og:image"
        />

        <meta content="Rarity user" property="twitter:title" />
        <meta content={profile?.bio} property="twitter:description" />
        <meta
          property="twitter:url"
          content={`https://rarity.market/profile-details/${profile?.id}`}
        />
        <meta
          content="https://rarity.market/static/media/rarityLogo.6556aa1d.svg"
          property="twitter:image"
        />
     </Helmet>
      {profile?.id ? (
        <div>
          <div className="collection-show-header">
           {extbanner!==null &&  <CollectionHeader
              infosProfile={profile}
              profile={true}
              owner={address ? true : false}
              localBanner={localBanner}
              setLocalBanner={setLocalBanner}
              extbanner={extbanner}
              setExtbanner={setExtbanner}
            />}
          </div>
          <Row justify="center" className="big-collection">
            <Col span={5}>
              <div className="collection-show-content profile-siders">
                <div className="profile-About">
                  <ProfileSider profile={true} infosProfile={profile} />
                </div>
                <div
                  className={
                    address
                      ? "profile-statistic"
                      : "profile-statistic satatistic-condition "
                  }
                >
                  <ProfileStatics
                    checked={userOrProfile}
                    erdAdressConnect={profile?.erd_address}
                  />
                </div>
              </div>
            </Col>
            <Col span={14}>
              <TabsCollectionProfile profileAddress={profile?.erd_address} profileOwns={profile?.count_nft_owns}/>
            </Col>
            <ScrollToTop showBelow={250} />
          </Row>
        </div>
      ) : (
        <div className="item-details-load">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};
export default ProfileDetails;
