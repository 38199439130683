import i18n from './i18n';
import { components } from "./components";
export const i18nLayouts = [ '/staking', '/faq', '/help-center', '/mint-crypto-animal' ];
export const landingRoutes = [
	// {
	//   path: "/genesis-collection",
	//   title: "Genesis collection",
	//   component: "GenesisCollection",
	// },
	// {
	//   path: "/rarity-for-artists",
	//   title: "Rarity for artists",
	//   component: "ArtistsLanding",
	// },
	// {
	//   path: "/rarity-for-nft-project",
	//   title: "Rarity for NFT projects",
	//   component: "NftsLanding",
	// },
	// {
	//   path: "/rarity-for-collectors",
	//   title: "Rarity for collectors",
	//   component: "CollectorsLanding",
	// },
];
export const routes = {
	home: {
		path: '/',
		title: 'Home',
		component: components.HomePage,
		withLayout: true
	},
	editProfile: {
		path: '/my-profile',
		title: 'ProfilePage',
		component: components.EditProfile,
		authenticatedRoute: true,
		withLayout: true
	},
	collection: {
		path: '/collection',
		title: 'collection',
		component: components.CollectionPage,
		withLayout: true
	},
	collectionProfile: {
		path: '/collection-profile/:id',
		title: 'collection-profile',
		component: components.CollectionProfile,
		withLayout: true
	},
	createCollection: {
		path: '/create-collection/:id/:type',
		title: 'create-collection',
		component: components.CreateCollection,
		authenticatedRoute: true,
		withLayout: true
	},
	editCollection: {
		path: '/edit-collection/:slug',
		title: 'edit-collection',
		component: components.CreateCollection,
		authenticatedRoute: true,
		withLayout: true
	},
	createItem: {
		path: '/create-item/:collectionId',
		title: 'create-item',
		component: components.CreateItem,
		authenticatedRoute: true,
		withLayout: true
	},
	editItem: {
		path: '/edit-item/:itemId',
		title: 'edit-item',
		component: components.CreateItem,
		authenticatedRoute: true,
		withLayout: true
	},
	duplicateItem: {
		path: '/duplicate-item/:itemId/:collectionId',
		title: 'duplicate-item',
		component: components.CreateItem,
		authenticatedRoute: true,
		withLayout: true
	},
	collectionSuccess: {
		path: '/collection-success/:id',
		title: 'collection-success',
		component: components.CollectionSuccess,
		withLayout: true
	},
	activity: {
		path: '/activity',
		title: 'Activity',
		component: components.Activity,
		withLayout: true
	},
	ranking: {
		path: '/ranking',
		title: 'Ranking',
		component: components.Ranking,
		withLayout: true
	},
	genesis: {
		path: '/genesis',
		title: 'Genesis',
		component: components.Genesis,
		withLayout: false
	},
	message: {
		path: '/message',
		title: 'Message',
		component: components.Message,
		withLayout: true
	},
	profileDetails: {
		path: '/profile-details/:id',
		title: 'profile-details',
		component: components.ProfileDetails,
		withLayout: true
	},
	newCollection: {
		path: '/newcollection',
		title: 'newCollection',
		component: components.NewCollection,
		withLayout: true
	},
	itemDetails: {
		path: '/item-details/:id',
		title: 'item-details',
		component: components.ItemDetails,
		withLayout: true
	},
	nftDetails: {
		path: '/nft/:id',
		title: 'nft-details',
		component: components.ItemDetails,
		withLayout: true
	},
	explore: {
		path: '/explore',
		title: 'explore',
		component: components.Explore,
		withLayout: true
	},
	error: {
		path: '/error/:type',
		title: 'error',
		component: components.ErrorPage,
		withLayout: true
	},
	maintenance: {
		path: '/maintenance',
		title: 'Maintenance',
		component: components.MaintenancePage,
		withLayout: true
	},
	cookies: {
		path: '/cookies',
		title: 'Cookies',
		component: components.CookiesMissing,
		withLayout: true
	},
	auth: {
		path: '/auth',
		title: 'auth',
		component: components.Auth,
		isDappRoute: true
	},
	create: {
		path: '/create',
		title: 'create',
		component: components.Auth,
		isDappRoute: true
	},
	imprint: {
		path: '/imprint',
		title: 'imprint',
		component: components.Imprint,
		withLayout: true
	},
	privacyPolicy: {
		path: '/privacy-policy',
		title: 'privacyPolicy',
		component: components.DataProtection,
		withLayout: true
	},
	collectionSlug: {
		path: '/collection/:slug',
		title: 'collectionSlug',
		component: components.CollectionProfile,
		withLayout: true
	},
	profileConfirmEmail: {
		path: '/:status/confirm-email/:code',
		title: 'confirmEmail',
		component: components.ConfirmEmail,
		withLayout: true
	},
	faq: {
		path: `/${i18n.language}/faq`,
		title: 'faq',
		component: components.PageFaq,
		withLayout: true
	},
	staking: {
		path: `/${i18n.language}/staking`,
		title: 'Staking page',
		component: components.Staking,
		withLayout: true
	},
	helpCenter: {
		path: `/${i18n.language}/help-center`,
		title: 'helpCenter',
		component: components.HelpCenter,
		withLayout: true
	},
	cryptoAnimal: {
		path: `/${i18n.language}/mint-crypto-animal`,
		title: 'Crypto Animal',
		component: components.CryptoAnimal
	},
	slugOnly: {
		path: '*',
		title: 'collectionSlug',
		component: components.CollectionProfile,
		withLayout: true
	}
};
