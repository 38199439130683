import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { isValidURL } from "../../helpers/validateUrl";
import svgEGLD from "../../../assets/images/EGLD.svg";
import { useNavigate } from "react-router-dom";
import Nodata from "../../components/noData/nodata";
import CustomImage from "../../components/image/customImage";
import NumberDisplayi18n from "../../helpers/numbersi18n";
import { useEffect, useState } from "react";
import { intToString } from "../../helpers/communFunctions";

interface Props {
  data: any[];
  dataLoad: boolean;
  handleShowMore: any;
  timeFilter: any;
  categoryFilter: any;
}

const TableActivity = ({ data, handleShowMore,categoryFilter, timeFilter, dataLoad }: Props) => {
  const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL;
  const serverAPIEndpoint = process.env.REACT_APP_SERVER_IMAGE_BASE_URL;
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const [spin, setSpin] = useState<boolean>(true);

  useEffect(() => {

    if (data.length > 0) {
      setSpin(false);
    }else{
      setSpin(true);
      setInterval(() => {
        setSpin(false);
      }, 4000);
    }
  
  }, [data]);
  
  if (!serverBaseURL) {
    throw new Error();
  }
  if (!serverAPIEndpoint) {
    throw new Error();
  }
  const IMG_URL = serverBaseURL + serverAPIEndpoint;

  if (!isValidURL(IMG_URL)) {
    throw new Error();
  }

  const assert = (text: any, count: any) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  }


  const dataTable = data.map((el, key) => {
    return {
      key: el?.key,
      rank: (<div style={{padding: '0 30px'}}>

        {key + 1}
      </div>
        ),
      collection: (
        <div className="d-flex flex-wrap" style={{ paddingTop: 5 }}>
          <div className="row">
            <span style={{ cursor: "pointer" }} onClick={() => navigate(`/item-details/${el?.id}`)}>
              <CustomImage image={el?.logo_image} extensionImage={el?.logo_image_ext} className={"img-item"} />
            </span>
          </div>
          <div className="item-text">
            <p className="fw-bolder row font-weight-normal" style={{ cursor: "pointer" }} onClick={() => navigate(`/${el?.homepage}`)}>
              {(el?.collection_name) ? assert(el?.collection_name, 15) : ""}
            </p>
          </div>
        </div>
      ),
      volume: (
        <>
        {Number(el?.rankings[0].volume) > 0 ?
        <>
          <NumberDisplayi18n 
            value={Number(Number(el?.rankings[0].volume).toFixed(9))}
            currency=""
            crypto={false}
          />
          <img src={svgEGLD} width="20px" className="egld-icon"/>
        </>
        :"---"
        } 
        </>
      ),
      hours: (
        <>
          {Number(el?.percentage_24h) > 0 ?
          <>
            <NumberDisplayi18n 
              value={Number(Number(el?.percentage_24h).toFixed(9))}
              currency=""
              crypto={false}
            />
            <img src={svgEGLD} width="20px" />
          </>
          :"---"
        } 
        </>
      ),
      days: (
        <>
        {Number(el?.rankings[0].percentage_1d) > 0 ?
        <>
          <NumberDisplayi18n 
            value={Number(Number(el?.rankings[0].percentage_1d).toFixed(9))}
            currency=""
            crypto={false}
          />
          <img src={svgEGLD} width="20px" />
        </>
        :"---"
        } 
        </>
      ),
      price: (
        <>
        {Number(el?.rankings[0].price_avg) > 0 ?
        <>
          <NumberDisplayi18n 
            value={Number(Number(el?.rankings[0].price_avg).toFixed(9))}
            currency=""
            crypto={false}
          />
          <img src={svgEGLD} width="20px" className="egld-icon"/>
        </>
        :"---"
        } 
        </>
      ),
      owners: (
        <>
          {intToString(parseInt(el?.owners_count))}
        </>
      ),
      items:(
        <>
          {intToString(parseInt(el?.items_count))}
        </>
      ),
    };

  });

  const nodataTable = {
    days: (
      <div style={{ margin: "30px auto" }}>
        <Nodata />
      </div>
    ),
  };

  const columns = [
    {
      title: t("ranking.rank"),
      dataIndex: "rank",
      key: "rank",
      className: "text-left",
    },
    {
      title: t("common.collection"),
      dataIndex: "collection",
      key: "collection",
      className: "text-left",
    },
    {
      title: t("ranking.volume"),
      dataIndex: "volume",
      key: "volume",
      className: "text-left",
    },
    {
      title: t("ranking.24h"),
      dataIndex: "hours",
      key: "hours",
      className: "text-left",
    },
    {
      title: t("ranking.7d"),
      dataIndex: "days",
      key: "days",
      className: "text-left",
    },
    {
      title: t("ranking.price"),
      dataIndex: "price",
      key: "price",
      className: "text-left",
    },
    {
      title: t("ranking.owners"),
      dataIndex: "owners",
      key: "owners",
      className: "text-left",
    },
    {
      title: t("ranking.items"),
      dataIndex: "items",
      key: "items",
      className: "text-left",
    },

  ];
  const handelFooter = () => {
    if (dataLoad === true) {
      return <button className="button-seeMore ant-btn ant-btn-default collections-button" onClick={() => handleShowMore(true, timeFilter ,categoryFilter)}>
        {t("activity.see-more")}
      </button>
    } else {
      return ""
    }
  }

  return (
    <>
      <div>

        <Table
          className="table-responsive  table-activity" 
          size={"small"}
          pagination={false}
          columns={columns}
          loading={spin}
          dataSource={(dataTable.length > 0) ? dataTable : [nodataTable]}

          footer={handelFooter}
        />
        <div>

        </div>
      </div>
      {/* {(data.length > 0) ? data.length : 'false'} */}
    </>
  );
};

export default TableActivity;
