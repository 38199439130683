import { useState } from "react";
import { Select, Space } from "antd";
import { Checkbox } from "antd";
import { Radio } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;

interface Props {
  data: any[];
  multiple: boolean;
  keyTraduction: string;
  filterOption: any;
  setFilterOption: any;
  loadMore?: any;
  moreData?: boolean;
  isNotUsingTranslation?: boolean;
}

const OptionSelect = ({
  data,
  filterOption,
  setFilterOption,
  multiple,
  moreData,
  keyTraduction,
  loadMore,
  isNotUsingTranslation
}: Props) => {
  const { t } = useTranslation();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const onChangeCheckbox = (e: any) => {
    setFilterOption(
      e.target.checked ? data.map((el) => (el.value ? el.value : null)) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChange = async (list: any) => {
    setFilterOption(list);
    setIndeterminate(!!list.length && list.length < data.length);
    setCheckAll(list.length === data.length);
  };

  const onChangeRadio = (list: any) => {
    if (list.target.value !== `${t("All")}`) {
      setFilterOption(list.target.value);
    } else {
      setFilterOption("");
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Select
        listHeight={400}
        mode="multiple"
        placeholder={t("All")}
        value={
          filterOption !== "" && filterOption !== null && multiple === false
            ? t(`${keyTraduction}.${filterOption}`)
            : filterOption !== "" &&
              filterOption !== null &&
              filterOption.length > 0 &&
              multiple === true
            ? `${filterOption.map((elm: any) => {
                return isNotUsingTranslation ?`${elm}`: t(`${keyTraduction}.${elm}`) ;
              })}`
            : []
        }
        maxTagCount="responsive"
        showArrow={true}
        className="checkbox-activity"
        showSearch={false}>
        {multiple === true ? (
          <>
            <Option value="">
              <Checkbox
                value={t("All")}
                indeterminate={indeterminate}
                onChange={onChangeCheckbox}
                checked={checkAll}>
                {t("All")}
              </Checkbox>
            </Option>
            <Option value="">
              <CheckboxGroup
                options={data}
                value={filterOption}
                onChange={onChange}
              />
            </Option>
            {moreData == true && (
              <Option value="">
                <button
                  type="button"
                  className="ant-btn"
                  style={{ width: "100%", border: "0", padding: "0" }}
                  onClick={() => loadMore(true)}>
                  <span>{t("activity.see-more")}</span>
                </button>
              </Option>
            )}
          </>
        ) : (
          <Option value="">
            <RadioGroup
              name="radiogroup"
              defaultValue={filterOption===""?"All":filterOption}
              onChange={onChangeRadio}>
              <Space direction="vertical">
                {data.map((el,key) => {
                  return <Radio key={key} value={el.value}>{el.label}</Radio>;
                })}
              </Space>
            </RadioGroup>
          </Option>
        )}
      </Select>
    </Space>
  );
};

export default OptionSelect;
