import { Button } from "antd";
import { useTranslation } from "react-i18next";
import "./display.less";

interface RegisterBrandProps {
    setRegisterGrant: ()=>void;
}

const RegisterGrantDisplay = ({setRegisterGrant}: RegisterBrandProps) => {
  const { t } = useTranslation();

  const registerClick = () => {
      setRegisterGrant()
  };

  return (
    <div>
      <h4 className="primary-color">{t("action-box.create-nft-sft-grant")}</h4>
      <h5 className="action-description-text">
        {t("action-box.create-grant-desc")}
      </h5>
      <Button type="primary" block onClick={registerClick}>
        {t("action-box.register-grant-button")}
      </Button>
    </div>
  );
};

export default RegisterGrantDisplay;
