import { action, makeAutoObservable, observable } from 'mobx';
import { INodesGenesis } from '../modules/genesis/genesis';

class GenesisCollection {
	@observable nodesJoined: INodesGenesis[] = [];
	@observable nodesNomminated: INodesGenesis[] = [];
	@observable isAPICalled: boolean = false;
	@observable isDataReady: boolean = false

	constructor() {
		makeAutoObservable(this);
	}

	/* Setter and getter nodesJoined */
	@action
	setNodesJoined(value: INodesGenesis[]) {
		this.nodesJoined = value;
	}
	/* Setter and getter nodesNomminated */
	@action
	setNodesNomminated(value: INodesGenesis[]) {
		this.nodesNomminated = value;
	}
	/* Setter and getter nodesNomminated */
	@action
	setIsAPICalled(value: boolean) {
		this.isAPICalled = value;
	}
	@action
	setIsDataReady(value: boolean) {
		this.isDataReady = value;
	}
}

export default new GenesisCollection();
