import axios from "axios";
import { isValidURL } from "./main/helpers/validateUrl";
import { logout } from "@multiversx/sdk-dapp/utils";

//create one instance of axios
const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL;
const serverAPIEndpoint = process.env.REACT_APP_SERVER_API_ENDPOINT;

if (!isValidURL(serverBaseURL) || !serverBaseURL) {
  throw new Error();
}
if (!serverAPIEndpoint) {
  throw new Error();
}
const instanceOfAxios = axios.create({
  baseURL: serverBaseURL + serverAPIEndpoint + "/",
});
// Add a request interceptor
// instanceOfAxios.interceptors.request.use(
// 	(config) => {
// 		const token = localStorage.getItem('token')
// 		if (token) {
// 			config.headers['Authorization'] = 'Bearer ' + token;
// 		}
// 		// config.headers['Content-Type'] = 'application/json';
// 		return config;
// 	},
// 	(error) => {
// 		Promise.reject(error);
// 	}
// );

//Add a response interceptor

instanceOfAxios?.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    switch (error.response && error.response.status) {
      case 400:
        //handle token delete(we can do lie signout action but from the login is better)
        break;
      case 401:
        //handle token delete(we can do lie signout action but from the login is better)
        localStorage.clear()
        sessionStorage.clear()
        sessionStorage.setItem('prevPath', window.location.pathname);
        logout(`${window.location.origin}/auth`);
        break;
      case 403:
        //handle token delete(we can do lie signout action but from the login is better)
        // window.location.pathname = "/error/403";
        break;
      case 404:
       window.location.pathname = "/error/404";
        break;
      case 500:
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);
export default instanceOfAxios;
