import React, { Suspense, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Spin } from 'antd';

import GltModel from './gltModel';
import FbxModel from './fbxModel';
import ObjModel from './objModel';
import { IPrivew3DObjects } from './preview3DObject.interface';

function Privew3DObjects(props: IPrivew3DObjects) {
	const { path, fileType } = props;

	return (
		<div>
			<Suspense fallback={<Spin className="spin-3d" />}>
				<Canvas className="canvas-3d">
					<pointLight position={[ 10, 10000, 10000 ]} intensity={1} />
					<pointLight position={[ -10000, 10, 10 ]} intensity={1} />
					<pointLight position={[ 10000, -1000, 10 ]} intensity={2} />
					<color attach="background" args={[ '#f8f8f8' ]} />
					{fileType.includes('.glb') || fileType.includes('.gltf') ? (
						<GltModel modelPath={path} />
					) : fileType.includes('.fbx') ? (
						<FbxModel modelPath={path} />
					) : fileType.includes('.obj') ? (
						<ObjModel modelPath={path} />
					) : null}
					<OrbitControls />
				</Canvas>
			</Suspense>
		</div>
	);
}

export default Privew3DObjects;
