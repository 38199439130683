import React, { useState } from "react";
import { Button, message } from "antd";
import { useTranslation } from "react-i18next";
import "./display.less";
import AcceptOffer from "../boxes/acceptOffer";
import { useContext } from "../../../../../context";
import { verifyNFTonExpectedAddress } from "../../../../../helpers/communFunctions";
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
const SetupAuctionDisplay = ({ item }: { item: any }) => {
  const { t } = useTranslation();
  const { marketPlaceAddress } = useContext();
  const [auctionVisible, setAuctionVisible] = useState(false);
  const { address:userAddress}= useGetAccountInfo();
  const blockchainCurrency = "EGLD";
  const userCurrency = "EUR";

  const setupAuctionClick = () => {
    verifyNFTonExpectedAddress(
      userAddress,
      item?.token_identifier,
    ).then((res: any) => {
      if (res === true) {
        setAuctionVisible(true);
      }else {
        message.warning(t("action-box.nft-not-on-wallet"))
      }
    });
  };

  const handleCancel = () => {
    setAuctionVisible(false);
  };

  return (
    <div>
      <h4 className="primary-color">{t("action-box.setup-auction-title-1")}</h4>
      <h4>{t("action-box.setup-auction-title-2")}</h4>
      <h6 className="action-description-text">
        {t("action-box.setup-auction-desc")}
      </h6>
      <Button type="primary" block onClick={setupAuctionClick}>
        {t("action-box.setup-auction-button")}
      </Button>
      <AcceptOffer
        visible={auctionVisible}
        handleOk={handleCancel}
        handleCancel={handleCancel}
        blockchainCurrency={blockchainCurrency}
        userCurrency={userCurrency}
        offerPrice={0.5}
        order="setup"
        item={item}
        royality={item?.royalties}
      />
    </div>
  );
};

export default SetupAuctionDisplay;
