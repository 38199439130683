import React, { useEffect, useState } from "react";
import { Upload, message, Button, Row, Col, Spin } from "antd";
import { useTranslation } from "react-i18next";
import "./uploadAvatar.less";
import LightButton from "../../../../components/buttons/lightButton";
import instanceOfAxios from "../../../../../configAxios";
import { useContext, useDispatch } from "../../../../context";
import useImageDisplay from "../../../../helpers/useImageDisplay";
import loading from "../../../../../assets/images/loading.png";
import { logger } from "../../../../context/logger";
import { observer } from "mobx-react-lite";
import { useGlobalContext } from "../../../../context/globalContext";
import { OpenErrorNotification } from "../../../../components/toasts/openToast";
import { getItemFromLocalStorage } from "../../../../helpers/useLocalStorage";

export interface UploadAvatarProps {
  avatar: string;
  ext: string;
  onChangeAvatar: (imageUrl: any, ext: any) => void;
}

const UploadAvatar = observer(({ avatar, ext, onChangeAvatar }: UploadAvatarProps) => {
  const { t } = useTranslation();
  const { address } = useContext();
  const{profieInfoStore}= useGlobalContext()
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState({
    imageSrc: avatar,
    imageHash: Date.now(),
  });
  const [avatarLoading, setAvatarLoading] = useState(false);

  let avatarDisplay = useImageDisplay(imageUrl?.imageSrc, `__m${ext}`);
  // let avatarDisplay = useImageDisplay(imageUrl?.imageSrc, `__m${ext}`) + ` ${imageUrl?.imageSrc?.includes("gravatar") ? "" : "?" + new Date().getTime()}`

  function getBase64(img: Blob, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file: any) {
    avatarDisplay = `${loading}`;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(
        t("profile.image-type-error", { supported_image_types: "Png or Jpg" })
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t("profile.image-size-error"));
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = (info: any) => {
    onChangeAvatar(loading, "");
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        setImageUrl({
          imageSrc: imageUrl,
          imageHash: Date.now(),
        });
        onChangeAvatar(imageUrl, "");
      });
    } else if (info.file.status === "error") {
      message.error(t("profile.upload-error"));
    }
  };

  const resetPicture = () => {
    instanceOfAxios
      .delete("upload/" + address,{
        headers: {
          "x-access-token": getItemFromLocalStorage("token"),
        },
      })
      .then((res: any) => {
        setImageUrl({
          imageSrc: res?.data?.avatar,
          imageHash: Date.now(),
        });
        onChangeAvatar(res?.data?.avatar, res?.data?.avatar_ext);
        let user =JSON.parse(localStorage.getItem("user") || "{}");
        user.avatar=res?.data?.avatar
        user.avatar_ext=res?.data?.avatar_ext
        localStorage.setItem("user", JSON.stringify(user));
        dispatch({ type: "setProfile", user: user });
        profieInfoStore.setIsPorifleImageUpdated( new Date().getTime())     
        message.success("Reset picture successfully");
      })
      .catch((err: any) => {
        if(err?.response.status===401){
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-unauthorized-user"),
            3
          );                    
        }else{
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        }     
      });
  };
    
  return (
    <Row>
      <Col span={24} className="profile-photo-part-container">
        <div className="avatar-image-container">
          {avatarLoading ? (
            <Spin />
          ) : (
            <img src={ext ?avatarDisplay+"?" + new Date().getTime() :avatarDisplay} alt="avatar" className="avatar-image" />
          )}
        </div>
        <div className="profile-photo-desc-part">
          <h5>{t("profile.profile-photo")}</h5>
          <h6 className="text-regular-font">
            {t("profile.profile-photo-desc", {
              recomended_image_size: "300x300",
            })}
          </h6>
          <div className="upload-buttons-holder">
            <Upload
              showUploadList={false}
              name="avatar"
              className="avatar-uploader"
              customRequest={(e) => {
                let formData = new FormData();
                formData.append("avatar", e.file);
                setAvatarLoading(true);
                instanceOfAxios
                  .post(`upload/${address}`, formData,{
                    headers: {
                      "x-access-token": getItemFromLocalStorage("token"),
                    },
                  })
                  .then((res) => {
                    onChangeAvatar(
                      `${res?.data?.avatar}`,
                      `${res?.data?.avatar_ext}`
                    );
                    setImageUrl({
                      imageSrc: res?.data?.avatar,
                      imageHash: Date.now(),
                    });
                    let user =JSON.parse(localStorage.getItem("user") || "{}");
                    user.avatar=res?.data?.avatar
                    user.avatar_ext=res?.data?.avatar_ext
                    localStorage.setItem("user", JSON.stringify(user));
                    dispatch({ type: "setProfile", user: user });
                    profieInfoStore.setIsPorifleImageUpdated( new Date().getTime())         


                    setAvatarLoading(false);
                    return res;
                  })
                  .catch((err) => {
                    logger.warn("error in upload avatar : ", err);
                    if(err?.response.status===401){
                      OpenErrorNotification(
                        t("profile.error"),
                        t("profile.error-unauthorized-user"),
                        3
                      );                    
                    }else{
                      OpenErrorNotification(
                        t("profile.error"),
                        t("profile.error-desc"),
                        3
                      );
                    }
                    setAvatarLoading(false);
                  });
              }}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              <Button type="primary">{t("profile.upload-photo")}</Button>
            </Upload>
            <div className="reset-button">
              <LightButton
                onClick={resetPicture}
                disabled={imageUrl?.imageSrc?.includes("https")}
              >
                {t("profile.reset")}
              </LightButton>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
});

export default UploadAvatar;
