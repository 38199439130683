import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RegisterBrand from "../boxes/registerBrand";
import "./display.less";

interface RegisterBrandProps {
  item?: any;
}

const RegisterBrandDisplay = ({ item }: RegisterBrandProps) => {
  const { t } = useTranslation();
  const [registerModal, setRegisterModal] = useState(false);

  const registerClick = () => {
    setRegisterModal(true);
  };

  const handleRegister = () => {
    //***********api call ///////*/
    setRegisterModal(false);
  };

  return (
    <div>
      <h4 className="primary-color">{t("action-box.create-nft-sft-brand")}</h4>
      <h5 className="action-description-text">
        {t("action-box.create-brand-desc")}
      </h5>
      <Button type="primary" block onClick={registerClick}>
        {t("collection-page.register-brand-button")}
      </Button>
      <RegisterBrand
        item={item}
        visible={registerModal}
        handleOk={handleRegister}
        handleCancel={() => setRegisterModal(false)}
      />
    </div>
  );
};

export default RegisterBrandDisplay;
