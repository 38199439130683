import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { CheckCircleFilled } from '@ant-design/icons';

import thumbnailImage1 from '../../../../../assets/images/thumbnail-image-1.jpg';
import thumbnailImage2 from '../../../../../assets/images/thumbnail-image-2.jpg';

import { beforeUpload } from '../../../../helpers/uploadFunctions';
import './thumbnailImage.less';

interface IThumbnailImage {
	setSelectedThumbnailImage: any;
}

function ThumbnailImage(props: IThumbnailImage) {
	const { setSelectedThumbnailImage } = props;
	const { t } = useTranslation();
	var images: [string, string, any] = [ thumbnailImage1, thumbnailImage2, null ];
	const [ imageDisplay, setImageDisplay ] = useState<any>(images);
	const [ selectedImage, setSelectedImage ] = useState<any>(thumbnailImage1);

	useEffect(() => {
		setSelectedImage(thumbnailImage1);
		setSelectedThumbnailImage(thumbnailImage1);
	}, [])
	
	const draggerThumbnailProps = {
		name: 'file',
		multiple: true,
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		onChange(info: any) {
			let file = info.file.originFileObj;
			if(file){
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = function(e) {
					images[2] = file;
					setImageDisplay(images);
					setSelectedImage(file);
					setSelectedThumbnailImage(file);
				};
			}else {
				setSelectedImage(info);
				setSelectedThumbnailImage(info);
			}
		}
	};

	const handleSelectImage = (image: any) => {
		setSelectedImage(image);
		setSelectedThumbnailImage(image);
	};

	return (
		<Row gutter={[ 12, 12 ]} className="thumbnail-image-propositions-container">
			<div className="thumnail-images">
				{imageDisplay.map(
					(image: any, key: number) =>
						image !== null ? (
							<div
								key={key}
								onClick={() => handleSelectImage(image)}
								className={
									selectedImage === image ? (
										'thumbnail-image-proposition thumbnail-image-highlighted-proposition'
									) : (
										'thumbnail-image-proposition'
									)
								}>
								<img src={image?.type?URL.createObjectURL(image):image} alt="thumbnail-image-video" className="thumbnail-image-show-cover" />
								{selectedImage === image && (
									<CheckCircleFilled className="thumbnail-image-checked-button-container" />
								)}
							</div>
						) : (
							''
						)
				)}
			</div>
			<div>
				<ImgCrop>
					<Upload
						showUploadList={false}
						className="upload-banner-button"
						beforeUpload={(file: any) =>
							beforeUpload(
								file,
								t('profile.image-type-error', {
									supported_image_types: 'Png or Jpg'
								}),
								t('profile.image-size-error')
							)}
						accept=".jpg, .jpeg, .png"
						{...draggerThumbnailProps}>
						<h5 className="primary-color uploadBanner">{t('common.upload')}</h5>
					</Upload>
				</ImgCrop>
			</div>
		</Row>
	);
}

export default ThumbnailImage;
