import * as React from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setItem } from "../../../../../storage/session";

interface NotConnectedModalProps {
  visible: boolean;
}

const NotConnectedModal = ({ visible }: NotConnectedModalProps) => {
  const  navigate = useNavigate();
  const { t } = useTranslation();

  const redirectToLogin = () => {
    setItem("goToUrl", window.location.href);
    window.scrollTo(0, 0);
    navigate("/auth");
  };

  return (
    <Modal    
      visible={visible}
      footer={null}
      maskClosable={false}
      closable={false}
      keyboard={false}
    >
      <div className="transaction-modal-container">
        <h3 className="primary-color">
          {t("action-box.please-connect-title")}
        </h3>

        <h5 className="transaction-wait-message">
          {t("action-box.please-connect-desc")}
        </h5>

        <Button type="primary" onClick={redirectToLogin}>
          {t("action-box.connect-back")}
        </Button>
      </div>
    </Modal>
  );
};

export default NotConnectedModal;
