import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Input, DatePicker, Form, message } from "antd";
import { useState } from "react";
import moment from "moment";
import "moment/locale/de";
import germanLocale from "antd/es/date-picker/locale/de_DE";
import "moment/locale/en-in";
import englishLocale from "antd/es/date-picker/locale/en_US";
import "moment/locale/fr";
import frenchLocale from "antd/es/date-picker/locale/fr_FR";
import "moment/locale/ar";
import arabicLocale from "antd/es/date-picker/locale/ar_EG";
import "moment/locale/ro";
import romanianLocale from "antd/es/date-picker/locale/ro_RO";
import TimeKeeper from "react-timekeeper";
import { useNavigate } from "react-router-dom";
import {
  sendTransactions,
} from "@multiversx/sdk-dapp/services";
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account'
import "../actionSwitch.less";
import dateIcon from "../../../../../../assets/images/date icon.svg";
import egldIcon from "../../../../../../assets/images/EGLD.svg";
import timeIcon from "../../../../../../assets/images/time icon.svg";
import CustomModal from "../../../../../components/modal/customModal";
import IconInput from "../../../../../components/input/icon input/iconInput";
import NumberDisplayi18n from "../../../../../helpers/numbersi18n";
import instanceOfAxios from "../../../../../../configAxios";
import { useContext, useDispatch } from "../../../../../context";
import { setItem } from "../../../../../storage/session";
import { logger } from "../../../../../context/logger";

export interface AcceptOfferProps {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  blockchainCurrency: string;
  userCurrency: string;
  offerPrice: number;
  order: string;
  item?: any;
  offer?: any;
  royality?: number;
  acceptOfferCase?: boolean;
}

const AcceptOffer = ({
  userCurrency,
  visible,
  handleOk,
  handleCancel,
  offerPrice,
  offer,
  order,
  item,
  royality,
  acceptOfferCase,
}: AcceptOfferProps) => {
  const { t } = useTranslation();
  const { address, marketplace_fee, actionCode } = useContext();
  const  navigate = useNavigate();
  const dispatch = useDispatch();
  const { account } = useGetAccountInfo();
  const currentLang = localStorage.getItem("language");
  const [time, setTime] = useState<any>("00:00 PM");
  const [date, setDate] = useState<any>("");
  const [timeVisible, setTimeVisible] = useState(false);
  const [dateVisible, setDateVisible] = useState(false);
  const [form] = Form.useForm();
  const [offerPriceConverted, setOfferPriceConverted] = useState<number>(
    offerPrice || 0
  );
  const [minConverted, setMinConverted] = useState<number>(offerPrice || 0);
  const [maxConverted, setMaxConverted] = useState<number>(0);
  const [auctionLoading, setAuctionLoading] = useState(false);
  const enableCurrentDay=process.env.REACT_APP_ENABLE_AUCTION_ON_CURRENT_DAY

  const /*transactionSessionId*/ [, setTransactionSessionId] = useState<
      string | null
    >(null);

  React.useEffect(() => {
    form.setFieldsValue({ time: "00:00pm" });
    form.setFieldsValue({ min_bid: offerPrice || 0 });

    if (offerPrice > 0) {
      instanceOfAxios
        .get(`currency/exchange_rates/egld/${offerPrice}/eur`)
        .then((res: any) => {
          setOfferPriceConverted(res?.data?.rate);
          setMinConverted(res?.data?.rate);
        })
        .catch((err: any) => {});
    }
  }, []);

  const clickTimePicker = () => {
    const isTimeVisible = !timeVisible;
    setTimeVisible(isTimeVisible);
    setDateVisible(false);
  };

  const clickDatePicker = () => {
    const isVisible = !dateVisible;
    setDateVisible(isVisible);
    setTimeVisible(false);
  };

  function disabledDate(current: any) {
    if(enableCurrentDay==="true"){
  // Can not select days before today
      return moment(current).subtract(-1, 'day') &&  moment(current).subtract(-1, 'day') < moment().endOf("day");
    }else {
      // Can not select days before today and today
      return current && current < moment().endOf("day");
    }
  }

  const onChangeDate = (newDate: any) => {
    form.setFieldsValue({ date: newDate });
    setDate(newDate);
  };

  const onChangeTime = (newTime: any) => {
    form.setFieldsValue({ time: newTime });
    setTime(newTime.formatted12);
  };

  const sendToSetupAuction = async (
    customtransaction: any,
    auctionData: any
  ) => {
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: customtransaction,
      transactionsDisplayInfo: {
        processingMessage: t("action-box.processing-setup-auction-transaction"),
        errorMessage: t("action-box.setup-auction-transaction-error"),
        successMessage: t("action-box.setup-auction-transaction-success"),
      },
      redirectAfterSign: false,
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
      //here we gonna save an object that contains this sessionId along with the collection id and item id, so we can catch the transaction result by the sessionId and link it to the specific collection/item ...
      localStorage.setItem(
        "transactions-track",
        JSON.stringify([
          ...JSON.parse(localStorage.getItem("transactions-track") || "[]"),
          {
            itemId: item?.id,
            collectionId: item?.collection?.id,
            sessionId,
            address,
            actionCase: actionCode,
            necessData: {
              auctionData,
              item,
            },
            sentData: customtransaction?.data || "",
          },
        ])
      );
      //we save in context too, considering the case of no redirection , such as xPortal extension
      dispatch({
        type: "keepTrack",
        transactionsTrack: JSON.parse(
          localStorage.getItem("transactions-track") || "[]"
        ),
      });
    }
  };

  const onFinish = (values: any) => {
    let customDate = moment(date).format("YYYY-MM-DD");
    let dateBeforeIncrease =new Date(`${customDate} ${time}`)
    let diffTimeZome = new Date().getTimezoneOffset()/60;
    let momentObj = moment(new Date(dateBeforeIncrease.setHours(dateBeforeIncrease.getHours() + diffTimeZome)) , "YYYY-MM-DDLT");
    let dateNow= moment(new Date())

    if(momentObj<dateNow){
      message.warning(t("action-box.date-time-in-future-needed"))
      return 
    }
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    } else {
      if(momentObj.isValid()){
        localStorage.setItem(
          "auctionData",
          JSON.stringify({
            min_bid: Number(values?.min_bid),
            max_bid: Number(values?.max_bid),
            deadline: momentObj,
          })
        );
        
        setAuctionLoading(true);
        instanceOfAxios
          .get(
            `setup_nft_auction/address/${address}/${item?.id}?min_bid=${Number(
              values?.min_bid
            )}&max_bid=${Number(
              values?.max_bid
            )}&deadline=${momentObj}&payment_token=EGLD`
          )
          .then((res: any) => {
            if (
              Number(account?.balance)  <
              res?.data?.value
            ) {
              message.error(t("action-box.not-enough-balance"));
            } else {
              const customtransaction = {
                receiver: res?.data?.receiver,
                gasLimit: res?.data?.gas_limit,
                value: res?.data?.value ,
                data: res?.data?.data_example,
              };
              if (offer?.fromAddress) {
                instanceOfAxios
                  .post(
                    `accept_offer/address/${address}/${item?.id}/${offer?.id}`
                  )
                  .then(async (res: any) => {
                    logger.warn(
                      "///// send transaction to set up Auction (from offer) : ",
                      customtransaction
                    );
                    sendToSetupAuction(customtransaction, {
                      min_bid: Number(values?.min_bid),
                      max_bid: Number(values?.max_bid),
                      deadline: momentObj,
                    });
                    handleCancel();
                    setAuctionLoading(false);
                  })
                  .catch((err: any) => {
                    return "failed sending accept offer";
                  });
              } else {
                logger.warn(
                  "///// send transaction to set up Auction : ",
                  customtransaction
                );
                sendToSetupAuction(customtransaction, {
                  min_bid: Number(values?.min_bid),
                  max_bid: Number(values?.max_bid),
                  deadline: momentObj,
                });
              }
            }
          })
          .catch((err: any) => {});
      }else {
        message.error(t("action-box.wrong-date-selected"));
      }
      }

  };

  const handleChangePrice = (e: any, type: string) => {
    const price = Number(e?.target?.value);
    if (!isNaN(price)) {
      switch (type) {
        case "min":
          if (price === 0) {
            setMinConverted(0);
          } else {
            price > 0 &&
              instanceOfAxios
                .get(`currency/exchange_rates/egld/${price}/eur`)
                .then((res: any) => {
                  setMinConverted(res?.data?.rate);
                })
                .catch((err: any) => {});
          }
          break;

        case "max":
          if (price === 0) {
            setMaxConverted(0);
          } else {
            price > 0 &&
              instanceOfAxios
                .get(`currency/exchange_rates/egld/${price}/eur`)
                .then((res: any) => {
                  setMaxConverted(res?.data?.rate);
                })
                .catch((err: any) => {});
          }

          break;

        default:
          break;
      }
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="450px"
      closable={true}
      handleOk={handleOk}
      handleCancel={handleCancel}>
      <Form form={form} onFinish={onFinish}>
        <div className="auction-modal-header">
          <h4>
            {order === "accept"
              ? t("collection-page.setup-auction-title")
              : t("collection-page.auction-setup-title")}
          </h4>
          <h6 className="text-regular-font">
            {t("collection-page.setup-auction-desc")}
          </h6>
        </div>

        {order === "accept" && (
          <>
            <h6 style={{ padding: "10px 0" }}>
              {t("collection-page.offer-price")}
            </h6>
            <div className="bid-price-input-holder">
              <Input
                suffix={
                  <img alt="EGLD" src={egldIcon} className="small-egld-logo" />
                }
                placeholder={`${t("collection-page.enter-your")} ${t(
                  "collection-page.offer-price"
                )}`}
                value={offerPrice}
                disabled
              />
              <h6 className="auction-approximative-price">
                ≈
                <NumberDisplayi18n
                  value={Number(offerPriceConverted?.toFixed(3))}
                  currency={userCurrency}
                  crypto={false}
                />
              </h6>
            </div>
          </>
        )}
      
        <h6 style={{ padding: "10px 0" }}>{t("collection-page.min-price")}</h6>
        <Form.Item
          name="min_bid"
          className="bid-price-input-holder"
          rules={[
            {
              required: true,
              message: t("action-box.minimal-price-required"),
            },
            () => ({
              validator(_, value) {
                if (isNaN(Number(value))) {
                  return Promise.reject(t("action-box.must-number"));
                } else if (Number(value) <= 0) {
                  return Promise.reject(t("action-box.must-positive-number"));
                } else {
                  const integerPart = value.toString().split(".")[0];
                  const decimalPart = value.toString().split(".")[1];
                  if (integerPart?.length > 18) {
                    return Promise.reject(
                      t("action-box.integer-limit-reached")
                    );
                  }
                  if (decimalPart?.length > 3) {
                    return Promise.reject(
                      t("action-box.decimals-limit-reached")
                    );
                  }

                  return Promise.resolve();
                }
              },
            }),
          ]}>
          <Input
            suffix={
              <img alt="EGLD" src={egldIcon} className="small-egld-logo" />
            }
            placeholder={`${t("collection-page.enter-your")} ${t(
              "collection-page.min-price"
            )}`}
            onChange={(e: any) => handleChangePrice(e, "min")}
          />
        </Form.Item>
        <h6 className="auction-approximative-price">
          ≈
          <NumberDisplayi18n
            value={Number(minConverted?.toFixed(3))}
            currency={userCurrency}
            crypto={false}
          />
        </h6>

        <h6 style={{ padding: "10px 0" }}>{t("collection-page.max-price")}</h6>
        <Form.Item
          name="max_bid"
          className="bid-price-input-holder"
          rules={[
            {
              required: true,
              message: t("action-box.maximal-price-required"),
            },
            () => ({
              validator(_, value) {
                if (isNaN(Number(value))) {
                  return Promise.reject(t("action-box.must-number"));
                } else if (Number(value) <= 0 ) {
                  return Promise.reject(t("action-box.must-positive-number"));
                } else if (Number(value) <= form.getFieldValue("min_bid")) {
                  return Promise.reject(t("action-box.must-be-more-than-min"));
                } else {
                  const integerPart = value.toString().split(".")[0];
                  const decimalPart = value.toString().split(".")[1];
                  if (integerPart?.length > 18) {
                    return Promise.reject(
                      t("action-box.integer-limit-reached")
                    );
                  }
                  if (decimalPart?.length > 3) {
                    return Promise.reject(
                      t("action-box.decimals-limit-reached")
                    );
                  }

                  return Promise.resolve();
                }
              },
            }),
          ]}>
          <Input
            suffix={
              <img alt="EGLD" src={egldIcon} className="small-egld-logo" />
            }
            placeholder={`${t("collection-page.enter-your")} ${t(
              "collection-page.max-price"
            )}`}
            onChange={(e: any) => handleChangePrice(e, "max")}
          />
        </Form.Item>
        <h6 className="auction-approximative-price">
          ≈
          <NumberDisplayi18n
            value={Number(maxConverted?.toFixed(3))}
            currency={userCurrency}
            crypto={false}
          />
        </h6>

        <h6 style={{ padding: "10px 0" }}>
          {t("collection-page.auction-end-date")}
        </h6>
        <Form.Item
          name="date"
          className="date-picker-container"
          rules={[
            { required: true, message: t("action-box.end-date-required") },
          ]}>
          <div className="real-custom-datepicker">
            <IconInput
              placeholder={`${t("collection-page.enter-your")} ${t(
                "collection-page.auction-end-date"
              )}`}
              icon={dateIcon}
              value={date}
              type="date"
              onClick={clickDatePicker}
            />
          </div>
          <DatePicker
            className="custom-datepicker "
            open={dateVisible}
            showToday={false}
            renderExtraFooter={() => (
              <div
                className="close-timepicker-button dark-button-picker"
                onClick={() => setDateVisible(false)}>
                {t("collection-page.close")}
              </div>
            )}
            disabledDate={disabledDate}
            locale={
              currentLang === "de"
                ? germanLocale
                : currentLang === "ar"
                ? arabicLocale
                : currentLang === "fr"
                ? frenchLocale
                : currentLang === "ro"
                ? romanianLocale
                : englishLocale
            }
            onChange={onChangeDate}
          />
        </Form.Item>
        <h6 style={{ padding: "10px 0" }}>
          {t("collection-page.auction-end-time")}
        </h6>
        <Form.Item
          name="time"
          rules={[
            { required: true, message: t("action-box.end-time-required") },
          ]}>
          <IconInput
            placeholder={`${t("collection-page.enter-your")} ${t(
              "collection-page.auction-end-time"
            )}`}
            icon={timeIcon}
            value={time}
            type="time"
            onClick={clickTimePicker}
          />

          {timeVisible && (
            <div
              className="time-picker-container item-wrapper"
              onBlur={() => setTimeVisible(false)}>
              <TimeKeeper
                time={time}
                onChange={onChangeTime}
                doneButton={() => (
                  <div
                    className="close-timepicker-button dark-button-picker"
                    onClick={() => setTimeVisible(false)}>
                    {t("collection-page.close")}
                  </div>
                )}
              />
            </div>
          )}
        </Form.Item>
        <Divider />

        {address !== item?.creator?.erd_address &&
          form.getFieldValue("min_bid") &&
          form.getFieldValue("max_bid") && (
            <h6 className="text-regular-font red-color">{`*${t(
              "collection-page.creators-royalty-desc",
              {
                minVal: (
                  (form.getFieldValue("min_bid") / (royality || 1 / 100)) *
                  100
                )?.toFixed(2),
                maxVal: (
                  (form.getFieldValue("max_bid") / (royality || 1 / 100)) *
                  100
                )?.toFixed(2),
              }
            )}`}</h6>
          )}

        <h6
          className="text-regular-font red-color"
          style={{ padding: "10px 0" }}>{`*${t(
          "collection-page.marketplace-fee-desc",
          {
            fee: `${Number(marketplace_fee) / 1000000000000000000}%`, 
          }
        )}`}</h6>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ margin: "10px 0" }}
            loading={auctionLoading}>
            {t("collection-page.start-auction")}
          </Button>
          <Button type="default" block onClick={handleCancel}>
            {t("common.cancel")}
          </Button>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default AcceptOffer;
