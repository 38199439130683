import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { isValidURL } from "../../helpers/validateUrl";
import svgEGLD from "../../../assets/images/EGLD.svg";
import { useNavigate } from "react-router-dom";
import Nodata from "../../components/noData/nodata";
import CustomImage from "../../components/image/customImage";
import UserDisplay from "../../components/user display/userDisplay";
import NumberDisplayi18n from "../../helpers/numbersi18n";
import TimeI18n from "../../helpers/timei18n";
import { useEffect, useState } from "react";
import "./table.less";

interface Props {
  data: any[];
  dataLoad: boolean;
  handleShowMore: any;
  valueCol: any;
  eventFilter: any;
  listUsers: any;
}

const TableActivity = ({
  data,
  handleShowMore,
  dataLoad,
  valueCol,
  eventFilter,
  listUsers,
}: Props) => {
  const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL;
  const serverAPIEndpoint = process.env.REACT_APP_SERVER_IMAGE_BASE_URL;
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const [spin, setSpin] = useState<boolean>(true);
  /// url validation

  useEffect(() => {
    if (data.length > 0) {
      setSpin(false);
    } else {
      setSpin(true);
      setInterval(() => {
        setSpin(false);
      }, 4000);
    }
  }, [data]);

  if (!serverBaseURL) {
    throw new Error();
  }
  if (!serverAPIEndpoint) {
    throw new Error();
  }
  const IMG_URL = serverBaseURL + serverAPIEndpoint;

  if (!isValidURL(IMG_URL)) {
    throw new Error();
  }

  const assert = (text: any, count: any) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  };

  const dataTable = data.map((el) => {
    return {
      key: el?.key,
      event: (
        <p style={{ paddingLeft: "18px", paddingTop: "10px" }}>
          {el.status==="bought" || el.status==="won"? t(`collection-page.${el.status}`): t(`activity.${el.type}`)}
        </p>
      ),
      item: (
        <div className="d-flex flex-wrap item-block-table">
          <div className="row">
          <a href={el?.item?.type==="nft"?`/nft/${el?.item?.token_identifier}`:`/item-details/${el?.item?.id}`}
            onClick={(e) => {
              e.preventDefault();
            }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate(el?.item?.type==="nft"?`/nft/${el?.item?.token_identifier}`:`/item-details/${el?.item?.id}`)}>
              <CustomImage
                image={el?.item?.uri}
                extensionImage={el?.item?.uri_ext}
                className={"img-item"}
              />
            </span>
          </a>
          </div>
          <div className="item-text">
          <a href={el?.item?.type==="nft"?`/nft/${el?.item?.token_identifier}`:`/item-details/${el?.item?.id}`}
            onClick={(e) => {
              e.preventDefault();
            }}>
            <p
              className="fw-bolder  row font-weight-bold"
              style={{ marginBottom: "-1px",marginTop:"-15px", cursor: "pointer" }}
              onClick={() => navigate(el?.item?.type==="nft"?`/nft/${el?.item?.token_identifier}`:`/item-details/${el?.item?.id}`)}>
              {el?.item?.title ? assert(el?.item?.title, 15) : ""}
            </p>
            </a>
            <a href={`/${el?.item?.collection?.homepage}`}
            onClick={(e) => {
              e.preventDefault();
            }}>
            <p
              className="fw-bolder row font-weight-normal item-description-table"
              style={{ cursor: "pointer",marginBottom: "-5px" }}
              onClick={() =>
                navigate(`/${el?.item?.collection?.homepage}`)
              }>
              {el?.item?.collection?.collection_name
                ? assert(el?.item?.collection?.collection_name, 15)
                : ""}
            </p>
            </a>
          </div>
        </div>
      ),
      price: (
        <>
          {Number(el?.price) > 0 ? (
            <>
              <NumberDisplayi18n
                value={Number(Number(el?.price).toFixed(9)) / 1000000000000000000}
                currency=""
                crypto={false}
              />
              <img src={svgEGLD} width="20px" className="egld-icon" />
            </>
          ) : null}
        </>
      ),
      from: (
        <div
          style={{ cursor: "pointer" }}>
              <UserDisplay sizeAvatar="large" user={el?.fromAddress} />
        </div>
      ),
      to: (
        ((el.status!=="sent" && el.type!== "bid") || (el.type=== "bid" && el.status==="bought") || (el.type=== "bid" && el.status==="won"))&& <div
          style={{ cursor: el?.toAddress ? "pointer" : "" }}
            >
              <UserDisplay sizeAvatar="large" user={el?.toAddress} />
        </div>
      ),

      date: <TimeI18n time={el?.date} />,
      className: "text-center",
    };

    // return (<Rowtable el={el}/>)
  });

  const nodataTable = {
    price: (
      <div style={{ margin: "30px auto" }}>
        <Nodata />
      </div>
    ),
  };

  const columns = [
    {
      title: t("activity.title-table-event"),
      dataIndex: "event",
      key: "event",
      width:"16%",
    },
    {
      title: t("common.item"),
      dataIndex: "item",
      key: "item",
      width:"16%",
    },
    {
      title: t("common.price"),
      dataIndex: "price",
      key: "price",
      width:"16%",
    },
    {
      title: t("activity.title-table-from"),
      dataIndex: "from",
      key: "from",
      width:"16%",
    },
    {
      title: t("activity.title-table-to"),
      dataIndex: "to",
      key: "to",
      width:"16%",
    },
    {
      title: t("activity.title-table-date"),
      dataIndex: "date",
      key: "date",
      width:"16%",
    },
  ];
  const handelFooter = () => {
    if (dataLoad === true) {
      return (
        <button
          className="button-seeMore ant-btn ant-btn-default collections-button"
          onClick={() =>
            handleShowMore(true, valueCol, eventFilter, listUsers)
          }>
          {t("activity.see-more")}
        </button>
      );
    } else {
      return "";
    }
  };  

  return (
    <>
      <div>
        <Table
          className="table-responsive  table-activity"
          size={"small"}
          pagination={false}
          columns={columns}
          loading={spin}
          dataSource={dataTable.length > 0 ? dataTable : [nodataTable]}
          footer={handelFooter}
        />
        <div></div>
      </div>
      {/* {(data.length > 0) ? data.length : 'false'} */}
    </>
  );
};

export default TableActivity;
