import { useEffect, useState } from "react";
import { Button, Row, Col, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useContext, useDispatch } from "../../context";
import "./home.less";
import SliderImages from "./components/sliderImages/sliderImages";
import FeatureCollection from "./components/featureCollection";
import OrangeCard from "./components/orangeCard/orangeCard";
import illustration1 from "../../../assets/images/illustration1.png";
import illustration2 from "../../../assets/images/illustration2.png";
import RecentAuction from "./components/recentAuction/recentAuction";
import RecentItem from "./components/recentItem/recentItem";
import PaginationButtons from "./components/paginationButtons/paginationButtons";
import ItemsCarousel from "./components/carousel/ItemsCarousel";
import AuctionsCarousel from "./components/carousel/auctionsCarousel";
import CardsCarousel from "./components/carousel/cardsCarousel";
import instanceOfAxios from "../../../configAxios";
import LoadingItem from "./components/recentItem/loadingItem";
import { setItem } from "../../storage/session";
import Helmet from "react-helmet";

const HomePage = () => {
  const { address, loading, items } = useContext();
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const dispatch = useDispatch();
  const [likedItems, setLikedItems] = useState<any>([]);
  const [recentItems, setRecentItems] = useState<any>([]);
  const [collections, setCollections] = useState<any>([]);
  const [pageInfos, setPageInfos] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
  });
  const [pageNumber, setPageNumber] = useState(0);
  const pageItemsNumber = 4;

  useEffect(() => {
    instanceOfAxios
      .get(
        `filter_item_activity${
          address ? `?erd_address=${address}&` : "?"
        }first=${pageItemsNumber}&offset=${pageNumber *
          pageItemsNumber}&sortBy=created_at&sortOrder=DESC`
      )
      .then((res: any) => {
        dispatch({ type: "setItems", items: res?.data?.nodes });
        // setItems(res?.data?.nodes);
        setPageInfos(res?.data?.pageInfo);
        dispatch({ type: "loading", loading: false });
      })
      .catch((err: any) => {});
  }, [pageNumber]);

  useEffect(() => {
    //For fetching the featured collections
    instanceOfAxios
      .get(`feature_collection?first=6&offset=0`)
      .then((res: any) => {
        setCollections(res?.data?.nodes);
      })
      .catch((err: any) => {});

    //For fetching the most liked items
    instanceOfAxios
      .get(`filter_item_activity?first=5&offset=0&sortBy=likes&sortOrder=DESC`)
      .then((res: any) => {
        setLikedItems(res?.data?.nodes);
      })
      .catch((err: any) => {});

    //For fetching the most recent items
    instanceOfAxios
      .get(
        `filter_item_activity?first=5&offset=0&sortBy=created_at&sortOrder=DESC`
      )
      .then((res: any) => {
        setRecentItems(res?.data?.nodes);
      })
      .catch((err: any) => {});
  }, []);

  const goPreviousPage = () => {
    if (pageNumber > 0) setPageNumber(pageNumber - 1);
  };

  const goNextPage = () => {
    if (pageNumber < pageInfos?.totalCount) setPageNumber(pageNumber + 1);
  };

  const redirectToCreate = () => {
    if (address) {
      window.scrollTo(0, 0);
      navigate("/collection");
    } else {
      setItem("goToUrl", window.location.href);
      navigate("/auth");
    }
  };

  return (
    <div>
        <Helmet>
        <title>Rarity.market | MultiversX NFT Marketplace </title>
        <meta
          name="description"
          content="A NFT marketplace for artists and collectors. Discover, buy and sell artwork in decentralized MultiversX ecosystem."
        />
        <meta
          name="keywords"
          content="MultiversX, NFT, marketplace, tokens, digital art, real art, trade, collectible, blockchain, elrond, elrondNFT"
        />
        <meta
          content="Rarity.market | MultiversX NFT Marketplace"
          property="og:title"
        />
        <meta
          content="A NFT marketplace for artists and collectors. Discover, buy and sell artwork within decentralized MultiversX ecosystem."
          property="og:description"
        />
        <meta property="og:url" content="https://rarity.market" />
        <meta
          content="https://rarity.market/static/media/rarityLogo.6556aa1d.svg"
          property="og:image"
        />

        <meta
          content="Rarity.market | MultivesX NFT Marketplace"
          property="twitter:title"
        />
        <meta
          content="A NFT marketplace for artists and collectors. Discover, buy and sell artwork within the decentralized MultiversX ecosystem."
          property="twitter:description"
        />
        <meta property="twitter:url" content="https://rarity.market" />
        <meta
          content="https://rarity.market/static/media/rarityLogo.6556aa1d.svg"
          property="twitter:image"
        />
        </Helmet>
      <div className="home-background">
        <Row style={{ width: "80%" }}>
          <Col span={24} className="home-content">
            <div className="home-text-container">
              <div className="home-title">
                <h1 className="text-regular-font">
                  {t("home.headline0-part1")}
                </h1>
                <div className="background-title">
                  <h1>
                    <span className="text-regular-font">
                      {t("home.headline0-part2")}
                    </span>
                    &nbsp;
                    {t("home.headline0-part3")}
                  </h1>
                </div>
              </div>
              <div className="home-description">
                <h4 className="text-regular-font">{t("home.headline1")}</h4>
                <h4 className="text-regular-font description-text-headline">
                  {t("home.headline2")}
                </h4>
              </div>
              <a
                href={`/explore`}
                onClick={(e) => {
                  e.preventDefault();
                }}>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/explore");
                  }}
                  type="primary"
                  className="home-button">
                  {t("home.menu-browse")}
                </Button>
                </a>
                <a
                href={`/collection`}
                onClick={(e) => {
                  e.preventDefault();
                }}>
              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/collection");
                }}
                type="default"
                className="home-button orange-outline-button">
                {t("home.menu-create")}
              </Button>
                </a>
            </div>
            <SliderImages />
          </Col>
        </Row>
      </div>
      <div className="part-container">
        <Row>
          <Col span={24}>
            <h3 className="home-title">
              {t("home.navigation-featured-collections")}
            </h3>
          </Col>
        </Row>
        <Row gutter={[48, 48]} className="collections-grid">
          {collections?.map((el: any,key:number) => {
            return (
              <Col xs={16} sm={12} lg={8} key={key}>
                <FeatureCollection collection={el} />
              </Col>
            );
          })}
        </Row>
        <div className="featured-collection-mobile-version">
          <FeatureCollection collection={collections[0]} />
        </div>
        <div className="collections-button-container">
          <a
              href={`/explore`}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Button
                type="default"
                className="collections-button"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/explore");
                }}>
                {t("home.navigation-view-all-Collections")}
              </Button>
            </a>
        </div>
      </div>
      {!address && 
      <div className="part-container">
        <div className="orange-cards-container">
          <Row gutter={[64, 64]} style={{ width: "100vw" }}>
            <Col span={12}>
              <OrangeCard
                image={illustration1}
                title={t("home.call-to-action0-headline")}
                description={t("home.call-to-action0-text")}
                buttonTitle={t("home.call-to-action0-button")}
                firstImage={true}
                onClick={redirectToCreate}
              />
            </Col>
            <Col span={12}>
              <OrangeCard
                image={illustration2}
                title={t("home.call-to-action1-headline")}
                description={t("home.call-to-action1-text")}
                buttonTitle={t("home.call-to-action2-text")}
                firstImage={false}
                onClick={redirectToCreate}
              />
            </Col>
          </Row>
        </div>
        <div className="orange-mobile-cards">
          <CardsCarousel />
        </div>
      </div>}
      <div className="part-container">
        <Row gutter={[30, 30]} className="most-like-recent-grid web-auctions-part">
          <Col span={10}>
            <RecentAuction
              partTitle={t("home.navigation-most-liked")}
              elementsList={likedItems}
            />
          </Col>
          <Col span={10}>
            <RecentAuction
              partTitle={t("home.navigation-most-recent")}
              elementsList={recentItems}
            />
          </Col>
          {/* <Col span={8}>
            <RecentAuction
              partTitle={t("home.navigation-highest-price-auctions")}
              elementsList={HighestPriceAuctionData}
            />
          </Col> */}
        </Row>
        <div className="mobile-auctions-part">
          <AuctionsCarousel recentItems={recentItems} likedItems={likedItems} />
        </div>
      </div>
      <div className="part-container">
        <div className="web-recent-items">
          <Row>
            <Col span={24} className="recent-items-header">
              <h3 className="home-title">
                {t("home.navigation-recent-items")}
              </h3>
              <PaginationButtons
                pageInfos={pageInfos}
                goPreviousPage={goPreviousPage}
                goNextPage={goNextPage}
              />
            </Col>
          </Row>
          <Row gutter={[32, 32]} style={{ marginTop: "30px" }}>
            {!loading ? (
              items.map((el: any,key:number) => {
                return (
                  <Col span={6} key={key}>
                    <RecentItem item={el} />
                  </Col>
                );
              })
            ) : (
              <>
                <Col span={6}>
                  <LoadingItem />
                </Col>
                <Col span={6}>
                  <LoadingItem />
                </Col>
                <Col span={6}>
                  <LoadingItem />
                </Col>
                <Col span={6}>
                  <LoadingItem />
                </Col>
              </>
            )}
          </Row>
        </div>
        <div className="mobile-recent-items">
          <Row>
            <Col span={24} className="recent-items-header">
              <h3 className="home-title">
                {t("home.navigation-recent-items")}
              </h3>
              <PaginationButtons
                pageInfos={pageInfos}
                goPreviousPage={goPreviousPage}
                goNextPage={goNextPage}
              />
            </Col>
          </Row>
          {loading ? (
            <div className="mobile-recent-items-spin">
              <Spin size="large" />
            </div>
          ) : (
            <ItemsCarousel ItemsData={items} />
          )}
        </div>
      </div>

      {/*  <Button onClick={logOut}>
        <a href="/auth">Logout</a>
      </Button> */}
    </div>
  );
};

export default HomePage;
