import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Select } from "antd";
import "../actionSwitch.less";
import CustomModal from "../../../../../components/modal/customModal";
import { useState } from "react";
import NumberDisplayi18n from "../../../../../helpers/numbersi18n";
import instanceOfAxios from "../../../../../../configAxios";
import { useContext } from "../../../../../context";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../../components/toasts/openToast";

export interface MakeOfferProps {
  item?: any;
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  blockchainCurrency: string;
  userCurrency: string;
}

const MakeOffer = ({
  item,
  visible,
  handleOk,
  handleCancel,
  blockchainCurrency,
  userCurrency,
}: MakeOfferProps) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const { address } = useContext();
  const { Option } = Select;
  const [form] = Form.useForm();
  const exprationList = [7, 10, 15, 21];
  const [expiration, setExpiration] = useState(exprationList[0]);
  const [priceDisplay, setPriceDisplay] = useState(0);

  const handlePriceDisplay = (e: any) => {
    const price = Number(e?.target?.value);
    if (price === 0) {
      setPriceDisplay(0);
    } else {
      price > 0 &&
        instanceOfAxios
          .get(`currency/exchange_rates/egld/${price / 1000000000000000000}/eur`)
          .then((res: any) => {
            setPriceDisplay(res?.data?.rate);
          })
          .catch((err: any) => {});
    }
  };

  const onFinish = (values: any) => {
    let body = values;
    body.price = Number(values?.price);
    instanceOfAxios
      .post(`make_offer/address/${address}/${item?.id}`, body)
      .then((res: any) => {
        OpenSuccessNotification(
          t("profile.success"),
          t("action-box.offer-sent"),
          3
        );
        handleCancel();
      })
      .catch((err: any) => {
        OpenErrorNotification(t("profile.error"), t("profile.error-desc"), 3);
        handleCancel();
      });
  };

  return (
    <CustomModal
      visible={visible}
      width="400px"
      closable={true}
      handleOk={handleOk}
      handleCancel={handleCancel}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ expiration: exprationList[0] }}
      >
        <div className="auction-modal-header">
          <h4>{t("collection-page.make-offer-title")}</h4>
          <h6 className="text-regular-font">
            {t("collection-page.make-offer-desc")}
          </h6>
        </div>
        <h6 style={{ paddingBottom: "10px", paddingTop: "20px" }}>
          {t("common.price")}
        </h6>
        <div className="bid-price-input-holder">
          <Form.Item
            name="price"
            className="bid-price-input-holder"
            rules={[
              {
                required: true,
                message: t("action-box.minimal-price-required"),
              },
              () => ({
                validator(_, value) {
                  if (isNaN(Number(value))) {
                    return Promise.reject(t("action-box.must-number"));
                  } else if (Number(value) < 0) {
                    return Promise.reject(t("action-box.must-positive-number"));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              suffix={blockchainCurrency}
              placeholder={t("collection-page.price-placeholder")}
              onChange={(e: any) => handlePriceDisplay(e)}
            />
          </Form.Item>
          <h6 className="auction-approximative-price">
            ≈
            <NumberDisplayi18n
              value={Number(priceDisplay?.toFixed(4))}
              currency={userCurrency}
              crypto={false}
            />
          </h6>
        </div>
        <h6 style={{ paddingBottom: "10px" }}>
          {t("collection-page.offer-expiration")}
        </h6>
        <Form.Item name="expiration">
          <Select
            defaultValue={expiration}
            style={{ width: "100%" }}
            onChange={(val: any) => setExpiration(val)}
          >
            {exprationList.map((el,key) => {
              return (
                <Option key={key} value={el} style={{ margin: "5px 20px" }}>{`${el} ${t(
                  "collection-page.days"
                )}`}</Option>
              );
            })}
          </Select>
        </Form.Item>
        <div style={{ margin: "10px 0" }}>
          <h6 style={{ paddingBottom: "10px" }}>
            {t("collection-page.refuse-message-title")}
          </h6>
          <Form.Item
            name="message"
            rules={[
              {
                required: true,
                message: t("action-box.offer-message-required"),
              },
            ]}
          >
            <TextArea
              placeholder={t("collection-page.refuse-message-placeholder")}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            type="primary"
            block
            htmlType="submit"
            style={{ margin: "10px 0" }}
          >
            {t("collection-page.make-offer")}
          </Button>
          <Button type="default" block onClick={handleCancel}>
            {t("common.cancel")}
          </Button>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default MakeOffer;
