import { action, makeAutoObservable, observable } from 'mobx';

class ActivityStore {
	@observable eventFilter: any = '';
	@observable listColllection: any = '';
	@observable listUsers: any = '';
	@observable data: any = [];
	@observable dataCollection: any = [];
	@observable valueCollection: any = '';
	@observable dataLoad: boolean = false;
	@observable isNotCalled: boolean = false;
	@observable scrollPosition: number = 0;
	@observable dataLoadCollection: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	setEventFilter = (value: any) => {
		this.eventFilter = value;
		this.setIsNotCalled(true);
	};
	setListColllection = (value: any) => {
		this.listColllection = value;
		this.setIsNotCalled(true);
	};
	setListUsers = (value: any) => {
		this.listUsers = value;
		this.setIsNotCalled(true);
	};

	//setter for data
	@action
	setData(value: any) {
		this.data = value;
	}

	//setter for dataCollection
	@action
	setDataCollection(value: any) {
		this.dataCollection = value;
	}
	//setter for valueCollection
	@action
	setValueCollection(value: any) {
		this.valueCollection = value;
	}
	//setter for dataLoad
	@action
	setDataload(value: boolean) {
		this.dataLoad = value;
	}
	//setter for isNotCalled
	@action
	setIsNotCalled(value: boolean) {
		this.isNotCalled = value;
	}
	/* Setter and getter scrollPosition */
	@action
	setScrollPosition(value: number) {
		this.scrollPosition = value;
	}
	/* Setter and getter scrollPosition */
	@action
	setDataloadcollection(value: boolean) {
		this.dataLoadCollection = value;
	}
}

export default new ActivityStore();
