import { action, makeAutoObservable, observable } from "mobx";

class LikesStore {
  @observable isLiked: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  /* Setter and getter isLiked */
  @action setIsLiked(value: boolean) {
    this.isLiked = value;
  }
}

export default new LikesStore();
