import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../../i18n";
import icon from "../../../../../assets/images/select icon.svg";
import "./languageSelect.less";
import instanceOfAxios from "../../../../../configAxios";
import { useDispatch, useContext } from "../../../../context";
import { useNavigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../../helpers/useLocalStorage";

const LanguageSelector = () => {
  const customSelect = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { address } = useContext();
  const navigate = useNavigate();
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("language")?.toLowerCase() || i18n.language
  );
  const [openedSelect, setOpenedSelect] = useState(false);
  const options = [
    { value: "en", label: t("home.footer-traduction-english") },
    { value: "de", label: t("home.footer-traduction-germany") },
    // { value: "fr", label: t("home.footer-traduction-french") },
    { value: "ar", label: t("home.footer-traduction-arabic") },
    { value: "ro", label: t("home.footer-traduction-romanian") },
  ];

  const handleClick = (e) => {
    if (customSelect.current.contains(e?.target)) {
      return;
    } else setOpenedSelect(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    localStorage.setItem("language", lang);
    address &&
      instanceOfAxios
        .put("profile/" + address, {
          ...JSON.parse(localStorage.getItem("user") || "{}"),
          language: lang,
        },{
          headers: {
            "x-access-token": getItemFromLocalStorage("token"),
          },        
        })
        .then((res) => {
          dispatch({ type: "setProfile", user: res?.data });
          localStorage.setItem("user", JSON.stringify(res?.data));
        })
        .catch((err) => {});
    setOpenedSelect(false);
  };
  const checkExistingUrl = (urls, lang) => {
    urls.map((url) => {
      if (window.location.pathname.includes(url)) {
        let splittedArray = window.location.pathname.split("/");
        splittedArray[1] = lang;
        navigate({ pathname: splittedArray.join("/") });
        changeLanguage(lang);
        window.location.reload();
      }
    });
  };

  const handleLanguageChange = (lang) => {
    if (localStorage.getItem("language") === "ar") {
      changeLanguage(lang);
      checkExistingUrl(["/staking", "/faq", "/help-center"], lang);
      window.location.reload();
    } else {
      changeLanguage(lang);
      checkExistingUrl(["/staking", "/faq", "/help-center"], lang);
      if (lang === "ar") {
        window.location.reload();
      }
    }
  };

  return (
    <div className="custom-select-wrapper" ref={customSelect}>
      <div className="custom-select">
        <div
          className="custom-select__trigger"
          onClick={() => setOpenedSelect(!openedSelect)}>
          <span>
            {
              options.filter((option) => {
                return option.value === currentLang;
              })[0]?.label
            }
          </span>
          <img alt="selectIcon" src={icon} style={{ width: "15px" }} />
        </div>
        <div
          className={
            openedSelect
              ? "custom-options custom-select-open"
              : "custom-options"
          }>
          {options.map((el,key) => {
            return (
              <li
              key={key}
                className={
                  currentLang === el.value
                    ? "custom-option selected"
                    : "custom-option"
                }
                data-value={el.value}
                onClick={() => handleLanguageChange(el.value)}>
                {el.label}
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
