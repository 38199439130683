import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { useNavigate } from 'react-router-dom';

import FbxModel from './fbxModel';
import GLTModel from './gltModel';
import ObjModel from './objModel';
import { IPrivew3DObjects } from './preview3DObject.interface';

function PrivewItemDetails(props: IPrivew3DObjects) {
	const navigate = useNavigate();
	const { path, fileType, className = 'item-details-canvas-3d', pathRedirection } = props;
	return (
		<div onClick={() => (pathRedirection ? navigate(pathRedirection) : '')}>
			<Canvas className={className}>
				<pointLight position={[ 10, 10000, 10000 ]} intensity={1} />
				<pointLight position={[ -10000, 10, 10 ]} intensity={1} />
				<pointLight position={[ 10000, -1000, 10 ]} intensity={2} />
				<color attach="background" args={[ '#FFDFD1' ]} />
				<Suspense fallback={null}>
					{fileType.includes('.glb') || fileType.includes('.gltf') ? (
						<GLTModel modelPath={path} />
					) : fileType.includes('.fbx') ? (
						<FbxModel modelPath={path} />
					) : fileType.includes('.obj') ? (
						<ObjModel modelPath={path} />
					) : null}
				</Suspense>
				<OrbitControls />
			</Canvas>
		</div>
	);
}

export default PrivewItemDetails;
