import React from "react";
import { Input } from "antd";
import "./linkInput.less";

export interface LinkInputProps {
  icon: string;
  color: string;
  placeholder: string;
  value?: string;
  onChange?: (e: any) => void;
}

const LinkInput = ({
  icon,
  color,
  placeholder,
  value,
  onChange,
}: LinkInputProps) => {
  return (
    <>
      <div
        className="social-link-icon-container"
        style={{ backgroundColor: color }}
      >
        <img alt="link icon" src={icon} className="link-button-icon" />
      </div>
      <Input
        value={value}
        className="social-link-input"
        placeholder={placeholder}
        onChange={onChange}
      />
    </>
  );
};

export default LinkInput;
