import Auth from "./main/modules/auth/authPage";
import CollectionPage from "./main/modules/collection/containers/select collection/collectionPage";
import CollectionSuccess from "./main/modules/collection/containers/collection success/collectionSuccess";
import CreateCollection from "./main/modules/collection/containers/create collection/createCollection";
import CreateItem from "./main/modules/collection/containers/create item/createItem";
import HomePage from "./main/modules/home/homePage";
import MaintenancePage from "./main/modules/errors/maintenance";
import CookiesMissing from "./main/modules/errors/cookiesMissing";
import EditProfile from "./main/modules/profile/container/editProfile/editProfile";
import Activity from "./main/modules/activity/activity";
import Ranking from "./main/modules/ranking/ranking";
import Genesis from "./main/modules/genesis/genesis";
import Message from "./main/modules/message/message";
import ProfileDetails from "./main/modules/profile/components/profileDetails/profileDetails";
import NewCollection from "./main/modules/collection/components/newCollection/newCollection";
import ItemDetails from "./main/modules/collection/containers/item details/itemDetails";
import Explore from "./main/modules/explore/explore";
import ErrorPage from "./main/modules/errors/errorPage/errorPage";
import Imprint from "./main/modules/staticpages/imprint";
import HelpCenter from "./main/modules/staticpages/helpCenter";
import DataProtection from "./main/modules/staticpages/dataProtection";
import PageFaq from "./main/modules/staticpages/Pagefaq";
import CollectionProfile from "./main/modules/collection/containers/collection profile/collectionProfile";
import ConfirmEmail from "./main/modules/profile/container/email confirmed/emailConfirmed";
import CryptoAnimal from "./main/modules/landing pages/crypto animal/cryptoAnimal";

import GenesisCollection from "./main/modules/landing pages/genesis collection/genesisCollection";
import ArtistsLanding from "./main/modules/landing pages/artists landing/artistsLanding";
import CollectorsLanding from "./main/modules/landing pages/collectors landing/collectorsLanding";
import NftsLanding from "./main/modules/landing pages/nfts landing/nftsLanding";
import Staking from "./main/modules/landing pages/staking/staking";
export const components = {
  Auth,
  CollectionPage,
  CollectionSuccess,
  CreateCollection,
  CreateItem,
  HomePage,
  MaintenancePage,
  CookiesMissing,
  EditProfile,
  Activity,
  Ranking,
  Genesis,
  Message,
  ProfileDetails,
  NewCollection,
  ItemDetails,
  Explore,
  ErrorPage,
  Imprint,
  HelpCenter,
  DataProtection,
  PageFaq,
  CollectionProfile,
  ConfirmEmail,
  CryptoAnimal,
  GenesisCollection,
  ArtistsLanding,
  CollectorsLanding,
  NftsLanding,
  Staking,
};
