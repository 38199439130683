import React from 'react';
import { Drawer } from 'antd';
import closeIcon from '../../../../../assets/images/close.svg';

export interface MobileDrawerProps {
	visible: boolean;
	onClose: () => void;
	children: any;
}

const MobileDrawer = ({ visible, onClose, children }: MobileDrawerProps) => {
	return (
		<Drawer
			onClose={onClose}
			visible={visible}
			width="100%"
			closeIcon={<img alt="closeIcon" src={closeIcon} />}
			destroyOnClose={true}>
			{children}
		</Drawer>
	);
};

export default MobileDrawer;
