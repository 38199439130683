import { useEffect, useState } from "react";

const useSocialMediaLink = (link: string, extension: string) => {
  const [displayLink, setDisplayLink] = useState<string>("");

  useEffect(() => {
    if (link && link?.includes("https")) {
      setDisplayLink(link);
    } else {
      setDisplayLink(`${extension}${link}`);
    }
  }, [link]);

  return displayLink;
};

export default useSocialMediaLink;
