import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SelectOption from "./components/select";
import { Slider, Row, Col, Spin } from "antd";
import ScrollToTop from "./components/scrollToTop";
import "./components/imagehover.less";
import "./explore.less";
import instanceOfAxios from "../../../configAxios";
import { useContext } from "../../context";
import { useLocation, useNavigate } from "react-router-dom";
import Nodata from "../../components/noData/nodata";
import Image from "./components/imageHover";
import { getItem } from "../../storage/session";
import { observer } from "mobx-react-lite";
import { useGlobalContext } from "../../context/globalContext";
import { Helmet } from "react-helmet";

export const getQueryItems = (
  filterName: String,
  options: object,
  address: any
) => {
  return (
    filterName +
    "&" +
    Object.entries(options)
      .map((el) => {
        if (
          Object.entries(options)[4][0] === "statusAuction" &&
          Object.entries(options)[4][1] === "onAuction"
        ) {
          if (el[0] === "sortBy" && el[1] === null) {
            el[1] = "auction_soon";
          }
        } else {
          if (el[0] === "sortBy" && el[1] === null) {
            el[1] = "created_at&sortOrder=DESC";
          }
        }
        return el[1] !== null && el[0] + "=" + el[1];
      })
      .filter(function (v) {
        return !!v;
      })
      .join("&")
  );
};

const Spinner = (
  <div className="spinner">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
);

const Explore = observer(() => {
  const { search, hash } = useLocation();
  const navigate = useNavigate();
  let filterSearch = new URLSearchParams(search).get("search");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [lodinNodes, setLoadingNodes] = useState(false);
  const { exploreStore } = useGlobalContext();
  const selectSortBy = [
    t("Name"),
    t("Recently-Created"),
    t("Recently-Sold"),
    t("Auction-ends-soon"),
    t("Price-Low-to-High"),
    t("Price-High-to-Low"),
    t("hightest-Last-Sale"),
    t("Most-viewed"),
    t("Most-Favorited"),
    t("Oldest"),
  ];

  const [filteroption, setFilterOption] = useState({
    status: t("All"),
    sortBy: t("All"),
    priceRange: [],
  });

  const { address } = useContext();
  const [userInfo, setUserInfo] = useState({});
  const [objectRequest, setObjectRequest] = useState({
    first:  exploreStore.pageItemsNumber, 
    sortBy: null,
    type: null,
    status: null,
    statusAuction: null,
    price_min: 0,
    price_max: 10,
  });

  const handleScroll = () => {
    const position = window.pageYOffset;
    exploreStore.setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, exploreStore.scrollPosition);
  }, [exploreStore.scrollPosition]);

  useEffect(() => {
    if (exploreStore.itemsExplore.length === 0) {
      getItemsFromBack(false);
    }
  }, [exploreStore.objectRequest, filteroption, filterSearch, hash]);

  useEffect(() => {
    if (filterSearch !== null) {
      exploreStore.setObjectRequest({
        first: exploreStore.pageItemsNumber,
        sortBy: null,
        type: null,
        status: null,
        statusAuction: null,
        price_min: 0,
        price_max: 10,
      });
      exploreStore.setIsSearchSetting(true);
      getItemsFromBack(false);
    }
  }, [filterSearch, hash]);

  useEffect(() => {
    if (
      exploreStore.filterSearchKeyWord !== null &&
      exploreStore.searchLengthKeys !== 0
    ) {
      navigate({
        pathname: window.location.pathname,
        search: `?search=${exploreStore.filterSearchKeyWord}`,
      });
    }
    if (
      exploreStore.isApiCalled &&
      exploreStore.isSearchSetting &&
      filterSearch === null
    ) {
      getItemsFromBack(false);

      exploreStore.setIsSearchSetting(false);
    }
  }, [window.location.pathname.search]);

  useEffect(() => {
    if (
      exploreStore.isApiCalled &&
      exploreStore.isSearchSetting &&
      filterSearch === "" &&
      exploreStore.searchLengthKeys === 0
    ) {
      getItemsFromBack(false);

      exploreStore.setIsSearchSetting(false);
    }
    if (exploreStore.searchLengthKeys === 0) {
      navigate({
        pathname: window.location.pathname,
        search: "",
      });
    }
  }, [filterSearch, exploreStore.searchLengthKeys]);

  let tabsSortBY = [
    "title",
    "created_at&sortOrder=DESC",
    "recently_sold",
    "auction_soon",
    "price&sortOrder=ASC",
    "price&sortOrder=DESC",
    "highest_last_sale",
    "views&sortOrder=DESC",
    "likes&sortOrder=DESC",
    "created_at&sortOrder=ASC",
  ];

  let tabsStatus =
    exploreStore.role === "admin" || exploreStore.role === "community-admin"
      ? [
          "disapproved",
          "approved",
          "nft",
          "sft",
          "onAuction",
          "onAuctionBids",
          "deadline",
        ]
      : ["item", "nft", "sft", "onAuction", "onAuctionBids", "deadline"];

  let selectStatus =
    exploreStore.role === "admin" || exploreStore.role === "community-admin"
      ? [
          t("Not-Approved"),
          t("Approved"),
          t("Nft"),
          t("Sft"),
          t("On-auction"),
          t("On-auction-with-bids"),
          t("finished-auctions"),
        ]
      : [
          t("Item"),
          t("Nft"),
          t("Sft"),
          t("On-auction"),
          t("On-auction-with-bids"),
          t("finished-auctions"),
        ];

  useEffect(() => {
    if (address) {
      setUserInfo(JSON.parse(localStorage.getItem("user") || "{}"));
      exploreStore.setRole(
        JSON.parse(localStorage.getItem("user") || "{}")?.role
      );
    }
  }, [address]);

  const getItemsFromBack = (filter: boolean) => {
    if (!filter) {
      setLoading(true);
    }
    if (hash) {
      filterSearch = filterSearch + hash;
    }
    
    instanceOfAxios
      .get(
        getQueryItems(
          `explore?afterCursor=${
            exploreStore?.cursor?.afterCursor
          }&beforeCursor=${exploreStore?.cursor?.beforeCursor}${
            address ? `&erd_address=${address}` : ""
          }${
            filterSearch !== null
              ? `&search=${encodeURIComponent(filterSearch)}`
              : ""
          }`,
          {...exploreStore.objectRequest,first: exploreStore?.cursor?.afterCursor ?100: exploreStore.pageItemsNumber},
          exploreStore.role === "admin" ||
            exploreStore.role === "community-admin"
            ? address
            : null
        )
      )
      .then((response: any) => {
        if (response?.data?.nodes.length === 0) {
          setLoadingNodes(true);
        } else {
          setLoadingNodes(false);
        }

        exploreStore.setCursor(response?.data?.cursor);
        exploreStore.setIsApiCalled(true);
        exploreStore.setLoading(true);
        exploreStore.setIsButtonDisbaled(false);
        exploreStore.setTotalCount(response?.data?.totalCount);


        if (!filter) {
          exploreStore.setItemsExplore(response?.data?.nodes);
        

          exploreStore.setCursor(response?.data?.cursor);
        } else {
          let concatArray: any[];
          concatArray = exploreStore.itemsExplore.concat(response?.data?.nodes);
          exploreStore.setItemsExplore(concatArray);
        
          exploreStore.setCursor(response?.data?.cursor);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("err  ", err);
        setLoading(false);
      });
  };

  const selectedStatusOption = (e: any, i: any) => {
    exploreStore.setItemsExplore([]);
    exploreStore.setCursor(null);
    setFilterOption((prev) => {
      let filterOption = { ...exploreStore.objectRequest, status: e };
      exploreStore.setObjectRequest(filterOption);
      return filterOption;
    });

    setObjectRequest((prev: any) => {
      let statusResult = {
        ...exploreStore.objectRequest,
        status:
          (tabsStatus[i.key] !== "All" && tabsStatus[i.key] === "approved") ||
          tabsStatus[i.key] === "disapproved"
            ? tabsStatus[i.key]
            : null,
        type:
          tabsStatus[i.key] === "nft" ||
          tabsStatus[i.key] === "sft" ||
          tabsStatus[i.key] === "item"
            ? tabsStatus[i.key]
            : tabsStatus[i.key] === "approved"
            ? "item"
            : null,

        statusAuction:
          tabsStatus[i.key] === "onAuction" ||
          tabsStatus[i.key] === "onAuctionBids" ||
          tabsStatus[i.key] === "notOnAuction" ||
          tabsStatus[i.key] === "deadline"
            ? tabsStatus[i.key]
            : null,
      };
      exploreStore.setObjectRequest(statusResult);
      return statusResult;
    });
  };

  const reloadApproval = (changedItem: any) => {
    if (exploreStore.itemsExplore?.length) {
      let resuslt = exploreStore.itemsExplore?.map((item: any) => {
        if (item?.id === changedItem?.id) {
          return { ...item, status: changedItem?.status };
        } else {
          return item;
        }
      });
      exploreStore.setItemsExplore(resuslt);
    }
  };

  const selectedSortByOption = (e: any, i: any) => {
    exploreStore.setItemsExplore([]);
    setFilterOption((prev) => {
      let filterOption = { ...exploreStore.objectRequest, sortBy: e };
      exploreStore.setObjectRequest(filterOption);
      return filterOption;
    });

    setObjectRequest((prev: any) => {
      let result = {
        ...exploreStore.objectRequest,
        sortBy: tabsSortBY[i.key] !== "All" ? tabsSortBY[i.key] : null,
      };
      exploreStore.setObjectRequest(result);
      return result;
    });
  };
  const getPriceRange = (e: any) => {
    exploreStore.setDefaultMinValue(e[0]);
    exploreStore.setDefaultMaxValue(e[1]);
  };

  const getPriceRangeAfterChange = (e: any) => {
    exploreStore.setItemsExplore([]);
    setFilterOption(e);
    setObjectRequest((prev: any) => {
      let result = {
        ...exploreStore.objectRequest,
        price_min: e[0],
        price_max: e[1],
      };
      exploreStore.setObjectRequest(result);

      return result;
    });
  };

  return (
    <div>
      <Helmet>
        <title>Rarity.market - Explore NFT artwork</title>
        <meta
          name="description"
          content="Explore artwork listed on Rarity marketplace"
        />
        <meta name="keywords" />
        content="NFT, marketplace, explore, item, art, digital, elrond"
        <meta
          content="Explore the items, NFT's and auctions in Rarity"
          property="og:title"
        />
        <meta
          content="A digital marketplace for artists and rare NFTs. Explore NFT's on auction and make bids on rare art."
          property="og:description"
        />
        <meta property="og:url" content="https://rarity.market/explore" />
        content="https://rarity.market/static/media/rarityLogo.6556aa1d.svg"
        <meta property="og:image" />
        <meta
          content="Explore the items, NFT's and auctions in Rarity"
          property="twitter:title"
        />
        <meta content="A digital marketplace for artists and rare NFTs. Explore NFT's on auction and make bids on rare art." />
        property="twitter:description"
        <meta property="twitter:url" content="https://rarity.market/explore" />
        <meta
          content="https://rarity.market/static/media/rarityLogo.6556aa1d.svg"
          property="twitter:image"
        />
      </Helmet>
      <ScrollToTop
        showBelow={250}
        resetOffest={() => exploreStore.setScrollPosition(0)}
      />
      <div className="explore-top">
        <Row>
          <Col>
            <a className="breadcrumbPath" href="/">
              {t("Home")}
            </a>
            <span>{">"}</span>
          </Col>
          <Col>
            {" "}
            <span className="primary-color">{t("home.menu-browse")}</span>
          </Col>
        </Row>
        <div className="explore-titel-filter ">
          <div className="explore-titel">
            <div>
              <h1>{t("Explore-titel")}</h1>
            </div>
          </div>
          <div className="filter-option">
            <div className="explore-result">
              {!loading && (
                <span>
                  {exploreStore?.totalCount} {t("Results")}
                </span>
              )}
            </div>
            <div className="explore-select">
              <span className="primary-color">{t("common.status")}</span>
              <SelectOption
                profile={false}
                optionsSelect={selectStatus}
                role={exploreStore.role}
                optionSelectAdmin={selectStatus}
                onChange={selectedStatusOption}
                disabled={loading}
                defaultValue={
                  exploreStore.objectRequest.status !== null
                    ? selectStatus[
                        tabsStatus.indexOf(exploreStore.objectRequest.status)
                      ]
                    : exploreStore.objectRequest.type !== null
                    ? selectStatus[
                        tabsStatus.indexOf(exploreStore.objectRequest.type)
                      ]
                    : selectStatus[
                        tabsStatus.indexOf(
                          exploreStore.objectRequest.statusAuction
                        )
                      ]
                }
              />
            </div>
            <div>
              <span className="primary-color">{t("Sort-By")}</span>
              <SelectOption
                profile={false}
                optionSelectAdmin={selectSortBy}
                optionsSelect={selectSortBy}
                role={exploreStore.role}
                onChange={selectedSortByOption}
                disabled={loading}
                defaultValue={
                  selectSortBy[
                    tabsSortBY.indexOf(exploreStore.objectRequest.sortBy)
                  ]
                }
              />
            </div>
            <div className="explore-range">
              <span className="primary-color">{t("Price-range")}</span>
              <div className="explore-slider">
                <Slider
                  className="slider"
                  range
                  value={[
                    exploreStore.defaultMinValue,
                    exploreStore.defaultMaxValue,
                  ]}
                  onChange={(value) => getPriceRange(value)}
                  onAfterChange={(value) => getPriceRangeAfterChange(value)}
                  max={10}
                  step={0.00001}
                  disabled={loading}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontSize: "10px" }}>0</span>
                <span style={{ fontSize: "10px" }}>10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="explore-loading-container">
          <Spin size="large" />
        </div>
      ) : exploreStore?.itemsExplore?.length ? (
        <>
          <div className="img-container">
            {exploreStore?.itemsExplore?.map((el: any, key: number) => (
              <Image
                key={key}
                reloadApproval={reloadApproval}
                userInfo={userInfo}
                data={el}
                array={exploreStore.itemsExplore.length}
              />
            ))}
          </div>
          {exploreStore?.cursor?.afterCursor &&
            (!exploreStore.isButtonDisbaled ? (
              <button
                className="button-seeMore ant-btn ant-btn-default collections-button"
                onClick={() => {
                  getItemsFromBack(true);
                  exploreStore.setIsButtonDisbaled(true);
                }}
              >
                {t("activity.see-more")}
              </button>
            ) : (
              <Spin size="large" className="spinner-loader-btn" />
            ))}
        </>
      ) : (
        <>
          {exploreStore?.cursor?.afterCursor && (
            <div className="empty-reload">
              <div className="img-container">{Spinner}</div>
            </div>
          )}
          {lodinNodes && (
            <div className="img-notfound">
              <div className="empty_data">
                <Nodata />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
});
export default Explore;


