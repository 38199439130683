import instanceOfAxios from "../../../configAxios";
import { logger } from "../../context/logger";
import { ITransactionHistory } from "./transaction.interface";

const saveTransactionHistoryApiCall = async (
  transaction: ITransactionHistory
) => {
  const {
    address,
    data,
    gasLimit,
    gasPrice,
    hash,
    nonce,
    reciever,
    smartContractResults1_data,
    status,
    value,
  } = transaction;
  if (reciever) {
    instanceOfAxios
      .post(
        `save_transaction_history/address/${transaction?.address || address}`,
        {
          nonce,
          hash,
          value,
          reciever,
          gasPrice,
          gasLimit,
          data,
          status,
          smartContractResults1_data,
        }
      )
      .then((res: any) => {
        console.log("call to save in TRANSACTION history");
        logger.warn("success of save in TRANSACTION history : ", res);
      })
      .catch((err: any) => {
        logger.warn("Error of saving in transaction history : ", err);
        return "failed call for register transaction";
      });
  } else {
    return "failed call for register transaction";
  }
};

export { saveTransactionHistoryApiCall };
