import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RecentItem from "../recentItem/recentItem";
import CustomDots from "./customDots/customDots";
import "./customCarousel.less";

const ItemsCarousel = ({ ItemsData,startIndex,endIndex}: { ItemsData: any,startIndex?:number,endIndex?:number }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    bigMobile: {
      breakpoint: { max: 600, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="caroussel my-own-custom-container">
      {ItemsData?.length !== 0 && (
        <Carousel
          partialVisbile={false}
          itemClass="items-carousel-item"
          deviceType={"desktop"}
          responsive={responsive}
          removeArrowOnDeviceType={["desktop"]}
          showDots={true}
          customDot={<CustomDots />}
          renderDotsOutside={true}
          dotListClass="custom-dot-list-style"
        >
          {ItemsData.slice(startIndex, endIndex).map((el: any,key:number) => {
            return <RecentItem key={key} item={el} />;
          })}
        </Carousel>
      )}
    </div>
  );
};

export default ItemsCarousel;
