import { action, makeAutoObservable, observable } from 'mobx';

class ItemFormStore {
	@observable collectionData: any;
	@observable extraFilePath: any = null;
	@observable isConfirmExtraFile: boolean = false;
	@observable showPhotosBlock: boolean = true;
	@observable showAudioBlock: boolean = false;
	@observable showVideoBlock: boolean = false;
	@observable show3DBlock: boolean = false;
	@observable isExtraFeatureChanged: boolean = false;
	constructor() {
		makeAutoObservable(this);
	}

	@action
	setCollectionData(value: any) {
		this.collectionData = value;
	}
	@action
	setExtraFilePath(value: any) {
		this.extraFilePath = value;
	}
	@action
	setIsConfirmExtraFile(value: boolean) {
		this.isConfirmExtraFile = value;
	}
	@action
	setShowPhotosBlock(value: boolean) {
		this.showPhotosBlock = value;
	}
	@action
	setShowAudioBlock(value: boolean) {
		this.showAudioBlock = value;
	}
	@action
	setShowVideoBlock(value: boolean) {
		this.showVideoBlock = value;
	}
	@action
	setShow3DBlock(value: boolean) {
		this.show3DBlock = value;
	}
	@action
	setIsExtraFeatureChanged(value: boolean) {
		this.isExtraFeatureChanged = value;
	}
}

export default new ItemFormStore();
