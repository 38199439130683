import { useTranslation } from 'react-i18next';
import './recentAuction.less';
import AuctionList from './auctionList/auctionList';
import { useNavigate } from 'react-router-dom';

export interface RecentAuctionProps {
	partTitle: string;
	elementsList: any;
}

const RecentAuction = ({ partTitle, elementsList }: RecentAuctionProps) => {
	const { t } = useTranslation();
	const  navigate = useNavigate();

	const handleRedirect = (e: any) => {
		e.preventDefault();
		navigate({ pathname: '/explore' });
	};

	return (
		<div className="auction-card">
			<div className="auction-card-header">
				<h3 className="header-title">{partTitle}</h3>
				<a
					href="/explore"
					className="auction-link-button text-regular-font "
					onClick={(e: any) => handleRedirect(e)}>
					{t('home.navigation-see-all')}
				</a>
			</div>
			{elementsList.map((el: any, key: number) => {
				return <AuctionList auction={el} key={key} />;
			})}
		</div>
	);
};

export default RecentAuction;
