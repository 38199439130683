import { Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import egldIcon from "../../../../../../assets/images/EGLD.svg";
import instanceOfAxios from "../../../../../../configAxios";
import { useContext, useDispatch } from "../../../../../context";
import NumberDisplayi18n from "../../../../../helpers/numbersi18n";
import useUserNaming from "../../../../../helpers/useUserNaming";
import { setItem } from "../../../../../storage/session";
import { logger } from "../../../../../context/logger";
import { useState } from "react";
import { verifyNFTonExpectedAddress } from "../../../../../helpers/communFunctions";

interface ClaimNFTProps {
  auctionData: any;
  item: any;
}

const ClaimNFT = ({ auctionData, item }: ClaimNFTProps) => {
  const { t } = useTranslation();
  const { address, actionCode, marketPlaceAddress } = useContext();
  const  navigate = useNavigate();
  const dispatch = useDispatch();
  const winner = useUserNaming(auctionData?.winner);

  const /*transactionSessionId*/ [, setTransactionSessionId] = useState<
      string | null
    >(null);
  // const { sendTransactions } = transactionServices;
  const { account } = useGetAccountInfo();

  const claimNFTcall = () => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    } else {
      verifyNFTonExpectedAddress(
        marketPlaceAddress,
        item?.token_identifier,
      ).then((res: any) => {
        if (res === true) {
          instanceOfAxios
            .get(`end_auction/address/${address}/${item?.id}`)
            .then(async (res: any) => {
              if (
                Number(account?.balance)  <
                res?.data?.value
              ) {
                message.error(t("action-box.not-enough-balance"));
              } else {
                const customtransaction = {
                  receiver: res?.data?.receiver,
                  gasLimit: res?.data?.gas_limit,
                  value: res?.data?.value ,
                  data: res?.data?.data_example,
                };
                logger.warn(
                  "******send transaction to end auction : ",
                  customtransaction
                );

                console.log("customtransaction======>",customtransaction)
                await refreshAccount();

                const { sessionId /*, error*/ } = await sendTransactions({
                  transactions: customtransaction,
                  transactionsDisplayInfo: {
                    processingMessage: t(
                      "action-box.processing-end-auction-transaction"
                    ),
                    errorMessage: t("action-box.end-auction-transaction-error"),
                    successMessage: t(
                      "action-box.end-auction-transaction-success"
                    ),
                  },
                  redirectAfterSign: false,
                });
                if (sessionId != null) {
                  setTransactionSessionId(sessionId);
                  //here we gonna save an object that contains this sessionId along with the collection id and item id, so we can catch the transaction result by the sessionId and link it to the specific collection/item ...
                  localStorage.setItem(
                    "transactions-track",
                    JSON.stringify([
                      ...JSON.parse(
                        localStorage.getItem("transactions-track") || "[]"
                      ),
                      {
                        itemId: item?.id,
                        collectionId: item?.collection?.id,
                        sessionId,
                        address,
                        actionCase: actionCode,
                        sentData: customtransaction?.data || "",
                      },
                    ])
                  );
                  //we save in context too, considering the case of no redirection , such as xPortal extension
                  dispatch({
                    type: "keepTrack",
                    transactionsTrack: JSON.parse(
                      localStorage.getItem("transactions-track") || "[]"
                    ),
                  });
                }
              }
            })
            .catch((error: any) => {
              console.log("err", error);
              return error;
            });
        }else {
          message.warning(t("action-box.nft-not-on-auction"))
        }
      });
    }
  };

  return (
    <div className="auction-content">
      <h4 className="primary-color">
      {Number(auctionData?.auction?.bid) === 0? 
         t("collection-page.auction-box-no-bid") 
      :t("collection-page.auction-box-success")}
      </h4>
      <h5 className="desc-auction">
        {Number(auctionData?.auction?.bid) === 0 ? (
          t("collection-page.no-bid-done")
        ) : (
          <>
            {address === auctionData?.winner?.erd_address ? (
              t("collection-page.success-auction-current-user")
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: t("collection-page.success-auction", {
                    winner: `<a href="/profile-details/${auctionData?.winner?.id}">${winner}</a>`,
                  }),
                }}
              ></div>
            )}
            <NumberDisplayi18n
              value={Number(Number(auctionData?.auction?.bid).toFixed(6))/ 1000000000000000000}
              currency=""
              crypto={false}
            />
            {"   "}
            <img alt="EGLD" src={egldIcon} className="small-egld-logo" />
          </>
        )}
      </h5>
      {Number(auctionData?.auction?.bid) !== 0 && (
        <h6 className="details-auction">
          {t("collection-page.details-auction-success")}
        </h6>
      )}
      <Button type="primary" className="button-auction" onClick={claimNFTcall}>
        {Number(auctionData?.auction?.bid) !== 0
          ? t(
              `collection-page.${
                address === item?.owner?.erd_address
                  ? "button-claim-auction"
                  : address === auctionData?.winner?.erd_address
                  ? "claim-nft-sft"
                  : address === item?.creator?.erd_address
                  ? "get-royalty-fee"
                  : "end-auction-button"
              }`
            )
          : t(
              `collection-page.${
                address === item?.owner?.erd_address
                  ? "claim-nft-sft"
                  : "sign-transaction"
              }`
            )}
      </Button>
    </div>
  );
};

export default ClaimNFT;
