import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button, Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  sendTransactions,
} from "@multiversx/sdk-dapp/services";
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account'
import CustomModal from "../../../../components/modal/customModal";
import "./auctionModal.less";
import InfoNotif from "../../../../components/notifications/info notif/infoNotif";
import NumberDisplayi18n from "../../../../helpers/numbersi18n";
import instanceOfAxios from "../../../../../configAxios";
import egldIcon from "../../../../../assets/images/EGLD.svg";
import { useContext, useDispatch } from "../../../../context";
import { setItem } from "../../../../storage/session";
import { logger } from "../../../../context/logger";
import { checkLengthNumber } from "../../../../helpers/regularExpressions";
import i18n from "../../../../../i18n";
import Decimal from 'decimal.js';

export interface AuctionModalProps {
  order: string;
  price?: string;
  visible: boolean;
  setVisible: (status: boolean) => void;
  blockchainCurrency: string;
  userCurrency: string;
  royality?: number;
  item?: any;
  gasLimit?: number;
  minBid?: number;
  maxBid?: number;
  bidStep?: number;
}

const AuctionModal = ({
  order,
  price,
  visible,
  setVisible,
  blockchainCurrency,
  userCurrency,
  royality,
  item,
  gasLimit,
  minBid,
  maxBid,
  bidStep,
}: AuctionModalProps) => {
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const dispatch = useDispatch();
  const { address, actionCode } = useContext();
  const [priceConverted, setPriceConverted] = useState(0);
  const [userBid, setUserBid] = useState(0);
  const { account } = useGetAccountInfo();
  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const /*transactionSessionId*/[, setTransactionSessionId] = useState<
    string | null
  >(null);
  // const { sendTransactions } = transactionServices;
  const fee = (gasLimit || 1) / 1000000000000000000;

  useEffect(() => {
    Number(userBid) > 0 &&
      instanceOfAxios
        .get(`currency/exchange_rates/egld/${Number(userBid)}/eur`)
        .then((res: any) => {
          setPriceConverted(res?.data?.rate);
        })
        .catch((err: any) => { });
  }, [userBid]);

  useEffect(() => {
    setUserBid(Number(price));
    form.setFieldsValue({ user_bid: Number(price) / 1000000000000000000 });
  }, [price]);

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setLoadingButton(false);
    form.setFieldsValue({ user_bid: Number(price) / 1000000000000000000  });
  };

  const onFinish = (values: any) => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    }
    setLoadingButton(true);
    
    instanceOfAxios
      .get(
        `submit_bid/address/${address}/${item?.id}?value=${values?.user_bid * 1000000000000000000}`
      )
      .then(async (res: any) => {
        // localStorage.setItem("bid", values?.user_bid);
        // if (order === "purchase" || Number(values?.user_bid) === maxBid) {
        //   localStorage.setItem("bid_status", "bought");
        // } else {
        //   localStorage.setItem("bid_status", "sent");
        // }
        if (Number(account?.balance)  < res?.data?.value) {
          message.error(t("action-box.not-enough-balance"));
          setLoadingButton(false);
        } else {
          const customtransaction = {
            receiver: res?.data?.receiver,
            gasLimit: res?.data?.gas_limit,
            value: res?.data?.value,
            data: res?.data?.data_example,
          };
        
          logger.warn(
            "****** send a transaction of bid with : ",
            customtransaction
          );
          await refreshAccount();
          const { sessionId /*, error*/ } = await sendTransactions({
            transactions: customtransaction,
            transactionsDisplayInfo: {
              processingMessage: t("action-box.processing-bid-transaction"),
              errorMessage: t("action-box.bid-transaction-error"),
              successMessage: t("action-box.bid-transaction-success"),
            },
            redirectAfterSign: false,
          });
          if (sessionId != null) {
            setTransactionSessionId(sessionId);
            //here we gonna save an object that contains this sessionId along with the collection id and item id, so we can catch the transaction result by the sessionId and link it to the specific collection/item ...
            localStorage.setItem(
              "transactions-track",
              JSON.stringify([
                ...JSON.parse(
                  localStorage.getItem("transactions-track") || "[]"
                ),
                {
                  itemId: item?.id,
                  collectionId: item?.collection?.id,
                  sessionId,
                  address,
                  actionCase: actionCode,
                  necessData: {
                    item,
                    bid: values?.user_bid,
                    bidStatus:
                      order === "purchase" ||
                        Number(values?.user_bid) === maxBid
                        ? "bought"
                        : "sent",
                  },
                  sentData: customtransaction?.data || "",
                },
              ])
            );
            //we save in context too, considering the case of no redirection , such as xPortal extension
            dispatch({
              type: "keepTrack",
              transactionsTrack: JSON.parse(
                localStorage.getItem("transactions-track") || "[]"
              ),
            });
          }
        }
      })
      .catch((err: any) => {
        return "failed call for register grant";
      });
  };

  const handleRedirect = (path: string) => {
    navigate(path);
  };

  return (
    <CustomModal
      visible={visible}
      width="400px"
      closable={true}
      handleOk={handleOk}
      handleCancel={handleCancel}>
      <Form form={form} onFinish={onFinish}>
        <div className="auction-modal-header">
          <h4>
            {order === "place bid"
              ? t("collection-page.place-bid-button")
              : t("collection-page.checkout")}
          </h4>
          <h6 className="text-regular-font">
            {order === "place bid"
              ? t("collection-page.place-bid-desc")
              : t("collection-page.checkout-desc")}
          </h6>
        </div>
        <h6>
          {order === "place bid"
            ? t("collection-page.your-bid")
            : t("common.price")}
        </h6>
        <div className="bid-price-input-holder">
          <Form.Item
            name="user_bid"
            className="bid-price-input-holder"
            rules={[
              {
                required: true,
                message: t("action-box.maximal-price-required"),
              },
              () => ({
                validator(_, value ) {

                  if (isNaN(Number(value))) {
                    return Promise.reject(t("action-box.must-number"));
                  } else if ((Number(value)*1000000000000000000) < (minBid || 0)) {
                    return Promise.reject(
                      t("action-box.must-be-greater-that-minBid", {
                        bidStep: Number(bidStep) / 1000000000000000000 ,
                        auctionCurrencyUnit: "EGLD",
                      })
                    );
                  } else if (
                    order !== "purchase" &&
                    (Number(value) * 1000000000000000000) > (maxBid || 50)
                  ) {
                    return Promise.reject(
                      t("action-box.must-be-less-that-maxBid")
                    );
                  } else if (!checkLengthNumber.test(value)) {
                    return Promise.reject(
                      t("action-box.integer-limit-reached")
                    );
                  } else {
                    const integerPart = value.toString().split(".")[0];
                    const decimalPart = value.toString().split(".")[1];
                    if (integerPart?.length > 18) {
                      return Promise.reject(
                        t("action-box.integer-limit-reached")
                      );
                    }
                    if (decimalPart?.length > 3) {
                      return Promise.reject(
                        t("action-box.decimals-limit-reached")
                      );
                    }

                    return Promise.resolve();
                  }
                },
              }),
            ]}>
            <Input
              suffix={
                <img alt="EGLD" src={egldIcon} className="big-egld-icon" />
              }
              placeholder={t("collection-page.bid-placeholder")}
              disabled={order === "purchase"}
            
              onChange={(e: any) => setUserBid(Number(e?.target?.value))}
            />
          </Form.Item>

          <h6 className="approximative-price">
            ≈
            <NumberDisplayi18n
              value={Number(priceConverted?.toFixed(2))  }
              currency={userCurrency}
              crypto={false}
            />
          </h6>
        </div>
        <h6 className="text-regular-font red-color">
          {`*${t("collection-page.royality-notif")} `}
          <NumberDisplayi18n
            value={Number(
              ((Number(userBid/1000000000000000000) / (royality || 1 / 100)) * 100)?.toFixed(6)
            )}
            currency={""}
            crypto={false}
          />
          {blockchainCurrency}
        </h6>
        {/* <Divider />
        <div className="bid-description-row">
          <h5>{t("collection-page.network-fee")}</h5>
          <h5 className="text-regular-font">
            <CryptoCurrencyNumber value={fee} currency={blockchainCurrency} />
            <img
              alt="EGLD"
              src={egldIcon}
              className="small-auction-egld-icon"
            />
          </h5>
        </div>
        <div className="bid-description-row">
          <h5>{t("collection-page.total")}</h5>
          <h5 className="text-regular-font">
            <CryptoCurrencyNumber
              value={userBid + fee}
              currency={blockchainCurrency}
            />
            <img
              alt="EGLD"
              src={egldIcon}
              className="small-auction-egld-icon"
            />
          </h5>
        </div> */}
        <div className="auction-modal-button-holder">
          <Button
            type="primary"
            block
            htmlType="submit"
            loading={loadingButton}>
            {order === "place bid"
              ? t("collection-page.place-bid-button")
              : t("collection-page.buy-now")}
          </Button>
        </div>
        <div className="auction-modal-button-holder">
          <Button type="default" block onClick={handleCancel}>
            {t("common.cancel")}
          </Button>
        </div>
        <div
          onClick={() =>
            handleRedirect(`/${i18n.language}/faq?search=EGLD&tab=EGLD`)
          }>
          <InfoNotif
            description={t("collection-page.need-money", {
              currency: blockchainCurrency,
            })}
            buttonText={t("collection-page.buy-money", {
              currency: blockchainCurrency,
            })}
          />
        </div>
      </Form>
    </CustomModal>
  );
};

export default AuctionModal;
