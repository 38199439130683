import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomModal from "../modal/customModal";
import "./editorArea.less";

export interface EditorAreaProps {
  onChange: (e: any) => void;
  defaultDesc: string;
}

const EditorArea = ({ onChange, defaultDesc }: EditorAreaProps) => {
  const [description, setDescription] = useState(defaultDesc || "<p></p>");
  const [textResultVisible, setTextResultVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    defaultDesc && setDescription(defaultDesc);
  }, [defaultDesc]);

  const handelChange = (e: any) => {
    setDescription(e);
  };
  onChange(description);
  const handleOk = () => {
    setTextResultVisible(false);
  };

  const handleCancel = () => {
    setTextResultVisible(false);
  };

  return (
    <div>
      <ReactQuill
        value={description}
        onChange={(e) => handelChange(e)}
        className="editor-holder"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "-20px",
        }}
      >
        <Button
          type="link"
          size="small"
          onClick={() => setTextResultVisible(true)}
        >
          {t("collection-page.visualize-result")}
        </Button>
      </div>
      {textResultVisible && (
        <CustomModal
          width="400px"
          visible={textResultVisible}
          closable={true}
          handleOk={handleOk}
          handleCancel={handleCancel}
        >
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </CustomModal>
      )}
    </div>
  );
};

export default EditorArea;
