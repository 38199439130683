import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Spin, Menu, Dropdown, message, Tooltip } from "antd";
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import {
  EditFilled,
  DeleteFilled,
  DislikeFilled,
  CopyOutlined,
} from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { Link, useNavigate ,useParams} from "react-router-dom";
import flagIcon from "../../../../../assets/images/flag icon.svg";
import exportIcon from "../../../../../assets/images/export.svg";
import eyeIcon from "../../../../../assets/images/eye.svg";
import heartIcon from "../../../../../assets/images/heart.svg";
import refreshIcon from "../../../../../assets/images/refresh.svg";
import reportIcon from "../../../../../assets/images/report.svg";
import shareIcon from "../../../../../assets/images/share.svg";
import downloadIcon from "../../../../../assets/images/download.svg";
import nftIcon from "../../../../../assets/images/ic-gift.svg";
import IconButton from "../../../../components/buttons/IconButton/IconButton";
import "./itemDetails.less";
import UserDesc from "../../components/userDesc/userDesc";
import DetailDisplay from "../../components/detailDisplay/detailDisplay";
import RecentItem from "../../../home/components/recentItem/recentItem";
import ItemTab from "../../components/itemTab/itemTab";
import { useContext, useDispatch } from "../../../../context";
import instanceOfAxios from "../../../../../configAxios";
import ItemsCarousel from "../../../home/components/carousel/ItemsCarousel";
import LoadingItem from "../../../home/components/recentItem/loadingItem";
import { getItem, setItem } from "../../../../storage/session";
import ConfirmModal from "../../../../components/modal/confirm modal/confirmModal";
import useUserNaming from "../../../../helpers/useUserNaming";
import TransferGift from "../../components/transfer gift/transferGift";
import axios from "axios";
import TextAreaModal from "../../../../components/modal/textAreaModal/textAreaModal";
import useImageDisplay from "../../../../helpers/useImageDisplay";
import ActionSwitch from "../../components/item action box/actionSwitch";
import ItemPhotos from "../../components/itemPhotos/itemPhotos";
import { OpenErrorNotification } from "../../../../components/toasts/openToast";

import {
  intToString,
  verifyNFTonExpectedAddress,
} from "../../../../helpers/communFunctions";
import { useGlobalContext } from "../../../../context/globalContext";
import { observer } from "mobx-react-lite";
import { getItemFromLocalStorage } from "../../../../helpers/useLocalStorage";
import { Helmet } from "react-helmet";
import PaginationRelatedItem from "../../../home/components/paginationButtons/paginationRelatedItem";

const ItemDetails = observer((props: any) => {
  const params = useParams();
  const { t } = useTranslation();
  const { address:userAddress}= useGetAccountInfo();
  const  navigate = useNavigate();

  // const [itemdRelated, setItemRelated] = useState<any[]>([]);
  const [itemsDetails, setItemsDetails] = useState<any>({});
  const [pageIndex, setPageIndex] = useState(0);

  const startIndex = pageIndex * 4;
  const endIndex = pageIndex * 4 + 4;

  const {
    address,
    loading,
    // items,
    actionCode,
    refreshItem,
    marketPlaceAddress,
  } = useContext();
  const { likesStore,itemDetailsStore,itemFormStore } = useGlobalContext();
  const dispatch = useDispatch();
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem("user") || "{}")?.role
  );
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const menu = [
    "description",
    "properties",
    "info",
    "offers",
    "trading-history",
  ];
  const menuMobile = [
    {
      label: t("collection-page.description"),
      value: "description",
    },
    {
      label: t("collection-page.properties"),
      value: "properties",
    },
    {
      label: t("collection-page.info"),
      value: "info",
    },
    {
      label: t("collection-page.offers"),
      value: "offers",
    },
    {
      label: t("collection-page.trading-history"),
      value: "trading-history",
    },
  ];

  const [activeTab, setActiveTab] = useState("description");
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [offers, setOffers] = useState();
  const ownerName = useUserNaming(itemsDetails?.owner);
  const creatorName = useUserNaming(itemsDetails?.creator);
  const [arrayofAttribute, setArrayofAttribute] = useState<any[]>([]);
  const [tranferModal, setTransferModal] = useState(false);
  const pageItemsNumber = 4;
  const [reportModal, setReportModal] = useState(false);
  const [dislikeModal, setDislikeModal] = useState(false);
  const itemImage = useImageDisplay(
    itemsDetails?.uri,
    "__b" + itemsDetails?.uri_ext
  );
  const itemImage1 = useImageDisplay(
    itemsDetails?.uri1,
    "__b" + itemsDetails?.uri1_ext
  );
  const itemImage2 = useImageDisplay(
    itemsDetails?.uri2,
    "__b" + itemsDetails?.uri2_ext
  );
  const itemImage3 = useImageDisplay(
    itemsDetails?.uri3,
    "__b" + itemsDetails?.uri3_ext
  );
  const creatorAvatar = useImageDisplay(
    itemsDetails?.creator?.avatar,
    "__t" + itemsDetails?.creator?.avatar_ext
  );
  const ownerAvatar = useImageDisplay(
    itemsDetails?.owner?.avatar,
    "__t" + itemsDetails?.owner?.avatar_ext
  );
  const [itemImages, setItemImages] = useState<string[]>([]);
  const [downloadable, setDonwloadable] = useState(false);
  const [provideDownload, setProvideDownload] = useState(false);
  const [ownerOrCreator, setOwnerOrCreator] = useState(false);

  useEffect(() => {
     window.scrollTo(0, 0);
    if (
      window.location.search !== "" &&
      menu.filter((menuItem) => window.location.search.split("?tab=").includes(menuItem))
        ?.length > 0
    ) {
      setActiveTab(window.location.search.split("?tab=")[1]);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (itemImage3) {
      setItemImages([itemImage, itemImage1, itemImage2, itemImage3]);
    } else if (itemImage2) {
      setItemImages([itemImage, itemImage1, itemImage2]);
    } else if (itemImage1) {
      setItemImages([itemImage, itemImage1]);
    } else {
      setItemImages([itemImage]);
    }
  }, [itemImage]);

  const getItemAPIcall = () => {
    instanceOfAxios
      .get(`item/${params.id}${address ? `?erd_address=${address}` : ""}`)
      .then((res) => {
        setItemsDetails(res?.data);
        //seeting the collection id is important for blockchain transaction process (old process..may be deleted after testing safely)
        setItem("collId", res?.data?.collection?.id);
        setOwnerOrCreator(
          (JSON.parse(localStorage.getItem("user") || "{}") &&
            JSON.parse(localStorage.getItem("user") || "{}")?.id ===
              res?.data?.owner?.id) ||
            JSON.parse(localStorage.getItem("user") || "{}")?.id ===
              res?.data?.creator?.id
        );
        setOffers(res?.data?.offers);
        setArrayofAttribute([
          ...res.data?.item_attributes,
          ...res.data?.item_custom_attributes,
        ]);
        if (address && res?.data?.id) {
          setDonwloadable(
            (address === res?.data?.creator?.erd_address ||
              address === res?.data?.owner?.erd_address ||
              JSON.parse(localStorage.getItem("user") || "{}")?.role ===
                "admin") &&
              res?.data?.type !== "item"
          );
        }

        if(res?.data?.type==="nft"){
          if(window.location.search!==""){
            navigate(`/nft/${res.data?.token_identifier}${window.location.search}`);
          }else {
            navigate(`/nft/${res.data?.token_identifier}`);
          }
        }

        dispatch({ type: "refreshItem", refreshItem: false });
      });
  };

  useEffect(() => {
    getItemAPIcall();
  }, [address, role, params.id, likesStore.isLiked]);

  useEffect(() => {
    refreshItem && getItemAPIcall();
  }, [refreshItem]);

  useEffect(() => {
    address &&
      JSON.parse(localStorage.getItem("user") || "{}") &&
      setRole(JSON.parse(localStorage.getItem("user") || "{}")?.role);
  }, []);
  

  const callRelatedItemApi=()=>{
    itemDetailsStore.setIsItemPhotoLoading(true)
     instanceOfAxios
        .get(
          `related_item/item/${params.id}?${
            address ? `erd_address=${address}` : ""
          }`
        )
        .then((res) => {
          itemDetailsStore.setIsItemPhotoLoading(false)
          itemDetailsStore.setItemsRelated(res.data?.nodes);
          itemDetailsStore.setPageInfos(res?.data?.pageInfo);
          itemDetailsStore.setIsRelatedItemLoading(false)
        })
        .catch((err) => console.log(err));
  }

  useEffect(() => {
    if(itemDetailsStore.idItem!==params.id){
      itemDetailsStore.setIdItem(params.id!)
      callRelatedItemApi()      
    }
  }, [itemsDetails]);

  useEffect(() => {
    saveViews();
  }, []);

  const saveViews = () => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((res) => {
        if (
          !sessionStorage.getItem("viewItem") ||
          sessionStorage.getItem("viewItem") !== params.id
        ) {
          sessionStorage.setItem("viewItem", params.id!);
          instanceOfAxios
            .put(`increase_views/item/${params.id}`)
            .then((res: any) => {
              getItemAPIcall();
              return "successfull view";
            })
            .catch((err: any) => {
              return "failed call for view";
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const saveInSession = (field: string, functionCall: () => void) => {
    if (!getItem(field)) {
      functionCall();

      axios.get("https://api.ipify.org?format=json").then((res) => {
        setItem(
          field,
          JSON.stringify([
            {
              itemId: itemsDetails?.id,
              ip: res?.data?.ip,
            },
          ])
        );
      });
    } else if (
      JSON.parse(getItem(field)).filter((el: any) => el.itemId === params.id)
        .length === 0
    ) {
      functionCall();
      axios.get("https://api.ipify.org?format=json").then((res) => {
        let newViews = JSON.parse(getItem(field));
        newViews.push({
          itemId: itemsDetails?.id,
          ip: res?.data?.ip,
        });
        setItem(field, JSON.stringify(newViews));
      });
    } else {
      if (field === "report") {
        OpenErrorNotification(
          t("profile.error"),
          t("collection-page.already-reported"),
          3
        );
        setReportModal(false);
      } else if (field === "dislike") {
        OpenErrorNotification(
          t("profile.error"),
          t("collection-page.already-disliked"),
          3
        );
        setDislikeModal(false);
      }
    }
  };

  const reportCall = (reason: string) => {
    instanceOfAxios
      .post(`report_items/item/${itemsDetails?.id}/address/${address}`, {
        report: reason,
        dislike: false,
        reports: true,
      })
      .then((res: any) => {
        setReportModal(false);
        return "successfull report";
      })
      .catch((err: any) => {
        return "failed call for report item";
      });
  };

  const reportItem = (reason: string) => {
    if (address) {
      saveInSession("report", () => reportCall(reason));
    } else {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    }
  };

  const dislikeCall = (reason: string) => {
    instanceOfAxios
      .post(`report_items/item/${itemsDetails?.id}/address/${address}`, {
        report: reason,
        dislike: true,
        reports: false,
      })
      .then((res: any) => {
        setDislikeModal(false);
        return "successfull report";
      })
      .catch((err: any) => {
        return "failed call for report item";
      });
  };

  const dislikeItem = (reason: string) => {
    if (address) {
      saveInSession("dislike", () => dislikeCall(reason));
    } else {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    }
  };

  const itemMenu = (
    <Menu>
      {ownerOrCreator ? (
        <>
          <a
            href={`/edit-item/${itemsDetails?.id}`}
            onClick={(e) => {
              if(itemsDetails && itemsDetails?.uri1){
                if(itemsDetails?.uri1.includes(".mp3") || itemsDetails?.uri1.includes(".ogg") || itemsDetails?.uri1.includes(".wav")){
                  itemFormStore.setShowPhotosBlock(false)
                  itemFormStore.setShowAudioBlock(true)
                  itemFormStore.setShowVideoBlock(false)
                  itemFormStore.setShow3DBlock(false)
                }else if(itemsDetails?.uri1.includes(".mp4") || itemsDetails?.uri1.includes(".webm")){
                  itemFormStore.setShowPhotosBlock(false)
                  itemFormStore.setShowAudioBlock(false)
                  itemFormStore.setShowVideoBlock(true)
                  itemFormStore.setShow3DBlock(false)
                }
              }else if(itemsDetails && (itemsDetails?.uri.includes(".glb") || itemsDetails?.uri.includes(".gltf") 
              || itemsDetails?.uri.includes(".fbx") || itemsDetails?.uri.includes(".obj"))) {
                itemFormStore.setShowPhotosBlock(false)
                itemFormStore.setShowAudioBlock(false)
                itemFormStore.setShowVideoBlock(false)
                itemFormStore.setShow3DBlock(true)
              }else {
                itemFormStore.setShowPhotosBlock(true)
                itemFormStore.setShowAudioBlock(false)
                itemFormStore.setShowVideoBlock(false)
                itemFormStore.setShow3DBlock(false)
              }
              e.preventDefault();
            }}>
            <Menu.Item
              key="0"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/edit-item/${itemsDetails?.id}`);
              }}>
              <h5 className="menu-row">
                <EditFilled className="item-menu-icon" />
                {t("common.edit")}
              </h5>
            </Menu.Item>
            </a>
            <a
            href={`/duplicate-item/${itemsDetails?.id}/${itemsDetails?.collection?.id}`}
            onClick={(e) => {
              e.preventDefault();
            }}>
            <Menu.Item
              key="0"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(
                  `/duplicate-item/${itemsDetails?.id}/${itemsDetails?.collection?.id}`
                );
              }}>
              <h5 className="menu-row">
                <CopyOutlined className="item-menu-icon" />
                {t("common.duplicate-item-data")}
              </h5>
            </Menu.Item>
            </a>
          <Menu.Item key="1" onClick={() => setDeleteConfirm(true)}>
            <h5 className="menu-row red-color">
              {" "}
              <DeleteFilled className="item-menu-icon" />
              {t("common.delete")}
            </h5>
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key="0" onClick={() => setReportModal(true)}>
            <div className="collection-profile-menu-item">
              <img alt="flagIcon" src={flagIcon} className="flag-icon" />
              <h6>{t("collection-page.report-item")}</h6>
            </div>
          </Menu.Item>
          <Menu.Item key="3" onClick={() => setDislikeModal(true)}>
            <div className="collection-profile-menu-item">
              <DislikeFilled style={{ margin: "0 10px" }} />
              <h6>{t("collection-page.dislike-item")}</h6>
            </div>
          </Menu.Item>
          {role === "admin" && (
            <Menu.Item key="1" onClick={() => setDeleteConfirm(true)}>
              <h5 className="menu-row red-color">
                {" "}
                <DeleteFilled className="item-menu-icon" />
                {t("common.delete")}
              </h5>
            </Menu.Item>
          )}
        </>
      )}
    </Menu>
  );

  const goPreviousPage = () => {

    if(pageIndex>-1){
      setPageIndex(pageIndex-1);

    }
  };

  const goNextPage = () => {
    setPageIndex(pageIndex+1);
  };

  const deleteItem = () => {
    instanceOfAxios
      .delete("item/" + params.id + "/address/" + address, {
        headers: {
          "x-access-token": getItemFromLocalStorage("token"),
        },
      })
      .then((res) => {
        window.scrollTo(0, 0);
        navigate(`/profile-details/${user?.id}`);
      }).catch((err)=>{
        if(err?.response.status===401){
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-unauthorized-user"),
            3
          );                    
        }else{
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        }
        setDeleteConfirm(false)
      });
  };

  const handleOk = () => {
    setTransferModal(false);
  };

  const handleCancel = () => {
    setTransferModal(false);
  };

  const refreshMetadataCall = () => {
    instanceOfAxios
      .get(`refresh_metadata/${address}/${params.id}`)
      .then((res) => {
        message.success(t("collection-page.refresh-meta-progress-message"));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e) => {
        message.warning(t("collection-page.refresh-meta-wait-message"));
      });
  };

  return (
    <div className="item-details-page">
       <Helmet>
       <title>
          Rarity.market {`${itemsDetails?.collection?.collection_name}`} -{" "}
          {`${itemsDetails?.title}`}
        </title>
        <meta property="og:type" content="website"/>
        <meta name="description" content={itemsDetails?.description} />
        <meta
          name="keywords"
          content="NFT, item, bids, creation, auction, trading, offer, art, digital, elrond"
        />
        <meta content={itemsDetails?.title} property="og:title" />
       {itemsDetails?.description && 
       <meta content={itemsDetails?.description.replace('<p><strong style="color: rgb(0, 0, 0);">','').replace('</strong><span style="color: rgb(0, 0, 0);">&nbsp;',' ').replace('</span></p>','').replace('<p>','').replace('</p>',' ')} 
       property="og:description" />}
        <meta
          property="og:url"
          content={itemsDetails?.type==="nft"?`https://rarity.market/nft/${itemsDetails?.token_identifier}`
          :`https://rarity.market/item-details/${itemsDetails?.id}`}
        />
         <meta content={itemImage} property="og:image" />
         {itemImage1 && <meta content={itemImage1} property="og:image" />}
        {itemImage2 && <meta content={itemImage2} property="og:image" />}
        {itemImage3 && <meta content={itemImage3} property="og:image" />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta content={itemsDetails?.title} property="twitter:title" />
        <meta
          content={itemsDetails?.description}
          property="twitter:description"
        />
        <meta
          property="twitter:url"
          content={itemsDetails?.type==="nft"?`https://rarity.market/nft/${itemsDetails?.token_identifier}`
          :`https://rarity.market/item-details/${itemsDetails?.id}`}
        />
         <meta content={itemImage} property="twitter:image" />
        {itemImage1 && <meta content={itemImage1} property="twitter:image" />}
        {itemImage2 && <meta content={itemImage2} property="twitter:image" />}
        {itemImage3 && <meta content={itemImage3} property="twitter:image" />}
       </Helmet>
      {itemsDetails?.id ? (
        <>
          <Row className="rowItem">
            <Col span={24} className="item-details-header">
              <div className="item-details-header-text">
                <Tooltip title={itemsDetails?.title}>
                  <h1 className="text-regular-font">
                    {itemsDetails?.title?.length < 60
                      ? itemsDetails?.title
                      : `${itemsDetails?.title?.slice(0, 57)}...`}
                  </h1>
                </Tooltip>
                <a
                  href={`/${itemsDetails?.collection?.homepage}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}>
                <h2
                  className="text-regular-font collection-name-link"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(`/${itemsDetails?.collection?.homepage}`);
                  }}>
                  {itemsDetails?.collection?.collection_name}
                </h2>
                  </a>
              </div>
              <div className="item-details-icon-buttons">
                {address && (
                  <IconButton
                    title={t("collection-page.refresh-meta")}
                    icon={refreshIcon}
                    onClick={refreshMetadataCall}
                  />
                )}
                {address &&
                  itemsDetails?.type !== "item" &&
                  user?.id === itemsDetails?.owner?.id &&
                  actionCode !== 304 &&
                  actionCode !== 301 && (
                    <IconButton
                      title={t("collection-page.transfer-gift")}
                      icon={nftIcon}
                      onClick={() => {
                        verifyNFTonExpectedAddress(
                          userAddress,
                          itemsDetails?.token_identifier,
                        ).then((res: any) => {
                          if (res === true) {
                            setTransferModal(true);
                          }
                          else {
                            message.warning(t("action-box.nft-not-on-wallet"))
                          }
                        });
                      }}
                    />
                  )}
                {itemsDetails?.project_link?.includes("http") && (
                  <IconButton
                    title={t("collection-page.project-link-tooltip")}
                    icon={exportIcon}
                    onClick={() => window.open(itemsDetails?.project_link)}
                  />
                )}
                <IconButton
                  title={t("collection-page.copy-share")}
                  icon={shareIcon}
                  onClick={() => {
                    copy(window.location.href);
                    message.success(t("profile.link-copied"));
                  }}
                />
                {itemsDetails?.type === "item" && (
                  <Dropdown
                    overlay={itemMenu}
                    trigger={["click"]}
                    placement="bottomRight">
                    <IconButton icon={reportIcon} />
                  </Dropdown>
                )}
              </div>
              <TransferGift
                item={itemsDetails}
                visible={tranferModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
              />
              <ConfirmModal
                modalVisible={deleteConfirm}
                confirm={deleteItem}
                cancel={() => setDeleteConfirm(false)}
                title={t("collection-page.confirm-delete-item-title")}
                description={t("collection-page.confirm-delete-item")}
              />
            </Col>
          </Row>
          {reportModal && (
            <TextAreaModal
              visible={reportModal}
              title={t("collection-page.report-title")}
              description={t("collection-page.report-desc")}
              label={t("collection-page.refuse-message-title")}
              placeholder={t("collection-page.report-placeholder")}
              okButton={t("collection-page.report-ok-button")}
              handleOk={(reason) => reportItem(reason)}
              handleCancel={() => setReportModal(false)}
            />
          )}
          {dislikeModal && (
            <TextAreaModal
              visible={dislikeModal}
              title={t("collection-page.dislike-item")}
              description={t("collection-page.dislike-desc")}
              label={t("collection-page.refuse-message-title")}
              placeholder={t("collection-page.report-placeholder")}
              okButton={t("collection-page.report-ok-button")}
              handleOk={(reason) => dislikeItem(reason)}
              handleCancel={() => setDislikeModal(false)}
            />
          )}
          <div className="item-details-first-part">
            <div className="item-details-container">
              <ItemPhotos
                display={true}
                itemsDetails={itemsDetails}
                photosArray={itemImages}
              />
              <div className="item-details-content">
                <div className="item-details-content-header">
                  <div className="users-place">
                    {itemsDetails?.creator ? (
                      <UserDesc
                        avatar={creatorAvatar}
                        title={t("creator")}
                        username={creatorName}
                        userId={itemsDetails?.creator?.id}
                      />
                    ) : null}
                    {itemsDetails?.owner ? (
                      <UserDesc
                        avatar={ownerAvatar}
                        title={t("owner")}
                        username={ownerName}
                        userId={itemsDetails?.owner?.id}
                      />
                    ) : null}
                  </div>
                  <div className="item-stats">
                    <DetailDisplay
                      content={intToString(parseInt(itemsDetails?.views))}
                      icon={eyeIcon}
                    />
                    <DetailDisplay
                      content={intToString(
                        parseInt(itemsDetails?.likes) > 0
                          ? parseInt(itemsDetails?.likes)
                          : 0
                      )}
                      icon={heartIcon}
                    />
                  </div>
                </div>
                <div className="detail-row">
                  <div className="detail-part">
                    <DetailDisplay
                      content={t(
                        "collection-page.item-details-sales-description",
                        {
                          percent: itemsDetails?.royalties / 100,
                        }
                      )}
                    />
                  </div>
                  <div className="detail-part">
                    <DetailDisplay
                      content={
                        itemsDetails?.type === "item"
                          ? t("collection-page.item-description-bar", {
                              nbr:
                                itemsDetails?.countAllNfts +
                                itemsDetails?.countAllSfts,
                              total: itemsDetails?.countItems,
                            })
                          : itemsDetails?.type === "nft"
                          ? t("collection-page.nft-description-bar", {
                              nbr: itemsDetails?.countNFTs,
                              total: itemsDetails?.countAllNfts,
                            })
                          : itemsDetails?.type === "sft"
                          ? t("collection-page.sft-description-bar", {
                              nbr: itemsDetails?.countSFTs,
                              total: itemsDetails?.countAllSfts,
                            })
                          : ""
                      }
                    />
                  </div>
                  {downloadable && provideDownload && (
                    <div>
                      <Link to="/info">
                        <IconButton
                          title={t("collection-page.download-secret-files")}
                          icon={downloadIcon}
                          onClick={() => {
                            setActiveTab("info");
                          }}
                        />
                      </Link>
                    </div>
                  )}
                </div>

                <div className="item-details-tab-content-holder">
                  <ItemTab
                    menu={menuMobile}
                    itemdb={itemsDetails}
                    arrayofAttribute={arrayofAttribute}
                    customAttributes={itemsDetails?.item_custom_attributes}
                    activeTab={activeTab}
                    offers={offers}
                    setActiveTab={setActiveTab}
                    canDownload={downloadable}
                    showDownloadButton={setProvideDownload}
                  />
                  <ActionSwitch
                    item={itemsDetails}
                    collectionId={itemsDetails?.collection?.id}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="recent-items-header">
            <h3 className="home-title">{t("collection-page.related-items")}</h3>
            <PaginationRelatedItem
              pageInfos={Math.ceil(itemDetailsStore.itemsRelated.length / 4)}
              pageSelected={pageIndex}
              goPreviousPage={goPreviousPage}
              goNextPage={goNextPage}
            />
          </div>

          <div className="item-details-second-part">
            <Row gutter={[32, 32]} style={{ marginTop: "30px" }}>
              {!itemDetailsStore.isRelatedItemLoading && itemDetailsStore.itemsRelated ? (
                itemDetailsStore.itemsRelated?.slice(startIndex, endIndex).map((el: any,key:number) => {
                  return (
                    <Col span={6} key={key}>
                      <RecentItem item={el} />
                    </Col>
                  );
                })
              ) : (
                <>
                  <Col span={6}>
                    <LoadingItem />
                  </Col>
                  <Col span={6}>
                    <LoadingItem />
                  </Col>
                  <Col span={6}>
                    <LoadingItem />
                  </Col>
                </>
              )}
            </Row>
          </div>

          <div className="item-details-mobile-recent-items">
            {itemDetailsStore.isRelatedItemLoading ? (
              <div className="mobile-recent-items-spin">
                <Spin size="large" />
              </div>
            ) : itemDetailsStore.itemsRelated ? (
              <ItemsCarousel ItemsData={itemDetailsStore.itemsRelated} startIndex={startIndex} endIndex={endIndex} />
            ) : (
              <>
                <Col span={6}>
                  <LoadingItem />
                </Col>
                <Col span={6}>
                  <LoadingItem />
                </Col>
                <Col span={6}>
                  <LoadingItem />
                </Col>
                <Col span={6}>
                  <LoadingItem />
                </Col>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="item-details-load">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
});

export default ItemDetails;
