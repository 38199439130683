import type {
  ExtensionLoginButtonPropsType,
  WebWalletLoginButtonPropsType,
  OperaWalletLoginButtonPropsType,
  LedgerLoginButtonPropsType,
  WalletConnectLoginButtonPropsType,
} from "@multiversx/sdk-dapp/UI";
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  OperaWalletLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton,
  XaliasLoginButton,
} from "./sdkDappComponents";

type CommonPropsType =
  | OperaWalletLoginButtonPropsType
  | ExtensionLoginButtonPropsType
  | WebWalletLoginButtonPropsType
  | LedgerLoginButtonPropsType
  | WalletConnectLoginButtonPropsType;

const commonProps: CommonPropsType = {
  callbackRoute: sessionStorage.getItem("prevPath") ?? "/",
  nativeAuth: true,
};

export const Unlock = () => {
  return (
    <>
      <WalletConnectLoginButton
        loginButtonText="xPortal App"
        {...commonProps}
      />
      <LedgerLoginButton loginButtonText="Ledger" {...commonProps} />
      <ExtensionLoginButton loginButtonText="DeFi Wallet" {...commonProps} />
      <OperaWalletLoginButton
        loginButtonText="Opera Crypto Wallet - Beta"
        {...commonProps}
      />
      <WebWalletLoginButton loginButtonText="Web Wallet" {...commonProps} />
      <XaliasLoginButton loginButtonText="xAlias" {...commonProps} />
    </>
  );
};
