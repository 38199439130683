import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Form, Input, Button, Row, Col, Slider } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import mediumIcon from "../../../../assets/images/medium.svg";
import twitterIcon from "../../../../assets/images/twitter.svg";
import telegramIcon from "../../../../assets/images/telegram.svg";
import discordIcon from "../../../../assets/images/discord.svg";
import coverProp1 from "../../../../assets/images/cover_photo.webp";
import CollectionAttributes from "./collectionAttributes";
import LightButton from "../../../components/buttons/lightButton";
import EditorArea from "../../../components/editorArea/editorArea";
import LinkInput from "../../../components/input/link input/linkInput";
import instanceOfAxios from "../../../../configAxios";
import { useContext } from "../../../context";
import { setItem } from "../../../storage/session";
import { isValidURL } from "../../../helpers/validateUrl";
import {
  mediumRegex,
  mediumSubdomainRegex,
  twitterRegex,
  telegramRegex,
  discordRegex,
  tokenNameRegex,
  tokenTickerRegex,
} from "../../../helpers/regularExpressions";
import ImageUpload from "./upload image/imageUpload/imageUpload";
import CollectionBanner from "./upload image/collection banner/collectionBanner";
import useImageDisplay from "../../../helpers/useImageDisplay";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/toasts/openToast";
import { getItemFromLocalStorage } from "../../../helpers/useLocalStorage";

export interface collectionFormProps {
  collectionType: any;
  collection: any;
}

const CollectionForm = ({
  collectionType,
  collection,
}: collectionFormProps) => {
  const cover1S3 = "static/media/cover_photo.webp";
  const [mandatoryattribute, setMandatoryattribute] = useState<any[]>([]);
  const [suggestedAttributes, setSuggestedAttributes] = useState<any[]>([]);
  const [description, setDescription] = useState("<p></p>");
  const [collectionImage, setCollectionImage] = useState<any>();
  const [collectionBanner, setCollectionBanner] = useState<any>(
    process.env.REACT_APP_S3_ENABLED === "true" ? cover1S3 : coverProp1
  );
  const [requiredMissing, setRequiredMissing] = useState(false);
  const [royaltyValue, setRoyaltyValue] = useState(0);
  const [slugAvailable, setSlugAvailable] = useState(true);
  const [tokenNameDiff, setTokenNameDiff] = useState(false);
  const [tokenTickerDiff, setTokenTickerDiff] = useState(false);
  const [royalityDiff, setRoyalityDiff] = useState(false);
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const [form] = Form.useForm();
  const { address } = useContext();
  const imageRef = useRef<HTMLDivElement>(null);
  const descRef = useRef<HTMLDivElement>(null);
  const attributesRef = useRef<HTMLDivElement>(null);
  const slugRef = useRef<HTMLDivElement>(null);
  const specialCaracters = "`!@#$%^&*()+=[]{};':/\"|,.<>?~ ";
  var slugPattern = /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/g;
  const [marks, setmarks] = useState<any>({});
  const logoImage = useImageDisplay(
    collection?.logo_image,
    "__m" + collection?.logo_image_ext
  );
  const uploadedDisplay = useImageDisplay(
    collection?.banner_image,
    "__ba" + collection?.banner_image_ext
  );
  const [createLoading, setCreateLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(collection);
    setDescription(collection?.description);
    form.setFieldsValue({
      royalties: collection?.royalties,
      twitter: collection?.twitter || "",
      medium: collection?.medium || "",
      telegram: collection?.telegram || "",
      discord: collection?.discord || "",
      homepage2: collection?.homepage2 || "",
    });
    setRoyaltyValue(collection?.royalties / 100);
    setCollectionImage(collection?.logo_image);

    let manAtt = [];
    for (let i = 0; i < collection?.mandatory_attributes?.length; i++) {
      manAtt.push({
        id: `m-${i}`,
        text: collection?.mandatory_attributes[i]?.attribute?.key,
        type: "mandatory",
      });
    }
    setMandatoryattribute(manAtt);

    let optAtt = [];
    for (let i = 0; i < collection?.optional_attributes?.length; i++) {
      optAtt.push({
        id: `o-${i}`,
        text: collection?.optional_attributes[i]?.attribute?.key,
        type: "optional",
      });
    }
    setSuggestedAttributes(optAtt);
  }, [collection]);

  useEffect(() => {
    setmarks({});
    if (collectionType) {
      if (!collection?.royalties) {
        form.setFieldsValue({
          royalties: collectionType?.royality_default,
        });
        setRoyaltyValue(collectionType?.royality_default / 100);
      }
      let minValue = Math.trunc(collectionType?.royality_min / 1000) * 10;
      let maxValue = Number.isInteger(collectionType?.royality_max / 1000)
        ? collectionType?.royality_max / 100
        : (Math.trunc(collectionType?.royality_max / 1000) + 1) * 10;
      let customMarks: any = {};
      for (let i = minValue; i <= maxValue; i += 10) {
        customMarks[i] = `${i}%`;
      }
      setmarks(customMarks);
    }
  }, [collectionType]);

  useLayoutEffect(() => {});

  const onFinish = (values: any) => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    } else if (
      mandatoryattribute.length === 0 ||
      description?.length <
        (process.env.REACT_APP_SERVER_ELROND != "mainnet" ? 3 : 120) ||
      collectionImage === undefined ||
      collectionImage === "" ||
      !slugAvailable
    ) {
      setRequiredMissing(true);
      if (
        (collectionImage === undefined || collectionImage === "") &&
        imageRef.current
      ) {
        imageRef.current.scrollIntoView();
      } else if (
        description?.length <
          (process.env.REACT_APP_SERVER_ELROND != "mainnet" ? 3 : 120) &&
        descRef.current
      ) {
        descRef.current.scrollIntoView();
      } else if (mandatoryattribute.length === 0 && attributesRef.current) {
        attributesRef.current.scrollIntoView();
      } else if (slugAvailable === false && slugRef.current) {
        slugRef.current.scrollIntoView();
      }
      return 0;
    } else {
      setCreateLoading(true);
      values.erd_address = address;
      values.collection_type_id = collectionType?.collection_type_id;
      values.description = description;
      values.is_multiartist = false;
      values.default_banner = typeof collectionBanner === "string";

      let formData = new FormData();

      let collection_mandatory_attributes = "";
      let mandatoryPromise = new Promise(async function(myResolve, myReject) {
        for (let i = 0; i < mandatoryattribute.length; i++) {
          await instanceOfAxios
            .post(`attributes`, { key: mandatoryattribute[i].text })
            .then((res: any) => {
              if (collection_mandatory_attributes === "") {
                collection_mandatory_attributes = JSON.stringify({
                  attribute_id: res?.data?.id,
                });
              } else {
                collection_mandatory_attributes =
                  collection_mandatory_attributes +
                  "," +
                  JSON.stringify({ attribute_id: res?.data?.id });
              }
              if (i === mandatoryattribute.length - 1) {
                myResolve({
                  mandatory: collection_mandatory_attributes,
                });
              }
            })
            .catch((err: any) => {
              myReject();
            });
        }
      });

      mandatoryPromise.then((value: any) => {
        values.collection_mandatory_attributes = value.mandatory;
        let collection_optional_attributes = "";
        let optionalPromise = new Promise(async function(myResolve, myReject) {
          if (suggestedAttributes.length !== 0) {
            for (let i = 0; i < suggestedAttributes.length; i++) {
              await instanceOfAxios
                .post(`attributes`, { key: suggestedAttributes[i].text })
                .then((res: any) => {
                  if (collection_optional_attributes === "") {
                    collection_optional_attributes = JSON.stringify({
                      attribute_id: res?.data?.id,
                    });
                  } else {
                    collection_optional_attributes =
                      collection_optional_attributes +
                      "," +
                      JSON.stringify({ attribute_id: res?.data?.id });
                  }
                  if (i === suggestedAttributes.length - 1) {
                    myResolve({
                      optional: collection_optional_attributes,
                    });
                  }
                })
                .catch((err: any) => {
                  myReject();
                });
            }
          } else {
            values.collection_optional_attributes = "";
            myResolve({
              optional: "",
            });
          }
        });
        optionalPromise.then((value: any) => {
          const promise = new Promise((resolve, reject) => {
            values.collection_optional_attributes = value.optional;
            resolve(values);
          });
          promise.then((res: any) => {
            for (var key in res) {
              formData.append(key, res[key]);
            }

            formData.append("logo_image", collectionImage);
            formData.append(
              "logo_image_metadata",
              JSON.stringify({
                name: collectionImage?.name,
                size: collectionImage?.size,
                type: collectionImage?.type,
              })
            );

            formData.append("banner_image", collectionBanner);
            if (typeof collectionBanner !== "string") {
              formData.append(
                "banner_image_metadata",
                JSON.stringify({
                  name: collectionBanner?.name,
                  size: collectionBanner?.size,
                  type: collectionBanner?.type,
                })
              );
            }
            if (collection?.id) {
              //here in case we are in the edit page
              instanceOfAxios
                .put(`collections/${collection?.id}`, formData, {
                  headers: {
                    "x-access-token": getItemFromLocalStorage("token"),
                  },
                })
                .then((res: any) => {
                  if (res?.data) {
                    OpenSuccessNotification(
                      t("profile.success"),
                      t("profile.success-desc"),
                      3
                    );
                    setCreateLoading(false);
                    window.scrollTo(0, 0);
                    navigate(`/${res?.data?.homepage}`);
                  }
                })
                .catch((err: any) => {
                  if(err?.response.status===401){
                    OpenErrorNotification(
                      t("profile.error"),
                      t("profile.error-unauthorized-user"),
                      3
                    );                    
                  }else if(err?.response.status===403){
                    navigate("/collection")
                    OpenErrorNotification(
                      t("profile.error"),
                      t("errors.error-unauthorized-user"),
                      3
                    );
                  }
                  else{
                    OpenErrorNotification(
                      t("profile.error"),
                      t("profile.error-desc"),
                      3
                    );
                  }
                  setCreateLoading(false);
                });
            } else {
              // here in case we are in the create page
              instanceOfAxios
                .post(`collections`, formData,{
                  headers: {
                    "x-access-token": getItemFromLocalStorage("token"),
                  },        
                })
                .then((res: any) => {
                  if (res?.data) {
                    setCreateLoading(false);
                    window.scrollTo(0, 0);
                    navigate(`/collection-success/${res?.data?.id}`);
                  }
                })
                .catch((err: any) => {
                      if(err?.response.status===401){
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-unauthorized-user"),
            3
          );                    
        }else{
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        } 
                  setCreateLoading(false);
                });
            }
          });
        });
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const validateSlugAvailable = (value: string) => {
    if (value === collection?.homepage) {
      setSlugAvailable(true);
    } else {
      instanceOfAxios
        .post(`is-slug-available`, { slug: value })
        .then((res: any) => {
          setSlugAvailable(res?.data?.ok);
          return res?.data?.ok;
        })
        .catch((err: any) => {
          setSlugAvailable(false);
          return false;
        });
    }
  };

  const replaceWithMinus = (original: string) => {
    original = original.replace(slugPattern, "-");
    return original;
  };

  const suggestSlug = (e: any) => {
    form.setFieldsValue({
      homepage: replaceWithMinus(e.target.value).toLowerCase(),
    });
    validateSlugAvailable(replaceWithMinus(e.target.value).toLowerCase());
  };

  const writingSlug = (e: any) => {
    let value: string = e.target.value;
    if (specialCaracters.includes(value[value.length - 1])) {
      value = value.substring(0, value.length - 1) + "-";
    }
    form.setFieldsValue({ homepage: value.toLowerCase() });
    validateSlugAvailable(value.toLowerCase());
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="create-collection-container"
      form={form}
      scrollToFirstError={true}>
      <div className="upload-collection-picture-container">
        <h5>
          {`${t("collection-page.collection-picture")}`}
          <span className="required-field">*</span>:
        </h5>
        <h5 className="text-regular-font">
          {t("collection-page.collection-picture-description", {
            recomended_image_size: "520x520",
          })}
        </h5>
        <div className="upload-box" ref={imageRef}>
          <Form.Item
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle>
            <ImageUpload
              image={collection?.logo_image ? logoImage : ""}
              size={["300px", "300px"]}
              setCollectionImage={setCollectionImage}
            />
          </Form.Item>
          {requiredMissing &&
          (collectionImage === undefined || collectionImage === "") ? (
            <h4 className="red-color">
              {t("collection-page.required-collection-photo")}
            </h4>
          ) : null}
        </div>
        <div>
          <h5>
            {`${t("collection-page.collection-banner")}`}
            <span className="required-field">*</span>:
          </h5>
          <h5 className="text-regular-font">
            {t("collection-page.collection-banner-description")}
          </h5>
          <Form.Item
            valuePropName="banner"
            getValueFromEvent={normFile}
            noStyle>
            <CollectionBanner
              bannerext={collection?.banner_image_ext}
              image={
                collection?.banner_image
                  ? process.env.REACT_APP_S3_ENABLED === "true"
                    ? collection?.banner_image.includes("static/media")
                      ? collection?.banner_image
                      : collection?.banner_image +
                        "__ba" +
                        collection?.banner_image_ext
                    : uploadedDisplay
                  : ""
                // collection?.banner_image
                //   ?
                //   uploadedDisplay
                //   : ""
              }
              setCollectionBanner={setCollectionBanner}
            />
          </Form.Item>
        </div>
      </div>
      <div className="collection-form-container">
        <h5>
          {`${t("collection-page.collection-name")}`}
          <span className="required-field">*</span>:
        </h5>
        <Form.Item
          name="collection_name"
          rules={[
            {
              required: true,
              message: t("collection-page.required-collection-name"),
            },
            () => ({
              validator(_, value) {
                if (value?.length <= 60) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("collection-page.collection-title-max-error")
                );
              },
            }),
          ]}>
          <Input
            placeholder={`${t("collection-page.collection-name")}`}
            onChange={suggestSlug}
          />
        </Form.Item>
        <h5 ref={slugRef}>
          {`${t("collection-page.collection-url")}`}
          <span className="required-field">*</span>:
        </h5>
        <h5 className="text-regular-font slug-desc">
          {t("collection-page.collection-url-description")}{" "}
        </h5>
        {!slugAvailable && (
          <h5 className="red-color">
            {t("collection-page.slug-not-available")}
          </h5>
        )}
        <Form.Item
          name="homepage"
          rules={[
            {
              required: true,
              message: t("collection-page.required-collection-homepage"),
            },
            () => ({
              validator(_, value) {
                if (value?.length <= 60) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("collection-page.collection-url-max-error")
                );
              },
            }),
          ]}>
          <Input
            addonBefore="https://rarity.market/"
            placeholder="my-new-collection01"
            onChange={writingSlug}
          />
        </Form.Item>

        <h5>
          {`${t("collection-page.collection-description")}`}
          <span className="required-field">*</span>:
        </h5>
        <Form.Item name="description">
          <div ref={descRef}>
            <EditorArea
              defaultDesc={collection?.description || "<p></p>"}
              onChange={(e) => {
                setDescription(e);
              }}
            />
          </div>
          {requiredMissing &&
          description?.length <
            (process.env.REACT_APP_SERVER_ELROND != "mainnet" ? 3 : 120) ? (
            <h4 className="red-color">
              {t("collection-page.required-collection-description")}
            </h4>
          ) : null}
        </Form.Item>

        <h5>
          {`${t("collection-page.token-name")}`}
          <span className="required-field">*</span>:
        </h5>
        <h6 className="text-regular-font slug-desc">{`${t(
          "collection-page.token-name-desc"
        )}`}</h6>
        {tokenNameDiff && (
          <h6 className="primary-color">
            {t("collection-page.edit-collection-warning")}{" "}
          </h6>
        )}
        <Form.Item
          name="token_name"
          rules={[
            {
              required: true,
              message: t("collection-page.required-token-name"),
            },
            () => ({
              validator(_, value) {
                if (collection?.id && value !== collection?.token_name) {
                  setTokenNameDiff(true);
                } else setTokenNameDiff(false);
                if (tokenNameRegex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(t("collection-page.token-name-required"));
              },
            }),
          ]}>
          <Input placeholder={`${t("collection-page.token-name")}`} />
        </Form.Item>

        <h5>
          {`${t("collection-page.token-ticker")}`}
          <span className="required-field">*</span>:
        </h5>
        <h6 className="text-regular-font slug-desc">{`${t(
          "collection-page.token-ticker-desc"
        )}`}</h6>
        {tokenTickerDiff && (
          <h6 className="primary-color">
            {t("collection-page.edit-collection-warning")}{" "}
          </h6>
        )}
        <Form.Item
          name="token_ticker"
          rules={[
            {
              required: true,
              message: t("collection-page.required-token-ticker"),
            },
            () => ({
              validator(_, value) {
                if (collection?.id && value !== collection?.token_ticker) {
                  setTokenTickerDiff(true);
                } else setTokenTickerDiff(false);
                if (tokenTickerRegex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("collection-page.token-ticker-required")
                );
              },
            }),
          ]}>
          <Input placeholder={`${t("collection-page.token-ticker")}`} />
        </Form.Item>

        <h5>{`${t("collection-page.homepage-link")}`}:</h5>
        <Form.Item
          name="homepage2"
          rules={[
            {
              required: false,
            },
            () => ({
              validator(_, value) {
                if (
                  (isValidURL(value) && value?.length < 255) ||
                  value === ""
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(t("collection-page.homepage-validation"));
              },
            }),
          ]}>
          <Input
            placeholder={t("collection-page.homepage-link")}
            defaultValue=""
          />
        </Form.Item>

        <h5>{`${t("collection-page.collection-links")} :`}</h5>
        <Row gutter={[32, 0]} className="collection-links-container">
          <Col xs={24} lg={12}>
            <Form.Item
              name="medium"
              rules={[
                {
                  required: false,
                },
                () => ({
                  validator(_, value) {
                    if (
                      mediumRegex.test(value) ||
                      mediumSubdomainRegex.test(value) ||
                      value === ""
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("collection-page.medium-link-required")
                    );
                  },
                }),
              ]}>
              <LinkInput
                icon={mediumIcon}
                color="black"
                placeholder="https://medium.com/"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="twitter"
              rules={[
                () => ({
                  validator(_, value) {
                    if (twitterRegex.test(value) || value === "") {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("collection-page.twitter-link-required")
                    );
                  },
                }),
              ]}>
              <LinkInput
                icon={twitterIcon}
                color="#00acee"
                placeholder="https://twitter.com/"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="telegram"
              rules={[
                () => ({
                  validator(_, value) {
                    if (telegramRegex.test(value) || value === "") {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("collection-page.telegram-link-required")
                    );
                  },
                }),
              ]}>
              <LinkInput
                icon={telegramIcon}
                color="#0088cc"
                placeholder="https://t.me/"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="discord"
              rules={[
                () => ({
                  validator(_, value) {
                    if (discordRegex.test(value) || value === "") {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("collection-page.discord-link-required")
                    );
                  },
                }),
              ]}>
              <LinkInput
                icon={discordIcon}
                color="#7289da"
                placeholder="https://discord.com/"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="royalties">
          <h5>
            {`${t("collection-page.royalty-setting")}`}
            <span className="required-field">*</span>:
          </h5>
          <h5 className="text-regular-font">
            {t("collection-page.royalty-setting-description", {
              royaltyFee: royaltyValue,
            })}
          </h5>
          {royalityDiff && (
            <h6 className="primary-color">
              {t("collection-page.edit-collection-warning")}{" "}
            </h6>
          )}
          <Slider
            onChange={(value) => {
              setRoyaltyValue(value);
              form.setFieldsValue({ royalties: value * 100 });
              if (collection?.id && value * 100 !== collection?.royalties) {
                setRoyalityDiff(true);
              } else setRoyalityDiff(false);
            }}
            marks={marks}
            min={collectionType ? collectionType.royality_min / 100 : 0}
            max={collectionType ? collectionType.royality_max / 100 : 100}
            value={royaltyValue}
            className="collection-slider"
          />
        </Form.Item>
        <div ref={attributesRef}>
          <CollectionAttributes
            mandatoryArray={mandatoryattribute}
            optionalArray={suggestedAttributes}
            getMandatoryAttributes={setMandatoryattribute}
            getSuggestedAttributes={setSuggestedAttributes}
          />
          {requiredMissing && mandatoryattribute.length === 0 ? (
            <h4 className="red-color" style={{ padding: "0 0 30px 0" }}>
              {t("collection-page.required-collection-attributes")}
            </h4>
          ) : null}
        </div>

        <Form.Item>
          <div className="form-buttons">
            <div className="form-cancel-button">
              <LightButton
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/collection");
                }}>
                {t("common.cancel")}
              </LightButton>
            </div>
            <Button type="primary" htmlType="submit" loading={createLoading}>
              {collection?.id
                ? t("collection-page.edit-collection-button")
                : t("collection-page.create-collection")}
            </Button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};

export default CollectionForm;
