import { useEffect, useState } from "react";

const useUserNaming = (currentUser: any) => {
  const [displayName, setDisplayName] = useState<string>("");

  const cutIfLong = (word: string) => {
    if (word.length > 17) {
      return `${word?.slice(0, 7)}...${word?.substring(word?.length - 7)}`;
    } else {
      return word;
    }
  };

  useEffect(() => {
    if (currentUser?.nickname) {
      setDisplayName(cutIfLong(currentUser.nickname));
    } else if (currentUser?.name) {
      setDisplayName(cutIfLong(currentUser.name));
    } else if (currentUser?.erd_address) {
      setDisplayName(
        `${currentUser?.erd_address?.slice(
          0,
          5
        )}...${currentUser?.erd_address?.substring(
          currentUser?.erd_address?.length - 5
        )}`
      );
    } else {
      setDisplayName("");
    }
  }, [currentUser]);

  return displayName;
};

export default useUserNaming;
