import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { Collapse } from "antd";
import QuestionMark from "../../../../assets/images/QuestionFaq.svg";
import DownCircleFilled from "@ant-design/icons/lib/icons/DownCircleFilled";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../../staticpages/staticPage.less";
import { useContext } from "../../../context";
import instanceOfAxios from "../../../../configAxios";
import CustomModal from "../../../components/modal/customModal";
import { landingReception } from "../../../helpers/communFunctions";
import { Helmet } from "react-helmet";
import { getItemFromLocalStorage } from "../../../helpers/useLocalStorage";

const Staking = () => {
  const { t } = useTranslation();
  const [textResultVisible, setTextResultVisible] = useState(false);
  const { address } = useContext();
  const search = useLocation().search;
  const filterSearch = new URLSearchParams(search).get("search");
  const tabSearch = new URLSearchParams(search).get("tab");
  const [activeTab, setActiveTab] = useState<String>(`${tabSearch}`);
  const [currency, setCurrency] = useState<String>("usd");
  const [convert, setConvert] = useState<String>("");
  const [id, setId] = useState<String>(
    JSON.parse(localStorage.getItem("user") || "{}")?.id
  );

  const currencyChange = (value: any) => {
    axios.get(`/currency/exchange_rates/egld/1/${value / 1000000000000000000}`).then((res: any) => {
      setConvert(res.data.result);
    });
  };
  const ProfileCurrency = () => {
    instanceOfAxios.get(`profileById/${id}`,{
      headers: {
        "x-access-token": getItemFromLocalStorage("token"),
      },
    }).then((res: any) => {
      setCurrency(res.data.currency);
      currencyChange(res.data.currency);
    });
  };

  const { Panel } = Collapse;
  const stakingStructuredData =[
    {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "name": t("staking.0staking-delegation"),
        "mainEntity": [
          {
            "@type": "Question",
            "name":t("staking.00q"),
            "text":t("staking.00q"),
            "answerCount":"00q",
            "acceptedAnswer":
              {
                "@type": "Answer",
                "text":t("staking.00a1"),
                "url":null
              },     
              "suggestedAnswer": [
                {
                  "@type": "Answer",
                  "text": t("staking.00a1"),
                  "url": null
                },
              ]  
            
          },      
          {
            "@type": "Question",
            "name":t("staking.01q"),
            "text":t("staking.01q"),
            "answerCount":"01q",
            "acceptedAnswer":
              {
                "@type": "Answer",
                "text": t("staking.01a1"),
                "url":null
              },     
              "suggestedAnswer": [
                {
                  "@type": "Answer",
                  "text":   t("staking.01a1"),
                  "url": null
                },
                {
                  "@type": "Answer",
                  "text": t("staking.01a2"),
                  "url": null
                },        
              ]  
            
          },
          {
            "@type": "Question",
            "name":t("staking.02q"),
            "text":t("staking.02q"),
            "answerCount":"02q",
            "acceptedAnswer":
              {
                "@type": "Answer",
                "text": t("staking.02a1"),
                "url":null
              },     
              "suggestedAnswer": [
                {
                  "@type": "Answer",
                  "text":   t("staking.02a1"),
                  "url": null
                },
                {
                  "@type": "Answer",
                  "text": t("staking.02a2"),
                  "url":  "https://staking.rarity.market/",
                },        
              ]  
            
          },
          {
            "@type": "Question",
            "name":t("staking.03q"),
            "text":t("staking.03q"),
            "answerCount":"03q",
            "acceptedAnswer":
              {
                "@type": "Answer",
                "text":  t("staking.03a1"),
                "url":null
              },     
              "suggestedAnswer": [
                {
                  "@type": "Answer",
                  "text":    t("staking.03a1"),
                  "url": null
                },
                {
                  "@type": "Answer",
                  "text": t("staking.03a2"),
                  "url":  null
                },        
              ]  
            
          },
          {
            "@type": "Question",
            "name":t("staking.04q"),
            "text":t("staking.04q"),
            "answerCount":"04q",
            "acceptedAnswer":
              {
                "@type": "Answer",
                "text":  t("staking.04a1"),
                "url":null
              },     
              "suggestedAnswer": [
                {
                  "@type": "Answer",
                  "text":    t("staking.04a1"),
                  "url": null
                },
                {
                  "@type": "Answer",
                  "text": t("staking.04a2"),
                  "url":  null
                },        
              ]  
            
          },
          {
            "@type": "Question",
            "name":t("staking.05q"),
            "text":t("staking.05q"),
            "answerCount":"05q",
            "acceptedAnswer":
              {
                "@type": "Answer",
                "text":   t("staking.05a1"),
                "url":"https://explorer.multiversx.com/providers/erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq70llllss57t2f",
              },     
              "suggestedAnswer": [
                {
                  "@type": "Answer",
                  "text":     t("staking.05a1"),
                  "url": "https://explorer.multiversx.com/providers/erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq70llllss57t2f",
                },
                {
                  "@type": "Answer",
                  "text": t("staking.05a2"),
                  "url":  null
                },        
              ]  
            
          },
        ]
    },
    {
      "name": t("staking.10support"),
      "mainEntity": [
        {
          "@type": "Question",
          "name":t("staking.10q"),
          "text":t("staking.10q"),
          "answerCount":"10q",
          "acceptedAnswer":
            {
              "@type": "Answer",
              "text":t("staking.10a1"),
              "url":null
            },     
            "suggestedAnswer": [
              {
                "@type": "Answer",
                "text": t("staking.10a1"),
                "url": null
              },       
              {
                "@type": "Answer",
                "text": t("staking.10a2"),
                "url": "https://t.me/RarityMarketStakingOfficial",
              },       
            ]  
          
        }, 
      ]
    },
    {
      "name": "EGLD",
      "mainEntity": [
        {
          "@type": "Question",
          "name":t("faq.210q"),
          "text":t("faq.210q"),
          "answerCount":"10q",
          "acceptedAnswer":
            {
              "@type": "Answer",
              "text":t("faq.210a1"),
              "url":null
            },     
            "suggestedAnswer": [
              {
                "@type": "Answer",
                "text": t("faq.210a1"),
                "url": null
              },       
              {
                "@type": "Answer",
                "text": t("faq.210a2"),
                "url": null
              },       
              {
                "@type": "Answer",
                "text": t("faq.210a3"),
                "url": "https://explorer.multiversx.com/providers",
              },       
            ]  
          
        }, 
        {
          "@type": "Question",
          "name":t("faq.220q"),
          "text":t("faq.220q"),
          "answerCount":"10q",
          "acceptedAnswer":
            {
              "@type": "Answer",
              "text": t("faq.220a1"),
              "url":"https://wallet.multiversx.com",
            },     
            "suggestedAnswer": [
              {
                "@type": "Answer",
                "text": t("faq.220a2"),
                "url": null
              },       
              {
                "@type": "Answer",
                "text": t("faq.210a2"),
                "url": null
              },       
              {
                "@type": "Answer",
                "text":t("faq.220a3"),
                "url": "https://buy.multiversx.com",
              },       
              {
                "@type": "Answer",
                "text":t("faq.220a4"),
                "url": "https://rarity.market",
              },       
            ]  
          
        }, 
      ]
    },
  ]

  const handleOk = () => {
    setTextResultVisible(false);
  };

  const handleCancel = () => {
    setTextResultVisible(false);
  };

  const scroll = (pos: any) => {
    window.scroll(0, pos - 150);
  };

  useEffect(() => {
    var idSearch = document
      .getElementById(`${filterSearch}`)
      ?.getBoundingClientRect().top;

    scroll(idSearch);

    if (!address) {
      currencyChange(currency);
    } else {
      ProfileCurrency();
    }

    //this is specific for the language routes like landing pages routes
    landingReception(window.location.pathname);
  }, []);

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(stakingStructuredData)}
      </script>
      <Helmet>
        <title>Rarity.market | EGLD Staking</title>
      </Helmet>
      <div className="faq-body-container">
        <Row>
          <Col>
            <a href="/">
              <span
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                {t("Home")}
              </span>
            </a>
            <span>{">"}</span>
          </Col>
          <Col>
            <span className="primary-color">{t("home.menu-egld-staking")}</span>
          </Col>
        </Row>
        <div style={{marginTop: "20px" }}>
          <h4>{t("home.menu-egld-staking")}</h4>

          <Row>
            <Col>
              <p className="staking-page-description">
                {t("staking.frequently-asked-questions")}
              </p>
            </Col>
            <Col span={12}>
              <p style={{ marginTop: "20px", textAlign: "center" }}>
                {convert}
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <div className="collapset-faq container">
        {stakingStructuredData.map((panel, keyParent) => (
             <div id={panel.name} key={keyParent}>
              <Collapse
                    expandIconPosition={"right"}
                    defaultActiveKey={[`${activeTab}`]}
                    expandIcon={({ isActive }) => (
                      <DownCircleFilled
                        style={{ fontSize: "20px" }}
                        rotate={isActive ? 180 : 0}
                      />
                    )}>
             
                    <Panel
                      header={panel.name}
                      key={panel.name}
                      style={{ marginBottom: "20px" }}>   
                      {panel["mainEntity"].map((item,key)=>(
                      <div key={key}>
                {textResultVisible && (
                          <CustomModal
                            width="400px"
                            visible={textResultVisible}
                            closable={true}
                            handleOk={handleOk}
                            handleCancel={handleCancel}>
                            <div>{item.text} </div>
                          </CustomModal>
                        )}
                        <img src={QuestionMark} alt="question-mark" />
                        <span
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={() => setTextResultVisible(true)}
                          id={`${item.answerCount}`}>
                          {item.text}
                        </span>
                        <ul>
                          {item.suggestedAnswer.map((question,keyQute)=>(
                            <>
                              <li className={`response ${question.url!==null?"collapset-faq-link-ul":"collapset-faq-ul"}`} key={keyQute}>
                                {question.url!== null ? (
                                  <a target="_blank" href={question.url}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:question.text ,
                                      }}
                                    />
                                  </a>
                                ) : (
                                  <div
                                    dangerouslySetInnerHTML={{ __html: question.text }}
                                  />
                                )}
                              </li>
                            </>

                          ))}
                        </ul>
                      </div>               
                         ))}
                      </Panel>
                     
                    </Collapse>
             </div>
        ))}
        </div>
      </Row>
    </>
  );
};
export default Staking;
