import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import instanceOfAxios from "../../../../../configAxios";
import { collectionTypeInterface } from "../../collectionInterface";
import CollectionItemNew, {
  CollectionNewProps,
} from "./collectionType/collectionType";

import "./newCollection.less";

const NewCollection = ({ col }: any) => {
  const { t } = useTranslation();
  const [collectionsItemss, setCollectionsItemss] = useState<
    collectionTypeInterface[]
  >([]);

  useEffect(() => {
    instanceOfAxios
      .get("collection_types")
      .then((response) => {
        setCollectionsItemss(response.data);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  return (
    <div className="collection-tab">
      {col ? (
        ""
      ) : (
        <h2 className=" title-newcollection">
          {t("collection-page.create-new-collection")}
        </h2>
      )}
      <div className="buttons-tab">
        <div className="tab-button">
          <div className="new-collection">
            <h5 className="text-regular-font text-new-collection">
              {t("collection-page.title-new-collection")}
            </h5>
            <div className="items-collections">
              {collectionsItemss.map(
                ({ ...restOfData }: CollectionNewProps) => (
                  <CollectionItemNew {...restOfData} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCollection;
