import { Avatar, Table,Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { isValidURL } from "../../helpers/validateUrl";
import svgEGLD from "../../../assets/images/EGLD.svg";
import { useNavigate } from "react-router-dom";
import Nodata from "../../components/noData/nodata";
import CustomImage from "../../components/image/customImage";
import NumberDisplayi18n from "../../helpers/numbersi18n";
import { useEffect, useState } from "react";
import iconVideo from "../../../assets/images/Icon-video.png";
import iconPodcasts from "../../../assets/images/Icon-podcasts.png";
import iconHeart from "../../../assets/images/Icon-heart.png";
import twitter from "../../../assets/images/twitterBlue.svg";
import instagram from "../../../assets/images/instaBleu.png";
import discord from "../../../assets/images/discord.png";
import { INodesGenesis } from "./genesis";
import { observer } from "mobx-react-lite";

interface Props {
  data: any[];
  dataLoad: boolean;
  timeFilter: any;
  categoryFilter: any;
}

const TableGenesis = observer(({ data, categoryFilter, timeFilter, dataLoad }: Props) => {
  const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL;
  const serverAPIEndpoint = process.env.REACT_APP_SERVER_IMAGE_BASE_URL;
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const [spin, setSpin] = useState<boolean>(true);


  useEffect(() => {

    if (data.length > 0) {
      setSpin(false);
    }else{
      setSpin(true);
      setInterval(() => {
        setSpin(false);
      }, 4000);
    }
  
  }, [data]);
  
  if (!serverBaseURL) {
    throw new Error();
  }
  if (!serverAPIEndpoint) {
    throw new Error();
  }
  const IMG_URL = serverBaseURL + serverAPIEndpoint;

  if (!isValidURL(IMG_URL)) {
    throw new Error();
  }

  const assert = (text: any, count: any) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  }

  const openInNewTab = (url:string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const dataTable = data.map((genesis:INodesGenesis, key) => {
    return {
      key: genesis.id,
    
      artist: (  
        <div className="ArtistInfo">           
              <div className="key-artist">
              {key + 1}
              </div>
              <a
                href={`/profile-details/${genesis.profile.id}`}
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="ArtistInfo"
              >
              <div className="item-flex">
                <span style={{ cursor: "pointer" }} onClick={() => navigate(`/profile-details/${genesis.profile.id}`)}>
                  <CustomImage image={genesis.profile.avatar.uriName} extensionImage={genesis.profile.avatar.uriExtName} className="img-item-genesis" />
                </span>
              </div>
              <div>
                <p className="fullnameArtists" onClick={() => navigate(`/profile-details/${genesis.profile.id}`)}>
                  {genesis.profile.nickname ?genesis.profile.nickname : 
                  genesis.profile.name? genesis.profile.name:
                  genesis.profile.lastname?genesis.profile.lastname:
                  assert(genesis.profile.erd_address, 10) }
                </p>
              </div>
              </a>
        </div>  
      ),
      spotlight: (
        <>
        {genesis.spotlight!==null?
          <div className="ArtistInfo">
          <a
              href={genesis.spotlight}
              onClick={(e) => {
                e.preventDefault();
              }}
              className="ArtistInfo"
              style={{textDecoration:"none"}}
            >
            <div className="item-flex">        
              <span style={{ cursor: "pointer" }}  onClick={() => openInNewTab(genesis.spotlight)} >
                <img src={iconVideo} alt ="iconVideo" style={{ width: 25  }}/>
              </span>
            </div>
            <div >
              <p className="fw-bolder row font-weight-normal watch-spotlight spotlight-prodcast-links" onClick={() => openInNewTab(genesis.spotlight)}>
             {t("genesis-collection.Watch-spotlight")}
              </p>
            </div>
            </a>
        </div>
        :
        <p className="no-data">_ _</p>
        }
        </>
      ),
      podcast:(      
      <>
       {genesis.podcast!==null?
       <div className="ArtistInfo">
            <a
              href={genesis.podcast}
              onClick={(e) => {
                e.preventDefault();
              }}
              className="ArtistInfo"
              style={{textDecoration:"none"}}
            >
            <div className="item-flex">
          <span style={{ cursor: "pointer" }}  onClick={() => openInNewTab(genesis.podcast)}>
                <img src={iconPodcasts} alt="iconPodcasts" style={{ width: "20px" }}/>
              </span>
            </div>
            <div>
               <p className="fw-bolder row font-weight-normal watch-spotlight spotlight-prodcast-links" onClick={() => openInNewTab(genesis.podcast)}>
               {t("genesis-collection.listen-to-podcast")}
              </p>
            </div>
          </a>
        </div>
       :
       <p className="no-data">_ _</p>
       }
      </>  ),
      SocialMedia:(
        <>
        {genesis.collection.twitter!==null || genesis.collection.discord!==null || genesis.collection.instagram!==null
        || genesis.profile.twitter!==null || genesis.profile.discord!==null || genesis.profile.instagram!==null?
        <div className="ArtistInfo"> 
          {genesis.collection.twitter!==null || genesis.profile.twitter!==null?
          <a
              href={genesis.collection.twitter?genesis.collection.twitter:genesis.profile.twitter}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="item-media">
                <Tooltip title={genesis.collection.twitter?genesis.collection.twitter:genesis.profile.twitter} placement="top">
                  <img src={twitter} alt="twitter" width="20px" onClick={() => openInNewTab(genesis.collection.twitter?genesis.collection.twitter:genesis.profile.twitter)}/>
                </Tooltip>
              </div>
            </a>
          :""}         
          {genesis.collection.discord!==null || genesis.profile.discord!==null?
          <a
           href={genesis.collection.discord?genesis.collection.discord:genesis.profile.discord}
           onClick={(e) => {
             e.preventDefault();
           }}
            >
          <div className="item-media">
            <Tooltip title={genesis.collection.discord?genesis.collection.discord:genesis.profile.discord} placement="top">
              <img src={discord} alt ="discord" width="20px" onClick={() => openInNewTab(genesis.collection.discord?genesis.collection.discord:genesis.profile.discord)}/>
            </Tooltip>
          </div>
         </a>
          :""}
          {genesis.collection.instagram!==null || genesis.profile.instagram!==null?
          <a
             href={genesis.collection.instagram?genesis.collection.instagram:genesis.profile.instagram}
             onClick={(e) => {
               e.preventDefault();
             }}
           >
          <div  className="item-media">
            <Tooltip title={genesis.collection.instagram?genesis.collection.instagram:genesis.profile.instagram} placement="top">
              <img src={instagram} alt="instagram"  width="20px" onClick={() => openInNewTab(genesis.collection.instagram?genesis.collection.instagram:genesis.profile.instagram)}/>
            </Tooltip>
          </div>
           </a>
          :""}
        </div>
        :
        <p className="no-data">_ _</p>}
        </>
        ),
      genesis:(
        <>
         {genesis.genesisNft.length!==0?
        <Avatar.Group
        maxCount={4}
        maxStyle={{
        color: "white",
        backgroundColor: "#EC6321",
        cursor: "pointer",
      }}
      >
           
            {genesis.genesisNft.map((genes: any, index: number) => {
              if (index === genesis.genesisNft.length) {
                <Tooltip title="User" placement="top">
                  <CustomImage 
                     image={genes.uri.uriName} 
                     extensionImage={genes.uri.uriExtName}
                  className="collection-avatar" />

                    </Tooltip>;
                      } else {
                        return (
                        <a
                          href={`/nft/${genes.token_identifier}`}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                           >
                          <span style={{cursor:"pointer"}} onClick={() => navigate(`/nft/${genes.token_identifier}`)}>
                            <CustomImage 
                            image={genes.uri.uriName} 
                            extensionImage={genes.uri.uriExtName}
                            className="collection-avatar" />
                          </span>
                           </a>

                        );
                      }
                      <span style={{cursor:"pointer"}} onClick={() => navigate(`/nft/${genes.token_identifier}`)}>
                        <CustomImage 
                        image={genes.uri.uriName} 
                        extensionImage={genes.uri.uriExtName}
                        className="collection-avatar-tooltip" />
                      </span>
                      ;
            })
          }
      </Avatar.Group>
      : <p className="no-data">_ _</p>}
        </>
      ),   
      collection:(
        <>
        {genesis.artistNft.length!==0?
        <Avatar.Group
          maxCount={4}
          maxStyle={{
          color: "white",
          backgroundColor: "#EC6321",
          cursor: "pointer",
        }}
        >
          {genesis.artistNft.map((artist: any, index: number) => {
                if (index === genesis.artistNft.length) {
                  <Tooltip title="User" placement="top">
                    <CustomImage 
                    image={artist.uri.uriName} 
                    extensionImage={artist.uri.uriExtName}
                    className="collection-avatar" />

                      </Tooltip>;
                        } else {
                          return (
                            <a
                            href={`/nft/${artist.token_identifier}`}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                             >
                              <span style={{cursor:"pointer"}} onClick={() => navigate(`/nft/${artist.token_identifier}`)}>
                                <CustomImage 
                                image={artist.uri.uriName} 
                                extensionImage={artist.uri.uriExtName}
                                className="collection-avatar" />
                              </span>
                             </a>

                          );
                        }
                        <span style={{cursor:"pointer"}} onClick={() => navigate(`/nft/${artist.token_identifier}`)}>
                          <CustomImage 
                          image={artist.uri.uriName} 
                          extensionImage={artist.uri.uriExtName}
                          className="collection-avatar-tooltip" />
                        </span>
                        ;
              })}
        </Avatar.Group>
        :<p className="no-data">_ _</p>}
        </>
      ),   
      volume: (
        <div className="ArtistInfo">
          <div className="item-media">
            <NumberDisplayi18n 
              value={Number(Number(genesis.tradingVolume).toFixed(4))}
              currency=""
              crypto={false}
            />
          </div>
          <div>
            <img src={svgEGLD} width="20px" className="egld-icon"/>
          </div>
        </div>
      ),   
      likes:(
        <>
          <div className="ArtistInfo">
            <div className="item-flex">
              <span style={{ cursor: "pointer" }} >
                <img src={iconHeart} alt="iconHeart"  className="icon-heart" />
              </span>
            </div>
            <div  >
              <p className="fw-bolder row font-weight-normal watch-spotlight" style={{ margin: 0 }}>
                {genesis.countLikes}
              </p>
            </div>
        </div>
        </>
      ),
    };

  });

  const nodataTable = {
    SocialMedia: (
      <div style={{ margin: "30px auto" }}>
        <Nodata />
      </div>
    ),
  };

  const columns = [
    {
      title: t("genesis-collection.artist"),
      dataIndex: "artist",
      key: "artist",
      className: "text-left-artist",
    },
    {
      title: t("genesis-collection.spotlight"),
      dataIndex: "spotlight",
      key: "spotlight",
      className: "text-left",
    },
    {
      title: t("genesis-collection.podcast"),
      dataIndex: "podcast",
      key: "podcast",
      className: "text-left",
    },
    {
      title: t("genesis-collection.social-media"),
      dataIndex: "SocialMedia",
      key: "SocialMedia",
      className: "text-left",
    },
    {
      title: t("genesis-collection.genesis"),
      dataIndex: "genesis",
      key: "genesis",
      className: "text-left",
    },
    {
      title: t("common.collection"),
      dataIndex: "collection",
      key: "collection",
      className: "text-left",
    },
    {
      title: t("ranking.volume"),
      dataIndex: "volume",
      key: "volume",
      className: "text-left",
    },
    {
      title: t("likes"),
      dataIndex: "likes",
      key: "likes",
      className: "text-left",
    },

  ];

  return (
    <>
      <div>
        <Table
          className="table-genesis" 
          size={"small"}
          pagination={false}
          columns={columns}
          loading={spin}
          dataSource={(dataTable.length > 0) ? dataTable : [nodataTable]}
        /> 
      </div>
    </>
  );
});

export default TableGenesis;
