import React, { useEffect } from "react";
import { Avatar, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

import useImageDisplay from "../../helpers/useImageDisplay";
import useUserNaming from "../../helpers/useUserNaming";

interface UserDisplayProps {
  user: any;
  sizeAvatar: any;
  msg: string;
}

function UserDisplayWithTooltip({ user, sizeAvatar, msg }: UserDisplayProps) {
  const avatarDispaly = useImageDisplay(user?.avatar, "__t" + user?.avatar_ext);
  const userNaming = useUserNaming(user);
  const  navigate = useNavigate();

  const redirectToProfile = () => {
    navigate(`/profile-details/${user?.id}`);
  };

  return (
    <>
      {user?.id ? (
        <Tooltip title={msg}>
          <div className="user-display-container" onClick={redirectToProfile}>
            <div>
              <Avatar size={sizeAvatar} src={avatarDispaly} />
            </div>
            <h6
              className={
                sizeAvatar === "small"
                  ? "text-regular-font display-title"
                  : " display-title"
              }>
              {userNaming}
            </h6>
          </div>
        </Tooltip>
      ) : null}
    </>
  );
}
export default UserDisplayWithTooltip;
