import { Avatar } from "antd";
import useImageDisplay from "../../helpers/useImageDisplay";

interface CustomAvatarProps {
    avatarImage: string
    extensionImage: string
}

const CustomAvatar = ({ avatarImage, extensionImage }: CustomAvatarProps) => {
    const displayAvatar = useImageDisplay(avatarImage,'__t'+extensionImage);

    return (
        <Avatar
            src={displayAvatar}
        />
    );
}

export default CustomAvatar;