import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Dragger from 'antd/lib/upload/Dragger';
import { Button, Modal, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import screenfull from 'screenfull'
import { DeleteFilled } from "@ant-design/icons";
import { observer } from 'mobx-react-lite';

import ICAUDIO from '../../../../../assets/images/ic-add-audio.svg';
import ICVIDEO from '../../../../../assets/images/ic-add-video.svg';
import IC3D from '../../../../../assets/images/ic-add-3d.svg';

import "./itemExtraFile.less"
import Privew3DObjects from './privew3DObjects';
import ThumbnailImage from './thumbnailImage';
import { useGlobalContext } from '../../../../context/globalContext';
import  {beforeUploadAudio,beforeUploadVideo,beforeUpload3D} from "../../../../helpers/beforeUploadExtraFile"
import { beforeUpload } from '../../../../helpers/uploadFunctions';

interface IItemExtraFile{
  setExtraFile:any,
  showAudioBlock:boolean
  showVideoBlock:boolean
  show3DBlock:boolean
}

const ItemExtraFile=observer((props:IItemExtraFile) =>{
  const {setExtraFile,showAudioBlock,showVideoBlock,show3DBlock}=props
  const {itemFormStore}=useGlobalContext()

	  const { t } = useTranslation();
    const player = useRef<any>(null);
	  const [ fileType, setFileType ] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [playVideo, setPlayVideo] = useState<boolean>(false)
    const [isVideoLoading, setIsVideoLoading] = useState<boolean>(true)
    const [file3DType, setFile3DType] = useState("")
    const [selectedThumbnailImage, setSelectedThumbnailImage] = useState("")    

    const handleVideoUpload =async (file:any) => {
      if(file){
        setFile3DType(file.name)
        setExtraFile([file])
        itemFormStore.setExtraFilePath(URL.createObjectURL(file));
          if (file.type.includes("video")) {
              setFileType("video")
              setOpenModal(true)
            setIsVideoLoading(false)
            }
            else if (file.type.includes("audio")){
              setFileType("audio")
              setOpenModal(true)
              setIsVideoLoading(false)  
              itemFormStore.setIsConfirmExtraFile(true)    
          }else {
            setFileType("3d")
            setIsVideoLoading(false)    
            itemFormStore.setIsConfirmExtraFile(true) 
            notification.info({
              message: t("collection-page.sroll-in-object"),           
              placement:"bottomLeft",
            });
          }   
      }
      };
      
    
      const draggerProps = {
        name: "file",
        multiple: true,
        action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
        onChange(info:any) {
          handleVideoUpload(info?.file?.originFileObj);         
        }
      };   

      const onClose=()=>{
        itemFormStore.setExtraFilePath(null);
        setOpenModal(false)
        setIsVideoLoading(true)
      }

      const confirmeUplaod=()=>{
        itemFormStore.setIsConfirmExtraFile(true)
        setOpenModal(false)  
        setIsVideoLoading(true)
        setExtraFile((val:any)=>[selectedThumbnailImage,...val])
      }

      const onPlayVideo=()=>{
        setPlayVideo(true)
      }
      const onPauseVideo=()=>{
        setPlayVideo(false)
      }

      const deleteFile=()=>{
        setExtraFile(null)
        itemFormStore.setExtraFilePath(null)
        itemFormStore.setIsConfirmExtraFile(false)
        console.log("itemFormStore.isConfirmExtraFile ======>",itemFormStore.isConfirmExtraFile)
      }


      
  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request(player.current.wrapper);
    }
  };
    

	return (
		<div>
			{itemFormStore.isConfirmExtraFile?
            <div className='extra-file-container-bloc'>
            <DeleteFilled className='delete-container' onClick={deleteFile}/>
              {fileType==="video" &&
                <>
              <div style={{borderRadius: 8}}>
                <ReactPlayer
                  ref={player}
                  url={itemFormStore.extraFilePath}
                  className="block-video-play"                
                  playing={playVideo}
                  onPlay={onPlayVideo}
                  onPause={onPauseVideo}
                  config={{
                      file: {
                          attributes: {
                              onContextMenu: (e: any) => e.preventDefault()
                          }
                      }
                  }}
                />    
                
              </div>
              <div>
                <Button className="play button-video" onClick={onPlayVideo}>{t("collection-page.play")}</Button>
                <Button className="button-video" onClick={onPauseVideo}>{t("collection-page.pause")}</Button>
                <Button className="button-video" onClick={handleClickFullscreen}>{t("collection-page.fullscreen")}</Button>
                </div>       
                </>}
                {fileType==="audio" && itemFormStore.extraFilePath!==null &&  
                <>
                <audio controls className='audio-block-preview'>
                    <source src={itemFormStore.extraFilePath} />
                  </audio>
                </>
        }
        {fileType==="3d" && itemFormStore.extraFilePath!==null &&   <Privew3DObjects path={itemFormStore.extraFilePath} fileType={file3DType}/>}
            </div>
            :<div className='extra-file-dragger-bloc'>
            <Dragger
            className='item-upload-box-container'
            {...draggerProps}
            accept={showAudioBlock?".mp3  .ogg, .wav":showVideoBlock?".mp4, .webm":show3DBlock?".glb, .gltf, .fbx, .obj":""}
            showUploadList={false}
            beforeUpload={(file: any) =>
              showAudioBlock?
              beforeUploadAudio(
                file,
                t("profile.image-type-error", {
                  supported_image_types: "mp3, ogg or wav",
                }),
                t("profile.image-size-error")
              ):showVideoBlock?beforeUploadVideo(file,
                t("profile.image-type-error", {
                  supported_image_types: "mp4 or webm",
                }),
                t("profile.image-size-error")):
                show3DBlock?beforeUpload3D( file,
                  t("profile.image-type-error", {
                    supported_image_types: "glb, gltf, fbx or obj",
                  }),
                  t("profile.image-size-error")):
                  beforeUpload(  file,
                    t("profile.image-type-error", {
                      supported_image_types: "Png, Jpg, gif or svg",
                    }),
                    t("profile.image-size-error")
                  )
            }
          >
            <p className="ant-upload-drag-icon">
             <img src={showAudioBlock? ICAUDIO:showVideoBlock?ICVIDEO:show3DBlock?IC3D:""} alt="ICMEDIA" className='icon-media-upload'/>
            </p>
            <p className="ant-upload-text">
             {t("collection-page.drag-and-drop-file")}
            </p>
            <p className="ant-upload-hint">
              {t("collection-page.supported-file-description",{extension:`${showAudioBlock?".mp3  .ogg, .wav":showVideoBlock?".mp4, .webm":show3DBlock?".glb, .gltf, .fbx, .obj":""}`})}
            </p>
          </Dragger>
            </div> 
            }
            <Modal title="Item video for preview" visible={openModal} onCancel={onClose} destroyOnClose={true} onOk={confirmeUplaod}>  
            <div className='modal-preview'>
                {isVideoLoading && <Spin />}
            {fileType==="video" &&
            <>
                <div>   
                  {!isVideoLoading && <ReactPlayer
                      url={itemFormStore.extraFilePath}
                      width="100%"
                      height="100%"
                      playing={true}
                      config={{
                        file: {
                          attributes: {
                            onContextMenu: (e: any) => e.preventDefault()
                          }
                        }
                      }}
                    /> }    
                    </div>
                  {!isVideoLoading && 
                  <div className='thumbnail-image-block'>
                    <ThumbnailImage setSelectedThumbnailImage={setSelectedThumbnailImage}/>
                    </div> }
            </>  
               
                }         
                {fileType==="audio" &&  
                <>
                   <audio controls className='audio-container-modal '>
                   <source src={itemFormStore.extraFilePath} type="audio/wav" />
                 </audio>
                  <div className='thumbnail-image-block'>
                  <ThumbnailImage setSelectedThumbnailImage={setSelectedThumbnailImage}/>
                  </div>
                </>
                }
			</div>
    </Modal>
		</div>
	);
})

export default ItemExtraFile;
