import { Menu, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import "./notification.less";
import { isValidURL } from "../../../../helpers/validateUrl";
import iconVu from "../../../../../assets/images/iconVu.svg";
import iconNotif from "../../../../../assets/images/notif.svg";
import Imgnotif from "./imgNotif";
import { useNavigate } from "react-router-dom";
import instanceOfAxios from "../../../../../configAxios";
import { useContext } from "../../../../context";
import TimeI18n from "../../../../helpers/timei18n";
import { assert } from "../../../../helpers/communFunctions";
import { observer } from "mobx-react-lite";
import { useGlobalContext } from "../../../../context/globalContext";

interface NotificationProps {
  children: any;
  datanotif: any;
  DataNotif: any;
  dataLoad: boolean;
  notifType: any;
}
const Notification = observer(({
  children,
  datanotif,
  DataNotif,
  dataLoad,
  notifType,
}: NotificationProps) => {
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const { address } = useContext();
  const {notificationsStore}=useGlobalContext()

  const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL;
  const serverAPIEndpoint = process.env.REACT_APP_SERVER_IMAGE_BASE_URL;

  /// url validation

  if (!serverBaseURL) {
    throw new Error();
  }
  if (!serverAPIEndpoint) {
    throw new Error();
  }
  const IMG_URL = serverBaseURL + serverAPIEndpoint;

  if (!isValidURL(IMG_URL)) {
    throw new Error();
  }

  const notifRedirect = (key: any, id: any, eventId: any, homepage: any,item:any,tokenIdentifier?:string) => {
    instanceOfAxios
      .put(`read_event/address/${address}/event/${eventId}`)
      .then(function(res) {
        if (res.status === 200) {
          notificationsStore.setMenuVisible(false)
          switch (key) {
            case "item":
              if(tokenIdentifier ||item?.token_identifier){
                navigate(`/nft/${item?.token_identifier?item?.token_identifier:tokenIdentifier}`);
              }else {
                navigate(`/item-details/${id}`);
              }
              break;
            case "auction":
              navigate(`/nft/${item?.token_identifier?item?.token_identifier:tokenIdentifier}`);
              break;
            case "collection":
              navigate(`/${homepage}`);
              break;
            case "user":
              navigate(`/profile-details/${id}`);
              break;
            case "transfer":
              navigate(`/nft/${tokenIdentifier}`);
              break;
            default:
              " ";
          }
          DataNotif(false);
        }
      });
  };

  const handleRealLinkNotifiactions=(key: any, id: any, eventId: any, homepage: any,item:any,tokenIdentifier?:string)=>{
    switch (key) {
      case "item":
        if(tokenIdentifier ||item?.token_identifier){
     return`/nft/${item?.token_identifier?item?.token_identifier:tokenIdentifier}`
        }else {
     return`/item-details/${id}`
        }
      case "auction":
   return`/nft/${item?.token_identifier?item?.token_identifier:tokenIdentifier}`
      case "collection":
   return`/${homepage}`
      case "user":
   return`/profile-details/${id}`
      case "transfer":
   return`/nft/${tokenIdentifier}`
      default:
        " ";
  }
  }

  const handleRealLinkMsg=(id: any, msg: any)=>{
    if (id) {
      if (msg?.event?.group === "transfer") {
       return`/nft/${msg?.item?.token_identifier}?tab=trading-history`
      } else {
       return`/nft/${msg?.item?.token_identifier}?tab=offers`
      }
    }
  }

  const MsgNotifRedirect = (id: any, msg: any) => {
    instanceOfAxios
      .put(`read_message/address/${address}/message/${msg?.id}`)
      .then(function(res) {
        if (res.status === 200) {
          notificationsStore.setMenuVisible(false)
          if (id) {
            if (msg?.event?.group === "transfer") {
              navigate(`/nft/${msg?.item?.token_identifier}?tab=trading-history`);
            } else {
              navigate(`/nft/${msg?.item?.token_identifier}?tab=offers`);
            }
          }
          DataNotif(false);
        }
      });
  };

  const MgsMenu = (
    <Menu className="menuNotif">
      <strong>{t(`profile.messages`)}</strong>
      {datanotif?.length > 0 &&
        datanotif.map((item: any,key:number) => {
          if (item?.id)
            return (
              <a href={handleRealLinkMsg(item?.item?.id, item)} onClick={(e:any)=>	e.preventDefault()}>
              <li
              key={key}
                className="ant-dropdown-menu-item-only-child"
                onClick={() => MsgNotifRedirect(item?.item?.id, item)}>
                <div className="itemNotif">
                  <div style={{ width: "95%" }}>
                    <div className="txtNotifMsg">
                      <h6> {assert(item?.message, 25)}</h6>
                      <span className="itemTime">
                        <TimeI18n time={item?.created_at} />
                      </span>
                    </div>
                  </div>
                  {item?.read === null ? (
                    <img alt="iconVu" src={iconVu} className="iconVu" />
                  ) : (
                    ""
                  )}
                </div>
              </li>
              </a>
            );
        })}
      {dataLoad === true ? (
        <button
          type="button"
          className="ant-btn loading-more"
          style={{ width: "100%", border: "0" }}
          onClick={() => navigate(`/message`)}>
          <span>{t(`notifications.loading-more`)}</span>{" "}
        </button>
      ) : (
        " "
      )}
    </Menu>
  );

  const dataMenu = (
    <Menu className="menuNotif">
      <strong>{t(`profile.notifications`)}</strong>
      {datanotif?.length > 0 &&
        datanotif.map((item: any,key:number) => {
          if (item?.id)
            return (
              <a href={handleRealLinkNotifiactions(item?.eventGroup,
                item?.id,
                item?.eventId,
                item?.homepage,
                item?.item,
                item?.token_identifier)} onClick={(e:any)=>	e.preventDefault()}>
              <li
              key={key}
                className="ant-dropdown-menu-item-only-child "
                onClick={() =>
                  notifRedirect(
                    item?.eventGroup,
                    item?.id,
                    item?.eventId,
                    item?.homepage,
                    item?.item,
                    item?.token_identifier,
                  )
                }>
                <div className="itemNotif">
                  <div style={{ width: "95%" }}>
                    {/* condition for Icon notifition */}

                    {item?.icon !== null ? (
                      <Imgnotif item={item} />
                    ) : (
                      <span className="notifImgAlert">
                        <img alt="iconNotif" src={iconNotif} />
                      </span>
                    )}
                    <div className="txtNotif">
                      {item.itemName ? (
                        <h6>
                          {t(`notifications.${item.msg}`, {
                            item: item?.itemName,
                          })}
                        </h6>
                      ) : item.collectionName ? (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <h6 style={{ display: "flex" }}>
                            {t(`notifications.${item?.msg}`, {
                              colname: item?.collectionName,
                            })}
                          </h6>
                        </div>
                      ) : item.nickname ? (
                        <h6>
                          {t(`notifications.${item?.msg}`, {
                            nickname: item?.nickname,
                          })}
                        </h6>
                      ) : (
                        <h6>{t(`notifications.${item?.msg}`)}</h6>
                      )}
                      <span className="itemTime">
                        <TimeI18n time={item?.sendAt} />
                      </span>
                    </div>
                  </div>

                  {item.isRead === false ? (
                    <img alt="iconVu" src={iconVu} className="iconVu" />
                  ) : (
                    ""
                  )}
                </div>
              </li>
              </a>
            );
        })}

      {dataLoad === true ? (
        <button
          type="button"
          className="ant-btn loading-more"
          style={{ width: "100%", border: "0" }}
          onClick={() => DataNotif(true)}>
          <span>{t(`notifications.loading-more`)}</span>{" "}
        </button>
      ) : (
        " "
      )}
    </Menu>
  );
  return (
    <>
      <Dropdown
        overlay={notifType === "msg" ? MgsMenu : dataMenu}
        trigger={["click"]}
        placement="bottomRight"
        arrow>
        {children}
      </Dropdown>
    </>
  );
});

export default Notification;
