import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import RecentAuction from "../recentAuction/recentAuction";
import CustomDots from "./customDots/customDots";
import "./customCarousel.less";

export interface AuctionsCarouselProps {
  likedItems: any;
  recentItems: any;
}

const AuctionsCarousel = ({
  likedItems,
  recentItems,
}: AuctionsCarouselProps) => {
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="caroussel">
      <Carousel
        partialVisbile={false}
        itemClass="auction-carousel-item"
        deviceType={"desktop"}
        responsive={responsive}
        removeArrowOnDeviceType={["desktop"]}
        showDots={true}
        customDot={<CustomDots />}
        renderDotsOutside={true}
        dotListClass="custom-dot-list-style"
      >
        <RecentAuction
          partTitle={t("home.navigation-most-liked")}
          elementsList={likedItems}
        />
        <RecentAuction
          partTitle={t("home.navigation-most-recent")}
          elementsList={recentItems}
        />
      </Carousel>
    </div>
  );
};

export default AuctionsCarousel;
