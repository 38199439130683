import { getItem } from "../storage/session";

export interface User {
  language: string;
  nickname: string;
  name: string;
  lastname: string;
  email: string;
  location: string;
  country: string;
  discord: string;
  twitter: string;
  instagram: string;
  medium: string;
  telegram: string;
  currency: string;
  bio: string;
  avatar: string;
  avatar_ext: string;
}

export interface StateType {
  loading: boolean;
  marketPlaceAddress: string;
  actionCode: any;
  actionCodeRefresh: any;
  transactionsTrack: any[];
  refreshItem: boolean;
  marketplace_fee: Number;
  error: string;
  loggedIn: boolean;
  address: string;
  profile: User;
  items: any[];
  brandType: string;
  transactionsFetched: boolean | undefined;
}

export const initialState = (): {
  loading: boolean;
  marketPlaceAddress: string;
  actionCode: Number;
  actionCodeRefresh: any;
  transactionsTrack: any[];
  refreshItem: boolean;
  marketplace_fee: any;
  error: string;
  loggedIn: boolean;
  items: any[];
  address: any;
  transactionsFetched: boolean | undefined;
  profile: User;
  searchTerm: string;
  brandType: string;
} => {
  return {
    loading: false,
    marketPlaceAddress: "",
    actionCode: 0,
    actionCodeRefresh: { item: 0, collection: 0, actionLoading: false },
    transactionsTrack: JSON.parse(
      localStorage.getItem("transactions-track") || "[]"
    ),
    refreshItem: false,
    marketplace_fee: "3.0",
    error: "",
    loggedIn: JSON.parse(localStorage.getItem("loggedIn") || "false"),
    items: [],
    address: localStorage.getItem("address"),
    searchTerm: "",
    profile: {
      bio: "",
      country: "",
      currency: "",
      discord: "",
      email: "",
      instagram: "",
      language: "",
      lastname: "",
      location: "",
      medium: "",
      name: "",
      nickname: "",
      telegram: "",
      twitter: "",
      avatar_ext: "",
      avatar: "",
    },
    transactionsFetched: undefined,
    brandType: "",
  };
};
