import * as React from "react";
import { isValidURL } from "./validateUrl";
import { useEffect, useState } from "react";
import noDataIcon from "../../assets/images/noData.png";

const useImageDisplay = (image: string, extension: string) => {
  const [displayImage, setDisplayImage] = useState<string>("");
  if(image!==undefined && image!==null){
    if(image.includes(".mp3") || image.includes(".ogg") || image.includes(".wav") || image.includes(".mp4") || image.includes(".webm")
    || image.includes(".glb") || image.includes(".gltf") || image.includes(".fbx") || image.includes(".obj")){
      extension=""
    }
  }
  var typeExtension = extension.slice(-3);
  const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL;
  const serverImageBaseURL = process.env.REACT_APP_SERVER_IMAGE_BASE_URL;
  const s3ImageBaseURL = process.env.REACT_APP_SERVER_IMAGE_S3_BASE_URL;

  if (!isValidURL(serverBaseURL) || !serverBaseURL) {
    throw new Error();
  }
  if (!serverImageBaseURL) {
    throw new Error();
  }
  const imgBaseUrl = serverBaseURL + serverImageBaseURL;
  if (!isValidURL(imgBaseUrl)) {
    throw new Error();
  }

  useEffect(() => {
    if (image?.includes("http")) {
      setDisplayImage(image);
    } else if (image) {
      if (process.env.REACT_APP_S3_ENABLED === "true") {
        if (image?.includes("static/media")) {
          setDisplayImage(`${s3ImageBaseURL}${image}`);
        } else {
          setDisplayImage(
            `${s3ImageBaseURL}${image}${typeExtension === "gif" || typeExtension === "svg"
              ? ""
              : extension
            }`
          );
        }
      } else {
        // with developpement env
        if (image?.includes("static/media")) {
          setDisplayImage(`${image}`);
        } else {  
            setDisplayImage(
              `${imgBaseUrl}${image}${typeExtension === "gif" || typeExtension === "svg"
                ? ""
                : extension
              }`
            );
        }
      }
    } else setDisplayImage("");
  }, [image, extension]);

  return displayImage;
};

export default useImageDisplay;
