import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import './mobileTab.less';
import { Link } from 'react-router-dom';

export interface CustomMobileTabProps {
	tabs: any;
	activeTab: string;
	customHeaderClass: string;
	onChangeTab: (tab: string) => void;
}

const MobileTab = ({ tabs, activeTab, customHeaderClass, onChangeTab }: CustomMobileTabProps) => {
	const { t } = useTranslation();

	const customMenuItem = (tab: string, value: any) => {
		return (
			<Menu.Item disabled={activeTab === value ? true : false} onClick={() => onChangeTab(value)}>
					<h6 className="text-regular-font">{tab}</h6>
			</Menu.Item>
		);
	};

	const menu = (
		<Menu>
			{tabs.map((el: any) => {
				return customMenuItem(el.label, el.value);
			})}
		</Menu>
	);

	return (
		<div className={`tab-header-mobile ${customHeaderClass}`}>
			<h6 className="tab-mobile active-tab">{activeTab}</h6>
			<Dropdown overlayClassName="mobileDropdown" overlay={menu} className="mobile-menu-dropdown">
				<MenuOutlined />
			</Dropdown>
		</div>
	);
};

export default MobileTab;
