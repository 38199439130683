import { useTranslation } from "react-i18next";
import { Switch } from "antd";
import "./notificationsPart.less";

export interface NotificationsProps { }

const NotificationsPart = () => {
  const { t } = useTranslation();

  return (
    <div className="notifications-container">
      <div>
        <h5 className="primary-color">{t("common.activity")}</h5>
        <div className="notif-container">
          <h5 className="text-regular-font">
            {t("profile.activity-notif-1")}{" "}
          </h5>
          <Switch defaultChecked={false} />
        </div>

        <div className="notif-container">
          <h5 className="text-regular-font">
            {t("profile.activity-notif-2")}{" "}
          </h5>
          <Switch defaultChecked />
        </div>

        <div className="notif-container">
          <h5 className="text-regular-font">
            {t("profile.activity-notif-3")}{" "}
          </h5>
          <Switch defaultChecked={false} />
        </div>
      </div>

      <div>
        <h5 className="primary-color">{t("common.activity")}</h5>
        <div className="notif-container">
          <h5 className="text-regular-font">{t("profile.news-notif-1")} </h5>
          <Switch defaultChecked />
        </div>
        <div className="notif-container">
          <h5 className="text-regular-font">{t("profile.news-notif-2")} </h5>
          <Switch defaultChecked={false} />
        </div>
      </div>
    </div>
  );
};

export default NotificationsPart;
