import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useImageDisplay from "../../../../../helpers/useImageDisplay";
import useWorstDisplay from "../../../../../helpers/useWorstDisplay";
import { useContext } from "../../../../../context";
import ButtonTab from "../../ButtonTab/buttonTab";
import Falg from "../../../../../../assets/images/flag.svg";
import EditCol from "../../../../../../assets/images/ic-edit.svg";
import "./collectionItem.less";
import useUserNaming from "../../../../../helpers/useUserNaming";

import { Avatar, Row } from "antd";

export interface CollectionProps {
  el?: any;
}

const CollectionItemExisting = ({ el }: CollectionProps) => {
  const { t } = useTranslation();
  const { address } = useContext();

  return (
    <div className="collection-item-existing">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Row>
            <Link to={`/profile-details/${el?.creator?.id}`}>
              {" "}
              <div>
                <Avatar
                  src={useImageDisplay(
                    el?.creator?.avatar,
                    "__t" + el?.creator?.avatar_ext
                  )}
                />
              </div>
            </Link>

            <Link to={`/profile-details/${el?.creator?.id}`}>
              <div
                style={{
                  marginLeft: "10px",
                  color: "black",
                  marginTop: "10px",
                }}
              >
                {useUserNaming(el?.creator)}
              </div>
            </Link>
          </Row>
        </div>
        {el?.isInvited ? (
          <div style={{ position: "relative" }}>
            <img
              src={Falg}
              width="70px"
              alt="flag"
              style={{ marginTop: "-32px", marginLeft: "54px" }}
            />
            <p className="text-invite-collectionExist">
              {t("collection-page.you-are-invited")}
            </p>
          </div>
        ) : el?.creator?.erd_address === address ? (
          <Link to={`/edit-collection/${el?.homepage}`}>
            {" "}
            <img src={EditCol} alt="edit" className="edit-icon-collection"/>
          </Link>
        ) : (
          ""
        )}
      </div>
      <Link to={el?.homepage}>
        <div className="image-collection">
          <img
             alt={`${el?.collection_name}`}
            src={useImageDisplay(el?.logo_image, "__m" + el?.logo_image_ext)}
            className="existed-collection-image"
          />
        </div>
      </Link>
      <div className="collection-content">
        <div className="other-collection">
          <div>
            <h5 className="title-royalatie-items">
              {useWorstDisplay(el?.collection_name, 15)}
            </h5>

            <h5>
              {" "}
              {el?.collectionType?.collection_type_name_i18ntag === "private"
                ? t("collection-type-private")
                : el?.collectionType?.collection_type_name_i18ntag === "charity"
                ? t("collection-type-charity")
                : el?.collectionType?.collection_type_name_i18ntag ===
                  "standard"
                ? t("collection-type-standard")
                : t("collection-type-open")}
            </h5>
          </div>
          <div>
            <h5 className="title-royalatie-items">
              {el?.royalties / 100}%{" "}
              <span>{t("collection-page.Royality")}</span>
            </h5>

            <h5>
              {el?.countItems} <span>{t("collection-page.Items")}</span>
            </h5>
          </div>
        </div>
      </div>
      <Link to={`/create-item/${el?.id}`}>
        <ButtonTab type="primary">{t("collection-page.add-item")}</ButtonTab>
      </Link>
    </div>
  );
};

export default CollectionItemExisting;
