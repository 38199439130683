import React, { useMemo, useRef, useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import GltfEmptyObject from './gltfEmptyObject';

function GLTModel({ modelPath }) {
	const [ gltf, set ] = useState();
	const mesh = useRef();
	const [ isNotVaildObject, setIsNotVaildObject ] = useState(false);
	useMemo(
		() => {
			new GLTFLoader().load(
				modelPath,
				function(gltf) {
					set(gltf);
					setIsNotVaildObject(false);
				},
				undefined,
				function(error) {
					console.log(error);
					setIsNotVaildObject(true);
				}
			);
		},
		[ modelPath ]
	);

	return gltf ? (
		<primitive object={gltf.scene} scale={30} />
	) : (
		<GltfEmptyObject isNotVaildObject={isNotVaildObject} />
	);
}

export default GLTModel;
