import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import orangeuserIcon from '../../../../../assets/images/orangeuser.svg';
import orangesocialIcon from '../../../../../assets/images/orangeshare.svg';
import orangesettingsIcon from '../../../../../assets/images/orangesettings.svg';
import orangenotifIcon from '../../../../../assets/images/orangenotifications.svg';
import './profileMobileTab.less';

export interface ProfileMobileTabProps {
	activeTab: string;
	onChangeTab: (tab: string) => void;
}

const ProfileMobileTab = ({ activeTab, onChangeTab }: ProfileMobileTabProps) => {
	const { t } = useTranslation();

	const menu = (
		<Menu>
			<Menu.Item disabled={activeTab === 'general-informations' ? true : false}>
				<h6 className="text-regular-font" onClick={() => onChangeTab('general-informations')}>
					{t('profile.general-informations')}{' '}
				</h6>
			</Menu.Item>
			<Menu.Item disabled={activeTab === 'social-links' ? true : false}>
				<h6 className="text-regular-font" onClick={() => onChangeTab('social-links')}>
					{t('profile.social-links')}{' '}
				</h6>
			</Menu.Item>
			<Menu.Item disabled={activeTab === 'settings' ? true : false}>
				<h6 className="text-regular-font" onClick={() => onChangeTab('settings')}>
					{t('profile.settings')}{' '}
				</h6>
			</Menu.Item>
			{/* <Menu.Item disabled={activeTab === "notifications" ? true : false}>
        <h6
          className="text-regular-font"
          onClick={() => onChangeTab("notifications")}
        >
          {t("profile.notifications")}{" "}
        </h6>
      </Menu.Item> */}
		</Menu>
	);

	return (
		<div className="profile-tab-header-mobile">
			<div className="profile-tab profile-active-tab">
				<img
					alt="iconTab"
					src={
						activeTab === 'general-informations' ? (
							orangeuserIcon
						) : activeTab === 'social-links' ? (
							orangesocialIcon
						) : activeTab === 'settings' ? (
							orangesettingsIcon
						) : (
							orangenotifIcon
						)
					}
					style={{ width: '20px' }}
				/>
				<h5 className="part-title primary-color">{t(`profile.${activeTab}`)}</h5>
			</div>

			<Dropdown overlay={menu} className="mobile-menu-dropdown">
				<MenuOutlined />
			</Dropdown>
		</div>
	);
};

export default ProfileMobileTab;
