import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import instanceOfAxios from "../../../../configAxios";
import EmailConfirm from "../../../components/modal/email confirm/emailConfirm";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/toasts/openToast";
import { useContext } from "../../../context";
import "./newsletter.less"

const { Search } = Input;
const Newsletter = () => {
  const { t } = useTranslation();
  const { address } = useContext();
  const [form] = Form.useForm();
  const [emailVerif, setEmailVerif] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState("");

  const validateMessages = {
    required: t("home.email-is-required"),
    types: {
      email: t("home.email-is-not-valid"),
    },
  };
  const onFinish = (values: any) => {
    setLoading(true);
    setEmail(values.email);
    instanceOfAxios
      .post(`newsletter${address ? `?erd_address=${address}` : ""}`, {
        email_address: values.email,
      })
      .then((res: any) => {
        if (!res?.data.opt_in) {
          setEmailVerif(true);
        }
        form.resetFields();
        setLoading(false);
        OpenSuccessNotification(
          t("profile.success"),
          t("home.subscribed-success"),
          3
        );
      })
      .catch((err: any) => {
        OpenErrorNotification(
          t("profile.error"),
          t("profile.already-subscribed"),
          3
        );
        setLoading(false);
      });
  };
  return (
    <Form
      form={form}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <h4 className="footer-title">{t("home.footer-newsletter")}</h4>
      <Form.Item name="email" rules={[{ required:true, message: t("home.email-is-required") },{
        type: "email", message: t("home.email-is-not-valid")
      }
    ]}>
        <Search
          placeholder={t("home.footer-subscribe-placeholder")}
          enterButton={
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              // className="subscription-btn"
            >
              {t("home.footer-subscribe-button")}
            </Button>
          }
          className="footer-button footer-newsletter-error-msg"
          style={{marginBottom:5}}
        />
      </Form.Item>
      {emailVerif && (
        <EmailConfirm
          confirmVisible={emailVerif}
          handleClose={() => setEmailVerif(false)}
          email={email}
          emailInput={false}
          closable={true}
        />
      )}
    </Form>
  );
};

export default Newsletter;
