import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabel from "./table";
import OptionSelect from "../../components/option/optionSelect";
import instanceOfAxios from '../../../configAxios';
import "./ranking.less";


function Ranking() {

  const pageItemsNumber = 20;
  const { t } = useTranslation();
  const [dataLoad, setDataload] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [timeFilter, setTimeFilter] = useState<any>(null);
  const [categoryFilter, setCategoryFilter] = useState<any>(null);

  const handleShowMore = (filter: boolean, time:any, category:any) => {

    instanceOfAxios.get(`rankings?first=${pageItemsNumber}&offset=${(filter) ? offset : 0}${((time !== "")&&(time !== null)) ? `&filter=${time}` : ""}${((category !== "")&&(category !== null)) ? `&category=${category}`  : ""}`)
    .then(function (res) {
      let result = res.data.nodes

      if (!filter) {
        setData(result)
      } else {
        setData((prev) => {
          return prev.concat(result);
        });
      }

      setDataload(res.data.pageInfo.hasNextPage)

      if ((res.data.pageInfo.hasNextPage) && (filter)) {
        setOffset(offset + pageItemsNumber)
      } else {
        setOffset(pageItemsNumber)
      }
    })
  }

  useEffect(() => {

    if (timeFilter !== null) {
      
      handleShowMore(false, timeFilter, categoryFilter)
    }

  }, [timeFilter]);
  
  useEffect(() => {

    if (categoryFilter !== null) {
      
      handleShowMore(false, timeFilter, categoryFilter)
    }

  }, [categoryFilter]);


  useEffect(() => {

    handleShowMore(false, "", "")

  }, []);

  const times = [
    {
      label: t("All"),
      value: t("All"),
    },
    {
      label: t("ranking.last_24h"),
      value: "last_24h",
    },
    {
      label: t("ranking.last_7_days"),
      value: "last_7_days",
    },
    {
      label: t("ranking.last_month"),
      value: "last_month",
    }
  ];

  const categories = [
    {
      label: t("All"),
      value: t("All"),
    },
    {
      label: t("ranking.art"),
      value: "art",
    },
    {
      label: t("ranking.collectibles"),
      value: "collectibles",
    },
    {
      label: t("ranking.ai-generated"),
      value: "ai_generated",
    },
    {
      label: t("ranking.photography"),
      value: "photography",
    },
    {
      label: t("ranking.physical_linked"),
      value: "physical_linked",
    }
  ];

  return (
    <>
      <div>
        <style>
          {"\
          .ant-select-dropdown {\
            position: fixed!important;\
            z-index: 10 !important;\
          }\
          "}
        </style>

        <div className="activity-main ">
          <div className="activity-filter">
            <div style={{ display: "grid" }}>
              <div > 
                <span className="link-home-activity ">
                <a href="/" className="breadcrumbPath">
                  {t("Home")}
                </a>{" "}
                {">"}
                <span className="primary-color link-home-activity ">
                  {t("common.ranking")}
                </span>{" "}
              </span>
              </div>
              <div style={{ marginTop: 50 }}><h4>{t("ranking.current-market-ranking")}</h4> </div>
            </div>
            <div className="selectors-select">
              <div className="optionSelect">
                <span  className="activity-select">
                  {t("ranking.times")}
                </span>
                <OptionSelect
                  data={times}
                  multiple = {false}
                  keyTraduction="ranking"
                  filterOption={timeFilter}
                  setFilterOption={setTimeFilter}/>
              </div>
              <div className="optionSelect">
                <span className="activity-select">
                  {t("ranking.categories")}
                </span>
                <OptionSelect
                  data={categories}
                  multiple = {false} 
                  keyTraduction="ranking"
                  filterOption={categoryFilter}
                  setFilterOption={setCategoryFilter}/>
              </div>
            </div>
          </div>
        </div>
        <div> 
          <Tabel data={data} handleShowMore={handleShowMore}
          timeFilter ={timeFilter}
          categoryFilter={categoryFilter}
          dataLoad={dataLoad} />
        </div>

      </div>
    </>
  );
}
export default Ranking;