import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Button, message, Table, Typography } from "antd";
import "./itemTab.less";
import MobileTab from "../../../../components/mobileTab/mobileTab";
import { useEffect } from "react";
import instanceOfAxios from "../../../../../configAxios";
import { useContext } from "../../../../context";
import UserDisplay from "../../../../components/user display/userDisplay";
import NumberDisplayi18n from "../../../../helpers/numbersi18n";
import egldIcon from "../../../../../assets/images/EGLD.svg";
import downloadIcon from "../../../../../assets/images/download.svg";
import disabledDownloadIcon from "../../../../../assets/images/download-disabled.svg";
import accept from "../../../../../assets/images/acceptButton.svg";
import refuse from "../../../../../assets/images/refuseButton.svg";
import TimeI18n from "../../../../helpers/timei18n";
import TextAreaModal from "../../../../components/modal/textAreaModal/textAreaModal";
import AcceptOffer from "../item action box/boxes/acceptOffer";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "../../../../components/buttons/IconButton/IconButton";
import axios from "axios";
import { OpenErrorNotification } from "../../../../components/toasts/openToast";
import { network } from "../../../../config/configGlobal";
import UserDisplayWithTooltip from "../../../../components/user display/userDisplayWithTooltip";
import { useGlobalContext } from "../../../../context/globalContext";
import { observer } from "mobx-react-lite";
import { getItemFromLocalStorage } from "../../../../helpers/useLocalStorage";
export interface ItemTabProps {
  menu: any[];
  itemdb: any;
  arrayofAttribute: any;
  customAttributes: any;
  activeTab: string;
  offers: any;
  setActiveTab: (tab: string) => void;
  canDownload: boolean;
  showDownloadButton: any;
}

const ItemTab = observer(({
  menu,
  itemdb,
  arrayofAttribute,
  customAttributes,
  activeTab,
  setActiveTab,
  canDownload,
  showDownloadButton,
}: ItemTabProps) => {
  const { t } = useTranslation();
  const { address, actionCode } = useContext();
  const { itemDetailsStore } = useGlobalContext();
 
  const [tradingData, setTradingData] = useState<any>([]);
  const [tradingObject, setTradingObject] = useState<any>({});
  const [offersData, setOffersData] = useState<any>([]);
  const [offersObject, setOffersObject] = useState<any>({});
  const [offersOffset, setOffersOffset] = useState(-itemDetailsStore.first);
  const [tradingLoading, setTradingLoading] = useState(false);
  const [offersLoading, setOffersLoading] = useState(false);
  const [refuseOfferModal, setRefuseOfferModal] = useState(false);
  const [acceptOfferModal, setAcceptOfferModal] = useState(false);
  const [idOffer, setIdOffer] = useState(0);
  const [attributesOccurence, setAttributesOccurence] = useState<any[]>([]);
  const [metadata, setMetadata] = useState<any[]>([]);
  const [downloadable, setDownloadable] = useState<any[]>([]);
  const [tokens, setTokens] = useState<string[]>([]);
  const navigate=useNavigate()

  const tableWidth = 150;

  const userCurrency = "€";
  const blockchainCurrency = "EGLD";

  useEffect(() => {
    itemDetailsStore.setOffset(-itemDetailsStore.first);
    setOffersOffset(-itemDetailsStore.first);
    if (activeTab === "trading-history") {
      getTradingHistory(false);
    } else if (activeTab === "offers") {
      getOffers(false);
    }
  }, [activeTab, itemdb]);

  useEffect(() => {
    canDownload &&
      address &&
      instanceOfAxios
        .get(`generate_secret_link/item/${itemdb?.id}/address/${address}`, {
          headers: {
            "x-access-token": getItemFromLocalStorage("token"),
          },
        })
        .then((res: any) => {
          setTokens([
            res?.data?.uri,
            res?.data?.uri1,
            res?.data?.uri2,
            res?.data?.uri3,
          ]);
        })
        .catch((err: any) => {
          if(err?.response.status===401){
            OpenErrorNotification(
              t("profile.error"),
              t("profile.error-unauthorized-user"),
              3
            );                    
          }else{
            OpenErrorNotification(
              t("profile.error"),
              t("profile.error-desc"),
              3
            );
          }
          return "failed call for get secret files tokens";
        });
  }, [canDownload]);

  useEffect(() => {
    if (
      itemdb?.uri1_metadata &&
      itemdb?.uri2_metadata &&
      itemdb?.uri3_metadata
    ) {
      setMetadata([
        JSON.parse(itemdb?.uri_metadata),
        JSON.parse(itemdb?.uri1_metadata),
        JSON.parse(itemdb?.uri2_metadata),
        JSON.parse(itemdb?.uri3_metadata),
      ]);
    } else if (itemdb?.uri1_metadata && itemdb?.uri2_metadata) {
      setMetadata([
        JSON.parse(itemdb?.uri_metadata),
        JSON.parse(itemdb?.uri1_metadata),
        JSON.parse(itemdb?.uri2_metadata),
      ]);
    } else if (itemdb?.uri1_metadata) {
      setMetadata([
        JSON.parse(itemdb?.uri_metadata),
        JSON.parse(itemdb?.uri1_metadata),
      ]);
    } else {
      setMetadata([JSON.parse(itemdb?.uri_metadata)]);
    }
  }, [itemdb?.uri_metadata]);

  useEffect(() => {
    setAttributesOccurence([]);
    arrayofAttribute.map((el: any) => {
      let attributeObject: any = {};
      attributeObject.key = el.collection_attribute?.attribute?.key;
      attributeObject.value = el.value;
      el.collection_attribute?.attribute?.id &&
        instanceOfAxios
          .post(
            `percentage_of_use/collection/${itemdb?.collection?.id}/${el.collection_attribute?.attribute?.id}`,
            { attribute_value: el.value }
          )
          .then((res: any) => {
            attributeObject.percentage = Number(
              res?.data?.percent_value || "0"
            );
            setAttributesOccurence((attributesOccurence) => [
              ...attributesOccurence,
              attributeObject,
            ]);
          })
          .catch((err: any) => {
            return "failed call for get percentage use";
          });
    });
  }, [arrayofAttribute]);

  useEffect(() => {
    setDownloadable([]);
    showDownloadButton(false);
    metadata?.map((file) => {
      if (
        file?.type !== "image/gif" &&
        file?.type !== "image/svg+xml" &&
        (file?.width >= 1024 || file?.height >= 1024)
      ) {
        setDownloadable((downloadable) => [...downloadable, file]);
        showDownloadButton(true);
      }
    });
  }, [metadata]);

  const getTradingHistory = (withPagination: boolean) => {
    setTradingLoading(true);
    if(withPagination){
      itemDetailsStore.setOffset(itemDetailsStore.offset + itemDetailsStore.first);
    }
    instanceOfAxios
      .get(
        `item_trading_history/item/${itemdb?.id}?${
          address ? `erd_address=${address}&` : ""
        }first=${itemDetailsStore.offset + itemDetailsStore.first + itemDetailsStore.first}&offset=${itemDetailsStore.offset + itemDetailsStore.first}`
      )
      .then((res: any) => {
        setTradingObject(res?.data);
        setTradingLoading(false);
        if (withPagination) {  
          setTradingData((prev:any) => {   return prev.concat(res?.data?.nodes)})
        }else {
          setTradingData(res?.data?.nodes)
        }
      })
      .catch((err: any) => {
        setTradingLoading(false);
        return "failed call for get trading history";
      });
  };
  

  const getOffers = (withPagination: boolean) => {
    setOffersLoading(true);
    instanceOfAxios
      .get(
        `offers_list/${itemdb?.id}?${
          address ? `erd_address=${address}&` : ""
        }first=${offersOffset + itemDetailsStore.first + itemDetailsStore.first}&offset=${offersOffset + itemDetailsStore.first}`
      )
      .then((res: any) => {
        setOffersObject(res?.data);
        setOffersLoading(false);
        setOffersOffset(offersOffset + itemDetailsStore.first);
        setOffersData(
          withPagination
            ? [...offersData, ...res?.data?.nodes]
            : res?.data?.nodes
        );
      })
      .catch((err: any) => {
        setOffersLoading(false);
        return "failed call for get offers";
      });
  };

  const downloadSecretFile = (row: any) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_BASE_URL}${
        process.env.REACT_APP_SERVER_API_ENDPOINT
      }/secret_file?token=${tokens[metadata?.indexOf(row)]}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", row?.name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => {
        OpenErrorNotification(t("profile.error"), t("profile.error-desc"), 3);
      });
  };

  const infoColumns = [
    {
      title: t("collection-page.file-name"),
      dataIndex: "",
      key: "",
      render: (row: any) => (
        <h6 className="text-regular-font">
          {t("collection-page.secret-file", {
            num: metadata?.indexOf(row) + 1,
          })}
        </h6>
      ),
    },
    {
      title: t("collection-page.file-size"),
      dataIndex: "size",
      key: "size",
      render: (text: any) => <h6 className="text-regular-font">{text}</h6>,
    },
    {
      title: t("collection-page.file-type"),
      dataIndex: "type",
      key: "type",
      render: (text: any) => <h6 className="text-regular-font">{text}</h6>,
    },
    {
      title: t("collection-page.file-resolution"),
      dataIndex: "",
      key: "",
      render: (row: any) => (
        <h6 className="text-regular-font">{`${row?.width}x${row?.height}`}</h6>
      ),
    },
    {
      title: t("collection-page.file-action"),
      dataIndex: "",
      key: "",
      render: (row: any) => {
        if (
          itemdb?.type !== "item" &&
          row?.type !== "image/gif" &&
          row?.type !== "image/svg+xml" &&
          (row?.width >= 1024 || row?.height >= 1024)
        )
          return (
            <IconButton
              title={t("collection-page.download-secret-files")}
              icon={downloadIcon}
              onClick={() => {
                downloadSecretFile(row);
              }}
              disabled={!canDownload}
              disabledIcon={disabledDownloadIcon}
              disabledTitle={t("collection-page.download-secret-disabled")}
            />
          );
        else return null;
      },
    },
  ];

  const descriptionPart = (
    <div className="scrollable-description" id="description">
      <div dangerouslySetInnerHTML={{ __html: itemdb?.description }}></div>
    </div>
  );

  const propertiesPart = (
    <div
      className="item-details-properties scrollable-description"
      id="properties">
      {attributesOccurence?.length !== 0
        ? attributesOccurence.map((el: any,key:number) => {
            return (
              <>
                <div className="item-details-property" key={key}>
                  <h6 className="primary-color">{el?.key}</h6>
                  <h6 style={{ color: "#000" }}>{el?.value}</h6>
                  <h6 className="text-regular-font" style={{ color: "#000" }}>
                    {t("collection-page.percentage-expression", {
                      percentage: el?.percentage || 0,
                    })}
                  </h6>
                </div>
              </>
            );
          })
        : null}
      {/* {customAttributes?.length !== 0
        ? customAttributes.map((el: any) => {
            return (
              <>
                <div className="item-details-property">
                  <h6 className="primary-color">{el?.key}</h6>
                  <h6>{el?.value}</h6>
                </div>
              </>
            );
          })
        : null} */}
    </div>
  );

  const infoPart = (
    <div className="scrollable-description" id="infos">
      {itemdb?.type === "item" ? (
        <div className="info-holder">
          <h6>{t("collection-page.item-token-id")}</h6>
        </div>
      ) : (
        <>
        <div className="info-holder">
          <h6>{t("collection-page.token-id")}</h6>
          <a
            href={`${network?.explorerAddress}nfts/${itemdb?.token_identifier}`}
            target="_blank">
            <h6 className="text-regular-font">
              {t("collection-page.nft-sft-token-id", {
                tokenId: itemdb?.token_identifier,
              })}
            </h6>
          </a>
        </div>
        {itemdb?.type==="nft" &&
        <div className="info-holder">
          <h6 className="approximative-price">{t("collection-page.internal-id")}</h6>
          <p></p>
            <h6 className="approximative-price">
            {itemdb?.id}
            </h6>
        </div>
        }
        </>
      )}
      {downloadable?.length > 0 ? (
        <Table
          size="middle"
          columns={infoColumns}
          dataSource={downloadable}
          pagination={false}
        />
      ) : null}
    </div>
  );

  const offersColumns = [
    {
      title: t("collection-page.date"),
      dataIndex: "created_at",
      key: "created_at",
      className: "item-table",
      render: (text: any) => (
        <h6 className="text-regular-font">
          <TimeI18n time={text} />
        </h6>
      ),
    },
    {
      title: t("common.status"),
      dataIndex: "status",
      key: "status",
      render: (text: any) => <h6 className="text-regular-font">{text}</h6>,
    },
    {
      title: t("collection-page.from"),
      dataIndex: "fromAddress",
      key: "fromAddress",
      render: (userObject: any) => {
        return userObject?.id ? (
          <UserDisplay sizeAvatar="small" user={userObject} />
        ) : (
          ""
        );
      },
    },
    {
      title: t("collection-page.to"),
      dataIndex: "toAddress",
      key: "toAddress",
      render: (userObject: any) => {
        return userObject?.id ? (
          <UserDisplay sizeAvatar="small" user={userObject} />
        ) : (
          ""
        );
      },
    },
    {
      title: t("common.price"),
      dataIndex: "price",
      key: "price",
      render: (text: string) => (
        <h6 className="text-regular-font">
          <NumberDisplayi18n
            value={Number(Number(text)?.toFixed(5)) / 1000000000000000000 }
            currency=""
            crypto={false}
          />
          <img alt="EGLD" src={egldIcon} className="egld-icon" />
        </h6>
      ),
    },
  ];

  const ownerActions = {
    title: t("common.actions"),
    dataIndex: "id",
    key: "actions",
    render: (offerId: any) => (
      <div>
        <img
          alt="refuse"
          src={refuse}
          className="offer-tab-button"
          onClick={() => {
            setIdOffer(offerId);
            setRefuseOfferModal(true);
          }}
        />
        <img
          alt="accept"
          src={accept}
          className="offer-tab-button"
          onClick={() => {
            setIdOffer(offerId);
            setAcceptOfferModal(true);
          }}
        />
      </div>
    ),
  };

  const tradingColumns = [
    {
      title: t("common.event"),
      dataIndex: "status",
      key: "status",
      className:"event-table-header font-trading-history-header",
      render: (text: any) =>
        text && (
          <h6 className="text-regular-font">
            {text === "sent"
              ? t(`collection-page.bid`)
              : t(`collection-page.${text}`)}
          </h6>
        ),
    },
    {
      title: t("collection-page.from"),
      dataIndex: ["fromAddress", "status", "type", "message"],
      key: "fromAddress",
      className:"font-trading-history-header",
      render: (userObject: any, row: any) => {
        return row["fromAddress"]?.id ? (
          row["status"] === "transferred" && row["type"] === "transfer" ? (
            <UserDisplayWithTooltip
              sizeAvatar="small"
              user={row["fromAddress"]}
              msg={row["message"]}
            />
          ) : (
            <UserDisplay sizeAvatar="small" user={row["fromAddress"]} />
          )
        ) : (
          ""
        );
      },
    },
    {
      title: t("collection-page.to"),
      dataIndex: "",
      key: "",
      className:"font-trading-history-header",
      render: (row: any) => {
        return row?.toAddress?.id && row?.status !== "sent" ? (
          <UserDisplay sizeAvatar="small" user={row?.toAddress} />
        ) : (
          ""
        );
      },
    },
    {
      title: t("common.price"),
      dataIndex: "price",
      key: "price",
      className:"font-trading-history-header",
      render: (text: string) =>
        Number(text) > 0 ? (
          <h6 className="text-regular-font">
            <NumberDisplayi18n
              value={Number(Number(text)?.toFixed(5))/ 1000000000000000000}
              currency=""
              crypto={false}
            />
            <img alt="EGLD" src={egldIcon} className="egld-icon" />
          </h6>
        ) : null,
    },
    {
      title: t("collection-page.date"),
      dataIndex: "date",
      key: "date",
      className: "item-table font-trading-history-header",
      render: (text: any) => (
        <h6 className="text-regular-font">
          <TimeI18n time={text} />
        </h6>
      ),
    },
  ];

  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
    navigate({pathname:itemdb.type==="nft"?`/nft/${itemdb?.token_identifier}`:`/item-details/${itemdb?.id}`})
  };

  const itemTabElement = (tab: any) => {
    return (
        <h6
          className={
            activeTab === tab?.value
              ? "item-tab active-tab"
              : "item-tab text-regular-font"
          }
          onClick={() => changeActiveTab(tab.value)}>
          {tab.label}
        </h6>
    );
  };

  const refuseOffer = (reason: string) => {
    instanceOfAxios
      .put(`refuse_offer/address/${address}/${itemdb?.id}/${idOffer}`, {
        message: reason,
      })
      .then((res: any) => {
        setOffersData(
          offersData?.filter((offer: any) => offer?.id !== res?.data?.id)
        );
        setRefuseOfferModal(false);
      })
      .catch((err: any) => {
        setRefuseOfferModal(false);
        return "failed call for get offers";
      });
  };

  return (
    <div className="item-tab-container">
      <div className="item-tab-header">
        {menu.map((el) => {
          return itemTabElement(el);
        })}
      </div>
      <MobileTab
        tabs={menu}
        activeTab={t(`collection-page.${activeTab}`)}
        customHeaderClass="item-details-mobile-tab"
        onChangeTab={(tab) => changeActiveTab(tab)}
      />
      <div className="item-tab-content item-table">
        {activeTab === "description" && descriptionPart}
        {activeTab === "properties" && propertiesPart}
        {activeTab === "info" && infoPart}
        {activeTab === "offers" && offersData?.length ? (
          <div>
            <Table
              size="middle"
              columns={
                address === itemdb?.owner?.erd_address && actionCode
                  ? [...offersColumns, ownerActions]
                  : offersColumns
              }
              dataSource={offersData}
              pagination={false}
              scroll={{ y: tableWidth }}
              loading={offersLoading}
            />
            {offersObject?.pageInfo?.hasNextPage && (
              <div className="trading-pagination-button">
                <Button size="small" onClick={() => getOffers(true)}>
                  {t("activity.see-more")}
                </Button>
              </div>
            )}
          </div>
        ) : null}
        {activeTab === "trading-history" && (
          <div className="block-table-trading-history">
            <Table
              size="middle"
              columns={tradingColumns}
              dataSource={tradingData}
              pagination={false}
              scroll={{ y: tableWidth }}
              loading={tradingLoading}
            />
            {tradingObject?.pageInfo?.hasNextPage && (
              <div className="trading-pagination-button">
                <Button className="see-more-trading-history-btn" size="small" onClick={() => getTradingHistory(true)}>
                  {t("activity.see-more")}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {refuseOfferModal && (
        <TextAreaModal
          visible={refuseOfferModal}
          title={t("collection-page.refuse-offer-title")}
          description={t("collection-page.refuse-offer-desc")}
          label={t("collection-page.refuse-message-title")}
          placeholder={t("collection-page.refuse-message-placeholder")}
          okButton={t("common.yes")}
          handleOk={(reason) => refuseOffer(reason)}
          handleCancel={() => setRefuseOfferModal(false)}
        />
      )}
      {acceptOfferModal && (
        <AcceptOffer
          visible={acceptOfferModal}
          handleOk={() => setAcceptOfferModal(false)}
          handleCancel={() => setAcceptOfferModal(false)}
          blockchainCurrency={blockchainCurrency}
          userCurrency={userCurrency}
          offerPrice={Number(
            offersData?.filter((offer: any) => offer?.id === idOffer)[0]?.price
          )}
          offer={offersData?.filter((offer: any) => offer?.id === idOffer)[0]}
          order="accept"
          item={itemdb}
          royality={itemdb?.royalties}
          acceptOfferCase={acceptOfferModal}
        />
      )}
    </div>
  );
});

export default ItemTab;
