import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { Col, Row } from "antd";
import { Collapse } from "antd";
import QuestionMark from "../../../assets/images/QuestionFaq.svg";
import DownCircleFilled from "@ant-design/icons/lib/icons/DownCircleFilled";
import CustomModal from "../../components/modal/customModal";
import { useLocation } from "react-router-dom";
import instanceOfAxios from "../../../configAxios";
import { useContext } from "../../context";
import "./staticPage.less";
import { Helmet } from "react-helmet";
import { getItemFromLocalStorage } from "../../helpers/useLocalStorage";

const PageFaq = () => {
  const { t } = useTranslation();
  const [textResultVisible, setTextResultVisible] = useState(false);
  const { address } = useContext();
  const search = useLocation().search;
  const filterSearch = new URLSearchParams(search).get("search");
  const tabSearch = new URLSearchParams(search).get("tab");
  const [activeTab, setActiveTab] = useState<String>(`${tabSearch}`);
  const [currency, setCurrency] = useState<String>("usd");
  const [convert, setConvert] = useState<String>("");
  const [id, setId] = useState<String>(
    JSON.parse(localStorage.getItem("user") || "{}")?.id
  );

  const currencyChange = (value: any) => {
    instanceOfAxios
      .get(`/currency/exchange_rates/egld/1/${value}`)
      .then((res: any) => {
        setConvert(res.data.rate);
      });
  };
  const ProfileCurrency = () => {
    instanceOfAxios.get(`profileById/${id}`,{
      headers: {
        "x-access-token": getItemFromLocalStorage("token"),
      },
    }).then((res: any) => {
      setCurrency(res.data.currency);
      currencyChange(res.data.currency);
    });
  };

  const { Panel } = Collapse;

  const faqStructuredData =
  [
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "name":t("faq.0basic-questions"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.00q"),
        "text":t("faq.00q"),
        "answerCount":"00q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.00a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text": t("faq.00a1"),
              "url": null
            },
            {
              "@type": "Answer",
              "text": t("faq.00a2"),
              "url": null
            },
            {
              "@type": "Answer",
              "text": t("faq.00a3"),
              "url": null
            }
          ]  
        
      },      
      {
        "@type": "Question",
        "name":t("faq.01q"),
        "text":t("faq.01q"),
        "answerCount":"01q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.00a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":   t("faq.01a1"),
              "url": null
            },
            {
              "@type": "Answer",
              "text": t("faq.01a2"),
              "url": null
            },        
          ]  
        
      },      

    ]
  },
  {
    "name":t("faq.1rarity"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.10q"),
        "text":t("faq.10q"),
        "answerCount":"10q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.10a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text": t("faq.10a1"),
              "url": null
            },       
          ]  
        
      },      
      {
        "@type": "Question",
        "name":t("faq.13q"),
        "text":t("faq.13q"),
        "answerCount":"13q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.13a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":  t("faq.13a1"),
              "url": null
            },
            {
              "@type": "Answer",
              "text": t("faq.13a2"),
              "url": "https://rarity.market/genesis"
            },        
          ]  
        
      },      
      {
        "@type": "Question",
        "name":t("faq.11q"),
        "text":t("faq.11q"),
        "answerCount":"11q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.11a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":  t("faq.11a1"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":  t("faq.11a2"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":   t("faq.11a3"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":   t("faq.11a4"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":   t("faq.11a5"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":   t("faq.11a6"),
              "url": null
            },               
          ]  
        
      },      
      {
        "@type": "Question",
        "name":t("faq.12q"),
        "text":t("faq.12q"),
        "answerCount":"12q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.12a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":  t("faq.12a1"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":  t("faq.12a2"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":   t("faq.12a3"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":    t("faq.12a4"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":   t("faq.12a5"),
              "url": null
            },               
            {
              "@type": "Answer",
              "text":   t("faq.12a6"),
              "url": null
            },               
          ]  
        
      },      

    ]
  },
  {
    "name":t("faq.2wallet"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.20q"),
        "text":t("faq.20q"),
        "answerCount":"20q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.20a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text": t("faq.20a1"),
              "url": null
            },       
            {
              "@type": "Answer",
              "text":t("faq.20a2"),
              "url": null
            },       
          ]  
        
      },      
      {
        "@type": "Question",
        "name":t("faq.21q"),
        "text":t("faq.21q"),
        "answerCount":"21q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.21a1"),
            "url":"https://xport.al/referral/e7a2n8vbpl"
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":  t("faq.21a1"),
              "url": "https://xport.al/referral/e7a2n8vbpl"
            },
            {
              "@type": "Answer",
              "text":t("faq.21a2"),
              "url": "https://docs.multiversx.com/wallet/wallet-extension/",
            },        
            {
              "@type": "Answer",
              "text":t("faq.21a3"),
              "url":"https://wallet.multiversx.com/create",
            },        
            {
              "@type": "Answer",
              "text":t("faq.21a4"),
              "url":"https://shop.ledger.com?r=402456732958&tracker=Rarity.market",
            },        
          ]  
        
      }, 
    ]
  },
  {
    "name":t("faq.3collections"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.30q"),
        "text":t("faq.30q"),
        "answerCount":"30q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text": t("faq.30a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":  t("faq.30a1"),
              "url": null
            },       
            {
              "@type": "Answer",
              "text":t("faq.30a2"),
              "url": null
            },       
          ]  
        
      }, 
    ]
  },
  {
    "name":t("faq.4collectionTypes"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.40q"),
        "text":t("faq.40q"),
        "answerCount":"40q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text": t("faq.40a1"),
            "url":"/collection"
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":  t("faq.40a1"),
              "url": "/collection"
            },       
            {
              "@type": "Answer",
              "text":t("faq.40a2"),
              "url": "/collection"
            },       
            {
              "@type": "Answer",
              "text": t("faq.40a3"),
              "url": "/collection"
            },       
            {
              "@type": "Answer",
              "text": t("faq.40a4"),
              "url": "/collection"
            },       
          ]  
        
      }, 
    ]
  },
  {
    "name":t("faq.5items"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.50q"),
        "text":t("faq.50q"),
        "answerCount":"50q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.50a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.50a1"),
              "url":null
            },        
          ]  
        
      }, 
    ]
  },
  {
    "name":t("faq.6nft"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.60q"),
        "text":t("faq.60q"),
        "answerCount":"60q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.60a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.60a1"),
              "url":null
            },        
          ]  
        
      }, 
    ]
  },
  {
    "name":t("faq.7sft"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.70q"),
        "text":t("faq.70q"),
        "answerCount":"70q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.70a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.70a1"),
              "url":null
            },        
            {
              "@type": "Answer",
              "text":t("faq.70a2"),
              "url":null
            },        
            {
              "@type": "Answer",
              "text": t("faq.70a3"),
              "url":null
            },        
          ]  
        
      }, 
    ]
  },
  {
    "name":t("faq.8auction"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.80q"),
        "text":t("faq.80q"),
        "answerCount":"80q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.80a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.80a1"),
              "url":null
            },       
          ]  
        
      }, 
      {
        "@type": "Question",
        "name":t("faq.81q"),
        "text":t("faq.81q"),
        "answerCount":"81q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.81a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.81a1"),
              "url":null
            },       
          ]  
        
      }, 
      {
        "@type": "Question",
        "name":t("faq.82q"),
        "text":t("faq.82q"),
        "answerCount":"82q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.82a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.82a1"),
              "url":null
            },       
            {
              "@type": "Answer",
              "text":t("faq.82a2"),
              "url":null
            },       
            {
              "@type": "Answer",
              "text":t("faq.82a3"),
              "url":null
            },       
            {
              "@type": "Answer",
              "text":t("faq.82a4"),
              "url":null
            },       
          ]  
        
      }, 
    ]
  },
  {
    "name":t("faq.9offer"),
    "mainEntity": [
      {
        "@type": "Question",
        "name": t("faq.90q"),
        "text": t("faq.90q"),
        "answerCount":"90q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.90a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.90a1"),
              "url":null
            },       
          ]  
        
      }, 
    ]
  },
  {
    "name":t("faq.10transfer"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.100q"),
        "text":t("faq.100q"),
        "answerCount":"100q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.100a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.100a1"),
              "url":null
            },       
            {
              "@type": "Answer",
              "text": t("faq.100a2"),
              "url":null
            },       
            {
              "@type": "Answer",
              "text":  t("faq.100a3"),
              "url":"/faq?search=80q&tab=" + t("faq.8auction"),
            },       
          ]  
        
      }, 
    ]
  },
  {
    "name":t("faq.200elrond"),
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.200q"),
        "text":t("faq.200q"),
        "answerCount":"200q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.200a0"),
            "url":"https://multiversx.com"
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.200a0"),
              "url":"https://multiversx.com"
            },       
            {
              "@type": "Answer",
              "text": t("faq.200a1"),
              "url": "https://multiversx.com",
            },
            {
              "@type": "Answer",
              "text": t("faq.200a2"),
              "url": "https://explorer.multiversx.com/validators",
            },
            {
              "@type": "Answer",
              "text": t("faq.200a3"),
              "url": "https://linktr.ee/elrond",
            },
            {
              "@type": "Answer",
              "text": t("faq.200a4"),
              "url": null,
            },
            {
              "@type": "Answer",
              "text": t("faq.200a5"),
              "url": "https://growth.multiversx.com/",
            },
            {
              "@type": "Answer",
              "text": t("faq.200a6"),
              "url": "https://files.multiversx.com/multiversx-multiversxtem.pdf",
            },
            {
              "@type": "Answer",
              "text": t("faq.200a7"),
              "url": null,
            },
            
          ]  
        
      }, 
    ]
  },
  {
    "name":"EGLD",
    "mainEntity": [
      {
        "@type": "Question",
        "name":t("faq.210q"),
        "text":t("faq.210q"),
        "answerCount":"210q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.210a1"),
            "url":null
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.210a1"),
              "url":null
            }, 
            {
              "@type": "Answer",
              "text":t("faq.210a2"),
              "url":null
            }, 
            {
              "@type": "Answer",
              "text": t("faq.210a3"),
              "url":`/${i18n.language}/staking`
            }, 
          ]  
        
      }, 
      {
        "@type": "Question",
        "name":t("faq.220q"),
        "text":t("faq.220q"),
        "answerCount":"220q",
        "acceptedAnswer":
          {
            "@type": "Answer",
            "text":t("faq.220a1"),
            "url":"https://wallet.multiversx.com",
          },     
          "suggestedAnswer": [
            {
              "@type": "Answer",
              "text":t("faq.220a1"),
              "url":"https://wallet.multiversx.com",
            }, 
            {
              "@type": "Answer",
              "text":t("faq.220a2"),
              "url":null
            }, 
            {
              "@type": "Answer",
              "text":  t("faq.220a3"),
              "url":"https://buy.multiversx.com"
            }, 
            {
              "@type": "Answer",
              "text":  t("faq.220a4"),
              "url":"https://rarity.market"
            }, 
          ]  
        
      }, 
    ]
  },
] 
  const handleOk = () => {
    setTextResultVisible(false);
  };

  const handleCancel = () => {
    setTextResultVisible(false);
  };

  const scroll = (pos: any) => {
    window.scroll(0, pos - 150);
  };

  useEffect(() => {
    var idSearch = document
      .getElementById(`${filterSearch}`)
      ?.getBoundingClientRect().top;

    scroll(idSearch);
    if (!address) {
      currencyChange(currency);
    } else {
      ProfileCurrency();
    }
  }, []);

  return (
    <>
       <script type="application/ld+json">
        {JSON.stringify(faqStructuredData)}
      </script>
      <Helmet>
          <title>Rarity.market | FAQ</title>
        </Helmet>
      <div className="faq-body-container">
        <Row>
          <Col>
            <a href="/" className="breadcrumbPath">
              <span
                style={{
                  cursor: "pointer",
                }}>
                {t("Home")}
              </span>
            </a>
            <span>{">"}</span>
          </Col>
          <Col>
            <span className="primary-color">{t("home.faq")}</span>
          </Col>
        </Row>
        <div style={{ marginTop: "20px" }}>
          <h4>{t("home.menu-faq")}</h4>

          <Row>
            <Col span={12}>
              <p style={{ marginTop: "20px",  }}>
                {t("faq.Learn-answers-to-frequently-asked-questions-on-Rarity")}
              </p>
            </Col>
            <Col span={12}>
              <p style={{ marginTop: "20px", textAlign: "center" }}>
               1 EGLD = {Number(convert).toFixed(2)} {currency.toLocaleUpperCase()} 
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <div className="collapset-faq container">
        {faqStructuredData.map((panel, keyParent) => (
             <div id={panel.name} key={keyParent}>
              <Collapse
                    expandIconPosition={"right"}
                    defaultActiveKey={[`${activeTab}`]}
                    expandIcon={({ isActive }) => (
                      <DownCircleFilled
                        style={{ fontSize: "20px" }}
                        rotate={isActive ? 180 : 0}
                      />
                    )}>
             
                    <Panel
                      header={panel.name}
                      key={panel.name}
                      style={{ marginBottom: "20px" }}>   
                      {panel["mainEntity"].map((item,key)=>(
                      <div key={key}>
                {textResultVisible && (
                          <CustomModal
                            width="400px"
                            visible={textResultVisible}
                            closable={true}
                            handleOk={handleOk}
                            handleCancel={handleCancel}>
                            <div>{item.text} </div>
                          </CustomModal>
                        )}
                        <img src={QuestionMark} alt="question-mark" />
                        <span
                          style={{ marginLeft: "20px", cursor: "pointer" }}
                          onClick={() => setTextResultVisible(true)}
                          id={`${item.answerCount}`}>
                          {item.text}
                        </span>
                        <ul>
                          {item.suggestedAnswer.map((question,keyQute)=>(
                            <>
                              <li className={`response ${question.url!==null?"collapset-faq-link-ul":"collapset-faq-ul"}`} key={keyQute}>
                                {question.url!== null ? (
                                  <a target="_blank" href={question.url}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:question.text ,
                                      }}
                                    />
                                  </a>
                                ) : (
                                  <div
                                    dangerouslySetInnerHTML={{ __html: question.text }}
                                  />
                                )}
                              </li>
                            </>

                          ))}
                        </ul>
                      </div>               
                         ))}
                      </Panel>
                     
                    </Collapse>
             </div>
        ))}
        </div>
      </Row>
    </>
  );
};
export default PageFaq;
