import { useTranslation } from "react-i18next";
import {useState } from "react";
import { Avatar, Divider, List, Tooltip } from "antd";
import currencyLogo from "../../../../../assets/images/EGLD.svg";
import mediumIcon from "../../../../../assets/images/medium.svg";
import twitterIcon from "../../../../../assets/images/twitter.svg";
import telegramIcon from "../../../../../assets/images/telegram.svg";
import discordIcon from "../../../../../assets/images/discord.svg";
import instagramIcon from "../../../../../assets/images/icon instagram.svg";
import websiteIcon from "../../../../../assets/images/browser.svg";
import ProfileIcon from "../../../../../assets/images/Icon-user-alt.svg";
import "./profileSider.less";
import DateCustomi18n from "../../../../helpers/datei18n";
import useUserNaming from "../../../../helpers/useUserNaming";
import IconButton from "../../../../components/buttons/IconButton/IconButton";
import InviteUser from "../../../../../assets/images/inviteUser.svg";
import IconPrivateAccess from "../../../../../assets/images/private.svg";
import { useContext } from "../../../../context";
import CustomModal from "../../../../components/modal/customModal";
import UserFullDisplay from "./userFullDisplay";
import useSocialMediaLink from "../../../../helpers/useSocialMediaLink";
import CustomAvatar from "../../../../components/avatar/customAvatar";
import getSocialMediaUsername from "../../../../helpers/getSocialMediaUsername";
import { discordRegexUserName, instagramRegex,meduimRegexUserName,telegramRegex, twitterRegex } from "../../../../helpers/regularExpressions";

export interface ProfileSiderProps {
  profile?: Boolean;
  stat?: Boolean;
  isCreator?: any;
  creator?: any;
  totalCountContributer?: any;
  colDetails?: any;
  infosProfile?: any;
  checked?: any;
  erdAdressConnect?: any;
  collectionType?: any;
  collection?: any;
  lenghtNft?: any;
  lenghSft?: any;
  privateAccess?: any;
  contributers?: any;
  totalCountAccessPrivate?: any;
  idCol?: any;
  creatorId?: any;
}

const ProfileSider = ({
  totalCountContributer,
  privateAccess,
  totalCountAccessPrivate,
  profile,
  lenghtNft,
  lenghSft,
  contributers,
  collection,
  isCreator,
  infosProfile,
  collectionType,
}: ProfileSiderProps) => {
  const { t } = useTranslation();
  const { address } = useContext();
  const profileDisplay = useUserNaming(infosProfile);
  const [contributersModalVisible, setContributersModalVisible] = useState(
    false
  );
  const mediumLink = useSocialMediaLink(
    `${profile ? infosProfile?.medium : collection?.medium}`,
    "https://medium.com/"
  );
  const twitterLink = useSocialMediaLink(
    `${profile ? infosProfile?.twitter : collection?.twitter}`,
    "https://twitter.com/"
  );
  const telegramLink = useSocialMediaLink(
    `${profile ? infosProfile?.telegram : collection?.telegram}`,
    "https://t.me/"
  );
  const discordLink = useSocialMediaLink(
    `${profile ? infosProfile?.discord : collection?.discord}`,
    "https://discord.com/"
  );
  const intaLink = useSocialMediaLink(
    infosProfile?.instagram,
    "https://instagram.com/"
  );  

  const privateCollection =
    collection?.collectionType?.collection_type_name_i18ntag === "private";

  function intToString(value: number) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 !== 0) {
      shortValue = parseFloat(shortValue.toFixed(1));
    }
    return shortValue + suffixes[suffixNum];
  }

  const handleContributor = () => {
    //new implementation in the future
  };
  const handlePrivate = () => {
    //new implementation in the future
  };  

  const SocialIconLink = (color: string, icon: string, link: string) => {
    return (
      <div
        className="collection-profile-social-holder"
        style={{ backgroundColor: color }}
        onClick={() => window.open(link)}
      >
        <img alt="social icon" src={icon} className="collection-profile-social-icon" />
      </div>
    );
  };

  const substName = (word: any) => {
    if (word && word.length > 30) {
      return word.substring(0, 23) + "...";
    } else {
      return word;
    }
  };
  const toolTiped = (title: any,link:string, className: any) => {  
      return (
        <Tooltip title={link} placement="left">
          <h5 className={className}>{substName(title)}</h5>
        </Tooltip>
      );   
  };

  return (
    <>
      <div className={profile ? "" : "profile-sider-container-collection"}>
        {profile === true ? (
          <div className="profile-sider-container">
            <>
              <div className="profil-side-about">
                <h5 className="text-regular-font text-regular">
                  {t("collection-page.about")}
                </h5>
              </div>
              {infosProfile?.lastname || infosProfile?.name ? (
                <div className="profil-side-about">
                  <h5 className="text-regular-font">
                    {SocialIconLink(
                      "#ec6321",
                      ProfileIcon,
                      window.location.href
                    )}
                  </h5>
                  {toolTiped(profileDisplay,profileDisplay, "text-regular-font")}
                </div>
              ) : (
                ""
              )}
              {infosProfile?.telegram ? (
                <div className="profil-side-about">
                  <h5 className="text-regular-font">
                    {SocialIconLink(
                      "#0088CC",
                      telegramIcon,
                      infosProfile?.telegram
                    )}
                  </h5>
                  <a target="_blank" href={telegramLink}>
                    {toolTiped(getSocialMediaUsername(infosProfile?.telegram,telegramRegex),infosProfile?.telegram, "text-regular-font")}
                  </a>
                </div>
              ) : (
                ""
              )}
              {infosProfile?.twitter ? (
                <div className="profil-side-about">
                  <h5 className="text-regular-font">
                    {SocialIconLink(
                      "#00ACEE",
                      twitterIcon,
                      infosProfile?.twitter
                    )}
                  </h5>
                  <a target="_blank" href={twitterLink}>
                    {toolTiped(getSocialMediaUsername(infosProfile?.twitter,twitterRegex),infosProfile?.twitter, "text-regular-font")}
                  </a>
                </div>
              ) : (
                ""
              )}
              {infosProfile?.instagram ? (
                <div className="profil-side-about">
                  <h5 className="text-regular-font">
                    {SocialIconLink(
                      "#f3308e",
                      instagramIcon,
                      infosProfile?.instagram
                    )}
                  </h5>
                  <a target="_blank" href={intaLink}>
                    {toolTiped(getSocialMediaUsername(infosProfile?.instagram,instagramRegex),infosProfile?.instagram, "text-regular-font")}
                  </a>
                </div>
              ) : (
                ""
              )}
              {infosProfile?.medium ? (
                <div className="profil-side-about">
                  <h5 className="text-regular-font">
                    {SocialIconLink("black", mediumIcon, infosProfile?.medium)}
                  </h5>
                  <a target="_blank" href={mediumLink}>
                    {toolTiped(getSocialMediaUsername(infosProfile?.medium,meduimRegexUserName),infosProfile?.medium, "text-regular-font")}
                  </a>
                </div>
              ) : (
                ""
              )}
              {infosProfile?.discord ? (
                <div className="profil-side-about">
                  <h5 className="text-regular-font">
                    {SocialIconLink(
                      "#2676e3",
                      websiteIcon,
                      infosProfile?.discord
                    )}
                  </h5>
                  <div className="collection-profile-price-holder">
                    <a target="_blank" href={discordLink}>
                      {toolTiped(getSocialMediaUsername(infosProfile?.discord,discordRegexUserName,"discord"),infosProfile?.discord, "text-regular-font")}
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}

              {infosProfile?.country ? (
                <div className="profile-sider-row">
                  <h5 className="text-regular-font">{t("profile.country")}</h5>
                  <div className="collection-profile-price-holder">
                    {toolTiped(infosProfile?.country,infosProfile?.country, "text-regular-font")}
                  </div>
                </div>
              ) : (
                ""
              )}
              {infosProfile?.location ? (
                <div className="profile-sider-row">
                  <h5 className="text-regular-font">{t("profile.location")}</h5>
                  <div className="collection-profile-price-holder">
                    {toolTiped(infosProfile?.location,infosProfile?.location, "text-regular-font")}
                  </div>
                </div>
              ) : (
                ""
              )}
              <Divider />
              {infosProfile?.created_at && (
                <>
                  <h5 className="member-since text-regular-font">
                    {" "}
                    {t("collection-page.member-since")}
                  </h5>
                  <h5 className="text-regular-font collection-profile-date-container">
                    <DateCustomi18n
                      date={infosProfile?.created_at}
                      format="long"
                    />
                  </h5>
                </>
              )}
            </>
          </div>
        ) : (
          <div className="collection-sider-container-collection">
            <div className="profile-sider-row">
              <h5 className="text-regular-font">
                {t("collection-page.owner")}
              </h5>
            </div>
            <div className="d-flex">
              <UserFullDisplay user={collection?.creator} />
            </div>
            {totalCountContributer === 0 ? (
              ""
            ) : (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.contributer")}
                </h5>

                <h5 className="text-regular-font">{totalCountContributer}</h5>
              </div>
            )}

            {!isCreator && totalCountContributer === 0 ? (
              ""
            ) : (
              <div className="profile-sider-row">
                {totalCountContributer === 0 ? (
                  <div></div>
                ) : (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setContributersModalVisible(true)}
                  >
                    <Avatar.Group
                      maxCount={7}
                      maxStyle={{
                        color: "white",
                        backgroundColor: "#EC6321",
                        cursor: "pointer",
                      }}
                    >
                      {contributers.map((el: any, index: number) => {
                        if (index === contributers?.length) {
                          <Tooltip title="Ant User" placement="top" key={index}>
                            <CustomAvatar
                              avatarImage={el?.profile?.avatar}
                              extensionImage={el?.profile?.avatar}
                            />
                          </Tooltip>;
                        } else {
                          return (
                            <CustomAvatar
                              avatarImage={el?.profile?.avatar}
                              extensionImage={el?.profile?.avatar}
                            />
                          );
                        }
                      })}
                    </Avatar.Group>
                  </div>
                )}
                {isCreator ? (
                  <h5 className="text-regular-font">
                    {" "}
                    <Tooltip title={t("common.coming-soon")}>
                      <div className="profilesider-icon">
                        <IconButton
                          icon={InviteUser}
                          onClick={handleContributor}
                        />
                      </div>
                    </Tooltip>
                  </h5>
                ) : (
                  ""
                )}
              </div>
            )}
            {contributersModalVisible && (
              <CustomModal
                width={"30rem"}
                closable={true}
                visible={contributersModalVisible}
                handleOk={() => setContributersModalVisible(false)}
                handleCancel={() => setContributersModalVisible(false)}
              >
                <>
                  <div className="profile-sider-row">
                    <h4 className="primary-color">
                      {t("collection-page.contributer")}
                    </h4>
                  </div>
                  <List
                    itemLayout="horizontal"
                    dataSource={contributers}
                    renderItem={(contributer: any) => (
                      <List.Item>
                        <UserFullDisplay user={contributer?.profile} />
                      </List.Item>
                    )}
                  />
                </>
              </CustomModal>
            )}
            {/* <div className="profile-sider-row align-items-center">
              <h5 className="text-regular-font">
                {t("collection-page.homepage-link")}
              </h5>
              <img src={homeIcon}  />
              <a href={collection?.homepage2} target="_blank">
                <h5 className="text-regular-font">
                  {useWorstDisplay(collection?.homepage2, 20)}
                </h5>
              </a>
            </div> */}
            {privateCollection ? (
              <>
                {totalCountAccessPrivate === 0 ? (
                  ""
                ) : (
                  <div className="profile-sider-row">
                    <h5 className="text-regular-font">
                      {t("collection-page.private-access-invitation")}
                    </h5>
                    <h5 className="text-regular-font">
                      {totalCountAccessPrivate}
                    </h5>
                  </div>
                )}

                <div className="profile-sider-row">
                  {totalCountAccessPrivate === 0 ? (
                    <div></div>
                  ) : (
                    <Avatar.Group
                      maxCount={7}
                      maxStyle={{
                        color: "white",
                        backgroundColor: "#EC6321",
                        cursor: "pointer",
                      }}
                    >
                      {privateAccess.map((el: any, index: number) => {
                        if (index === privateAccess?.length) {
                          <Tooltip title="Ant User" placement="top" key={index}>
                            <CustomAvatar
                              avatarImage={el?.profile?.avatar}
                              extensionImage={el?.profile?.avatar}
                            />
                          </Tooltip>;
                        } else {
                          return (
                            <CustomAvatar
                              avatarImage={el?.profile?.avatar}
                              extensionImage={el?.profile?.avatar}
                            />
                          );
                        }
                        <CustomAvatar
                          avatarImage={el?.profile?.avatar}
                          extensionImage={el?.profile?.avatar}
                        />;
                      })}
                    </Avatar.Group>
                  )}
                  {isCreator ? (
                    <h5 className="text-regular-font">
                      {" "}
                      <Tooltip title={t("common.coming-soon")}>
                        <div className="profilesider-icon">
                          <IconButton
                            icon={IconPrivateAccess}
                            onClick={handlePrivate}
                          />
                        </div>
                      </Tooltip>
                    </h5>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}

            <div className="profile-sider-row">
              <h5 className="text-regular-font">{t("collection-page.type")}</h5>
              <h5 className="text-regular-font">{collectionType}</h5>
            </div>
            <div className="profile-sider-row">
              <h5 className="text-regular-font">
                {t("collection-page.num-nft")}
              </h5>
              <h5 className="text-regular-font">{lenghtNft}</h5>
            </div>
            <div className="profile-sider-row">
              <h5 className="text-regular-font">
                {t("collection-page.num-sft")}
              </h5>
              <h5 className="text-regular-font">{lenghSft}</h5>
            </div>
            {collection?.rank && (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.rank")}
                </h5>
                <h5 className="text-regular-font">{collection?.rank}</h5>
              </div>
            )}
            {collection.average_price && (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.average-price")}
                </h5>
                <div className="collection-profile-price-holder">
                  <h5 className="text-regular-font">
                    {collection.average_price}
                  </h5>
                  <img alt="currency" src={currencyLogo} className="currency-logo" />
                </div>
              </div>
            )}
            {collection.volume_traded && (
              <div className="profile-sider-row">
                <h5 className="text-regular-font">
                  {t("collection-page.volume-traded")}
                </h5>
                <div className="collection-profile-price-holder">
                  <h5 className="text-regular-font">
                    {intToString(collection.volume_traded)}
                  </h5>
                  <img alt="currency" src={currencyLogo} className="currency-logo" />
                </div>
              </div>
            )}
            <div className="collection-profile-social-links-container">
              {collection?.medium ? (
                <Tooltip title={mediumLink}>
                  {SocialIconLink("black", mediumIcon, mediumLink)}{" "}
                </Tooltip>
              ) : (
                ""
              )}
              {collection?.telegram ? (
                <Tooltip title={telegramLink}>
                  {SocialIconLink("#0088CC", telegramIcon, telegramLink)}{" "}
                </Tooltip>
              ) : (
                ""
              )}
              {collection?.twitter ? (
                <Tooltip title={twitterLink}>
                  {SocialIconLink("#00ACEE", twitterIcon, twitterLink)}{" "}
                </Tooltip>
              ) : (
                ""
              )}
              {collection?.discord ? (
                <Tooltip title={discordLink}>
                  {" "}
                  {SocialIconLink("#7289da", discordIcon, discordLink)}{" "}
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            <Divider />
            <h5 className="text-regular-font collection-profile-date-container">
              {t("collection-page.created")}&nbsp;{" "}
              <DateCustomi18n date={collection?.created_at} format="long" />
            </h5>
          </div>
        )}
      </div>
    </>
  );
};
export default ProfileSider;
