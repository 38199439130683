import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext } from '../../../context';
import { useGlobalContext } from '../../../context/globalContext';

const CustomMenu = observer(({ mobile, setMenuVisible }: { mobile: boolean; setMenuVisible?: any }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const  navigate = useNavigate();
	const { address } = useGetAccountInfo();
	const { notificationsStore } = useGlobalContext();

	const menuList = [
		{
			text: 'home.menu-browse',
			path: '/explore',
			isReady: true,
			externalWebsite: false
		},
		{
			text: 'common.activity',
			path: '/activity',
			isReady: true,
			externalWebsite: false
		},
		{
			text: 'home.whitelabel',
			path: 'https://fourtytwo.com/white-label-dapp',
			isReady: true,
			externalWebsite: true
		},
		{
			text: 'home.menu-blog',
			path: 'https://medium.com/@rarity_market',
			isReady: true,
			externalWebsite: true
		}
	];

	useEffect(() => {
		if (sessionStorage.getItem('prevPath') && address) {
			window.location.pathname = sessionStorage.getItem('prevPath') || '/';
			sessionStorage.removeItem('prevPath');
		}
	}, []);

	useEffect(
		() => {
			if (!address && location.pathname !== '/auth') {

				sessionStorage.setItem('prevPath', location.pathname);
			}

			sessionStorage.setItem('currentPath', location.pathname);

		},
		[location.pathname]
	);

	const redirectPage = (path: string, externalWebsite: boolean) => {
		if (!externalWebsite) {
			window.scrollTo(0, 0);
			navigate(path);
			notificationsStore.setMenuVisible(false);
		} else {
			window.open(path, '_blank');
			notificationsStore.setMenuVisible(false);
		}
	};

	const menuItem = (text: string, path: string, isReady: boolean, externalWebsite: boolean, key: any) => {
		if (isReady) {
			return (
				<li className={!mobile ? 'custom-menu-li' : 'mobile-menu-li'} key={key}>
					<a
						href={path}
						onClick={(e) => {
							e.preventDefault();
						}}>
						<h6
							className={`${!mobile ? 'custom-menu-item' : 'mobile-menu-item'} ${localStorage.getItem(
								'theme'
							) === 'dark'
								? 'dark-text'
								: 'light-text'}`}
							onClick={() => redirectPage(path, externalWebsite)}>
							{t(`${text}`)}
						</h6>
					</a>
				</li>
			);
		} else {
			return (
				<Tooltip title={t('common.coming-soon')} key={key}>
					<li className={!mobile ? 'custom-menu-li' : 'mobile-menu-li '}>
						<h6
							className={`${!mobile
								? 'custom-menu-item disabled-link'
								: 'mobile-menu-item disabled-link'} ${localStorage.getItem('theme') === 'dark'
									? 'dark-text'
									: 'light-text'}`}>
							{t(`${text}`)}
						</h6>
					</li>
				</Tooltip>
			);
		}
	};

	return (
		<ul className={!mobile ? 'custom-menu' : 'mobile-menu-drawer'}>
			{menuList.map((el: any, key: number) => {
				return menuItem(el.text, el.path, el.isReady, el.externalWebsite, key);
			})}
		</ul>
	);
});

export default CustomMenu;
