import { useEffect, useState } from "react";
import { Image } from "antd";
import "./zoomableImage.less";

interface ZoomableImageProps {
  photoSrc: string;
  customImageClassname: string;
}

const ZoomableImage = ({
  photoSrc,
  customImageClassname,
}: ZoomableImageProps) => {
  const [keepRelationImage, setKeepRelation] = useState("");

  useEffect(() => {
    if (photoSrc !== "") {
      let splitSrc = photoSrc?.split("__");
      setKeepRelation(
        `${splitSrc[0]}__${splitSrc[1]}__keepRelation.${
          splitSrc[2]?.split(".")[1]
        }`
      );
    }
  }, [photoSrc]);

  return (
    <>
      <Image
        src={photoSrc}
        placeholder={
          <Image
            preview={false}
            src="https://www.cronobierzo.es/wp-content/uploads/2020/01/no-image.jpg"
          />
        }
        preview={{
          src: keepRelationImage,
        }}
      />
    </>
  );
};

export default ZoomableImage;
