import React from "react";
import { Button } from "antd";

import "./index.less";
import { ILandingButton } from "../buttons.interfces";

function LandingButton(props: ILandingButton) {
  const { text, onClick } = props;
  return (
    <Button
      className="btn-landing-page landing-crypto-animals-btn"
      onClick={onClick}>
      {text}
    </Button>
  );
}

export default LandingButton;
