import React, { useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import { useTranslation } from 'react-i18next';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import gltObject from '../../../../../assets/3d/object.glb';
import { OpenErrorNotification } from '../../../../components/toasts/openToast';

function GltfEmptyObject({ isNotVaildObject }) {
	const gltfEmpty = useLoader(GLTFLoader, gltObject);
	const { t } = useTranslation();

	useEffect(
		() => {
			if (isNotVaildObject) {
				OpenErrorNotification(t('profile.error'), t('profile.error-desc'), 3);
			}
		},
		[ isNotVaildObject ]
	);

	return <primitive object={gltfEmpty.scene} />;
}

export default GltfEmptyObject;
