import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ButtonTab from "../ButtonTab/buttonTab";
import "./collectionTab.less";

interface props {
  typeCollections: string;
  setTypeCollections: Dispatch<SetStateAction<string>>;
  children: any;
}

const CollectionTab = ({
  typeCollections,
  setTypeCollections,
  children,
}: props) => {
  const { t } = useTranslation();

  return (
    <div className="collection-tab">
      <div className="buttons-tab">
        <div className="tab-button">
          {/* <ButtonTab
            onClick={() => setTypeCollections("existing-collection")}
            type={
              typeCollections === "existing-collection"
                ? "primary"
                : "secondary"
            }
          >
            {t("collection-page.existing-collection")}
          </ButtonTab>
        </div>
        <div className="tab-button">
          <ButtonTab
            onClick={() => setTypeCollections("new-collection")}
            type={
              typeCollections === "new-collection" ? "primary" : "secondary"
            }
          >
            {t("collection-page.new-collection")}
          </ButtonTab> */}
        </div>
      </div>
      {children}
    </div>
  );
};

export default CollectionTab;
