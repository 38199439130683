import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../../assets/images/rarityLogo.svg";
import twitter from "../../../../assets/images/twitterBlue.svg";
import telegramAnn from "../../../../assets/images/telegramSound.svg";
import telegramChat from "../../../../assets/images/telegramChat.svg";
import youtube from "../../../../assets/images/youtube.svg";
import instagram from "../../../../assets/images/instagramIcon.svg";
import medium from "../../../../assets/images/mediumIcon.svg";
import FooterMenu from "./footerMenu";
import LanguageSelector from "./languageSelect/languageSelect";
import Newsletter from "./newsletter";
import { useContext } from "../../../context";
import { Col, Row } from "antd";
import SwitchMode from "./switchMode";
import { getItem } from "../../../storage/session";
import i18n from "../../../../i18n";

const Footer = () => {
  const { t } = useTranslation();
  const {  address } = useContext();

  const firstMenu = [
    // {
    //   name: t("home.whitelabel"),
    //   link: "https://fourtytwo.com/white-label-dapp",
    //   isReady: true,
    // },
    {
      name: t("home.Help-Center"),
      link: `/${i18n.language}/help-center`,
      isReady: true,
    },
    {
      name: t("home.imprint"),
      link: "/imprint",
      isReady: true,
    },
    {
      name: t("home.Data-protection"),
      link: "/privacy-policy",
      isReady: true,
    },
  ];

  const secondMenu = [
    {
      name: t("home.menu-egld-staking"),
      link: `/${i18n.language}/staking`,
      isReady: true,
    },
    {
      name: t("home.menu-faq"),
      link: `/${i18n.language}/faq`,
      isReady: true,
    },
  ];

  return (
    <div className="footer">
      <Row>
        <Col span={24} className="footer-content">
          <div className="footer-first-part">
            <div className="mobile-footer-part">
              {((address &&
                !JSON.parse(localStorage.getItem("user") || "{}")?.opt_in) ||
                !address) && <Newsletter />}
            </div>
            <img alt="logo" src={logo} />
            <h5
              className="text-regular-font footer-text"
              style={{ padding: "20px 0" }}>
              {t("home.headline1")}
            </h5>
            <div className="web-footer-part">
              <LanguageSelector />
              <div className="switch-button-container">
                <SwitchMode />
              </div>
            </div>
          </div>
          <div className="footer-second-part">
            <FooterMenu
              title={t("home.footer-quick-access")}
              menu={firstMenu}
            />
            <FooterMenu title={t("home.footer-community")} menu={secondMenu} />
          </div>
          <div className="footer-third-part">
            <div className="web-footer-part">
              {((address &&
                !JSON.parse(localStorage.getItem("user") || "{}")?.opt_in) ||
                !address) && <Newsletter />}
            </div>
            <div>
              <h4 className="footer-title">{`${t(
                "home.footer-follow-title"
              )} :`}</h4>
              <div className="social-media-block">
                <a href="https://t.me/RarityMarketAnn" target="_blank">
                  <img
                    alt="telegram-link-ann"
                    src={telegramAnn}
                    className="social-icon"
                  />
                </a>
                <a href="https://t.me/RarityMarketOfficial" target="_blank">
                  <img
                    alt="telegram-link-chat"
                    src={telegramChat}
                    className="social-icon"
                  />
                </a>
                <a href="https://twitter.com/Rarity_market" target="_blank">
                  <img
                    alt="twitter-link"
                    src={twitter}
                    className="social-icon"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCCaMl7hWdVN23ImMvr8w94g"
                  target="_blank">
                  <img
                    alt="youtube-link"
                    src={youtube}
                    className="social-icon"
                  />
                </a>
                <a href="https://instagram.com/Rarity_market" target="_blank">
                  <img
                    alt="instagram-link"
                    src={instagram}
                    className="social-icon"
                  />
                </a>
                <a href="https://medium.com/@rarity_market" target="_blank">
                  <img alt="medium-link" src={medium} className="social-icon" />
                </a>
              </div>
            </div>
            <div className="mobile-footer-part">
              <LanguageSelector />
              <div className="switch-button-container">
                <SwitchMode />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="footer-copyrights">
        <h5> {t("home.footer-copyright")}</h5>
      </div>
    </div>
  );
};

export default Footer;
