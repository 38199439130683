import { Col, Row } from "antd";
import React from "react";
import { useEffect } from "react";
import arrow from "../../../../../assets/images/activeArrow.svg";
import "./paginationButtons.less";

export interface PaginationButtonsProps {
  pageInfos: any;
  goPreviousPage?: () => void;
  goNextPage?: () => void;
}

const PaginationButtons = ({
  pageInfos,
  goPreviousPage,
  goNextPage,
}: PaginationButtonsProps) => {
  useEffect(() => {}, [pageInfos]);
  return (
    <Row>
      <Col span={24} className="pagination-butons-container">
        <div
          className={
            pageInfos?.hasPreviousPage
              ? "arrow-container"
              : "arrow-container low-opacity"
          }
          onClick={pageInfos?.hasPreviousPage ? goPreviousPage : () => {}}
        >
          <img
            alt="arrow"
            src={arrow}
            className={
              localStorage.getItem("language") === "ar" ? "" : "reverse-arrow"
            }
          />
        </div>
        <div
          className={
            pageInfos?.hasNextPage
              ? "arrow-container"
              : "arrow-container low-opacity"
          }
          onClick={pageInfos?.hasNextPage ? goNextPage : () => {}}
        >
          <img
            alt="arrow"
            src={arrow}
            className={
              localStorage.getItem("language") !== "ar" ? "" : "reverse-arrow"
            }
          />
        </div>
      </Col>
    </Row>
  );
};

export default PaginationButtons;
