import { message } from "antd";

export function beforeUploadAudio(
    file: any,
    typeMessage: string,
    sizeMessage: string
) {
    const isAcceptableType =
        file.type === "audio/mp3" ||
        file.type === "audio/mpeg" ||
        file.type === "audio/ogg" ||
        file.type === "audio/wav";
    if (!isAcceptableType) {
        message.error(typeMessage);
    }
    return isAcceptableType;
}
export function beforeUploadVideo(
    file: any,
    typeMessage: string,
    sizeMessage: string
) {
    const isAcceptableType =
        file.type === "video/mp4" || file.type === "video/webm";
    if (!isAcceptableType) {
        message.error(typeMessage);
    }
    return isAcceptableType;
}

export function beforeUpload3D(
    file: any,
    typeMessage: string,
    sizeMessage: string
) {
    const isAcceptableType =
        file.name.includes(".glb") ||
        file.name.includes(".gltf") ||
        file.name.includes(".fbx") ||
        file.name.includes(".obj");
    if (!isAcceptableType) {
        message.error(typeMessage);
    }
    return isAcceptableType;
}
