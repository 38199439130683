import * as React from 'react';
import { Input } from 'antd';
import './iconInput.less';
import DateCustomi18n from '../../../helpers/datei18n';

export interface IconInputProps {
	placeholder: string;
	icon: string;
	value: any;
	type: string;
	onClick: () => void;
}

const IconInput = ({ placeholder, icon, value, type, onClick }: IconInputProps) => {
	return (
		<div className="icon-input-container" onClick={onClick}>
			{/* <Input value={value} placeholder={placeholder} /> */}
			<div className="input-faker">
				{value ? type === 'date' ? (
					<DateCustomi18n date={value} format="medium" />
				) : (
					<h5>{value}</h5>
				) : (
					<h5 className="input-faker-placeholder">{placeholder}</h5>
				)}
			</div>
			<div className="input-icon-holder">
				<img alt="input icon" src={icon} className="input-icon" />
			</div>
		</div>
	);
};

export default IconInput;
