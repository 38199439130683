import React, { useRef } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

const FbxModel = ({ modelPath }) => {
	const mesh = useRef();
	useFrame(() => (mesh.current.rotation.y += 0.005));
	const fbx = useLoader(FBXLoader, modelPath);

	return <primitive ref={mesh} object={fbx} scale={30} />;
};

export default FbxModel;
