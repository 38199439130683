import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { landingReception } from "../../../helpers/communFunctions";
import "./collectorsLanding.less";

const CollectorsLanding = () => {
  const { t } = useTranslation();
  const languageParam = window.location.pathname?.split("/")[1];

  useEffect(() => {
    landingReception(window.location.pathname);
  }, []);
  return (
    <div>
      <h1>
        {t("landing-pages.collectors-landing.title") +
          `   (${languageParam} version)`}
      </h1>
    </div>
  );
};

export default CollectorsLanding;
