import { Tooltip } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import DateCustomi18n from "./datei18n";

export interface TimeI18nProps {
  time: string;
}

const TimeI18n = ({ time }: TimeI18nProps) => {
  const currentLang = localStorage.getItem("language") || "en";
  var customTime = moment
    .utc(time)
    .locale(currentLang)
    .utcOffset(new Date().getTimezoneOffset());
  var now = moment
    .utc()
    .locale(currentLang)
    .utcOffset(new Date().getTimezoneOffset());
  const [result, setResult] = useState("");
  const dateDisplay = (times: boolean) => {
    return (
      <>
        <DateCustomi18n date={customTime} format="medium" time={times} />
      </>
    );
  };

  useEffect(() => {
    if (now.isBefore(customTime)) {
      setResult(now.to(customTime));
    } else {
      setResult(customTime.from(now));
    }
  }, [currentLang, customTime]);

  return (
    <>
      <Tooltip title={dateDisplay(true)}>{result}</Tooltip>
    </>
  );
};

export default TimeI18n;
