import {  useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account'
import "../item action box/actionSwitch.less";
import CustomModal from "../../../../components/modal/customModal";
import instanceOfAxios from "../../../../../configAxios";
import { useContext, useDispatch } from "../../../../context";
import { setItem } from "../../../../storage/session";
import { logger } from "../../../../context/logger";
import useImageDisplay from "../../../../helpers/useImageDisplay";


export interface TransferGiftProps {
  item: any;
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

interface ITransfertGiftForm {
  address: string;
  message: string;
}

const TransferGift = ({
  item,
  visible,
  handleOk,
  handleCancel,
}: TransferGiftProps) => {
  const { t } = useTranslation();
  const { address, actionCode } = useContext();
  const  navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm<ITransfertGiftForm>();
  const { TextArea } = Input;
  const /*transactionSessionId*/ [, setTransactionSessionId] = useState<
      string | null
    >(null);
  // const { sendTransactions } = transactionServices;
  const [sendLoading, setSendLoading] = useState(false);

  const itemImage = useImageDisplay(item?.uri, "__t" + item?.uri_ext);

  const onFinish = async (values: ITransfertGiftForm) => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    } else {
      setSendLoading(true);
      instanceOfAxios
        .post(`send_gift/address/${address}/item/${item?.id}`, {
          receiver_address: values?.address,
          value: 0,
        })
        .then(async (res: any) => {
          if (item?.type === "item") {
            setSendLoading(false);
            window.location.reload();
          } else {
            // setItem("gift-reciever", values?.address);
            const customtransaction = {
              receiver: res?.data?.receiver,
              gasLimit: res?.data?.gas_limit,
              value: res?.data?.value ,
              data: res?.data?.data_example,
            };
            logger.warn(
              "****** send a transaction of send gift with : ",
              customtransaction
            );
            await refreshAccount();

            const { sessionId /*, error*/ } = await sendTransactions({
              transactions: customtransaction,
              transactionsDisplayInfo: {
                processingMessage: t("action-box.processing-gift-transaction"),
                errorMessage: t("action-box.gift-transaction-error"),
                successMessage: t("action-box.gift-transaction-success"),
              },
              redirectAfterSign: false,
            });
            if (sessionId != null) {
              setTransactionSessionId(sessionId);
              //here we gonna save an object that contains this sessionId along with the collection id and item id, so we can catch the transaction result by the sessionId and link it to the specific collection/item ...
              localStorage.setItem(
                "transactions-track",
                JSON.stringify([
                  ...JSON.parse(
                    localStorage.getItem("transactions-track") || "[]"
                  ),
                  {
                    itemId: item?.id,
                    collectionId: item?.collection?.id,
                    sessionId,
                    address,
                    actionCase: actionCode,
                    necessData: {
                      giftReciever: values?.address,
                      message: values.message,
                    },
                    sentData: customtransaction?.data || "",
                  },
                ])
              );
              //we save in context too, considering the case of no redirection , such as xPortal extension
              dispatch({
                type: "keepTrack",
                transactionsTrack: JSON.parse(
                  localStorage.getItem("transactions-track") || "[]"
                ),
              });
              handleCancel();
            }
          }
        })
        .catch((e: any) => {
          setSendLoading(false);
          handleOk();
        });
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="500px"
      closable={true}
      handleOk={handleOk}
      handleCancel={handleCancel}>
      <div>
        <div className="auction-modal-header">
          <h4>{t("collection-page.transfer-gift-title")}</h4>
          <h6 className="text-regular-font">
            {t("collection-page.transfer-gift-desc")}
          </h6>
        </div>
        <Form
          name="gift"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          initialValues={{ egld_gift: 0 }}>
          <div className="first-input-block">
            <h6>{t("collection-page.transfer-gift-input")}</h6>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: t("collection-page.recepient-address-required"),
                },
                () => ({
                  validator(_, value) {
                    if (
                      value !== "" &&
                      value.length === 62 &&
                      value.slice(0, 4) === "erd1"
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("collection-page.recepient-address-required")
                    );
                  },
                }),
              ]}>
              <Input
                placeholder={t("collection-page.transfer-gift-placeholder")}
              />
            </Form.Item>
          </div>
          {/* nft details block */}
          <div>
            <div className="nft-block">
              <h6>{t("collection-page.transferring-NFT")}</h6>
              <h5 className="text-regular-font">{item?.title}</h5>
            </div>
            <div className="tiny-image-block">
              <img
                alt="add icon"
                src={itemImage}
                className="add-item-photo-button-icon"
              />
              <h5 className="text-regular-font">{item?.token_identifier}</h5>
            </div>
          </div>
          {/* end nft details block */}
          {/* message block */}
          <div className="input-block">
            <div className="display-flex-block">
              <h6>{t("collection-page.refuse-message-title")}</h6>
              <h6 className="text-regular-font optional">
                {t("collection-page.optional-delivered")}
              </h6>
            </div>

            <Form.Item name="message">
              <TextArea
                placeholder={t("collection-page.refuse-message-placeholder")}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </Form.Item>
          </div>
          {/* end message block  */}
          <div>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                className="input-block"
                loading={sendLoading}>
                {t("collection-page.transfer")}
              </Button>
              <Button type="default" block onClick={handleCancel}>
                {t("common.cancel")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </CustomModal>
  );
};

export default TransferGift;
