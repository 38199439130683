import * as React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import useUserNaming from "../../../../helpers/useUserNaming";
import useImageDisplay from "../../../../helpers/useImageDisplay";

const UserFullDisplay = ({ user }: { user: any }) => {
  const nameDisplay = useUserNaming(user);
  const avatarDisplay = useImageDisplay(user?.avatar,'__m'+user?.avatar_ext);

  return (
    <Link to={"/profile-details/" + user?.id}>
      <div className="collection-owner-block">
        <Avatar src={avatarDisplay} />
        <span
          className="nameOwner"
          style={{
            marginLeft: "10px",
            marginTop: "4px",
          }}
        >
          {nameDisplay}
        </span>
      </div>
    </Link>
  );
};

export default UserFullDisplay;
