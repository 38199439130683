import { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Image } from 'antd';
import { observer } from "mobx-react-lite";
import ICPLAY from '../../../../assets/images/ic-play-button.png';
import ICAUDIO from '../../../../assets/images/ic-audio.png';
import select from "../../../../assets/images/icon-selected.png";
import egldIcon from "../../../../assets/images/EGLD.svg";
import threeRotate from "../../../../assets/images/3d-rotate.png";
import instanceOfAxios from "../../../../configAxios";
import LikeButton from "../../home/components/likeButton";
import useWorstDisplay from "../../../helpers/useWorstDisplay";
import useImageDisplay from "../../../helpers/useImageDisplay";
import NumberDisplayi18n from "../../../helpers/numbersi18n";
import moment from "moment";
import DateCustomi18n from "../../../helpers/datei18n";
import { getItemFromLocalStorage } from "../../../helpers/useLocalStorage";
import LoadingImage from "../../../../assets/images/loader-spinner.gif"
import { useGlobalContext } from "../../../context/globalContext";
import { EyeOutlined } from '@ant-design/icons';
import PrivewItemDetails from "../../collection/components/itemExtraFile/privewItemDetails";
import { OpenErrorNotification } from "../../../components/toasts/openToast";
export interface imageProps {
  data: any;
  userInfo: any;
  array: any;
  reloadApproval: (changedItem: any) => void;
  likeCollection?: () => void;
}

const Img = observer(({
  data,
  userInfo,
  reloadApproval,
}: imageProps) => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const  navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const image = useImageDisplay(data?.uri, "__b" + data?.uri_ext);
  const [approved, setApproved] = useState(
    data?.status === "approved" ? true : false
  );
  const [restTime, setRestTime] = useState<any>({});
  const [restTimePerDays, setRestTimePerDays] = useState<number>();
  var now = moment(new Date()); //todays date
  const { exploreStore } = useGlobalContext();

  useEffect(() => {
    if (data?.auctions?.length > 0) {
      const customDate = moment(
        new Date(data?.auctions[data?.auctions.length - 1].deadline * 1000)
      );

      if (now > customDate) {
        setRestTime(customDate);
      } else {
        let duration = moment.duration(customDate.diff(now));
        let durationPerDays = moment.duration(customDate.diff(now)).asDays();
        setRestTimePerDays(Math.floor(durationPerDays))
        setRestTime({
          years: duration.years(),
          months: duration.months(),
          days: duration.days(),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      }
    }
  }, [data]);

  const changeStatus = () => {
    instanceOfAxios
      .post(
        "change_status_item/" + data?.id + "/address/" + userInfo?.erd_address,
        {},
        {
          headers: {
            "x-access-token": getItemFromLocalStorage("token"),
          },
        }
      )
      .then((res) => {
        setApproved(!approved);
        setUpdate(!update);
        reloadApproval(res?.data);
      }).catch((error)=>{
        if(error?.response.status===401){
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-unauthorized-user"),
            3
          );                    
        }else{
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        } 
      });
  };

  useEffect(() => {
    setApproved(data?.status === "approved" ? true : false);
  }, [data?.status, update]);

  useEffect(() => {
    const timer = setTimeout(() => {
      exploreStore.setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [data]);

  const Spinner = (
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );    

  const handleRedirect=()=>{
    window.scrollTo(0, 0);
    if(data.type==="nft"){
      navigate(`/nft/${data?.token_identifier}`);
    }else {
      navigate(`/item-details/${data?.id}`);
    }
  }
  

  return (
    <>
      <div className="box">
        <div className="imgBx">
          {!exploreStore.loading ? ( 
            <a
            href={data.type==="nft"?`/nft/${data?.token_identifier}`:`/item-details/${data?.id}`}
            onClick={(e) => {
              e.preventDefault();
            }}
             className="imgBx"
          >
            {image!==undefined && image!==null &&
              <>
              {image.includes(".glb") || image.includes(".gltf") || image.includes(".obj") || image.includes(".fbx")?
                    <PrivewItemDetails  path={image} fileType={image} className="canvas-3d-without-radius" pathRedirection={data.type==="nft"?`/nft/${data?.token_identifier}`:`/item-details/${data?.id}`}/>
                :
                <>
                <Image
                className="image-box-explore"
                  alt={`${data?.title}`}
                  style={{ cursor: "pointer" }}
                  src={image}  
                  preview={{
                    mask: (
                      <span className="preview-bloc-image-details">
                        {t("see-details")}
                        <span>
                       <EyeOutlined />
                        </span>
                      </span>
                    ),
                  }}
                  placeholder={
                    <Image
                      preview={false}
                      src={LoadingImage}
                      width={300}
                      height={300}
                    />
                  }
                  onClick={handleRedirect}
                />
             {data.uri1!==undefined && data.uri1!==null && 
                <>
                {(data.uri1.includes(".mp3") || data.uri1.includes(".ogg") || data.uri1.includes(".wav") 
                || data.uri1.includes(".mp4") || data.uri1.includes(".webm")) &&  
                  <>
                      <img src={(data.uri1.includes(".mp4") || data.uri1.includes(".webm"))?ICPLAY:ICAUDIO} alt="ICPLAY" className="play-block-icon-explore-item"/>
                      <div className="circle-explore-item  delay-explore-item1"></div>
                      <div className="circle-explore-item  delay-explore-item2"></div>
                      <div className="circle-explore-item  delay-explore-item3"></div>
                      <div className="circle-explore-item  delay-explore-item4"></div>
                    </>}
                </>}
                </>
                }
              </>
            }
       
              </a>
          ) : (
            Spinner
          )}
        </div>

        <div className="content" style={{ width: "100%" }}>
          <div className="topCollection">
            <div className="top-left">
            <a
                href={`/profile-details/${data?.owner?.id}`}
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="image-explore-avatar"
                >
              <img
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(`/profile-details/${data?.owner?.id}`);
                }}
                src={useImageDisplay(
                  data?.owner?.avatar,
                  "__t" + data?.owner?.avatar_ext
                )}
                alt={`${data?.owner?.nickname}`}

                className="image-explore-avatar"
              />

                </a>
              <div className="nft-details">
                <Link to={"/item-details/" + data?.id}>
                  {" "}
                  <span>{useWorstDisplay(data?.title, 12)}</span>
                </Link>

                <Link to={data?.collection?.homepage}>
                  <span>
                    {useWorstDisplay(data?.collection?.collection_name, 12)}
                  </span>
                </Link>
              </div>
            </div>
              {(image.includes(".glb") || image.includes(".gltf") 
              || image.includes(".obj") || image.includes(".fbx"))
               && !exploreStore.loading && 
               <img src ={threeRotate} alt="threeRotate" className="icon-3d-rotate"/>}
            {data?.type == "item" && approved ? (
              <img
                alt="im"
                className="select-image-explore"
                style={{ borderRadius: "50%" }}
                src={select}
                width="20px"
              />
            ) : data?.type === "item" && !approved ? (
              <div className="select-image-explore"></div>
            ) : data?.type !== "item" ? (
              <div className="likeButton">
                <LikeButton liked={data?.isFavorite} itemId={data?.id} className="like-button-container-collection-item" />
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* auction details block */}
          {data?.auctions[data?.auctions.length - 1]?.auction_status !==
            "ended" && (
            <div className="auctionDetails">
              <div className="two-btn">
                {data?.type === "item" && user?.role === "admin" ? (
                  <div>
                    <div>
                      <button
                        className=" float-explore-approved"
                        onClick={() => changeStatus()}>
                        {t("Decline")}
                      </button>
                    </div>
                    <div>
                      {!approved && (
                        <button
                          className=" float-explore-decline"
                          onClick={() => changeStatus()}>
                          {t("Approve")}
                        </button>
                      )}
                    </div>
                  </div>
                ) : data?.type !== "item" && data?.auctions?.length > 0 ? (            
                  <div style={{ width: "100%" }}>
                    <div className="auction-info-box-holder">
                      <div className="auction-infos-box">
                        <div style={{ display: "grid" }}>
                          <p className="title-float-auction">
                            {data?.auctions[data?.auctions.length - 1]
                              ?.auction_status === "deadline" &&
                            data?.auctions[data?.auctions.length - 1]
                              ?.bid_count === 0
                              ? t("Auction-ended")
                              : data?.auctions[data?.auctions.length - 1]
                                  ?.auction_status === "deadline" &&
                                data?.auctions[data?.auctions.length - 1]
                                  ?.bid ===
                                  data?.auctions[data?.auctions.length - 1]
                                    ?.max_bid
                              ? t("Has-been-purchased")
                              : data?.auctions[data?.auctions.length - 1]
                                  ?.auction_status === "deadline" &&
                                data?.auctions[data?.auctions.length - 1]
                                  ?.bid_count !== 0
                              ? t("Auction-won")
                              : t("Ending-in")}
                          </p>
                          {!(
                            data?.auctions[data?.auctions.length - 1]
                              ?.auction_status === "deadline" &&
                            data?.auctions[data?.auctions.length - 1]?.bid ===
                              data?.auctions[data?.auctions.length - 1]?.max_bid
                          ) &&
                            (now >
                            moment(
                              new Date(
                                data?.auctions[data?.auctions.length - 1]
                                  .deadline * 1000
                              )
                            ) ? (
                              <p className="text-min-auction">
                                <DateCustomi18n
                                  date={restTime}
                                  format="medium"
                                />
                              </p>
                            ) : (
                              <p className="text-min-auction">{`${restTimePerDays}d ${restTime?.hours}h ${restTime?.minutes}m`}</p>
                            ))
                          }
                        </div>
                        <div style={{ display: "grid" }}>
                          <p className="title-float-auction">
                            {t("Last-auction")}
                          </p>
                          <p className="text-min-auction">
                            <NumberDisplayi18n
                              value={Number(
                                Number(data?.price)?.toFixed(6)
                              ) / 1000000000000000000}
                              currency=""
                              crypto={true}
                            />
                            <img
                              alt="EGLD"
                              src={egldIcon}
                              className="egld-icon"
                            />
                          </p>
                        </div>                
                      </div>
                      <div>
                      <a
                        href={`/item-details/${data?.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="place-bid-button"
                      >
                        <button
                          className="place-bid-button"
                          onClick={() =>
                            navigate(`/item-details/${data?.id}`)
                          }>
                          {data?.auctions[data?.auctions.length - 1]
                            ?.auction_status === "deadline"
                            ? t("collection-page.end-auction-button")
                            : t("submit-a-bid")}
                        </button>

                      </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div></div>
                    <div>
                      <button
                        className=" float-explore-decline"
                        style={{ opacity: 0 }}>
                        {t("Approve")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* end auction details block */}
        </div>
      </div>
    </>
  );
});
export default Img;
