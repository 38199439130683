import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import english from "./translation/english.json";
import german from "./translation/rarity_de.json";
import arabic from "./translation/rarity_ar.json";
import romanian from "./translation/rarity_ro.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: english,
      },
      de: {
        translation: german,
      },
      ar: {
        translation: arabic,
      },
      ro: {
        translation: romanian,
      },
    },
    // lng: localStorage.getItem("language") || "en",
    fallbackLng: ["en", "de", "ar", "ro"],
    lng:
      localStorage.getItem("language") ||
      [...(window.navigator.languages || [])]
        .filter(Boolean)
        .map((language) => language.substr(0, 2))
        .find((language) => ["de", "ar", "en", "ro"].includes(language)) ||
      "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
