import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { useNavigate,useParams } from "react-router-dom";
import "./createCollection.less";
import CollectionForm from "../../components/collectionForm";
import { useContext } from "../../../../context";
import instanceOfAxios from "../../../../../configAxios";
import { setItem } from "../../../../storage/session";
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';

const CreateCollection = (props: any) => {
  const { t } = useTranslation();
  const { address:userAddress}= useGetAccountInfo();
  const params = useParams();
  const { address } = useContext();
  const  navigate = useNavigate();
  const [collection, setCollection] = useState<any>({});
  const [collectionType, setCollectionType] = useState<any>({});
  const [tag, setTag] = useState(params?.type || "");

  useEffect(() => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    }

    params?.slug &&
      instanceOfAxios
        .get(`collections/by-slug/${params?.slug}`)
        .then((res) => {
          setCollection(res?.data);
          setCollectionType(res?.data?.collectionType);
          setTag(res?.data?.collectionType?.collection_type_name_i18ntag);
          if(res?.data?.creator?.erd_address!==userAddress){
            navigate("/collection")
          }
        })
        .catch((error) => {
          return error;
        });

    params?.id &&
      instanceOfAxios
        .get("collection_types")
        .then((response) => {
          setCollectionType(
            response?.data?.filter(
              (type: any) => type?.collection_type_id === parseInt(params?.id!)
            )[0]
          );
        })
        .catch((error) => {
          return error;
        });
  }, []);

  return (
    <div className="create-collection-page">
      <Row>
        <Col span={24} className="create-collection-header">
          <h2 className="text-regular-font">
            {params?.id
              ? `${t("collection-page.create-title")} ${t(
                "collection-type-" + tag
              )}`
              : `${t("collection-page.edit-collection-title")}`}
          </h2>
          {params?.id && (
            <h5 className="text-regular-font">
              {t("collection-page.create-description")}
            </h5>
          )}
        </Col>
      </Row>
      <CollectionForm collectionType={collectionType} collection={collection} />
    </div>
  );
};

export default CreateCollection;
