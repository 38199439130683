import * as React from "react";
import "./buttons.less";

export interface OptionButtonProps {
  icon: any;
  option: string;
  description: string;
  link?: string;
  linkName?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const OptionButton = ({
  icon,
  option,
  description,
  link,
  linkName,
  onClick,
}: OptionButtonProps) => {
  return (
    <div className="option-button" onClick={onClick}>
      <div className="button-icon-container">
        <img alt="icon" src={icon} className="button-icon" />
      </div>
      <div className="text-container">
        <h4>{option}</h4>
        <h5 className="text-regular-font description-text">
          {description}
          <a href={link}>{linkName}</a>
        </h5>
      </div>
    </div>
  );
};

export default OptionButton;
