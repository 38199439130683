import { Button } from "antd";
import { useTranslation } from "react-i18next";
import CustomModal from "../customModal";
import "./confirmModal.less";

export interface ConfirmModalProps {
  modalVisible: boolean;
  confirm: () => void;
  cancel: () => void;
  title: string;
  description: string;
}

const ConfirmModal = ({
  modalVisible,
  confirm,
  cancel,
  title,
  description,
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      width="450px"
      visible={modalVisible}
      closable={true}
      handleOk={confirm}
      handleCancel={cancel}
    >
      <div className="confirm-modal-header">
        <h3>{title}</h3>
        <h5 className="text-regular-font">{description}</h5>
      </div>
      <div className="confirm-modal-footer-buttons">
        <Button type="default" block onClick={cancel}>
          {t("common.no")}
        </Button>
        <Button type="primary" block onClick={confirm}>
          {t("common.yes")}
        </Button>
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
