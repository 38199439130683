import { CloseCircleFilled } from "@ant-design/icons";
import "./attribute.less";

export interface AttributeProps {
  text: string;
  popular?: boolean;
  closable?: boolean;
  suggested?: boolean;
  mandatory?: boolean;
  onClose?: () => void;
}

const Attribute = ({
  text,
  popular,
  mandatory,
  suggested,
  closable,
  onClose,
}: AttributeProps) => {
  return (
    <div
      className={
        popular
          ? "empty-attribute-container popular-attribute-container"
          : mandatory
          ? "empty-attribute-container mandatory-attribute-container"
          : "empty-attribute-container suggested-attribute-container"
      }
    >
      <h5
        className={
          mandatory
            ? "text-regular-font red-color"
            : suggested
            ? "text-regular-font green-color"
            : "text-regular-font primary-color"
        }
      >
        {text}
      </h5>
      {closable ? (
        <CloseCircleFilled onClick={onClose} className="attribute-close-icon" />
      ) : null}
    </div>
  );
};

export default Attribute;
