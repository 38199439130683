import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabel from "./table";
import OptionSelect from "./option";
import "./message.less";
import instanceOfAxios from "../../../configAxios";
import { useContext } from "../../context";
import { setItem } from "../../storage/session";
import { useNavigate } from "react-router-dom";

function Message() {
  const pageItemsNumber = 20;
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const [dataLoad, setDataload] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [timeFilter, setTimeFilter] = useState<any>(null);
  const [filter, setFilter] = useState<any>(null);
  const { address } = useContext();

  if (!address) {
    setItem("goToUrl", window.location.href);

    window.scrollTo(0, 0);

    navigate("/auth");
  }

  const handleShowMore = (load: boolean, time: any, filter: any) => {
    instanceOfAxios
      .get(
        `/message_page/address/${address}?first=${pageItemsNumber}&offset=${
          load ? offset : 0
        }${time !== "" && time !== null ? `&time=${time}` : ""}${
          filter !== "" && filter !== null ? `&filter=${filter}` : ""
        }`
      )
      .then(function(res) {
        let result = res.data.nodes;
        if (!load) {
          setData(result);
        } else {
          setData((prev) => {
            return prev.concat(result);
          });
        }

        setDataload(res.data.pageInfo.hasNextPage);

        if (res.data.pageInfo.hasNextPage && load) {
          setOffset(offset + pageItemsNumber);
        } else {
          setOffset(pageItemsNumber);
        }
      });
  };

  useEffect(() => {
    if (timeFilter !== null) {
      handleShowMore(false, timeFilter, filter);
    }
  }, [timeFilter]);

  useEffect(() => {
    if (filter !== null) {
      handleShowMore(false, timeFilter, filter);
    }
  }, [filter]);

  useEffect(() => {
    handleShowMore(false, "", "");
  }, []);

  const times = [
    {
      label: t("All"),
      value: t("All"),
    },
    {
      label: t("ranking.last_24h"),
      value: "last_24h",
    },
    {
      label: t("ranking.last_7_days"),
      value: "last_7_days",
    },
    {
      label: t("ranking.last_month"),
      value: "last_month",
    },
  ];

  const group = [
    {
      label: t("All"),
      value: t("All"),
    },
    {
      label: t("message.user"),
      value: "user",
    },
    {
      label: t("message.collection"),
      value: "collection",
    },
    {
      label: t("message.item"),
      value: "item",
    },
    {
      label: t("message.auction"),
      value: "auction",
    },
    {
      label: t("message.transaction"),
      value: "transaction",
    },
    {
      label: t("message.transfer"),
      value: "transfer",
    },
  ];

  return (
    <>
      <div>
        <style>
          {
            "\
          .ant-select-dropdown {\
            position: fixed!important;\
            z-index: 10 !important;\
          }\
          "
          }
        </style>

        <div className="activity-main ">
          <div className="activity-filter">
            <div style={{ display: "grid" }}>
              <div>
                {" "}
                <span className="link-home-activity ">
                  <a href="/" className="breadcrumbPath">
                    {t("Home")}
                  </a>{" "}
                  {">"}
                  <span className="primary-color link-home-activity ">
                    {t("message.message")}
                  </span>{" "}
                </span>
              </div>
              <div style={{ marginTop: 50 }}>
                <h4>{t("message.current-market-message")}</h4>{" "}
              </div>
            </div>

            <div className="selectors-select">
              <div className="optionSelect">
                <span className="activity-select">{t("message.time")}</span>
                <OptionSelect
                  data={times}
                  multiple={false}
                  filterOption={timeFilter}
                  setFilterOption={setTimeFilter}
                />
              </div>
              <div className="optionSelect">
                <span className="activity-select">{t("message.group")}</span>
                <OptionSelect
                  data={group}
                  multiple={false}
                  filterOption={filter}
                  setFilterOption={setFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Tabel
            data={data}
            handleShowMore={handleShowMore}
            timeFilter={timeFilter}
            categoryFilter={filter}
            dataLoad={dataLoad}
          />
        </div>
      </div>
    </>
  );
}

export default Message;
