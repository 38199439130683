import * as React from "react";
import { IntlProvider, FormattedDate } from "react-intl";

export interface DateCustomi18nProps {
  date: any;
  format: string;
  time?: boolean;
}

const DateCustomi18n = ({ date, format, time }: DateCustomi18nProps) => {
  return (
    <>
      <IntlProvider
        locale={localStorage.getItem("language") || "en"}
        defaultLocale="en"
      >
        <FormattedDate
          value={date}
          year="numeric"
          month={
            format === "long"
              ? "long"
              : format === "medium"
              ? "short"
              : "2-digit"
          }
          day="2-digit"
          hour={time ? "numeric" : undefined}
          minute={time ? "numeric" : undefined}
        />
      </IntlProvider>
    </>
  );
};

export default DateCustomi18n;
