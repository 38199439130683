import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, InputNumber } from "antd";
import "../actionSwitch.less";
import CustomModal from "../../../../../components/modal/customModal";

export interface CreateSFTProps {
  visible: boolean;
  handleOk: (nbr: number) => void;
  handleCancel: () => void;
}

const CreateSFT = ({ visible, handleOk, handleCancel }: CreateSFTProps) => {
  const { t } = useTranslation();

  const onFinish = (values: any) => {
    // console.log("count of sft :", values?.count);
    handleOk(values?.count);
  };

  return (
    <CustomModal
      visible={visible}
      width="400px"
      closable={true}
      handleOk={handleCancel}
      handleCancel={handleCancel}
    >
      <Form onFinish={onFinish}>
        <div className="auction-modal-header">
          <h4>{t("collection-page.create-sft-title")}</h4>
          <h6 className="text-regular-font">
            {t("collection-page.create-sft-desc")}
          </h6>
        </div>
        <div style={{ margin: "20px 0" }}>
          <h5 style={{ paddingBottom: "10px" }}>
            {t("collection-page.sft-count")}
          </h5>
          <Form.Item
            name="count"
            rules={[
              { required: true, message: t("action-box.sft-count-required") },
            ]}
          >
            <InputNumber
              min={2}
              max={2000}
              placeholder={t("collection-page.sft-count-placeholder")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ margin: "10px 0" }}
          >
            {t("collection-page.create-sft-button")}
          </Button>
          <Button type="default" block onClick={handleCancel}>
            {t("common.cancel")}
          </Button>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default CreateSFT;
