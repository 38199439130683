import { Address } from "@multiversx/sdk-core/out";
import { message } from "antd";
import axios from "axios";
import i18n from "../../i18n";
import { elrondApiUrl } from "../config/configGlobal";

export function intToString(value: number) {
  var suffixes = ["", "k", "m", "b", "t"];
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat(
    (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 !== 0) {
    shortValue = parseFloat(shortValue.toFixed(1));
  }
  return shortValue + suffixes[suffixNum];
}

export const verifyNFTonExpectedAddress: any = async (
  address: string,
  tokenIdentifier: string
) => {
  return axios
    .get(`${elrondApiUrl}nfts/${tokenIdentifier}`)
    .then((res: any) => (res?.data?.owner === address ? true : false))
    .catch((err: any) => {
      console.log("failed to check nfts API call => ", err);
      return true;
    });
};

export const assert = (text: any, count: number) => {
  return text?.slice(0, count) + (text?.length > count ? "..." : "");
};

export const landingReception: any = (path: string) => {
  let splittedArray = path?.split("/");
  if (splittedArray?.length === 3) {
    if (localStorage.getItem("language") !== splittedArray[1]) {
      i18n.changeLanguage(splittedArray[1]);
      localStorage.setItem("language", splittedArray[1]);
      window.location.pathname = splittedArray.join("/");
    }
  } else {
    window.location.pathname = `${localStorage.getItem("language") ||
      "en"}${path}`;
  }
};

export const checkExistingUrl = (urls: any[]) => {
  urls.map(url => {
    if (window.location.pathname.includes(url)) {
      landingReception(window.location.pathname);
    }
  });
};
