export const mediumRegex = new RegExp('^(?:https?:)?//medium.com/@([A-z0-9])');
export const mediumSubdomainRegex = new RegExp('^(?:https?:)?//(?:[A-z]+.).medium.com');
export const twitterRegex = new RegExp(
	'(?:https?:)?//(?:[A-z]+.)?twitter.com/@?(?!home|share|privacy|tos)([A-z0-9_]+)/?'
);
export const telegramRegex = new RegExp('(?:https?:)?//(?:t(?:elegram)?.me|telegram.org)/([a-zA-Z0-9_]{5,32})/?');
export const discordRegex = new RegExp('(https?://)?(www.)?(discord.(gg|io|me|li)|discordapp.com/invite)/.+[a-z]');

export const instagramRegex = new RegExp(
	'(?:https?:)?//(?:www.)?(?:instagram.com|instagr.am)/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:.(?!.))){0,28}(?:[A-Za-z0-9_]))?)'
);

export const tokenNameRegex = new RegExp('^[0-9a-zA-Z]{3,20}$');
export const tokenTickerRegex = new RegExp('^[0-9A-Z]{3,10}$');

export const checkLengthNumber: RegExp = new RegExp(/^[0-9]\d*([.][0-9]{1,10})?$/);

export const meduimRegexUserName = new RegExp('^(?:https?:)?//medium.com/@([A-z0-9]+)');
export const discordRegexUserName = new RegExp(
	'(?:https?://)?(?:www.)?discord.(gg|io|me|li)/(channels|invite|users)/([0-9]+)'
);
