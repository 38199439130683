import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import './customCheckBox.less';
import icon from '../../../assets/images/check icon.svg';

export interface CustomCheckBoxProps {
	checked: boolean;
	title: string;
	description: string;
	value: string;
	disabled: boolean;
	onChange: (val: string) => void;
}

const CustomCheckBox = ({ checked, title, description, value, disabled, onChange }: CustomCheckBoxProps) => {
	const { t } = useTranslation();
	return (
		<div
			className={
				!disabled && checked ? (
					'checked-checkbox-container checkbox-container'
				) : !disabled && !checked ? (
					'unchecked-checkbox-container checkbox-container'
				) : (
					'disabled-checkbox-container'
				)
			}
			onClick={() => !disabled && onChange(value)}>
			{checked ? (
				<img alt="checked icon" src={icon} className="checked-icon" />
			) : (
				<div className="empty-check-circle" />
			)}
			{disabled ? (
				<Tooltip title={t('common.coming-soon')}>
					<h6>{title}</h6>
					<h6 className="text-regular-font check-desc">{description}</h6>
				</Tooltip>
			) : (
				<div>
					<h6>{title}</h6>
					<h6 className="text-regular-font check-desc">{description}</h6>
				</div>
			)}
		</div>
	);
};

export default CustomCheckBox;
