import * as React from "react";
import { useTranslation } from "react-i18next";
import ButtonTab from "../ButtonTab/buttonTab";
import "./profileTab.less";

export interface ProfileTabProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  menu: any[];
  elementsCount: any[];
  profile: Boolean;
  collectionL?: any;
  draftL?: any;
  favoredL?: any;
  itemL?: any;
}

const ProfileTab = ({
  menu,
  elementsCount,
  activeTab,
  profile,
  draftL,
  favoredL,
  itemL,
  collectionL,
  setActiveTab,
}: ProfileTabProps) => {
  const { t } = useTranslation();



  return (
    <div className={collectionL ? "collection-profile-tab" : "collection"}>
      {menu.map((el, index) => {
        return (
          <>
            {profile ? (
              <ButtonTab
                onClick={() => setActiveTab(el?.value)}
                type={activeTab === el.value ? "primary" : "secondary"}
              >
                {el.label} &nbsp;
                {elementsCount[index] !== 0 ? `(${elementsCount[index]})` : ""}
              </ButtonTab>

            ) : (
              el?.label ? (
                <ButtonTab
                  onClick={() => setActiveTab(el?.value)}
                  type={activeTab === el.value ? "primary" : "secondary"}
                >

                  {el.label} &nbsp;
                  {
                    el.count !== 0 ? `(${el.count})` : ""
                  }
                </ButtonTab>
              )
                : ""
            )}
          </>
        );
      })}
    </div>
  );
};

export default ProfileTab;
