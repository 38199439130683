import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { landingReception } from "../../helpers/communFunctions";
import { Helmet } from "react-helmet";

const HelpCenter = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        //this is specific for the language routes like landing pages routes
        landingReception(window.location.pathname);
    }, []);

    return (
        <>
            <Helmet>
                <title>Rarity.market | Help Center</title>
            </Helmet>
            <div className="faq-body-container">
                <Row>
                    <Col>
                        <a href="/" className="breadcrumbPath">
                            {t("Home")}
                        </a>
                        <span>{">"}</span>
                    </Col>
                    <Col>
                        {" "}
                        <span className="primary-color">
                            {t("home.Help-Center")}
                        </span>
                    </Col>
                </Row>
                <div style={{ marginTop: "40px" }}>
                    <h4>{t("home.Help-Center")}</h4>
                </div>
                <Content style={{ marginTop: "40px" }}>
                    <div>
                        <h5>{t("help-center.here-you-will-get-help")}</h5>
                        <h5>You are a project on MultiversX?</h5>
                        <div>
                            <p>
                                <ul className="help-center-page-description">
                                    <li className="icon-block-help-center">
                                        <a href="https://fourtytwo.com/white-label-dapp">
                                            <img
                                                src="/static/media/dappIcon.af9bccd4.svg"
                                                alt="get your own dapp on MultiversX"
                                                className="dapp-icon-help-center"
                                            />
                                            <p>
                                                {t("home.whitelabel")} - A
                                                software as a service SaaS from
                                                provided by fourtytwo.com
                                            </p>
                                        </a>
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <h5>{t("home.menu-title")}</h5>
                        <p>
                            <ul className="help-center-page-description">
                                <li className="icon-block-help-center">
                                    <a
                                        href="https://medium.com/@rarity_market/what-to-do-when-youre-ready-to-purchase-an-artwork-on-rarity-market-f48390318381"
                                        target="_blank">
                                        <img
                                            src="/static/media/mediumIcon.6713a884.svg"
                                            alt="Medium Blog"
                                        />
                                        <p className="addational-padding">
                                            {t(
                                                "help-center.purchase-an-artwork-on-rarity-market"
                                            )}
                                        </p>
                                    </a>
                                </li>
                                <li className="icon-block-help-center">
                                    <a
                                        href="https://t.me/RarityMarketOfficial"
                                        target="_blank">
                                        <img
                                            src="/static/media/telegramChat.5334c0bc.svg"
                                            alt="Telegram Community"
                                        />
                                        <p>
                                            {t(
                                                "help-center.join-telegram-official"
                                            )}
                                        </p>
                                    </a>
                                </li>
                                <li className="icon-block-help-center">
                                    <a
                                        href="https://t.me/RarityMarketAnn"
                                        target="_blank">
                                        <img
                                            src="/static/media/telegramSound.08010616.svg"
                                            alt="Telegram Ann"
                                        />
                                        <p>
                                            {t("help-center.join-telegram-ann")}
                                        </p>
                                    </a>
                                </li>
                                <li className="icon-block-help-center">
                                    <a
                                        href="https://medium.com/@rarity_market"
                                        target="_blank">
                                        <img
                                            src="/static/media/mediumIcon.6713a884.svg"
                                            alt="Medium Blog"
                                        />
                                        <p className="addational-padding">
                                            {t("help-center.visit-our-blog")}
                                        </p>
                                    </a>
                                </li>
                                <li className="icon-block-help-center">
                                    <a
                                        href="https://www.youtube.com/channel/UCCaMl7hWdVN23ImMvr8w94g/videos"
                                        target="_blank">
                                        <img
                                            src="/static/media/youtube.57d56c5d.svg"
                                            alt="Video Guides"
                                        />
                                        <p className="addational-padding">
                                            {t(
                                                "help-center.watch-our-tutorials"
                                            )}
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </p>
                    </div>
                    <h5>{t("home.menu-egld-staking")}</h5>
                    <div>
                        <p>
                            <ul className="help-center-page-description">
                                <li className="icon-block-help-center">
                                    <a
                                        href="https://t.me/RarityMarketStakingOfficial"
                                        target="_blank">
                                        <img
                                            src="/static/media/telegramChat.5334c0bc.svg"
                                            alt="Telegram Support Staking"
                                        />
                                        <p>
                                            {t(
                                                "help-center.join-telegram-staking-official"
                                            )}
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </p>
                    </div>
                </Content>
            </div>
        </>
    );
};
export default HelpCenter;
