import { message } from 'antd';

export function beforeUpload(file: any, typeMessage: string, sizeMessage: string) {
	const isAcceptableType =
		file.type === 'image/jpeg' ||
		file.type === 'image/png' ||
		file.type === 'image/svg+xml' ||
		file.type === 'image/gif';
	if (!isAcceptableType) {
		message.error(typeMessage);
		return isAcceptableType;
	}
	const isLt2M = file.size / 1024 / 1024 < 20;

	if (!isLt2M) {
		message.error(sizeMessage);
		return isLt2M;
	}
	return isAcceptableType && isLt2M;
}
