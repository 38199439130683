import * as React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Upload } from "antd";
import coverProp1 from "../../../../../../assets/images/cover_photo.webp";
import coverProp2 from "../../../../../../assets/images/cover_photo2.webp";
import "./collectionBanner.less";
import BannerProposition from "./bannerProposition";
import CropImage from "../../../../../components/crop image/cropImage";
import { beforeUpload } from "../../../../../helpers/uploadFunctions";

const CollectionBanner = ({
  bannerext,
  image,
  setCollectionBanner,
}: {
  bannerext: string;
  image: string;
  setCollectionBanner: any;
}) => {
  const { t } = useTranslation();
  const cover1S3 = "static/media/cover_photo.webp";
  const cover2S3 = "static/media/cover_photo2.webp";
  const [imageDisplay, setImageDisplay] = useState(
    process.env.REACT_APP_S3_ENABLED === "true" ? cover1S3 : coverProp1
  );
  const [displayProp, setDisplayProp] = useState(
    process.env.REACT_APP_S3_ENABLED === "true"
      ? [process.env.REACT_APP_SERVER_IMAGE_S3_BASE_URL  +cover1S3, process.env.REACT_APP_SERVER_IMAGE_S3_BASE_URL  +cover2S3]
      : [coverProp1, coverProp2]
  );
  const [uploadedPhotos, setUploadedPhotos] = useState<any[]>([]);

  useEffect(() => {
    if (image && image !== "") {
      setImageDisplay(image);
      if (!image.includes("static/media")) {
        setDisplayProp([...displayProp, image]);
      }
    }
  }, [image]);

  useEffect(() => {
    //here we update the collection_banner for the api , it must be the original file if it's an upload
    if (imageDisplay?.length > 50 && imageDisplay.includes("data")) {
      // console.log("upload ");
      setCollectionBanner(
        uploadedPhotos.filter((photo: any) => photo?.src === imageDisplay)[0]
          ?.originalFile
      );
    } else {
      setCollectionBanner(imageDisplay);
    }
  }, [imageDisplay]);

  const props = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  };

  return (
    <div className="collection-banner-global-container">
      <div className="banner-show-cover-container">
        <img
          alt="banner cover"
          src={
            process.env.REACT_APP_S3_ENABLED === "true"?
            imageDisplay.includes("static/media")?
            imageDisplay.replace(process.env.REACT_APP_SERVER_IMAGE_S3_BASE_URL||"",""):
              imageDisplay?.length > 50 && imageDisplay.includes("data")
                ? imageDisplay
                :
                  imageDisplay 
              : imageDisplay
          }
          className="banner-show-cover-photo"
        />
      </div>
      <Row gutter={[16, 16]} className="propositions-container">
        {displayProp?.map((photo,key:number) => {
          return (
            <Col span={12} key={key}>
              <BannerProposition
                photo={photo}
                displayPhoto={imageDisplay}
                setImageDisplay={setImageDisplay}
              />
            </Col>
          );
        })}
        <Col span={12}>
          <CropImage
            title={t("collection-page.upload-banner-title")}
            shape={"rect"}
            width={5}
            height={10}
            grid={true}
            setOriginalFile={(e: File) => {}}
            setCroppedObject={(e: any) => {
              setDisplayProp([...displayProp, e?.previewUrl]);
              setUploadedPhotos([
                ...uploadedPhotos,
                {
                  src: e?.previewUrl,
                  originalFile: e?.originalFile,
                },
              ]);
              setImageDisplay(e?.previewUrl);
            }}
          >
            <Upload
              showUploadList={false}
              className="upload-banner-button"
              beforeUpload={(file: any) =>
                beforeUpload(
                  file,
                  t("profile.image-type-error", {
                    supported_image_types: "Png or Jpg",
                  }),
                  t("profile.image-size-error")
                )
              }
              accept=".jpg, .jpeg, .png"
              {...props}
            >
              <h5 className="primary-color uploadBanner">
                {t("common.upload")}
              </h5>
            </Upload>
          </CropImage>
        </Col>
      </Row>
    </div>
  );
};

export default CollectionBanner;
