import React, { useEffect } from 'react';
import { notification } from 'antd';
import successIcon from '../../../assets/images/success icon.svg';
import errorIcon from '../../../assets/images/Icon-error.svg';
import './toasts.less';

export const OpenSuccessNotification = (message: string, description: string, duration: number) => {
	const closeIcon = <div />;
	notification.open({
		message: message,
		description: description,
		className: 'success-notification',
		closeIcon,
		icon: (
			<div className="notif-icon-holder success-notif">
				<img alt="successIcon" src={successIcon} className="notif-icon" />
			</div>
		),
		duration: duration
	});
};

export const OpenErrorNotification = (message: string, description: string, duration: number) => {
	const closeIcon = <div />;
	notification.open({
		message: message,
		description: description,
		className: 'error-notification',
		closeIcon,
		icon: (
			<div className="notif-icon-holder error-notif">
				<img alt="errorIcon" src={errorIcon} className="notif-icon" />
			</div>
		),
		duration: duration
	});
};
