import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { isValidURL } from "../../helpers/validateUrl";
import svgEGLD from "../../../assets/images/EGLD.svg";
import { useNavigate } from "react-router-dom";
import Nodata from "../../components/noData/nodata";
import CustomImage from "../../components/image/customImage";
import NumberDisplayi18n from "../../helpers/numbersi18n";
import { useEffect, useState } from "react";
import { intToString } from "../../helpers/communFunctions";
import TimeI18n from "../../helpers/timei18n";
import "./table.less";

interface Props {
  data: any[];
  dataLoad: boolean;
  handleShowMore: any;
  timeFilter: any;
  categoryFilter: any;
}

const TableActivity = ({
  data,
  handleShowMore,
  categoryFilter,
  timeFilter,
  dataLoad,
}: Props) => {
  const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL;
  const serverAPIEndpoint = process.env.REACT_APP_SERVER_IMAGE_BASE_URL;
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const [spin, setSpin] = useState<boolean>(true);

  useEffect(() => {
    if (data.length > 0) {
      setSpin(false);
    } else {
      setSpin(true);
      setInterval(() => {
        setSpin(false);
      }, 4000);
    }
  }, [data]);

  if (!serverBaseURL) {
    throw new Error();
  }
  if (!serverAPIEndpoint) {
    throw new Error();
  }
  const IMG_URL = serverBaseURL + serverAPIEndpoint;

  if (!isValidURL(IMG_URL)) {
    throw new Error();
  }

  const assert = (text: any, count: any) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  };

  const dataTable = data.map((el) => {
    return {
      key: el?.key,
      event: (
        <div className="d-flex flex-wrap event-block">
          <div className="item-text">
            <p
              className="fw-bolder row font-weight-normal"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/item-details/${el?.item.id}?tab=offers`)
              }>
              {el?.event.i18n_tag
                ? assert(
                    t(`notifications.${el?.event.i18n_tag}`, {
                      item: el.item.title,
                    }),
                    35
                  )
                : ""}
            </p>
          </div>
        </div>
      ),
      message: (
        <div className="d-flex flex-wrap message-block">
          <div className="item-text">
            <p className="fw-bolder row font-weight-normal">
              {/* {el?.message ? assert(el?.message, 35) : ""} */}
              {el?.message}
            </p>
          </div>
        </div>
      ),
      price: (
        <>
          {Number(el?.price) > 0 ? (
            <>
              <NumberDisplayi18n
                value={Number(Number(el?.price).toFixed(9))}
                currency=""
                crypto={false}
              />
              <img src={svgEGLD} width="20px" className="egld-icon" />
            </>
          ) : (
            "---"
          )}
        </>
      ),
      group: (
        <>
          <div>{el?.event.group}</div>
        </>
      ),
      time: <TimeI18n time={el?.created_at} />,
    };
  });

  const nodataTable = {
    price: (
      <div className="no-data-block">
        <Nodata />
      </div>
    ),
  };

  const columns = [
    {
      title: t("activity.title-table-event"),
      dataIndex: "event",
      key: "event",
      className: "text-left",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      className: "text-left",
    },
    {
      title: t("common.price"),
      dataIndex: "price",
      key: "price",
      className: "text-left",
    },
    {
      title: t("message.group"),
      dataIndex: "group",
      key: "group",
      className: "text-left",
    },
    {
      title: t("message.time"),
      dataIndex: "time",
      key: "time",
      className: "text-left",
    },
  ];
  const handelFooter = () => {
    if (dataLoad === true) {
      return (
        <button
          className="button-seeMore ant-btn ant-btn-default collections-button"
          onClick={() => handleShowMore(true, timeFilter, categoryFilter)}>
          {t("activity.see-more")}
        </button>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <div>
        <Table
          className="table-responsive  table-activity"
          size={"small"}
          pagination={false}
          columns={columns}
          loading={spin}
          dataSource={dataTable.length > 0 ? dataTable : [nodataTable]}
          footer={handelFooter}
        />
        <div></div>
      </div>
      {/* {(data.length > 0) ? data.length : 'false'} */}
    </>
  );
};

export default TableActivity;
