import { initialState, StateType } from "./state";
import { getItem, removeItem } from "../storage/session";
import instanceOfAxios from "../../configAxios";

export type DispatchType = (action: ActionType) => void;

export type ActionType =
  | { type: "actionLike"; idItem: any }
  | { type: "setMarketAddress"; marketAddress: string }
  | { type: "setItems"; items: StateType["items"] }
  | { type: "login"; address: StateType["address"] }
  | { type: "logout" }
  | { type: "loading"; loading: StateType["loading"] }
  | { type: "actionCode"; actionCode: StateType["actionCode"] }
  | {
    type: "refreshActionCode";
    actionCodeRefresh: StateType["actionCodeRefresh"];
  }
  | {
    type: "keepTrack";
    transactionsTrack: StateType["transactionsTrack"];
  }
  | { type: "actionCode"; actionCode: StateType["actionCode"] }
  | { type: "refreshItem"; refreshItem: StateType["refreshItem"] }
  | { type: "marketplace_fee"; marketplace_fee: any }
  | { type: "setBrandType"; brandType: string }
  | { type: "setProfile"; user: StateType["profile"] };

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case "login": {
      const { address } = action;
      let loggedIn = address || address !== "" ? true : false;
      let goto = getItem("goToUrl");
      let fullAddress = "";
      if (address) {
        fullAddress = address;
      } else {
        //here in case of a bad connection that results an empty address in the action ..so we take it from the params
        fullAddress = window.location.search.split("=")[1];
      }
      if (!localStorage.getItem("loggedIn")) {
        localStorage.setItem("loggedIn", JSON.stringify(loggedIn));
        localStorage.setItem("address", fullAddress);
        // let invitation = localStorage.getItem("invitation");
        instanceOfAxios
          .post("profile", { erd_address: fullAddress })
          .then((res: any) => {
            localStorage.setItem("user", JSON.stringify(res?.data?.profile));
            localStorage.setItem("token", res?.data?.token);
            // if (invitation) {
            //   window.location.replace(invitation);
            // } else {
            window.location.search = "";
            if (goto?.length > 0) {
              window.location.href = goto;
            }
            //   return res;
            // }
          })
          .catch((err: any) => {
            localStorage.clear();
            removeItem("loggedIn");
            removeItem("address");
            removeItem("user");
            removeItem("view");
            removeItem("ledgerLogin");
            removeItem("walletConnectLogin");
            if (document.cookie || !navigator.cookieEnabled) {
              window.location.pathname = "/cookies";
            } else {
              window.location.pathname = "/maintenance";
            }
          });
      }
      return {
        ...state,
        address: fullAddress,
        loggedIn: loggedIn,
      };
    }

    case "setBrandType": {
      const { brandType } = action;
      localStorage.setItem("brandType", brandType);
      return {
        ...state,
        brandType,
      };
    }

    case "setMarketAddress": {
      const { marketAddress } = action;
      return {
        ...state,
        marketPlaceAddress: marketAddress,
      };
    }

    case "loading": {
      const { loading } = action;
      return {
        ...state,
        loading,
      };
    }

    case "actionCode": {
      const { actionCode } = action;
      return {
        ...state,
        actionCode,
      };
    }

    case "refreshActionCode": {
      const { actionCodeRefresh } = action;
      return {
        ...state,
        actionCodeRefresh,
      };
    }

    case "refreshItem": {
      const { refreshItem } = action;
      return {
        ...state,
        refreshItem,
      };
    }

    case "keepTrack": {
      const { transactionsTrack } = action;
      return {
        ...state,
        transactionsTrack,
      };
    }

    case "marketplace_fee": {
      const { marketplace_fee } = action;
      return {
        ...state,
        marketplace_fee,
      };
    }

    case "setItems": {
      const { items } = action;
      return {
        ...state,
        items,
      };
    }

    case "actionLike": {
      const { idItem } = action;
      return {
        ...state,
        items: state.items.map((element: any) => {
          return element.id !== idItem
            ? element
            : {
              ...element,
              likes:
                element?.isFavorite === true
                  ? element?.likes > 0
                    ? element?.likes - 1
                    : 0
                  : element?.likes + 1,
              isFavorite: !element?.isFavorite,
            };
        }),
      };
    }

    case "logout": {
      localStorage.clear();
      removeItem("loggedIn");
      removeItem("address");
      removeItem("user");
      removeItem("view");
      removeItem("ledgerLogin");
      removeItem("walletConnectLogin");
      removeItem("goToUrl");
      window.location.pathname = "/";
      return initialState();
    }

    case "setProfile": {
      const { user } = action;
      localStorage.setItem("user", JSON.stringify(user));
      return {
        ...state,
        profile: user,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}
