import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const { Option } = Select;

export interface SelectOptionProps {
	titel?: boolean;
	className?: string;
	optionsSelect: string[];
	role: any;
	activeTab?: any;
	profile: boolean;
	selectRef?: any;
	optionSelectAdmin: string[];
	onChange?: any;
	disabled: boolean;
	defaultValue?: any;
}
const SelectOption = ({
	optionsSelect,
	className,
	onChange,
	profile,
	role,
	optionSelectAdmin,
	disabled,
	defaultValue
}: SelectOptionProps) => {
	const { t } = useTranslation();
	const [ classNames, setClassName ] = React.useState(false);

	function onFocus() {
		setClassName(true);
	}

	return (
		<div className={className + ' explore_select'}>
			<Select
				onFocus={onFocus}
				style={{ fontWeight: 'bold', width: '200px' }}
				onChange={onChange}
				placeholder={t('All')}
				className={profile ? `select-option` : ''}
				showArrow={true}
				size="middle"
				disabled={disabled}
				defaultValue={defaultValue}
				value={defaultValue}>
				{role === 'admin' || role === 'community-admin' ? (
					optionSelectAdmin.map((el, i) => (
						<Option style={{ fontSize: 12 }} key={i} value={el} className="option-explore">
							{t(el)}
						</Option>
					))
				) : (
					optionsSelect.map((el, i) => (
						<Option
							className="option-explore"
							style={{ fontSize: 12 }}
							key={i}
							value={el}
							onChange={onChange}>
							{t(el)}
						</Option>
					))
				)}
			</Select>
		</div>
	);
};
export default SelectOption;
