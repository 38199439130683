import React, { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import "./settings.less";
import { useContext } from "../../../../context";
import instanceOfAxios from "../../../../../configAxios";

const { Option } = Select;

export interface SettingsProps {
  userInfos: any;
  currencies: any;
  defaultCurrency: any;
  setUserInfos: (obj: any) => void;
  setCurrentLanguage?: (el: any) => void;
}

const Settings = ({
  userInfos,
  currencies,
  defaultCurrency,
  setUserInfos,
  setCurrentLanguage,
}: SettingsProps) => {
  const { t } = useTranslation();
  const { address } = useContext();
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("language") || userInfos?.language
  );

  const handleChangeLang = (value: any) => {
    setCurrentLang(value);
    setUserInfos({ ...userInfos, language: value });
  };

  const handleChangeCurrency = (value: any) => {    
    setUserInfos({ ...userInfos, currency: value.split("-")[0] });
  };

  const handleChangeTheme = (value: any) => {
    setUserInfos({ ...userInfos, theme: value });
  };
 

  const options = [
    { value: "en", label: t("home.footer-traduction-english") },
    { value: "de", label: t("home.footer-traduction-germany") },
    // { value: "fr", label: t("home.footer-traduction-french") },
    { value: "ar", label: t("home.footer-traduction-arabic") },
    { value: "ro", label: t("home.footer-traduction-romanian") },
  ];

  return (
    <Row>
      <Col span={24} className="profile-settings-container">
        <div>
          <h5 className="text-regular-font settings-label">{`${t(
            "profile.language-label"
          )} :`}</h5>
          <Select
            style={{ width: "100%" }}
            onChange={handleChangeLang}
            value={currentLang}
          >
            {options.map((el,key:number) => {
              return (
                <Option key={key} value={el.value} className="optionCol">
                  {el.label}
                </Option>
              );
            })}
          </Select>
        </div>

        <div>
          <h5 className="text-regular-font settings-label">{`${t(
            "profile.currency-label"
          )} :`}</h5>
          <Select
            showSearch
            defaultValue={`${defaultCurrency?.symbol}-${defaultCurrency?.name}`}
            // defaultValue={userInfos.currency}
            style={{ width: "100%" }}
            onChange={handleChangeCurrency}
            optionLabelProp="label"
          >
            {currencies.map((item: any,key:number) => {
              return (
                <Option key={key} value={`${item?.symbol}-${item?.name}`} className="optionCol">
                  {item?.symbol} - {item?.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <h5 className="text-regular-font settings-label">{`${t(
            "profile.theme-label"
          )} :`}</h5>
          <Select
            defaultValue={userInfos?.theme ? userInfos?.theme : "light"}
            style={{ width: "100%" }}
            onChange={handleChangeTheme}
          >
            <Option value="light" className="optionCol">
              {t("profile.light-mode")}
            </Option>
            <Option value="dark" disabled className="optionCol">
              {t("profile.dark-mode")}
            </Option>
          </Select>
        </div>
      </Col>
    </Row>
  );
};

export default Settings;
