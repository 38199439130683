import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';

export interface ScrollToTopProps {
	showBelow: number;
	resetOffest?: any;
}

const ScrollToTop = ({ showBelow, resetOffest }: ScrollToTopProps) => {
	const [ show, setShow ] = useState(showBelow ? false : true);
	const handleScroll = () => {
		if (window.pageYOffset > 0.0) {
			if (!show) setShow(true);
		} else {
			if (show) setShow(false);
		}
	};
	useEffect(() => {
		if (showBelow) {
			window.addEventListener(`scroll`, handleScroll);
			return () => window.removeEventListener(`scroll`, handleScroll);
		}
	});
	const handleClick = () => {
		window[`scrollTo`]({ top: 0, behavior: `smooth` });
		if (resetOffest) {
			resetOffest();
		}
	};
	return (
		<div>
			{show && (
				<Button onClick={handleClick} className="scroll-to-top" type="primary" shape="circle" size={'middle'}>
					<ArrowUpOutlined className="arrow-icon" />
				</Button>
			)}
		</div>
	);
};
export default ScrollToTop;
