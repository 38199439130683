import React from 'react';
import './detailDisplay.less';

export interface DetailDisplayProps {
	content: string;
	icon?: string;
}

const DetailDisplay = ({ content, icon }: DetailDisplayProps) => {
	return (
		<div className={icon ? 'detail-container' : ' detail-container bigger-width'}>
			{icon && <img alt="detail icon" src={icon} className="detail-icon" />}
			<h6 className="text-regular-font">{content}</h6>
		</div>
	);
};

export default DetailDisplay;
