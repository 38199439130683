import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RegisterBrandDisplay from "./display/registerBrand";
import "./actionSwitch.less";
import { useContext, useDispatch } from "../../../../context";
import instanceOfAxios from "../../../../../configAxios";
import { message, Result, Spin } from "antd";
import { getItem } from "../../../../storage/session";
import LoadingModal from "./loadingModal/loadingModal";
import SetupAuctionDisplay from "./display/setupAuction";
import SuccessRegisterDisplay from "./display/successRegister";
import CreateSFT from "./boxes/createSFT";
import RegisterGrantDisplay from "./display/registerGrant";
import { OpenErrorNotification } from "../../../../components/toasts/openToast";
import AuctionDisplay from "./display/auctionDisplay";
import ClaimNFT from "./display/claimNFT";
import {
  sendTransactions,
} from "@multiversx/sdk-dapp/services";
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account'
import { useGetAccountInfo, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks'
import ReleaseNFTsoon from "./display/releaseNFTsoon";
import AdminApproveDisplay from "./display/adminApproveDisplay";
import MakeOfferDisplay from "./display/makeOfferDisplay";
import { logger } from "../../../../context/logger";
import NotConnectedModal from "./notConnectedModal/notConnectedModal";
import auctionIcon from "../../../../../assets/images/auction.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { useGlobalContext } from "../../../../context/globalContext";

interface ActionSwitchProps {
  item: any;
  collectionId: number;
}

const ActionSwitch = observer(({ item, collectionId }: ActionSwitchProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { itemDetailsStore } = useGlobalContext()
  const { address, brandType, actionCodeRefresh } = useContext();
  const [actionCase, setActionCase] = useState<any>(0);
  const { account } = useGetAccountInfo();
  const [transactionData, setTransactionData] = useState<any>({});
  const [transactionModal, setTransactionModal] = useState(false);
  const [actionAllowed, setActionAllowed] = useState(false);
  const [sftCount, setSFTCount] = useState<number>(0);
  const [createAuctionModal, setCreateAuctionModal] = useState(false);
  const [auctionData, setAuctionData] = useState<any>({});
  const [createNFTLoading, setCreateNFTLoading] = useState(false);
  const [notConnectedVisible, setNotConnectedVisible] = useState(false);
  const [getActionLoading, setActionLoading] = useState(false);
  const [callGetAction, setCallGetAction] = useState(false);
  const [runningTransactionLoad, setRunningTransactionLoad] = useState(false);
  const /*transactionSessionId*/ [, setTransactionSessionId] = React.useState<
      string | null
    >(null);
  // const { sendTransactions } = transactionServices;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const {
    pendingTransactionsArray,
  } = useGetPendingTransactions();

  const userCurrency = "€";

  useEffect(() => {
    if (pendingTransactionsArray?.length === 0) {
      if (runningTransactionLoad === true) {
        setRunningTransactionLoad(false);
      }
    } else {
      if (
        JSON.parse(localStorage.getItem("transactions-track") || "[]")?.length >
        0
      ) {
        JSON.parse(localStorage.getItem("transactions-track") || "[]").map(
          (track: any) => {
            if (
              pendingTransactionsArray?.filter(
                (transaction: any) => transaction[0] === track?.sessionId
              )?.length > 0 &&
              runningTransactionLoad === false
            ) {
              setRunningTransactionLoad(true);
            } else if (
              (actionCase === 200 || actionCase === 201) &&
              track?.collectionId === collectionId &&
              runningTransactionLoad === false
            ) {
              setRunningTransactionLoad(true);
            }
          }
        );
      }
    }
  }, [pendingTransactionsArray]);

  useEffect(() => {
    if (item?.id) {
      setActionLoading(true);
      instanceOfAxios
        .get(
          `item_box_action/${item?.id}${
            address ? `?erd_address=${address}` : ""
          }`
        )
        .then((res: any) => {
          setActionAllowed(false);
          itemDetailsStore.setIsAuctionBoxStatusChange(false)
          setActionCase(res?.data?.code);
          if (res?.data?.data?.marketPlaceAddress) {
            dispatch({
              type: "setMarketAddress",
              marketAddress: res?.data?.data?.marketPlaceAddress,
            });
          }
          dispatch({ type: "actionCode", actionCode: res?.data?.code });
          dispatch({
            type: "marketplace_fee",
            marketplace_fee: res?.data?.data?.marketplaceFee || 3,
          });
          if (res?.data?.data?.auction) {
            setAuctionData(res?.data?.data);
          }
          setActionLoading(false);
          setCallGetAction(false);
        })
        .catch((error: any) => {
          setActionLoading(false);
          logger.warn("Error of getting the item status : ", error);
          console.log("err", error);
          return error;
        });
    }
  }, [item, callGetAction,itemDetailsStore.isAuctionBoxStatusChange]);

  useEffect(() => {
    if (
      (actionCase === 200 || actionCase === 201) &&
      actionCodeRefresh?.collection === collectionId &&
      actionCodeRefresh?.actionLoading === false
    ) {
      setCallGetAction(true);
      dispatch({
        type: "refreshActionCode",
        actionCodeRefresh: {
          item: 0,
          collection: 0,
          actionLoading: false,
        },
      });
    } else if (
      actionCodeRefresh?.item === item?.id &&
      actionCodeRefresh?.actionLoading === false
    ) {
      setCallGetAction(true);
      dispatch({
        type: "refreshActionCode",
        actionCodeRefresh: {
          item: 0,
          collection: 0,
          actionLoading: false,
        },
      });
    }
  }, [actionCodeRefresh]);

  useEffect(() => {
    switch (actionCase) {
      case 201:
        actionAllowed &&
          instanceOfAxios
            .get(`grant_transaction/address/${address}/${collectionId}`)
            .then(async (res: any) => {
              if (
                Number(account?.balance)  <
                res?.data?.value
              ) {
                message.error(t("action-box.not-enough-balance"));
              } else {
                const customtransaction = {
                  receiver: res?.data?.receiver,
                  gasLimit: res?.data?.gas_limit,
                  value: res?.data?.value ,
                  data: res?.data?.data_example,
                };
                logger.warn(
                  "-----we send to the transaction of set special role : ",
                  customtransaction
                );
                await refreshAccount();

                const { sessionId /*, error*/ } = await sendTransactions({
                  transactions: customtransaction,
                  transactionsDisplayInfo: {
                    processingMessage: t(
                      "action-box.processing-permission-transaction"
                    ),
                    errorMessage: t("action-box.permission-transaction-error"),
                    successMessage: t(
                      "action-box.permission-transaction-success"
                    ),
                  },
                  redirectAfterSign: false,
                });
                if (sessionId != null) {
                  setTransactionSessionId(sessionId);
                  //here we gonna save an object that contains this sessionId along with the collection id and item id, so we can catch the transaction result by the sessionId and link it to the specific collection/item ...
                  localStorage.setItem(
                    "transactions-track",
                    JSON.stringify([
                      ...JSON.parse(
                        localStorage.getItem("transactions-track") || "[]"
                      ),
                      {
                        itemId: item?.id,
                        collectionId: item?.collection?.id,
                        sessionId,
                        address,
                        actionCase: actionCase,
                        sentData: customtransaction?.data || "",
                      },
                    ])
                  );
                  //we save in context too, considering the case of no redirection , such as xPortal extension
                  dispatch({
                    type: "keepTrack",
                    transactionsTrack: JSON.parse(
                      localStorage.getItem("transactions-track") || "[]"
                    ),
                  });
                }
              }
            })
            .catch((err: any) => {
              logger.warn("Error of getting grant config : ", err);
              return "failed call for getting grant config";
            });

        break;

      case 203:
        actionAllowed &&
          instanceOfAxios
            .get(`upload_item_metadata/erd_address/${address}/item/${item?.id}`)
            .then((res: any) => {
              console.log("call to get metadata from IPFS");
              instanceOfAxios
                .get(`create_nft_sft/address/${address}/${item?.id}?type=nft`)
                .then(async (res: any) => {
                  if (
                    Number(account?.balance)<
                    res?.data?.value
                  ) {
                    message.error(t("action-box.not-enough-balance"));
                  } else {
                    const customtransaction = {
                      receiver: res?.data?.receiver,
                      gasLimit: res?.data?.gas_limit,
                      value: res?.data?.value ,
                      data: res?.data?.data_example,
                    };
                    logger.warn(
                      "-----we send to the transaction of create nft : ",
                      customtransaction
                    );
                    await refreshAccount();

                    const { sessionId /*, error*/ } = await sendTransactions({
                      transactions: customtransaction,
                      transactionsDisplayInfo: {
                        processingMessage: t(
                          "action-box.processing-nft-transaction"
                        ),
                        errorMessage: t("action-box.nft-transaction-error"),
                        successMessage: t("action-box.nft-transaction-success"),
                      },
                      redirectAfterSign: false,
                    });
                    if (sessionId != null) {
                      setTransactionSessionId(sessionId);
                      //here we gonna save an object that contains this sessionId along with the collection id and item id, so we can catch the transaction result by the sessionId and link it to the specific collection/item ...

                      localStorage.setItem(
                        "transactions-track",
                        JSON.stringify([
                          ...JSON.parse(
                            localStorage.getItem("transactions-track") || "[]"
                          ),
                          {
                            itemId: item?.id,
                            collectionId: item?.collection?.id,
                            sessionId,
                            address,
                            actionCase: actionCase,
                            sentData: customtransaction?.data || "",
                          },
                        ])
                      );
                      //we save in context too, considering the case of no redirection , such as xPortal extension
                      dispatch({
                        type: "keepTrack",
                        transactionsTrack: JSON.parse(
                          localStorage.getItem("transactions-track") || "[]"
                        ),
                      });
                    }
                  }
                })
                .catch((err: any) => {
                  OpenErrorNotification(
                    t("profile.error"),
                    t("profile.error-desc"),
                    3
                  );
                  logger.warn("Error of getting NFT config : ", err);
                  setCreateNFTLoading(false);
                  return "failed call for get NFT config";
                });
            })
            .catch((err: any) => {
              setCreateNFTLoading(false);
              OpenErrorNotification(
                t("profile.error"),
                t("action-box.upload-ipfs-fail"),
                3
              );
              logger.warn("ERROR in uploading to ipfs : ", err);
              return "failed call for upload to ipfs";
            });

        break;

      default:
        break;
    }
  }, [actionCase, transactionData, actionAllowed, brandType]);

  return (
    <>
      {getActionLoading ? (
        <div className="action-switch-container centered-load">
          <Spin />
        </div>
      ) : actionCodeRefresh?.item === item?.id &&
        actionCodeRefresh?.actionLoading === true ? (
        <div className="action-switch-container centered-load">
          <div>
            <h4>
              {actionCase === 200
                ? t("action-box.create-brand-wait-message")
                : actionCase === 201
                ? t("action-box.create-grant-wait-message")
                : actionCase === 203
                ? t("action-box.create-nft-wait-message")
                : actionCase === 204
                ? t("action-box.create-sft-wait-message")
                : actionCase === 301
                ? t("action-box.end-auction-wait-message")
                : actionCase === 304
                ? t("action-box.send-bid-wait-message")
                : actionCase === 300 && actionCodeRefresh?.giftCase
                ? t("action-box.send-gift-wait-message")
                : ""}
            </h4>
            {actionCase === 300 && !actionCodeRefresh?.giftCase ? (
              <Result
                icon={<img src={auctionIcon} style={{ width: "60px" }} />}
                title={<h3>{t("action-box.create-auction-title")}</h3>}
                subTitle={
                  <h5>{t("action-box.create-auction-wait-message")}</h5>
                }
              />
            ) : (
              <Spin indicator={antIcon} />
            )}
          </div>
        </div>
      ) : actionCodeRefresh?.item === item?.id &&
        actionCodeRefresh?.actionError === true ? (
        <div className="action-switch-container centered-load">
          <h1>{t("action-box.item-action-box-generic-error")}</h1>
        </div>
      ) : (
        <div
          className={
            actionCase === 0
              ? ""
              : runningTransactionLoad
              ? "action-switch-container action-box-loading"
              : actionCase === 305?
              "action-switch-container-make-offre":
               "action-switch-container"
          }
        >
          {runningTransactionLoad && (
            <div className="loading-filter">
              <Spin />
            </div>
          )}
          <div className="action-box-content">
            {actionCase === 100 ? (
              <AdminApproveDisplay item={item} />
            ) : actionCase === 102 ? (
              <h4>{t("action-box.waiting-for-approval")}</h4>
            ) : actionCase === 201 ? (
              <RegisterGrantDisplay
                setRegisterGrant={() => setActionAllowed(true)}
              />
            ) : actionCase === 202 ? (
              <h4>{t("action-box.must-be-n-items", { numb: 5 })}</h4>
            ) : actionCase === 200 ? (
              <RegisterBrandDisplay item={item} />
            ) : actionCase === 203 ? (
              <SuccessRegisterDisplay
                type="nft"
                loading={createNFTLoading}
                createNFTorSFT={() => {
                  setActionAllowed(true);
                  setCreateNFTLoading(true);
                }}
              />
            ) : actionCase === 204 ? (
              <SuccessRegisterDisplay
                type="sft"
                loading={false}
                createNFTorSFT={setActionAllowed}
              />
            ) : actionCase === 205 ? (
              <ReleaseNFTsoon />
            ) : actionCase === 300 ? (
              <SetupAuctionDisplay item={item} />
            ) : actionCase === 304 ? (
              <AuctionDisplay
                gasLimit={auctionData?.gasLimit || 0}
                item={item}
              />
            ) : actionCase === 305 ? (
              <MakeOfferDisplay item={item} userCurrency={userCurrency} />
            ) : actionCase === 301 ? (
              <ClaimNFT auctionData={auctionData} item={item} />
            ) : null}
            <CreateSFT
              visible={actionCase === 204 && actionAllowed}
              handleOk={(nbr: number) => {
                setActionAllowed(true);
                setSFTCount(nbr);
              }}
              handleCancel={() => setActionAllowed(false)}
            />
          </div>

          <LoadingModal
            visible={transactionModal}
            title={t("action-box.transaction-success-title")}
            message={
              actionCase === 200
                ? t("action-box.create-brand-wait-message")
                : actionCase === 201
                ? t("action-box.create-grant-wait-message")
                : actionCase === 203
                ? t("action-box.create-nft-wait-message")
                : actionCase === 204
                ? t("action-box.create-sft-wait-message")
                : actionCase === 301
                ? t("action-box.end-auction-wait-message")
                : actionCase === 304
                ? t("action-box.send-bid-wait-message")
                : ""
            }
          />
          <LoadingModal
            visible={createAuctionModal}
            title={t("action-box.create-auction-title")}
            message={t("action-box.create-auction-wait-message")}
            auctionSetup={true}
          />
          <NotConnectedModal visible={notConnectedVisible} />
        </div>
      )}
    </>
  );
});

export default ActionSwitch;
