import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isValidURL } from '../../../../../helpers/validateUrl';
import './collectionType.less';
export interface CollectionNewProps {
	collection_type_id: number;
	collection_type_name: string;
	collection_type_name_i18ntag: string;
}

const CollectionItemNew = ({
	collection_type_id,
	collection_type_name,
	collection_type_name_i18ntag
}: CollectionNewProps) => {
	const { t } = useTranslation();
	const  navigate = useNavigate();
	const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL;
	const serverImageBaseURL = process.env.REACT_APP_SERVER_IMAGE_BASE_URL;

	if (!serverBaseURL) {
		throw new Error();
	}
	if (!serverImageBaseURL) {
		throw new Error();
	}
	const backPhotoAPI = serverBaseURL + serverImageBaseURL;
	if (!isValidURL(backPhotoAPI)) {
		throw new Error();
	}

	const redirectToCreate = () => {
		window.scrollTo(0, 0);
		navigate(`/create-collection/${collection_type_id}/${collection_type_name_i18ntag}`);
	};
	let image = require('../../../../../../assets/images/' + collection_type_name_i18ntag + '.svg').default;
	return (
		<div className="collection-item" onClick={redirectToCreate}>
			<h5>
				{' '}
				{collection_type_name_i18ntag === 'private' ? (
					t('collection-type-private')
				) : collection_type_name_i18ntag === 'charity' ? (
					t('collection-type-charity')
				) : collection_type_name_i18ntag === 'standard' ? (
					t('collection-type-standard')
				) : (
					t('collection-type-open')
				)}
			</h5>

			<div className="image-collection">
				<img alt="collection icon" src={`${image}`} className="existed-collection-image" />
			</div>
			<h5 className="text-regular-font desc-text">{t('collection-desc-' + collection_type_name_i18ntag)}</h5>
		</div>
	);
};

export default CollectionItemNew;
