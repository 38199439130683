import { Col, Row } from "antd";
import { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import instanceOfAxios from "../../../../../configAxios";
import EmailConfirm from "../../../../components/modal/email confirm/emailConfirm";
import ItemForm from "../../components/itemForm";
import "./createItem.less";

export interface CreateItemProps {}

const CreateItem = (props: any) => {
  const { t } = useTranslation();
  const params = useParams();
  const currentUser = JSON.parse(localStorage.getItem("user") ?? "{}");
  const [collection, setCollection] = useState<any>({});
  const [emailModal, setEmailModal] = useState(false);

  useEffect(() => {
    params.collectionId &&
      instanceOfAxios
        .get(`collections/${params.collectionId}`)
        .then((res) => {
          setCollection(res?.data);
        })
        .catch((error) => {
          return error;
        });

    if (currentUser?.email === "" || !currentUser?.opt_in) {
      setEmailModal(true);
    }
  }, []);

  return (
    <div className="create-collection-page">
      <Row>
        <Col span={24} className="create-collection-header">
          <h2 className="text-regular-font">
            {params.collectionId
              ? t("collection-page.create-item")
              : t("collection-page.edit-item")}
          </h2>
          {params.collectionId ? (
            <>
              <h5 className="text-regular-font">
                {t("collection-page.create-description")}
                <span
                  className="create-item-collection-name"
                  onClick={() =>
                    window.open(
                      `${window.location.origin}/${collection?.homepage}`
                    )
                  }
                >
                  {collection?.collection_name}
                </span>
              </h5>
            </>
          ) : (
            <>
              <h5 className="text-regular-font">
                {t("collection-page.update-description")}
                <span
                  className="create-item-collection-name"
                  onClick={() =>
                    window.open(
                      `${window.location.origin}/${collection?.homepage}`
                    )
                  }
                >
                  {collection?.collection_name}
                </span>
              </h5>
            </>
          )}
        </Col>
      </Row>
      <ItemForm
        collectionId={params?.collectionId!}
        setCollection={setCollection}
        itemId={params?.itemId!}
      />
      {emailModal && (
        <EmailConfirm
          confirmVisible={emailModal}
          handleClose={() => setEmailModal(false)}
          email={currentUser?.email}
          emailInput={true}
          closable={false}
        />
      )}
    </div>
  );
};

export default CreateItem;
