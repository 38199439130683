import { useTranslation } from "react-i18next";

interface ReleaseNFTsoonProps {}

const ReleaseNFTsoon = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className="primary-color">{t("action-box.congrats-art-welcome")}</h4>
      <h5 className="action-description-text">
        {t("action-box.NFT-coming-soon")}
      </h5>
    </div>
  );
};

export default ReleaseNFTsoon;
