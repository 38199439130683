import CustomImage from "../../../../components/image/customImage";
import useImageDisplay from "../../../../helpers/useImageDisplay";
import { isValidURL } from "../../../../helpers/validateUrl";

interface ImgnotifProps {
  item: any
}

const Imgnotif = ({ item }: ImgnotifProps) => {
  
  return (
    <span>
      <img alt="notifIcon" src={useImageDisplay(item?.icon,'__t'+item?.iconExt)} className="notifImg" />

      {(item?.smallIcon !== null) ?
        // <img alt="" src={item?.smallIcon?.includes("http") ? item?.smallIcon : IMG_URL + item?.smallIcon} className="icon-notif-gr" />
        <CustomImage image={item?.smallIcon} extensionImage={item?.smallIconExt} className={"icon-notif-gr"} />
        : ((item?.collectionType !== null) && (item?.eventGroup === "collection")) ?
          <img alt="icon-notif" src={`../../../../../assets/images/${item?.collectionType}.svg`} className="icon-notif" />
          : ""
      }
    </span>
  )
}

export default Imgnotif;

