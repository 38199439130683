import React, { useRef } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

function ObjModel({ modelPath }) {
	const mesh = useRef();
	useFrame(() => (mesh.current.rotation.y += 0.005));
	const obj = useLoader(OBJLoader, modelPath);

	return <primitive ref={mesh} object={obj} />;
}

export default ObjModel;
