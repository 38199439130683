import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Divider, message, Row, Col, Tooltip } from "antd";
import { CountryDropdown } from "react-country-region-selector";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./generalInfo.less";
import LightButton from "../../../../components/buttons/lightButton";
import UploadAvatar from "../../components/upload avatar/uploadAvatar";
import copyIcon from "../../../../../assets/images/icon copy.svg";
import { useDispatch, useContext } from "../../../../context";
import instanceOfAxios from "../../../../../configAxios";
import { getItem } from "../../../../storage/session";
import { getItemFromLocalStorage } from "../../../../helpers/useLocalStorage";
import { OpenErrorNotification } from "../../../../components/toasts/openToast";

const { TextArea } = Input;

export interface GeneralInfoProps {
  userInfos: any;
  setUserInfos: (obj: any) => void;
}

const GeneralInfo = ({ userInfos, setUserInfos }: GeneralInfoProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { address } = useContext();
  const currentEmail = JSON.parse(localStorage.getItem("user") || "{}")?.email;

  useEffect(() => {
    form.setFieldsValue(userInfos);
  }, [userInfos]);

  const onChangeAttribute = (obj: Object) => {
    setUserInfos({ ...userInfos, ...obj });
  };

  const linkCopied = () => {
    message.success(t("profile.link-copied"));
  };

  const onChangeAvatar = (img: string, ext: string) => {
    onChangeAttribute({ avatar: img });
    dispatch({
      type: "setProfile",
      user: { ...userInfos, avatar: img, avatar_ext: ext },
    });
    setUserInfos({ ...userInfos, avatar: img, avatar_ext: ext });
  };

  const defaultUser = {
    bio: "",
    country: "",
    currency: "EUR",
    discord: "",
    email: userInfos?.email && userInfos?.opt_in ? userInfos?.email : "",
    instagram: "",
    language: "en",
    lastname: "",
    location: "",
    medium: "",
    name: "",
    nickname: "",
    telegram: "",
    twitter: "",
    avatar: "",
  };

  const resetAccount = () => {
    instanceOfAxios
      .delete("upload/" + address, {
        headers: {
          "x-access-token": getItemFromLocalStorage("token"),
        },
      })
      .then((res: any) => {
        instanceOfAxios
          .put("profile/" + address, defaultUser, {
            headers: {
              "x-access-token": getItemFromLocalStorage("token"),
            },
          })
          .then((res: any) => {
            dispatch({ type: "setProfile", user: res.data });
            setUserInfos(res.data);
            message.success("Reset account successfully");
          })
          .catch((err: any) => {});
        onChangeAvatar(res?.data?.avatar, res?.data?.avatar_ext);
      })
      .catch((err: any) => {
        if(err?.response.status===401){
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-unauthorized-user"),
            3
          );                    
        }else{
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        } 
      });
  };

  return (
    <div>
      <UploadAvatar
        avatar={userInfos?.avatar}
        ext={userInfos?.avatar_ext}
        onChangeAvatar={onChangeAvatar}
      />
      <div>
        <Row>
          <Col span={24}>
            <h5 className="primary-color">
              {t("profile.account-informations")}
            </h5>
          </Col>
        </Row>

        <Form
          name="global_state"
          form={form}
          layout="inline"
          onValuesChange={onChangeAttribute}
          initialValues={userInfos}
          style={{ margin: "20px 0" }}>
          <div className="profile-attribute-holder">
            <h5 className="attribute-label text-regular-font">{`${t(
              "profile.erd-address"
            )} :`}</h5>
            <Form.Item>
              <div className="erd-address-field">
                <div className="erd-address-input">
                  <div>{`${userInfos?.erd_address}`}</div>
                </div>
                <CopyToClipboard
                  text={userInfos?.erd_address}
                  onCopy={linkCopied}>
                  <div className="copy-button">
                    <img alt="copyIcon" src={copyIcon} />
                  </div>
                </CopyToClipboard>
              </div>
            </Form.Item>
          </div>
          <div className="profile-attribute-holder">
            <h5 className="attribute-label text-regular-font">{`${t(
              "profile.nickname"
            )} :`}</h5>
            <Form.Item name="nickname">
              <Input placeholder={t("profile.nickname-placeholder")} />
            </Form.Item>
          </div>

          <div className="profile-attribute-holder double-attribute-row">
            <div className="double-attribute-row-element">
              <h5 className="attribute-label text-regular-font">{`${t(
                "profile.first-name"
              )} :`}</h5>
              <Form.Item name="name">
                <Input placeholder={t("profile.first-name-placeholder")} />
              </Form.Item>
            </div>
            <div className="double-attribute-row-element">
              <h5 className="attribute-label text-regular-font">{`${t(
                "profile.last-name"
              )} :`}</h5>
              <Form.Item name="lastname">
                <Input placeholder={t("profile.last-name-placeholder")} />
              </Form.Item>
            </div>
          </div>

          <div className="profile-attribute-holder">
            <h5 className="attribute-label text-regular-font">{`${t(
              "profile.email"
            )} :`}</h5>
            <Form.Item
              name="email"
              rules={[
                { type: "email", message: t("profile.valid-email-required") },
              ]}>
              <Input
                placeholder={t("profile.email-placeholder")}
                suffix={
                  <Tooltip
                    title={
                      userInfos?.opt_in
                        ? t("profile.verified")
                        : t("profile.verification-pending")
                    }>
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            {!userInfos?.opt_in &&
              userInfos?.email &&
              userInfos?.email !== "" &&
              userInfos?.email === currentEmail && (
                <h5 className="red-color">
                  {t("profile.please-verify-email", {
                    email: userInfos?.email,
                  })}
                </h5>
              )}
          </div>

          <div className="profile-attribute-holder">
            <h5 className="attribute-label text-regular-font">{`${t(
              "profile.bio"
            )} :`}</h5>
            <Form.Item name="bio">
              <TextArea
                placeholder={t("profile.bio-placeholder")}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
          </div>

          <div className="profile-attribute-holder double-attribute-row">
            <div className="double-attribute-row-element">
              <h5 className="attribute-label text-regular-font">{`${t(
                "profile.location"
              )} :`}</h5>
              <Form.Item name="location">
                <Input placeholder={t("profile.location-placeholder")} />
              </Form.Item>
            </div>
            <div className="double-attribute-row-element">
              <h5 className="attribute-label text-regular-font">{`${t(
                "profile.country"
              )} :`}</h5>
              <Form.Item name="country">
                <CountryDropdown
                  classes="profile-input"
                  defaultOptionLabel={t("profile.country-placeholder")}
                  value={userInfos?.country}
                  valueType="short"
                  labelType="full"
                  disabled={false}
                  onChange={(e) => onChangeAttribute({ country: e })}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      <Divider />
      <Row>
        <Col span={24} className="delete-part">
          <div>
            <h5 className="primary-color">{t("profile.reset-account")}</h5>
            <h6 className="text-regular-font">
              {t("profile.reset-account-desc")}
            </h6>
          </div>
          <div>
            <LightButton onClick={resetAccount}>
              {t("profile.reset-account")}
            </LightButton>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GeneralInfo;
