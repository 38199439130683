import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Form, Input, Button, Upload, message, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LightButton from "../../../components/buttons/lightButton";
import ItemAttributes from "./item attributes/itemAttributes";
import EditorArea from "../../../components/editorArea/editorArea";
import instanceOfAxios from "../../../../configAxios";
import { useContext } from "../../../context";
import CustomModal from "../../../components/modal/customModal";
import { setItem } from "../../../storage/session";
import ItemPhotos from "./itemPhotos/itemPhotos";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/toasts/openToast";
import { getItemFromLocalStorage } from "../../../helpers/useLocalStorage";
import { Helmet } from "react-helmet";
import { useGlobalContext } from "../../../context/globalContext";
import { observer } from "mobx-react-lite";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account";

const { Dragger } = Upload;

export interface customAttributeObject {
  attribute: string;
  values: string[];
}

const ItemForm =observer(({
  collectionId,
  setCollection,
  itemId,
}: {
  setCollection: any;
  collectionId: string;
  itemId: string;
}) => {
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const { address } = useContext();
  const [form] = Form.useForm();
  const {itemFormStore}=useGlobalContext()
  const [description, setDescription] = useState("");
  const [requiredMissing, setRequiredMissing] = useState(false);
  const [customArray, setCustomArray] = useState<customAttributeObject[]>([]);
  const [itemAttributes, setItemAttributes] = useState({
    mandatory: [],
    optional: [],
  });
  const imageRef = useRef<HTMLDivElement>(null);
  const descRef = useRef<HTMLDivElement>(null);
  const attributesRef = useRef<HTMLDivElement>(null);
  const [royalties, setRoyalties] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newitemId, setNewItemId] = useState("");
  const [collAttributes, setCollAttributes] = useState<any>({
    mandatory: [],
    optional: [],
  });
  const [itemDetails, setItemsDetails] = useState<any>();
  const [itemPhotos, setItemPhotos] = useState<any[]>([]);
  const [croppedPhotos, setCroppedPhotos] = useState<any[]>([]);
  const [originalFiles, setOriginalFiles] = useState<any[]>([]);
  const [photosChanged, setPhotosChanged] = useState(false);
  const { address:userAddress}= useGetAccountInfo();

  useEffect(() => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      navigate("/auth");
    }

    form.setFieldsValue({ project_link: "" });

    collectionId &&
      instanceOfAxios
        .get(`check_permission/collection/${collectionId}/address/${address}`)
        .then((res) => {
          if (res?.data?.code === 4) {
            OpenErrorNotification(
              t("profile.error"),
              t("collection-page.dont-have-access"),
              4
            );
            setItem("goToUrl", window.location.href);
            window.scrollTo(0, 0);
            navigate("/auth");
          }
        });

    collectionId && getCollectionCall(collectionId);

    itemId &&
      instanceOfAxios
        .get("item/" + itemId + "?erd_address=" + address)
        .then((res) => {
          getCollectionCall(res?.data?.collection?.id);
          setItemsDetails(res?.data);
          form.setFieldsValue(res?.data);
          handleRecieveAttributes(res?.data?.item_attributes);
          if(res?.data?.creator?.erd_address !==userAddress){
            navigate(`/item-details/${itemId}`);
          }
          console.log("rerere")

          const cusAtt = res?.data?.item_custom_attributes;
          let cusObj: any[] = [];
          for (let i = 0; i < cusAtt.length; i++) {
            if (!cusObj.some((el: any) => el.attribute === cusAtt[i].key)) {
              cusObj.push({
                attribute: cusAtt[i].key,
                values: [cusAtt[i].value],
              });
            } else {
              cusObj[
                cusObj.findIndex((el: any) => el.attribute === cusAtt[i].key)
              ].values.push(cusAtt[i].value);
            }
          }
          setCustomArray(cusObj);
        });
  }, []);

  useLayoutEffect(() => {});  

  const getCollectionCall = (id: any) => {
    instanceOfAxios
      .get(`collections/${id}?profile_id=${JSON.parse(localStorage.getItem("user") || "{}")?.id}`)
      .then((res) => {
        setRoyalties(res?.data?.royalties);
        setCollection(res?.data);
        itemFormStore.setCollectionData(res?.data)
        setCollAttributes({
          mandatory: res?.data?.mandatory_attributes,
          optional: res?.data?.optional_attributes,
        });
      })
      .catch((error) => {
        return error;
      });
  };

  const handleRecieveAttributes = (itemAtt: any[]) => {
    let tempAtt: any = {
      mandatory: [],
      optional: [],
    };
    for (let i = 0; i < itemAtt.length; i++) {
      switch (itemAtt[i]?.collection_attribute?.type) {
        case "mandatory":
          tempAtt.mandatory.push({
            value: itemAtt[i]?.value,
            collection_attribute_id: itemAtt[i]?.collection_attribute?.id,
          });
          break;

        case "optional":
          tempAtt.optional.push({
            value: itemAtt[i]?.value,
            collection_attribute_id: itemAtt[i]?.collection_attribute?.id,
          });
          break;
        default:
          break;
      }

      setItemAttributes(tempAtt);
    }
  };

  const verifyMandatory = () => {
    let full = true;
    for (let i = 0; i < collAttributes?.mandatory.length; i++) {
      if (
        itemAttributes?.mandatory.some(
          (el: any) =>
            el.collection_attribute_id === collAttributes?.mandatory[i].id
        ) === false
      ) {
        return false;
      }
    }
    return full;
  };

  const verifyCustom = () => {
    let full = true;
    let cusObj: customAttributeObject[] = [];
    customArray.map((el: customAttributeObject) => {
      if (el.attribute !== "" && el.values.length > 0) {
        cusObj.push(el);
        return true;
      } else if (el.attribute === "" && el.values.length === 0) {
        return true;
      } else {
        full = false;
        return full;
      }
    });
    return full;
  };

  const onFinish = (values: any) => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    }
    if (
      verifyMandatory() === false ||
      description?.length <
        (process.env.REACT_APP_SERVER_ELROND != "mainnet" ? 3 : 120) ||
      originalFiles?.length === 0 ||
      verifyCustom() === false
    ) {
      setRequiredMissing(true);
      if (originalFiles?.length === 0 && imageRef.current) {
        imageRef.current.scrollIntoView();
      } else if (
        (description === "" || description === "<p><br></p>") &&
        descRef.current
      ) {
        descRef.current.scrollIntoView();
      } else if (
        (verifyMandatory() === false || verifyCustom() === false) &&
        attributesRef.current
      ) {
        attributesRef.current.scrollIntoView();
      }
      return 0;
    } else {
      if (!form.getFieldValue("project_link")) {
        values.project_link = "";
      }

      setButtonLoading(true);
      setIsLoading(true)
      if (collectionId) {
        values.collection_id = collectionId;
      } else {
        values.collection_id = itemDetails?.collection?.id;
      }
      values.description = description;
      values.created_on_blockchain = false;
      values.royalties = royalties;
      let item_attributes = "";
      for (let i = 0; i < itemAttributes?.mandatory.length; i++) {
        if (item_attributes === "") {
          item_attributes = JSON.stringify(itemAttributes?.mandatory[i]);
        } else {
          item_attributes =
            item_attributes +
            "," +
            JSON.stringify(itemAttributes?.mandatory[i]);
        }
      }
      for (let i = 0; i < itemAttributes?.optional.length; i++) {
        if (item_attributes === "") {
          item_attributes = JSON.stringify(itemAttributes?.optional[i]);
        } else {
          item_attributes =
            item_attributes + "," + JSON.stringify(itemAttributes?.optional[i]);
        }
      }
      values.item_attributes = item_attributes;

      let item_custom_attributes = "";
      for (let i = 0; i < customArray.length; i++) {
        for (let j = 0; j < customArray[i].values.length; j++) {
          if (item_custom_attributes === "") {
            item_custom_attributes = JSON.stringify({
              key: customArray[i].attribute,
              value: customArray[i].values[j],
            });
          } else {
            item_custom_attributes =
              item_custom_attributes +
              "," +
              JSON.stringify({
                key: customArray[i].attribute,
                value: customArray[i].values[j],
              });
          }
        }
      }
      values.item_custom_attributes = item_custom_attributes;

      if (!collectionId) {
        //here in the case of edit: we send this important var
        values.isChanged = photosChanged;
      }

      let formData = new FormData();
      for (var key in values) {
        formData.append(key, values[key]);
      }

      if (croppedPhotos?.length > 0) {
        for (let i = 0; i < croppedPhotos?.length; i++) {
          formData.append(`uri${i !== 0 ? i : ""}`, croppedPhotos[i]);
          if(croppedPhotos[i]?.name && originalFiles[i]?.size){
            formData.append(
              `uri${i !== 0 ? i : ""}_metadata`,
              JSON.stringify({
                name: croppedPhotos[i]?.name,
                size: originalFiles[i]?.size,
                type: croppedPhotos[i]?.type,
                width: croppedPhotos[i]?.imgWidth,
                height: croppedPhotos[i]?.imgHeight,
              })
            );
          }else {
            formData.append(`uri${i !== 0 ? i : ""}_metadata`,JSON.stringify(null));
          }
        }
      }

      if (originalFiles?.length > 0) {
        for (let i = 0; i < originalFiles?.length; i++) {
          formData.append(`originalUri${i !== 0 ? i : ""}`, originalFiles[i]);
        }
      }
      if (collectionId) {      
        instanceOfAxios
          .post(`item/address/${address}`, formData, {
            headers: {
              "x-access-token": getItemFromLocalStorage("token"),
            },
          })
          .then((res: any) => {
            if (res?.data?.id) {           
              setButtonLoading(false);
              setNewItemId(res?.data?.id);
              setSuccessModal(true);
            } else if (res?.data?.Code === 4) {
              OpenErrorNotification(
                t("profile.error"),
                t("collection-page.dont-have-access"),
                4
              );            
            }
            itemFormStore.setExtraFilePath(null)
            itemFormStore.setIsConfirmExtraFile(false)
            setButtonLoading(false);
            setIsLoading(false)
          })
          .catch((err: any) => {
            if(err?.response.status===401){
              OpenErrorNotification(
                t("profile.error"),
                t("profile.error-unauthorized-user"),
                3
              );                    
            }else{
              OpenErrorNotification(
                t("profile.error"),
                t("profile.error-desc"),
                3
              );
            }
            setButtonLoading(false);
            setIsLoading(false)
          });
      } else {  
        console.log("croppedPhotos ed===========>",croppedPhotos)     
        console.log("originalFiles ed===========>",originalFiles)     
        instanceOfAxios
          .put(`item/${itemDetails?.id}/address/${address}`, formData, {
            headers: {
              "x-access-token": getItemFromLocalStorage("token"),
            },
          })
          .then((res: any) => {
            if (collectionId) {
              setButtonLoading(false);
              setNewItemId(res?.data?.id);
              setSuccessModal(true);
              setButtonLoading(false);
            }
             else {
              setButtonLoading(false);
              setIsLoading(false)
              OpenSuccessNotification(
                t("profile.success"),
                t("profile.success-desc"),
                3
              );        
              setButtonLoading(false);
              setIsLoading(false)   
              window.scrollTo(0, 0);
              navigate(`/item-details/${itemId}`);
            }
          })
          .catch((err: any) => {
            if(err?.response.status===401){
              OpenErrorNotification(
                t("profile.error"),
                t("profile.error-unauthorized-user"),
                3
              );                    
            }else if(err?.response.status===403){
              navigate(`/item-details/${itemId}`);
              OpenErrorNotification(
                t("profile.error"),
                t("errors.error-unauthorized-user"),
                3
              );
            }
            else{
              OpenErrorNotification(
                t("profile.error"),
                t("profile.error-desc"),
                3
              );
            }           
            setButtonLoading(false);
            setIsLoading(false)
          });
      }
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };  

  useEffect(() => {
    console.log("originalFiles ========>",originalFiles)
    console.log("croppedPhotos ========>",croppedPhotos)
  }, [originalFiles,croppedPhotos])
  
  
  return (
    <>
      <Helmet>
        <title>Rarity.market | Create Item</title>
      </Helmet>
      {isLoading 
      && <div className="item-add-spinner">
              <Spin size="large" />
      </div>}
    <Form
      name="basic"
      onFinish={onFinish}
      className="create-item-container"
      scrollToFirstError={true}
      form={form}>
      <div className="upload-collection-picture-container">
        <h5>
          {itemFormStore.showPhotosBlock && `${t("collection-page.item-picture")}`}
          {itemFormStore.showAudioBlock && `${t("collection-page.item-audio")}`}
          {itemFormStore.showVideoBlock && `${t("collection-page.item-video")}`}
          {itemFormStore.show3DBlock && `${t("collection-page.item-3d")}`}
          <span className="required-field">*</span>:
        </h5>
        <h5 className="text-regular-font">
          {itemFormStore.showPhotosBlock && t("collection-page.collection-picture-description", {
            recomended_image_size: "520x520",
          })}
          {itemFormStore.showAudioBlock && t("collection-page.collection-audio-description")}
          {itemFormStore.showVideoBlock && t("collection-page.collection-video-description")}
          {itemFormStore.show3DBlock && t("collection-page.collection-3d-description")}
        </h5>
        <div className="upload-box" ref={imageRef}>
          <Form.Item
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle>
            <ItemPhotos
              display={false}
              photosArray={itemPhotos}
              setPhotosArray={setItemPhotos}
              croppedArray={croppedPhotos}
              setCropped={(val: any) => {
                console.log("val ========>",val)
                setCroppedPhotos(val);
              }}
              originalFiles={originalFiles}
              setOriginalFiles={setOriginalFiles}
              itemsDetails={collectionId && itemId ? [] : itemDetails}
              setButtonDisabled={setButtonDisabled}
              setPhotosChanged={setPhotosChanged}
            />
          </Form.Item>
          {requiredMissing && (croppedPhotos?.length === 0 || originalFiles?.length === 0) ? (
            <h4 className="red-color">
              {t("collection-page.required-item-photo")}
            </h4>
          ) : null}
        </div>
      </div>
      <div className="collection-form-container">
        <h5>
          {`${t("collection-page.item-name")}`}
          <span className="required-field">*</span>:
        </h5>
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: t("collection-page.required-item-name"),
            },
            () => ({
              validator(_, value) {
                if (value?.length <= 255) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("collection-page.item-title-max-error")
                );
              },
            }),
          ]}>
          <Input />
        </Form.Item>
        <h5>
          {`${t("collection-page.item-description")}`}
          <span className="required-field">*</span>:
        </h5>
        <Form.Item name="description">
          <div ref={descRef}>
            <EditorArea
              defaultDesc={itemId ? itemDetails?.description : "<p></p>"}
              onChange={(e) => setDescription(e)}
            />
          </div>
          {requiredMissing &&
          description?.length <
            (process.env.REACT_APP_SERVER_ELROND != "mainnet" ? 3 : 120) ? (
            <h4 className="red-color">
              {t("collection-page.required-collection-description")}
            </h4>
          ) : null}
        </Form.Item>
        <h5>{`${t("collection-page.project-link")} :`}</h5>
        <Form.Item name="project_link">
          <Input />
        </Form.Item>
        <div ref={attributesRef}>
          <ItemAttributes
            customArray={customArray}
            setCustomArray={setCustomArray}
            collectionAttributes={collAttributes}
            itemAttributes={itemAttributes}
            setItemAttributes={setItemAttributes}
            collectionId={collectionId || itemDetails?.collection?.id}
          />
          {requiredMissing && verifyMandatory() === false ? (
            <h4 className="red-color" style={{ padding: "0 0 30px 0" }}>
              {t("collection-page.required-collection-mandatory-attributes")}
            </h4>
          ) : requiredMissing && verifyCustom() === false ? (
            <h4 className="red-color" style={{ padding: "0 0 30px 0" }}>
              {t("collection-page.required-collection-custom-attributes")}
            </h4>
          ) : null}
        </div>
        <Form.Item>
          <div className="form-buttons">
            <div className="form-cancel-button">
              <LightButton
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/");
                }}>
                {t("common.cancel")}
              </LightButton>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              disabled={buttonDisabled}
              loading={buttonLoading}>
              {collectionId
                ? t("collection-page.upload-item")
                : t("collection-page.edit-item")}
            </Button>
          </div>
        </Form.Item>
      </div>
      <CustomModal
        width="450px"
        visible={successModal}
        closable={false}
        handleOk={() => setSuccessModal(false)}
        handleCancel={() => setSuccessModal(false)}>
        <div>
          <div className="item-success-title">
            <h4>{t("collection-page.create-item-successfull")} </h4>
          </div>
          <div className="success-modal-buttons">
            <Button
              type="primary"
              block
              className="success-modal-button"
              onClick={() => window.location.reload()}>
              {t("collection-page.add-another-item")}
            </Button>
            <button
              className=" ant-btn-primary ant-btn-block success-modal-button custom-item-btn"
              onClick={() => {
                setSuccessModal(false);
                setCroppedPhotos([]);
                setItemPhotos([]);
                setOriginalFiles([]);
              }}>
              {t("collection-page.add-item-same-data")}
            </button>
            <Button
              type="default"
              block
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/item-details/${newitemId}`);
              }}
              className="success-modal-button">
              {t("collection-page.see-item")}
            </Button>
          </div>
        </div>
      </CustomModal>
    </Form>
    </>
  );
});

export default ItemForm;
