import React, { useState, useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import userIcon from "../../../../../assets/images/user.svg";
import socialIcon from "../../../../../assets/images/social link .svg";
import settingsIcon from "../../../../../assets/images/settings.svg";
import orangeuserIcon from "../../../../../assets/images/orangeuser.svg";
import orangesocialIcon from "../../../../../assets/images/orangeshare.svg";
import orangesettingsIcon from "../../../../../assets/images/orangesettings.svg";
import mediumIcon from "../../../../../assets/images/medium.svg";
import twitterIcon from "../../../../../assets/images/twitter.svg";
import telegramIcon from "../../../../../assets/images/telegram.svg";
import instagramIcon from "../../../../../assets/images/icon instagram.svg";
import "./editProfile.less";
import LightButton from "../../../../components/buttons/lightButton";
import LinkInput from "../../../../components/input/link input/linkInput";
import GeneralInfo from "../general info/generalInfo";
import Settings from "../../components/settings/settings";
import NotificationsPart from "../../components/notifications part/notificationsPart";
import ProfileMobileTab from "../../components/profile mobile tab/ProfileMobileTab";
import { useContext, useDispatch } from "../../../../context";
import instanceOfAxios from "../../../../../configAxios";
import { User } from "../../../../context/state";
import { getItem, setItem } from "../../../../storage/session";
import i18n from "../../../../../i18n";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../components/toasts/openToast";
import {
  mediumRegex,
  twitterRegex,
  telegramRegex,
  instagramRegex,
} from "../../../../helpers/regularExpressions";
import EmailConfirm from "../../../../components/modal/email confirm/emailConfirm";
import { getItemFromLocalStorage } from "../../../../helpers/useLocalStorage";

export let util = { getUserCall: () => {}, saveChangesCall: () => {} };

const EditProfile = () => {
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const { address, loading } = useContext();
  const dispatch = useDispatch();
  const [profilePart, setProfilePart] = useState("general-informations");
  const [userInfos, setUserInfos] = useState<User>(
    JSON.parse(localStorage.getItem("user") || "{}")
  );
  const [currentLang, setCurrentLanguage] = useState<any>("");
  const [currencies, setCurrencies] = useState<any>();
  const [emailVerif, setEmailVerif] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const currentEmail = JSON.parse(localStorage.getItem("user") || "{}")?.email;

  util.getUserCall = () => {
    instanceOfAxios
      .get("profile/" + address,{
        headers: {
          "x-access-token": getItemFromLocalStorage("token"),
        },        
      })
      .then((res: any) => {
        setUserInfos(res.data);
        dispatch({ type: "setProfile", user: userInfos });
        return "successfull call for get user";
      })
      .catch((err: any) => {
        return "failed call for get user";
      });
  };
  const handelchngelong = (val: any) => {
    setCurrentLanguage(val);
  };

  const getListCurrencies = () => {
    instanceOfAxios.get("currencies_list").then((res: any) => {
      setCurrencies(res.data.nodes);
    });
  };

  useEffect(() => {
    getListCurrencies();

    if (address) {
      util.getUserCall();
    } else {
      navigate("/auth");
    }
  }, []);

  const profileTab = (part: string, icon: string, orangeIcon: string) => {
    return (
      <div onClick={() => setProfilePart(part)}>
        {profilePart === part ? (
          <Row>
            <Col span={24} className="profile-tab profile-active-tab">
              <img alt="orangeIcon" src={orangeIcon} />
              <h5 className="part-title primary-color">
                {t(`profile.${part}`)}
              </h5>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24} className="profile-tab">
              <img alt="iconTab" src={icon} />
              <h5 className="part-title text-regular-font">
                {t(`profile.${part}`)}
              </h5>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const onChangeLink = (type: string, newVal: string) => {
    switch (type) {
      case "twitter":
        setUserInfos({ ...userInfos, twitter: newVal });
        break;

      case "telegram":
        setUserInfos({ ...userInfos, telegram: newVal });
        break;

      case "instagram":
        setUserInfos({ ...userInfos, instagram: newVal });
        break;

      case "medium":
        setUserInfos({ ...userInfos, medium: newVal });
        break;

      default:
        break;
    }
  };

  const profileLink = (type: string, icon: string, color: string) => {
    return (
      <div className="profile-social-link">
        <h5 className="text-regular-font link-label">{`${t(
          `profile.${type}`
        )} :`}</h5>
        <Form.Item
          name={type}
          rules={[
            () => ({
              validator(_, value) {
                switch (type) {
                  case "twitter":
                    if (twitterRegex.test(value) || value === "") {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("collection-page.twitter-link-required")
                    );

                  case "telegram":
                    if (telegramRegex.test(value) || value === "") {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("collection-page.telegram-link-required")
                    );

                  case "medium":
                    if (mediumRegex.test(value) || value === "") {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("collection-page.medium-link-required")
                    );
                  case "instagram":
                    if (instagramRegex.test(value) || value === "") {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t("collection-page.instagram-link-required")
                    );

                  default:
                    break;
                }
              },
            }),
          ]}>
          <LinkInput
            icon={icon}
            color={color}
            placeholder={`www.${type}.com/`}
            onChange={(e) => onChangeLink(type, e.target.value)}
          />
        </Form.Item>
      </div>
    );
  };

  util.saveChangesCall = () => {
    instanceOfAxios
      .put("profile/" + address, userInfos, {
        headers: {
          "x-access-token": getItemFromLocalStorage("token"),
        },
      })
      .then((res: any) => {
        dispatch({ type: "setProfile", user: res?.data });
        dispatch({ type: "loading", loading: false });
        localStorage.setItem("user", JSON.stringify(res?.data));
        setUserInfos(res?.data);
        if (localStorage.getItem("language") !== res?.data?.language) {
          localStorage.setItem("language", res?.data?.language);
          i18n.changeLanguage(res?.data?.language);
        }
        OpenSuccessNotification(
          t("profile.success"),
          t("profile.success-desc"),
          3
        );

        if (
          userInfos?.email !== "" &&
          userInfos?.email !== currentEmail &&
          res?.data?.opt_in === null
        ) {
          // this was the implementation when we though the email is unique
          // if (res?.data?.code === 1) {
          //   setEmailExist(true);
          // }
          setEmailVerif(true);
        }

        if (
          res?.data?.language === "ar" &&
          localStorage.getItem("language") !== "ar"
        ) {
          localStorage.setItem("language", res?.data?.language);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (
          res?.data?.language !== "ar" &&
          localStorage.getItem("language") === "ar"
        ) {
          localStorage.setItem("language", res?.data?.language);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err: any) => {
        if(err?.response.status===401){
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-unauthorized-user"),
            3
          );                    
        }else{
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        } 
      });
  };

  const onFinish = () => {
    dispatch({ type: "loading", loading: true });
    util.saveChangesCall();
  };

  return (
    <div className="edit-profile-page">
      <Row>
        <Col span={24} className="edit-profile-header">
          <div>
            <h2>{t("profile.edit-profile-title")}</h2>
            <h5 className="text-regular-font">
              {t("profile.edit-profile-desc")}
            </h5>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="edit-container">
          <div className="profile-vertical-tab">
            {profileTab("general-informations", userIcon, orangeuserIcon)}
            {profileTab("social-links", socialIcon, orangesocialIcon)}
            {profileTab("settings", settingsIcon, orangesettingsIcon)}
            {/* {profileTab("notifications", notifIcon, orangenotifIcon)} */}
          </div>
          <ProfileMobileTab
            activeTab={profilePart}
            onChangeTab={setProfilePart}
          />
          <div className="profile-tab-content">
            {profilePart === "general-informations" && (
              <GeneralInfo userInfos={userInfos} setUserInfos={setUserInfos} />
            )}

            {profilePart === "social-links" && (
              <Form
                layout="inline"
                initialValues={userInfos}
                className="profile-social-links-container">
                {profileLink("telegram", telegramIcon, "#0088CC")}
                {profileLink("twitter", twitterIcon, "#00ACEE")}
                {profileLink("instagram", instagramIcon, "#F45843")}
                {profileLink("medium", mediumIcon, "black")}
              </Form>
            )}

            {profilePart === "settings" && (
              <div className="settings-container">
                <Settings
                  userInfos={userInfos}
                  setUserInfos={setUserInfos}
                  setCurrentLanguage={handelchngelong}
                  currencies={currencies}
                  defaultCurrency={
                    currencies.filter(
                      (currency: any) => currency?.symbol === userInfos.currency
                    )[0]
                  }
                />
              </div>
            )}

            {profilePart === "notifications" && <NotificationsPart />}
            <Row>
              <Col span={24} className="edit-profile-buttons-container">
                <div className="profile-cancel-button-container">
                  <LightButton onClick={() => navigate("/")}>
                    {t("common.cancel")}
                  </LightButton>
                </div>
                <Button
                  type="primary"
                  className="save-changes-button"
                  onClick={onFinish}
                  loading={loading}>
                  {t("profile.save-changes")}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {emailVerif && (
        <EmailConfirm
          confirmVisible={emailVerif}
          emailExist={emailExist}
          handleClose={() => setEmailVerif(false)}
          email={userInfos?.email}
          emailInput={emailExist}
          closable={true}
        />
      )}
    </div>
  );
};

export default EditProfile;
