import  { useState } from "react";
import { Divider, Button } from "antd";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../../../../assets/images/rarityLogo.svg";
import searchIcon from "../../../../../assets/images/searchIcon.svg";
import menuIcon from "../../../../../assets/images/menuIcon.svg";
import "./mobileNavbar.less";
import MobileDrawer from "./mobileDrawer";
import CustomMenu from "../menu";
import LightButton from "../../../../components/buttons/lightButton";
import Search from "../../../../components/input/search";
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import ProfileButton from "../profile button/profileButton";
import NotifIcons from "../notif icons/notifIcons";
import { useGlobalContext } from "../../../../context/globalContext";

const MobileNavbar = observer(({ scrolled }: { scrolled: boolean }) => {
  const { t } = useTranslation();
  const  navigate = useNavigate();
  const {notificationsStore}=useGlobalContext()
  const [searchVisible, setSearchVisible] = useState(false);
  const { address } = useGetAccountInfo();

  const redirectTo = (path: string) => {
    notificationsStore.setMenuVisible(false);
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <div
      className={
        scrolled
          ? "mobile-navbar-container mobilefloatingNav"
          : "mobile-navbar-container"
      }>
      <div
        className="navbar-first-part"
        onClick={() => {
          window.scrollTo(0, 0);
          navigate("/");
        }}>
        <img alt="logoMobile" src={logo} className="logo-img mobile-logo" />
      </div>
      <div className="navbar-second-part">
        <div
          className="mobile-menu-button"
          onClick={() => setSearchVisible(true)}>
          <img alt="searchIcon" src={searchIcon} className="mobile-menu-icon" />
        </div>
        <div
          className="mobile-menu-button"
          onClick={() => notificationsStore.setMenuVisible(true)}>
          <img alt="menuIcon" src={menuIcon} className="mobile-menu-icon" />
        </div>
      </div>
      <MobileDrawer visible={notificationsStore.menuVisible} onClose={() => notificationsStore.setMenuVisible(false)}>
        <div>
          <CustomMenu mobile={true} setMenuVisible={notificationsStore.setMenuVisible} />
          <Divider />
          <div className="mobile-auth-buttons">
            <div className="button-holder">
              <LightButton onClick={() => redirectTo("/collection")}>
                {t("home.menu-create")}
              </LightButton>
            </div>
            {address ? (
              <>
                <NotifIcons />
                <ProfileButton />
              </>
            ) : (
              <div className="button-holder">
                <Button
                  type="primary"
                  onClick={() => redirectTo("/auth")}
                  style={{ width: "100%" }}>
                  {t("home.menu-connect")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </MobileDrawer>

      <MobileDrawer
        visible={searchVisible}
        onClose={() => setSearchVisible(false)}>
        <div className="mobile-search">
          <h3>{t("home.menu-search-title")}</h3>
          <Search onSearch={() => setSearchVisible(false)} />
          <Divider />
        </div>
      </MobileDrawer>
    </div>
  );
});

export default MobileNavbar;
