import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input } from "antd";
import "./textAreaModal.less";
import CustomModal from "../customModal";

export interface TextAreaModalProps {
  visible: boolean;
  title: string;
  description: string;
  label: string;
  placeholder: string;
  okButton: string;
  handleOk: (value: string) => void;
  handleCancel: () => void;
}

const TextAreaModal = ({
  visible,
  title,
  description,
  label,
  placeholder,
  okButton,
  handleOk,
  handleCancel,
}: TextAreaModalProps) => {
  const { t } = useTranslation();
  const { TextArea } = Input;

  const onFinish = (values: any) => {
    handleOk(values.report);
  };

  return (
    <CustomModal
      visible={visible}
      width="400px"
      closable={true}
      handleOk={handleCancel}
      handleCancel={handleCancel}
    >
      <div>
        <Form onFinish={onFinish}>
          <div className="textarea-modal-header">
            <h4>{title}</h4>
            <h6 className="text-regular-font">{description}</h6>
          </div>
          <div style={{ margin: "20px 0" }}>
            <h5 style={{ paddingBottom: "10px" }}>{label}</h5>
            <Form.Item
              name="report"
              rules={[
                {
                  required: true,
                  message: t("collection-page.required-field"),
                },
              ]}
            >
              <TextArea
                placeholder={placeholder}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </Form.Item>
          </div>
          <div className="textarea-modal-buttons-holder">
            <Button type="default" onClick={handleCancel}>
              {t("common.cancel")}
            </Button>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {okButton}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </CustomModal>
  );
};

export default TextAreaModal;
