const getSocialMediaUsername = (link: string, regx: any, socailName?: string) => {
	let match = regx.exec(link);
	if (match) {
		if (socailName && socailName === 'discord') {
			return match[2];
		} else {
			return match[1];
		}
	}
};

export default getSocialMediaUsername;
