import { useEffect, useState } from "react";

import { useGetSuccessfulTransactions } from "@multiversx/sdk-dapp/hooks/transactions";
import { useTranslation } from "react-i18next";
import { Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import instanceOfAxios from "../../../configAxios";
import { useContext, useDispatch } from "../../context";
import { logger } from "../../context/logger";
import {
    OpenErrorNotification,
    OpenSuccessNotification,
} from "../../components/toasts/openToast";
import { getTransactionsByTxHash } from "./apiRequests";
import { network } from "../../config/configGlobal";
import { getItemFromLocalStorage } from "../../helpers/useLocalStorage";

const TransactionsProcessor = () => {
    //This component is designed for blockchain transactions to execute in any page on Rarity
    const { successfulTransactionsArray } = useGetSuccessfulTransactions();
    const { t } = useTranslation();
    const { address, transactionsTrack } = useContext();
    const dispatch = useDispatch();
    const  navigate = useNavigate();
    const [transactionsList, setTransactionsList] = useState<any[]>([]);
    const [apiCalled, setApiCalled] = useState(false);
    const [auctionId, setAuctionId] = useState<string>("");
    const [transactionsLength, setTransactionsLength] = useState(0);
    const [auctionResultRefresh, setAuctionResultRefresh] = useState(0);

    const linkData = () => {
        transactionsTrack?.length > 0 &&
            transactionsTrack.map((track: any) => {
                if (
                    successfulTransactionsArray?.filter(
                        (transaction: any) =>
                            transaction[0] === track?.sessionId
                    )?.length > 0
                ) {
                    dispatch({
                        type: "refreshActionCode",
                        actionCodeRefresh: {
                            item: track?.itemId,
                            collection: track?.collectionId,
                            actionLoading: true,
                            actionError: false,
                            giftCase: track?.necessData?.giftReciever,
                        },
                    });
                    console.log(
                        "original transaction result array => ",
                        successfulTransactionsArray
                    );
                    getTransactionsByTxHash(
                        network.apiAddress,
                        successfulTransactionsArray?.filter(
                            (transaction: any) =>
                                transaction[0] === track?.sessionId
                        )[0][1]?.transactions[0]?.hash
                    ).then(({ data, success }) => {
                        if (
                            track?.actionCase === 300 &&
                            track?.necessData?.auctionData
                        ) {
                            const results =
                                data?.data?.transaction?.smartContractResults;
                            if (results && results?.length) {
                                for (let i = 0; i < results.length; i++) {
                                    if (results[i].data?.includes("@6f6b@")) {
                                        setAuctionId(
                                            results[i].data?.split("@")[2]
                                        );
                                        logger.warn(
                                            "success of set auction transaction : ",
                                            data?.data?.transaction
                                        );
                                        setTransactionsList((list) => [
                                            ...list,
                                            {
                                                ...track,
                                                elrondResult:
                                                    data?.data?.transaction,
                                            },
                                        ]);
                                        console.log(
                                            "success of set auction transaction : ",
                                            data?.data?.transaction
                                        );
                                    } else if (
                                        auctionId === "" &&
                                        i === results.length - 1
                                    ) {
                                        const refreshNumb =
                                            auctionResultRefresh + 1;
                                        console.log(
                                            "***the transaction from MVX site: ",
                                            data?.data?.transaction
                                        );
                                        setTimeout(() => {
                                            setAuctionResultRefresh(
                                                refreshNumb
                                            );
                                        }, 3000);
                                    }
                                }
                            } else {
                                dispatch({
                                    type: "refreshActionCode",
                                    actionCodeRefresh: {
                                        item: track?.itemId,
                                        collection: track?.collectionId,
                                        actionLoading: false,
                                        actionError: true,
                                    },
                                });
                            }
                        } else {
                            if (
                                transactionsList?.filter(
                                    (transaction: any) =>
                                        transaction?.sessionId ===
                                        track?.sessionId
                                )?.length === 0
                            ) {
                                console.log(
                                    "***the transaction from MVX site: ",
                                    data?.data?.transaction
                                );
                                logger.warn(
                                    "***the result of transaction from elrond site : ",
                                    data?.data?.transaction
                                );
                                setTransactionsList((list) => [
                                    ...list,
                                    {
                                        ...track,
                                        elrondResult: data?.data?.transaction,
                                    },
                                ]);
                            }
                        }
                    });
                }
            });
    };

    useEffect(() => {
        if (transactionsLength !== successfulTransactionsArray?.length) {
            //this is very important to controle the number of rendering, to prevent infinite loop
            setTransactionsLength(successfulTransactionsArray?.length);
            transactionsTrack?.length > 0 && linkData();
        }
    }, [successfulTransactionsArray]);

    useEffect(() => {
        //This is specially for setup auction case
        linkData();
    }, [auctionResultRefresh]);

    const openNotification = (itemId: any, actionCase: Number) => {
        const key = `open${Date.now()}`;
        const btn = (
            <Button
                type="primary"
                size="small"
                className="button-toast-nofitication"
                onClick={() => {
                    notification.close(key);
                    navigate(`/item-details/${itemId}`);
                }}>
                {t("action-box.go-to-item")}
            </Button>
        );
        notification.destroy();
        notification.open({
            message: t("action-box.nft-successfull"),
            description: "",
            btn,
            key,
            duration: 6,
        });
    };

    const moveToNextStep = (transaction: any) => {
        //we clean the local state, in case of no redirection with MultiversX Defi Browser extension
        setTransactionsList((transactionsList) =>
            transactionsList?.filter(
                (track: any) => track?.sessionId !== transaction?.sessionId
            )
        );
        localStorage.setItem(
            "transactions-track",
            JSON.stringify(
                JSON.parse(
                    localStorage.getItem("transactions-track") || "[]"
                )?.filter(
                    (track: any) => track?.sessionId !== transaction?.sessionId
                )
            )
        );
        //In case we signed the end auction transaction and we have a new owner, so we fire this to refresh the item data
        //also the case after creating the NFT, to show the "SEND GIFT" button
        if (
            transaction?.actionCase === 301 ||
            transaction?.actionCase === 203 ||
            (transaction?.actionCase === 300 &&
                transaction?.necessData?.giftReciever)
        ) {
            dispatch({ type: "refreshItem", refreshItem: true });
        }
        //we save in context too, considering the case of no redirection , such as xPortal extension
        dispatch({
            type: "keepTrack",
            transactionsTrack: JSON.parse(
                localStorage.getItem("transactions-track") || "[]"
            )?.filter(
                (track: any) => track?.sessionId !== transaction?.sessionId
            ),
        });
        dispatch({
            type: "refreshActionCode",
            actionCodeRefresh: {
                item: transaction?.itemId,
                collection: transaction?.collectionId,
                actionLoading: false,
                actionError: false,
            },
        });
        setApiCalled(false);
        if (
            window.location.pathname !== `/item-details/${transaction?.itemId}`
        ) {
            if (transaction?.actionCase === 203) {
                openNotification(transaction?.itemId, transaction?.actionCase);
            }
        }
    };

    const saveTransactionApiCall = (transaction: any) => {
        if (transaction?.elrondResult?.receiver) {
            instanceOfAxios
                .post(
                    `save_transaction_history/address/${
                        transaction?.address || address
                    }`,
                    {
                        nonce: transaction?.elrondResult?.nonce,
                        hash: transaction?.elrondResult?.smartContractResults[0]
                            .originalTxHash,
                        value: transaction?.elrondResult?.value,
                        reciever: transaction?.elrondResult?.receiver,
                        gasPrice: transaction?.elrondResult?.gasPrice,
                        gasLimit: transaction?.elrondResult?.gasLimit,
                        data:
                            transaction?.sentData ||
                            transaction?.elrondResult?.data,
                        status: transaction?.elrondResult?.status,
                        smartContractResults1_data:
                            transaction?.elrondResult?.smartContractResults[0]
                                ?.data,
                    }
                )
                .then((res: any) => {
                    console.log("call to save in TRANSACTION history");
                    logger.warn(
                        "success of save in TRANSACTION history : ",
                        res
                    );
                    moveToNextStep(transaction);
                })
                .catch((err: any) => {
                    moveToNextStep(transaction);
                    logger.warn(
                        "Error of saving in transaction history : ",
                        err
                    );
                    return "failed call for register transaction";
                });
        } else {
            moveToNextStep(transaction);
        }
    };

    const saveInTradingHistory = async (
        transaction: any,
        type: string,
        price: any,
        from: string,
        to: string,
        status: any
    ) => {
        setApiCalled(true);
        logger.warn("****save in trading history : ", {
            date: transaction?.elrondResult?.timestamp,
            item_id: transaction?.itemId,
            type: type,
            tx: transaction?.elrondResult?.smartContractResults[0]
                .originalTxHash,
            price: price,
            price_unit: "EGLD",
            from_address: from,
            to_address: to,
            status: status,
        });
        try {
            instanceOfAxios
                .post(`trading_history`, {
                    date: transaction?.elrondResult?.timestamp,
                    item_id: transaction?.itemId,
                    type: type,
                    tx: transaction?.elrondResult?.smartContractResults[0]
                        .originalTxHash,
                    price: price,
                    price_unit: "EGLD",
                    from_address: from,
                    to_address: to,
                    status: status,
                })
                .then((res: any) => {
                    console.log("call to save in TRADING history");
                    logger.warn("success of save in Trading history : ", res);
                    saveTransactionApiCall(transaction);
                })
                .catch((err: any) => {
                    moveToNextStep(transaction);
                    logger.warn("Error of saving in trading history : ", err);
                    return "failed adding in trading history";
                });
        } catch (error) {
            logger.warn("Error of saving in trading history : ", error);
        }
    };

    const createNFTorSFT = (type: string, transaction: any) => {
        setApiCalled(true);
        instanceOfAxios
            .get(
                `collection_brand_byId/address/${
                    transaction?.address || address
                }/collection/${transaction?.collectionId}`
            )
            .then((res: any) => {
                if (
                    res?.data?.token_ticker &&
                    transaction?.elrondResult?.smartContractResults?.length !==
                        0
                ) {
                    let tockenTicker1 = res?.data?.token_ticker;
                    // NFT id from elrond results
                    let tockenTicker2 =
                        transaction?.elrondResult?.smartContractResults
                            .find((token: any) =>
                                token?.data.includes("@6f6b@")
                            )
                            .data.split("@")[2];
                    logger.warn("****create NFT api with : ", {
                        type,
                        token_identifier: `${tockenTicker1}-${tockenTicker2}`,
                    });
                    instanceOfAxios
                        .post(
                            `create_item_on_blockchain/address/${
                                transaction?.address || address
                            }/item/${transaction?.itemId}`,
                            {
                                type,
                                token_identifier: `${tockenTicker1}-${tockenTicker2}`,
                            }
                        )
                        .then((res: any) => {
                            console.log("call to save NFt");
                            logger.warn("success of creating NFT : ", res);
                            setApiCalled(true);
                            saveInTradingHistory(
                                transaction,
                                "create",
                                0,
                                transaction?.address || address,
                                "",
                                "created"
                            );
                            saveTransactionApiCall(transaction);
                        })
                        .catch((err: any) => {
                            //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                            moveToNextStep(transaction);
                            logger.warn("Error of saving NFT : ", err);
                            return "failed call for save nft or sft";
                        });
                } else {
                    //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                    moveToNextStep(transaction);
                    OpenErrorNotification(
                        t("profile.error"),
                        t("action-box.nft-creation-error"),
                        3
                    );
                }
            })
            .catch((err: any) => {
                //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                moveToNextStep(transaction);
                logger.warn("Error of getting collection brand : ", err);
                return "failed call for getting collection brand";
            });
    };

    useEffect(() => {
        transactionsList?.length > 0 &&
            transactionsList?.map((transaction: any) => {
                switch (transaction?.actionCase) {
                    case 200:
                        // register brand case
                        if (
                            !apiCalled &&
                            transaction?.elrondResult?.smartContractResults
                                ?.length
                        ) {
                            setApiCalled(true);
                            logger.warn("***********create Brand with : ", {
                                token_ticker:
                                    transaction?.elrondResult?.smartContractResults[0].data.split(
                                        "@"
                                    )[2],
                                type:
                                    transaction?.necessData?.brandType || "nft",
                                tx: transaction?.elrondResult
                                    ?.smartContractResults[0].originalTxHash,
                                profile_id: JSON.parse(
                                    localStorage.getItem("user") || "{}"
                                )?.id,
                                collection_id: transaction?.collectionId,
                            });
                            instanceOfAxios
                                .post(
                                    `collection_brand/address/${
                                        transaction?.address || address
                                    }`,
                                    {
                                        token_ticker:
                                            transaction?.elrondResult?.smartContractResults
                                                .find((token: any) =>
                                                    token?.data.includes(
                                                        "@6f6b@"
                                                    )
                                                )
                                                .data.split("@")[2],
                                        type:
                                            transaction?.necessData
                                                ?.brandType || "nft",
                                        tx: transaction?.elrondResult
                                            ?.smartContractResults[0]
                                            .originalTxHash,
                                        profile_id: JSON.parse(
                                            localStorage.getItem("user") || "{}"
                                        )?.id,
                                        collection_id:
                                            transaction?.collectionId,
                                    },
                                    {
                                        headers: {
                                            "x-access-token":
                                                getItemFromLocalStorage(
                                                    "token"
                                                ),
                                        },
                                    }
                                )
                                .then((res: any) => {
                                    console.log("call to save BRAND");
                                    logger.warn(
                                        "success of creating brand : ",
                                        res
                                    );
                                    saveTransactionApiCall(transaction);
                                })
                                .catch((err: any) => {
                                    //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                                    moveToNextStep(transaction);
                                    logger.warn(
                                        "Error of registering the brand : ",
                                        err
                                    );
                                    OpenErrorNotification(
                                        t("profile.error"),
                                        t("profile.error-desc"),
                                        3
                                    );
                                    return "failed call for register brand";
                                });
                        }
                        break;

                    case 201:
                        // set permission case
                        setApiCalled(true);
                        logger.warn("*****set special role : ", {
                            specialrole_tx:
                                transaction?.elrondResult
                                    ?.smartContractResults[0].originalTxHash,
                        });
                        console.log(
                            "*****set special role : transactionData ",
                            {
                                transactionData: transaction?.elrondResult,
                                specialrole_tx:
                                    transaction?.elrondResult
                                        ?.smartContractResults[0]
                                        .originalTxHash,
                            }
                        );
                        instanceOfAxios
                            .put(
                                `collection_brand/address/${
                                    transaction?.address || address
                                }/${transaction?.collectionId}`,
                                {
                                    specialrole_tx:
                                        transaction?.elrondResult
                                            ?.smartContractResults[0]
                                            .originalTxHash,
                                },
                                {
                                    headers: {
                                        "x-access-token":
                                            getItemFromLocalStorage("token"),
                                    },
                                }
                            )
                            .then((res: any) => {
                                console.log("call to set special role");
                                logger.warn(
                                    "success of set special role : ",
                                    res
                                );
                                saveTransactionApiCall(transaction);
                            })
                            .catch((err: any) => {
                                //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                                moveToNextStep(transaction);
                                logger.warn(
                                    "Error of setting permission : ",
                                    err
                                );
                                return "failed call for setting permission";
                            });
                        break;

                    case 203:
                        //create nft case
                        createNFTorSFT("nft", transaction);
                        break;

                    case 300:
                        //we have two cases: either setup auction , or send gift
                        if (!transaction?.necessData?.giftReciever) {
                            //setup auction case
                            instanceOfAxios
                                .get(
                                    `collection_brand_byId/address/${transaction?.necessData?.item?.creator?.erd_address}/collection/${transaction?.collectionId}`
                                )
                                .then((res: any) => {
                                    const tokenIdentifier =
                                        res?.data?.token_ticker;
                                    if (auctionId !== "") {
                                        instanceOfAxios
                                            .get(
                                                `token_identifier/address/${
                                                    transaction?.address ||
                                                    address
                                                }/item/${transaction?.itemId}`
                                            )
                                            .then((res: any) => {
                                                console.log(
                                                    "call to get the token identifier"
                                                );

                                                logger.warn(
                                                    "***//****** save Auction : ",
                                                    {
                                                        item_id:
                                                            transaction?.itemId,
                                                        erd_address:
                                                            transaction?.address ||
                                                            address,
                                                        auction_id: auctionId,
                                                        initial_tx:
                                                            transaction
                                                                ?.elrondResult
                                                                ?.blockHash,
                                                        token_identifier:
                                                            tokenIdentifier,
                                                        nft_nonce:
                                                            res?.data?.token_identifier.split(
                                                                "-"
                                                            )[2],
                                                        min_bid:
                                                            transaction
                                                                ?.necessData
                                                                ?.min_bid || 0,
                                                        max_bid:
                                                            transaction
                                                                ?.necessData
                                                                ?.max_bid || 0,
                                                        deadline:
                                                            transaction
                                                                ?.necessData
                                                                ?.deadline,
                                                        accepted_payment_token:
                                                            "EGLD",
                                                        bid: 0,
                                                        bid_count: 0,
                                                    }
                                                );

                                                // save Transaction Entity
                                                moveToNextStep(transaction);
                                                setApiCalled(true);
                                                // saveTransactionApiCall(transaction);

                                                // instanceOfAxios
                                                //   .post(`auction`, {
                                                //     item_id: transaction?.itemId,
                                                //     erd_address: transaction?.address || address,
                                                //     auction_id: auctionId,
                                                //     initial_tx:
                                                //       transaction?.elrondResult?.smartContractResults[0]
                                                //         .originalTxHash,
                                                //     token_identifier: tokenIdentifier,
                                                //     nft_nonce: res?.data?.token_identifier.split(
                                                //       "-"
                                                //     )[2],
                                                //     min_bid:
                                                //       transaction?.necessData?.auctionData?.min_bid ||
                                                //       0,
                                                //     max_bid:
                                                //       transaction?.necessData?.auctionData?.max_bid ||
                                                //       0,
                                                //     deadline:
                                                //       transaction?.necessData?.auctionData?.deadline,
                                                //     accepted_payment_token: "EGLD",
                                                //     bid: 0,
                                                //     bid_count: 0,
                                                //     transaction_date:
                                                //       transaction?.elrondResult?.timestamp,
                                                //   })
                                                //   .then((res: any) => {
                                                //     setApiCalled(true);
                                                //     saveTransactionApiCall(transaction);
                                                //   })
                                                //   .catch((err: any) => {
                                                //     //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                                                //     moveToNextStep(transaction);
                                                //     setApiCalled(true);
                                                //     OpenErrorNotification(
                                                //       t("profile.error"),
                                                //       t("profile.error-desc"),
                                                //       3
                                                //     );
                                                //     logger.warn(
                                                //       "Error of creating the auction : ",
                                                //       err
                                                //     );
                                                //     return "failed call for creating the auction";
                                                //   });
                                            })
                                            .catch((err: any) => {
                                                //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                                                moveToNextStep(transaction);
                                                OpenErrorNotification(
                                                    t("profile.error"),
                                                    t("profile.error-desc"),
                                                    3
                                                );
                                                logger.warn(
                                                    "Error of getting the token identifier : ",
                                                    err
                                                );
                                                return "failed call for getting the token identifier";
                                            });
                                    }
                                })
                                .catch((err: any) => {
                                    //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                                    moveToNextStep(transaction);
                                    logger.warn("Error in saving NFT : ", err);
                                    setApiCalled(true);
                                    OpenErrorNotification(
                                        t("profile.error"),
                                        t("profile.error-desc"),
                                        3
                                    );
                                    return "failed call for save nft or sft";
                                });
                        } else {
                            //here the case we are after send NFT gift transaction =>>>
                            setApiCalled(true);
                            instanceOfAxios
                                .put(
                                    `nft_owner/address/${
                                        transaction?.address || address
                                    }/item/${transaction?.itemId}`,
                                    {
                                        erd_receiver:
                                            transaction?.necessData
                                                ?.giftReciever,
                                        message:
                                            transaction?.necessData?.message,
                                        tx: transaction?.elrondResult
                                            ?.smartContractResults[0]
                                            .originalTxHash,
                                        date: transaction?.elrondResult
                                            ?.timestamp,
                                    }
                                )
                                .then((res: any) => {
                                    saveTransactionApiCall(transaction);
                                    OpenSuccessNotification(
                                        t("profile.success"),
                                        t("collection-page.gift-sent-success"),
                                        5
                                    );
                                })
                                .catch((e: any) => {
                                    //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                                    moveToNextStep(transaction);
                                    console.log(
                                        "*Error in sending a gift :",
                                        e
                                    );
                                    logger.warn("*error in send gift : ", e);
                                    moveToNextStep(transaction);
                                    if (e?.response.status === 401) {
                                        OpenErrorNotification(
                                            t("profile.error"),
                                            t(
                                                "profile.error-unauthorized-user"
                                            ),
                                            3
                                        );
                                    } else {
                                        OpenErrorNotification(
                                            t("profile.error"),
                                            t("profile.error-desc"),
                                            3
                                        );
                                    }
                                    return "failed call for sending nft";
                                });
                        }

                        break;

                    case 304:
                        //send bid/purchase case
                        if (
                            !apiCalled &&
                            transaction?.elrondResult?.blockHash
                        ) {
                            const { itemId, necessData } = transaction;
                            setApiCalled(true);
                            logger.warn("*******save the bid with : ", {
                                item_id: itemId,
                                bid: necessData?.bid,
                            });
                            console.log("*******save the bid with hash : ", {
                                item_id: itemId,
                                bid: Number(necessData?.bid),
                                hash: transaction?.elrondResult,
                                tx: transaction?.elrondResult
                                    .smartContractResults[0].originalTxHash,
                            });
                            console.log("***************** transaction bid");
                            console.log(transaction.elrondResult);
                            moveToNextStep(transaction);
                            // instanceOfAxios
                            //   .post(`send_bid/address/${transaction?.address || address}`, {
                            //     item_id: itemId,
                            //     bid: Number(necessData?.bid),
                            //     bid_tx:
                            //       transaction?.elrondResult.smartContractResults[0]
                            //         .originalTxHash,
                            //   })
                            //   .then((res: any) => {
                            //     console.log("call to save bid");
                            //     logger.warn("success of saving a bid : ", res);
                            //     setApiCalled(true);
                            //     saveInTradingHistory(
                            //       transaction,
                            //       "bid",
                            //       Number(necessData?.bid),
                            //       necessData?.bidStatus === "bought"
                            //         ? necessData?.item?.owner?.erd_address
                            //         : transaction?.address || address,
                            //       necessData?.bidStatus === "bought"
                            //         ? transaction?.address || address
                            //         : necessData?.item?.owner?.erd_address,
                            //       necessData?.bidStatus || "sent"
                            //     );
                            //   })
                            //   .catch((err: any) => {
                            //     //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                            //     moveToNextStep(transaction);
                            //     logger.warn("Error of saving the bid : ", err);
                            //     return "failed call for save bid";
                            //   });
                        }
                        break;

                    case 301:
                        console.log("***************** transaction end");
                        console.log(transaction.elrondResult);
                        moveToNextStep(transaction);
                        // saveTransactionApiCall(transaction);
                        // if (!apiCalled && transaction?.elrondResult?.blockHash) {
                        //   setApiCalled(true);
                        //   instanceOfAxios
                        //     .post(
                        //       `end_auction/address/${transaction?.address || address}`,
                        //       {
                        //         item_id: transaction?.itemId,
                        //         tx:
                        //           transaction?.elrondResult?.smartContractResults[0]
                        //             .originalTxHash,
                        //         date: transaction?.elrondResult?.timestamp,
                        //       }
                        //     )
                        //     .then((res: any) => {
                        //       console.log("call to end auction");
                        //       logger.warn("success of ending auction : ", res);
                        //       saveTransactionApiCall(transaction);
                        //     })
                        //     .catch((err: any) => {
                        //       //moving to next step here, is considered for cleaning the localstorage from this step, better than it appear again
                        //       moveToNextStep(transaction);
                        //       logger.warn("Error of set auction transaction : ", err);
                        //       return "failed call for save end auction";
                        //     });
                        // }
                        break;

                    default:
                        break;
                }
            });
    }, [transactionsList]);

    return (
        <>{/* Must have no UI elements, only functions in this component */}</>
    );
};

export default TransactionsProcessor;
