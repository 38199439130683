import * as React from "react";
import { Result, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MaintenancePage = () => {
  const { t } = useTranslation();
  const  navigate = useNavigate();

  return (
    <Result
      status="warning"
      title={t("errors.maintenance")}
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          {t("errors.redirect-home")}
        </Button>
      }
    />
  );
};

export default MaintenancePage;
