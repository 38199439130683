import { action, makeAutoObservable, observable } from 'mobx';

class ProfieInfoStore {
	@observable isPorifleImageUpdated: number = 0;

	constructor() {
		makeAutoObservable(this);
	}

	/* Setter and getter isPorifleImageUpdated */
	@action
	setIsPorifleImageUpdated(value: number) {
		this.isPorifleImageUpdated = value;
	}
}

export default new ProfieInfoStore();
