import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './errorPage.less';
import unauthorizedPhoto from '../../../../assets/images/unauthorized.svg';
import notFoundPhoto from '../../../../assets/images/notfound.svg';
import comingSoonPhoto from '../../../../assets/images/coming-soon.svg';
import rocketPhoto from '../../../../assets/images/rocket.svg';
import { Button } from 'antd';

const ErrorPage = (props: any) => {
	const { t } = useTranslation();
	const  navigate = useNavigate();
	const { match: { params } } = props;
	const type = params.type;

	return (
		<div className="coming-soon-container">
			{type === 'coming-soon' && <img alt="rocket" src={rocketPhoto} className="rocket-photo" />}
			<div className="error-global-container">
				<img
					alt="error type"
					src={type === '403' ? unauthorizedPhoto : type === 'coming-soon' ? comingSoonPhoto : notFoundPhoto}
					className="error-photo"
				/>
				<div className="error-content">
					<h3 className="primary-color">
						{type === '403' ? (
							t('errors.unauthorized-title')
						) : type === 'coming-soon' ? (
							''
						) : (
							t('errors.notFound-title')
						)}
					</h3>
					<h5 className="error-description text-regular-font">
						{type === '403' ? (
							t('errors.unauthorized-desc')
						) : type === 'coming-soon' ? (
							t('errors.coming-soon-desc')
						) : (
							t('errors.notFound-desc')
						)}
					</h5>
					<div className="errors-buttons-container">
						<Button type="primary" onClick={() => history.go(-1)}>
							{t('auth.go-back')}
						</Button>
						<Button type="link" onClick={() => navigate('/')}>
							{t('errors.return-home')}
						</Button>
						<Button type="link" onClick={() => navigate('/help-center')}>
							{t('errors.help-center')}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorPage;
