import React from "react";
import { Button } from "antd";
import "./buttonTab.less";

interface Props {
  children: any;
  type?: "primary" | "secondary";
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ButtonTab = ({ children, onClick, type }: Props) => {
  return (
    <Button
      type={type === "primary" ? "primary" : "text"}
      className={
        type === "primary"
          ? "button-collection-tab"
          : "button-collection-tab secondary-button text-regular-button"
      }
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ButtonTab;
