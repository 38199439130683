import { action, makeAutoObservable, observable } from 'mobx';

class ExploreStore {
	pageItemsNumber = 40;
	@observable cursor: any;
	objectRequest: any = {
		first: this.pageItemsNumber,
		sortBy: null,
		type: null,
		status: null,
		statusAuction: 'onAuction',
		price_min: 0,
		price_max: null
	};
	@observable defaultMinValue: number = 0;
	@observable defaultMaxValue: number = 10;
	@observable role: any;
	@observable itemsExplore: any = [];
	@observable totalCount: number = 0;
	@observable scrollPosition: number = 0;
	@observable offset: number = -3;
	@observable isApiCalled: boolean = false;
	@observable isSearchSetting: boolean = false;
	@observable filterSearchKeyWord = null;
	@observable searchLengthKeys: number = 0;
	@observable offsetPage: number = 0;
	@observable loading: boolean = true;
	@observable isButtonDisbaled: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	/* Setter and getter itemsExplore */
	@action
	setObjectRequest(value: any) {
		this.objectRequest = value;
	}
	/* Setter and getter role */
	@action
	setRole(value: any) {
		this.role = value;
	}
	//setter for itemsExplore
	@action
	setItemsExplore(value: any) {
		this.itemsExplore = value;
	}

	  /* Setter and getter for totalCount */
	  @action
	  setTotalCount(value: number) {
		  this.totalCount = value;
	  }

	/* Setter and getter itemsExplore */
	@action
	setCursor(value: any) {
		this.cursor = value;
	}

	/* Setter and getter scrollPosition */
	@action
	setScrollPosition(value: number) {
		this.scrollPosition = value;
	}

	@action
	setOffset(value: number) {
		this.offset = value;
	}
	/* Setter and getter isApiCalled */
	@action
	setIsApiCalled(value: boolean) {
		this.isApiCalled = value;
	}
	/* Setter and getter isSearchSetting */
	@action
	setIsSearchSetting(value: boolean) {
		this.isSearchSetting = value;
	}
	/* Setter and getter filterSearchKeyWord */
	@action
	setFilterSearchKeyWord(value: any) {
		this.filterSearchKeyWord = value;
	}
	/* Setter and getter searchLengthKeys */
	@action
	setSearchLengthKeys(value: number) {
		this.searchLengthKeys = value;
	}
	@action
	setDefaultMinValue(value: number) {
		this.defaultMinValue = value;
	}
	@action
	setDefaultMaxValue(value: number) {
		this.defaultMaxValue = value;
	}
	/* Setter and getter offsetPage */
	@action
	setOffsetPage(value: number) {
		this.offsetPage = value;
	}
	/* Setter and getter loading */
	@action
	setLoading(value: boolean) {
		this.loading = value;
	}
	/* Setter and getter isButtonDisbaled */
	@action
	setIsButtonDisbaled(value: boolean) {
		this.isButtonDisbaled = value;
	}
}

export default new ExploreStore();
