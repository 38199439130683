import React, { useState,useEffect } from "react";
import CollectionItemExisting from "./collectionItem/collectionItem";
import { useTranslation } from "react-i18next";
import ProfileTab from "../profile tab/profileTab";
import MobileTab from "../../../../components/mobileTab/mobileTab";
import instanceOfAxios from "../../../../../configAxios";
import { useContext } from "../../../../context";
import { Col, Row, Spin } from "antd";
import Nodata from "../../../../components/noData/nodata";
import { OpenErrorNotification } from "../../../../components/toasts/openToast";

const CollectionExistant = ({ }: any) => {
  const { t } = useTranslation();
  const { address } = useContext();
  const [offsetCollection, setOffsetcollection] = useState<number>(0);
  const [colldata, setColldata] = useState<any>([]);
  const [noMoreCollection, setNoMoreCollection] = useState(false);
  const [countTab, setCountTab] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("own");
  const [loading, setLoading] = useState<boolean>(false);

  const menuCollection = [
    { label: t("collection-page.own"), count: countTab[0] , value: "own" },
    { label: t("collection-page.invited"), count: countTab[1], value: "invited" },
    { label: t("collection-page.open"), count: countTab[2], value: "open" },
    { label: t("collection-page.charity"), count: countTab[3], value: "charity" },
  ];

  const getCollection = (filter:any , type:any) => {
    setLoading(true);
    address &&
      instanceOfAxios
        .get(
          `existing_collection/address/${address}?filter=${type}&first=12&offset=${filter ? offsetCollection :  0}`
        )
        .then((response) => {
          setNoMoreCollection(response?.data?.pageInfo?.hasNextPage);
          

            setLoading(false);    
       

          setCountTab([
            response?.data?.pageInfo?.countOwn,
            response?.data?.pageInfo?.countInvited,
            response?.data?.pageInfo?.countOpen,
            response?.data?.pageInfo?.countCharity,
          ])

          if (filter) {
            setColldata((prev: any) => {
              return prev.concat(...response?.data?.nodes);
            });
          } else {
            setColldata(response?.data?.nodes)
          }

          if ((response.data.pageInfo.hasNextPage) && (filter)) {
            setOffsetcollection(offsetCollection + 10)
          } else {
            setOffsetcollection(10)
          }
        }).catch(()=>{
          setLoading(false)
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-desc"),
            3
          );
        })
        
  };
  useEffect(() => {

    getCollection(false, activeTab)

  }, [ activeTab]);

  return (
    <div >
      {countTab.length > 0 ?
      <>
      <div className="profile-tab-responsive existingColTab">
        <ProfileTab
          profile={false}
          elementsCount={[]}
          menu={menuCollection}
          activeTab={activeTab}
          setActiveTab={(tab) => setActiveTab(tab)}
        />
      </div>
      <div style={{ marginBottom: "30px" }}>
        <MobileTab
          tabs={menuCollection}
          activeTab={t(`${activeTab}`)}
          customHeaderClass="collection-profile-mobile-tab"
          onChangeTab={(tab) => setActiveTab(tab)}
        />
      </div>
      <div className='collections-items'>
      <>
        {((colldata.length > 0) && loading === false) ? 
          <> 
          {
          colldata.map((el: any,key:number) => (  
            <CollectionItemExisting el={el} key={key} />
          ))}
          </>
          : 
          <> 
            {(loading)  ? 
              <div className="item-details-load">
                <Spin size="large" />
              </div>
            :
              <div className="nodataFound">
                <Nodata/>
              </div>
            }
            </>
        }
      </>
      </div>
        {noMoreCollection && 
          <button type="button" className="button-seeMore ant-btn ant-btn-default collections-button" onClick={() => getCollection(true, activeTab)}>
              <span>{t("activity.see-more")}</span> </button>
        }
        </>
        :<div className="item-details-load">
        <Spin size="large" />
      </div>
        }
    </div>
  );
};

export default CollectionExistant;
