import React from "react";
import { DotProps } from "react-multi-carousel/lib/types";
import "./customDots.less";

export interface CustomDotsProps {
  onClick: () => void;
  onMove: () => void;
  index: number;
  active: boolean;
  carouselState: { currentSlide: number; deviceType: string };
}

const CustomDots = ({ index, active, onClick, carouselState }: DotProps) => {
  return (
    <div
      onClick={onClick}
      className={active ? "custom-dot" : "custom-dot--active"}
    ></div>
  );
};

export default CustomDots;
