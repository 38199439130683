import React, { useEffect, useState } from "react";
import { Button, Form, Select, Space } from "antd";
import { Checkbox, Radio } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
interface Props {
  data: any[];
  title?: any;
  activeTab?: any;
  setBody: any;
  body: any;
  handleGetCollections?: any;
  setOffset?: any
  dataLoadCollection?: any

}

const FilterProfile = ({ dataLoadCollection, setOffset, activeTab, handleGetCollections, body, setBody, title, data }: Props) => {
  const { t } = useTranslation();
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [defaultVal, setDefaultVal] = useState<any>();


  const onCheckAllChange = (e: any) => {

    setCheckedList(
      e.target.checked ? data.map((el) => (el.value ? el.value : null)) : []
    );

    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChange = (list: any) => {

    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < data.length);
    setCheckAll(list.length === data.length);

  };

  const handelchange = (list: any) => {
    if (!list.includes(t("All")) && !list.includes("")) {
      setCheckedList(list)
      if (list.length == 0) {
        setCheckAll(false)
      }
    }
  }

  const onRadioChange = (list: any) => {
    if (list.target.value !== "All") {
      setCheckedList([list.target.value]);
      setDefaultVal(list.target.value);
    } else {
      setCheckedList([]);
      setDefaultVal("All");
    }
  };

  let tabsSortBY = [
    "nft",
    "sft",
    "onAuction",
    "notOnAuction",
    "onAuctionBids",
    "created_at",
    "recently_sold",
    "auction_soon",
    "price",
    "priceHigh",
    "highest_last_sale",
    "views",
    "likes",
    "oldest"
  ];

  const selectSortBy = [
    t("collection-page.nft"),
    t("collection-page.sft"),
    t("collection-page.on-auction"),
    t("collection-page.not-on-auction"),
    t("On-auction-with-bids"),
    t("Recently-Created"),
    t("Recently-Sold"),
    t("Auction-ends-soon"),
    t("Price-Low-to-High"),
    t("Price-High-to-Low"),
    t("hightest-Last-Sale"),
    t("Most-viewed"),
    t("Most-Favorited"),
    t("Oldest"),
  ];


  const filterbySort = (sort: any) => {

    switch (sort.join("")) {

      case 'created_at': if (sort.length !== 0) {
        let valueSortBy = sort.join("")
        setBody({ ...body, sortby: `&sortBy=${valueSortBy}&sortOrder=DESC` })
      }
        break;
      case 'oldest': if (sort.length !== 0) {
        setBody({ ...body, sortby: `&sortBy=created_at&sortOrder=ASC` })
      }
        break;
      case 'price': if (sort.length !== 0) {
        let valueSortBy = sort.join("")
        setBody({ ...body, sortby: `&sortBy=${valueSortBy}&sortOrder=ASC` })
      }
        break;
      case 'priceHigh': if (sort.length !== 0) {
        setBody({ ...body, sortby: `&sortBy=price&sortOrder=DESC` })
      }
        break;
      case 'recently_sold': if (sort.length !== 0) {
        let valueSortBy = sort.join("")
        setBody({ ...body, sortby: `&sortBy=${valueSortBy}` })
      }
        break;
      case 'auction_soon': if (sort.length !== 0) {
        let valueSortBy = sort.join("")
        setBody({ ...body, sortby: `&sortBy=${valueSortBy}` })
      }
        break;
      case 'highest_last_sale': if (sort.length !== 0) {
        let valueSortBy = sort.join("")
        setBody({ ...body, sortby: `&sortBy=${valueSortBy}` })
      }
        break;
      case 'views': if (sort.length !== 0) {
        let valueSortBy = sort.join("")
        setBody({ ...body, sortby: `&sortBy=${valueSortBy}&sortOrder=DESC` })
      }
        break;
      case 'likes': if (sort.length !== 0) {
        let valueSortBy = sort.join("")
        setBody({ ...body, sortby: `&sortBy=${valueSortBy}&sortOrder=DESC` })
      }
        break;
      default: setBody({ ...body, sortby: `&sortBy=created_at&sortOrder=DESC` })
        break;

    }
  }

  useEffect(() => {
    switch (title) {

      case 'Collection': let collections = checkedList.map((el: any) => {
        return `&collection=${el}`;
      });
        let valueCol = collections.join("")
        setBody({ ...body, collection: valueCol })
        // setFilterCollection(valueCol);
        break;
      case 'Type': if (checkedList.length !== 0) {
        let valueType = checkedList.join("")
        setBody({ ...body, type: `&type=${valueType}` })
      } else {
        setBody({ ...body, type: "" })
      }
        break;
      case 'Status': if (checkedList.length !== 0) {
        let valueStatus = checkedList.join("")
        setBody({ ...body, statusAuction: `&statusAuction=${valueStatus}` })
      } else {
        setBody({ ...body, statusAuction: "" })
      }

        break;
      case 'Sort By': if (checkedList.length !== 0) {
        filterbySort(checkedList)
      }

        break;
      default:
        break;

    }
    setOffset(0)

  }, [checkedList])



  useEffect(() => {
    setBody({ collection: "", type: "", sortby: "", statusAuction: "" });
    setCheckedList([]);
    setDefaultVal("All");
  }, [activeTab])


  return (
    <Space direction="vertical" style={{ width: "100%" }} >
      <p className="activity-select">
        {title}
      </p>
      <Select
        className={(title) ? "checkbox-activity myclassname" : "checkbox-activity spacetop myclassname"}
        mode="multiple"
        placeholder={(title !== "Sort By") ? "All" : "Recently created"}
        value={checkedList.map((elm: any) => {
          return tabsSortBY.indexOf(elm)!==-1? selectSortBy[tabsSortBY.indexOf(elm)]:elm;
        }) }
        onChange={handelchange}
        maxTagCount="responsive"
        showArrow={true}
        showSearch={false}
        id={title}
      >
        {(title === "Collection") ?
          <>
            <Option value=''>
              <Checkbox
                value={t("All")}
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                {t("All")}
              </Checkbox>
            </Option>
            <Option value=""  >
              <CheckboxGroup
                options={data}
                value={checkedList}
                onChange={onChange}
              />
            </Option>
          </>
          : <Option value=""  >
            {/* <Form.Item name="priority"> */}
            <RadioGroup name="radiogroup" defaultValue={"All"} value={defaultVal} onChange={onRadioChange} >
              <Space direction="vertical">
                {
                  data.map((el,key:number) => {
                    return <Radio key={key} value={el.value} >{el.label}</Radio>

                  })
                }
              </Space>
            </RadioGroup>
            {/* </Form.Item> */}
          </Option>
        }
        {((title === "Collection") && (dataLoadCollection === true)) ?

          <Option value="">
            <Button style={{ width: "100%", border: "0px" }} onClick={() => handleGetCollections(true,activeTab)}>
              <span>{t("activity.see-more")}</span>
            </Button>
          </Option>
          : ""
        }
      </Select>
    </Space>
  );
};

export default FilterProfile;
