import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, message } from "antd";
import {
  sendTransactions,
} from "@multiversx/sdk-dapp/services";
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account'
import "../actionSwitch.less";
import CustomModal from "../../../../../components/modal/customModal";
import CustomCheckBox from "../../../../../components/checkbox/customCheckBox";
import { useEffect, useState } from "react";
import instanceOfAxios from "../../../../../../configAxios";
import { useContext, useDispatch } from "../../../../../context";
import { getItem } from "../../../../../storage/session";

export interface RegisterBrandProps {
  item?: any;
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

const RegisterBrand = ({
  item,
  visible,
  handleOk,
  handleCancel,
}: RegisterBrandProps) => {
  const { t } = useTranslation();
  const [itemType, setItemType] = useState("nft");
  const { address, actionCode } = useContext();
  const { account } = useGetAccountInfo();
  const [brandConfig, setBrandConfig] = useState({
    receiver: "",
    data: "",
    value: 0,
    gasLimit: 10000000,
  });
  const [registerLoading, setRegisterLoading] = useState(false);
  const dispatch = useDispatch();
  const /*transactionSessionId*/ [, setTransactionSessionId] = React.useState<
      string | null
    >(null);
  // const { sendTransactions } = transactionServices;

  const getConfig = (type: string) => {
    address &&
      instanceOfAxios
        .get(`generic_transaction/4?type=${type}&item_id=${item?.id}`)
        .then((res) => {
          setBrandConfig({
            receiver: res?.data?.receiver,
            gasLimit: res?.data?.gas_limit,
            value: res?.data?.value,
            data: res?.data?.data_example,
          });
        })
        .catch((error) => {
          console.log("err", error);
          return error;
        });
  };

  useEffect(() => {
    getConfig("nft");
    if (!localStorage.getItem("brandType")) {
      dispatch({ type: "setBrandType", brandType: "nft" });
    }
  }, []);

  const chooseType = (val: string) => {
    setItemType(val);
    getConfig(val);
    dispatch({ type: "setBrandType", brandType: val });
  };

  const register = async (e: any) => {
    setRegisterLoading(true);
    e.preventDefault();
    if (Number(account?.balance) < Number(brandConfig?.value)) {
      message.error(t("action-box.not-enough-balance"));
      setRegisterLoading(false);
    } else {
      await refreshAccount();

      const { sessionId /*, error*/ } = await sendTransactions({
        transactions: brandConfig,
        transactionsDisplayInfo: {
          processingMessage: t("action-box.processing-brand-transaction"),
          errorMessage: t("action-box.brand-transaction-error"),
          successMessage: t("action-box.brand-transaction-success"),
        },
        redirectAfterSign: false,
      });
      if (sessionId != null) {
        setTransactionSessionId(sessionId);
        //here we gonna save an object that contains this sessionId along with the collection id and item id, so we can catch the transaction result by the sessionId and link it to the specific collection/item ...
        localStorage.setItem(
          "transactions-track",
          JSON.stringify([
            ...JSON.parse(localStorage.getItem("transactions-track") || "[]"),
            {
              itemId: item?.id,
              collectionId: item?.collection?.id,
              sessionId,
              address,
              actionCase: actionCode,
              necessData: {
                brandType: itemType,
              },
              sentData: brandConfig?.data || "",
            },
          ])
        );
        //we save in context too, considering the case of no redirection , such as xPortal extension
        dispatch({
          type: "keepTrack",
          transactionsTrack: JSON.parse(
            localStorage.getItem("transactions-track") || "[]"
          ),
        });
      }
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="450px"
      closable={true}
      handleOk={handleOk}
      handleCancel={handleCancel}
    >
      <div>
        <div className="auction-modal-header">
          <h4>{t("collection-page.register-brand-button")}</h4>
          <h6 className="text-regular-font">
            {t("collection-page.create-sft-desc")}
          </h6>
        </div>
        <div style={{ margin: "20px 0" }}>
          <h5 style={{ paddingBottom: "10px" }}>
            {t("collection-page.item-type")}
          </h5>
          <CustomCheckBox
            checked={itemType === "nft"}
            title="NFT"
            description={t("collection-page.nft-desc")}
            value="nft"
            onChange={chooseType}
            disabled={false}
          />
          <CustomCheckBox
            checked={itemType === "sft"}
            title="SFT"
            description={t("collection-page.sft-desc")}
            value="sft"
            onChange={chooseType}
            disabled={true}
          />
        </div>
        <div>
          <Button
            type="primary"
            block
            style={{ margin: "10px 0" }}
            disabled={itemType === ""}
            onClick={register}
            loading={registerLoading}
          >
            {t("collection-page.register-button")}
          </Button>
          <Button type="default" block onClick={handleCancel}>
            {t("common.cancel")}
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default RegisterBrand;
