import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CollectionTab from "../../components/CollectionTab/collectionTab";
import CollectionExistant from "../../components/existingCollection/existingCollection";
import NewCollection from "../../components/newCollection/newCollection";
import { useContext } from "../../../../context";
import "./collection.less";
import { Button, Col, Row } from "antd";
import { setItem } from "../../../../storage/session";
import ScrollToTop from "../../../explore/components/scrollToTop";
import { Helmet } from "react-helmet";

const CollectionPage = () => {
  const { t } = useTranslation();
  const { address } = useContext();
  const  navigate = useNavigate();

  const [typeCollections, setTypeCollections] = useState<string>("existing-collection");
  const [typeCollection, setTypeCollection] = useState<boolean>(false);

  useEffect(() => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    }
  }, []);


  return (
    <div className="collection-container">
      <Helmet>
      <title>
          Rarity.market | {typeCollection ? "Select Collection" : "Collections"}
        </title>
      </Helmet>
      <div className="collection-content existingCollRow" >
        <Row >
          <Col span={19} className="collection-text">
            <h1 className="text-regular-font title-collection">
              {t("collection-page.select-collection")}
            </h1>
            <h2 className="text-regular-font">
              {t("collection-page.desc-collection")}
            </h2>
          </Col>
          <Col span={4}>
            <div className="tab-button tabBtnExitingCol">
              <Button
                onClick={() => setTypeCollection(!typeCollection)}
                type={typeCollection  ? "primary": "default"}
                className={typeCollection  ? "" : "collections-button"}
              >
                {typeCollection ? t("collection-page.existing-collection") : t("collection-page.new-collection")}
              </Button>
            </div>
          </Col>
        </Row>
        {address ? (
          <CollectionTab
            typeCollections={typeCollections}
            setTypeCollections={setTypeCollections}
          >
            {typeCollection === false && (
              <>
                <ScrollToTop showBelow={250} />
                <CollectionExistant/>
              </>
            )}
            {typeCollection === true && <NewCollection col />}
          </CollectionTab>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CollectionPage;
