import instanceOfAxios from "../../configAxios";
const pino = require('pino')
export let logger: any
if (process.env.REACT_APP_REMOTE_LOGGING_ENABLED === "true") {
    logger = pino({
        browser: {
            write: (o: any) => {
                // disable console.log
            },
            transmit: {
                level: 'warn',
                send: async function (level: any, logEvent: any) {
                    if (Number(process.env.REACT_APP_LOGGING_LEVEL) >= 40) {
                        try {
                            const response = await instanceOfAxios.post('/log', { data: JSON.stringify(logEvent) });
                        } catch (err) {
                            if (process.env.REACT_APP_NODE_ENV == "development") {
                                console.log("log endpoint is not working!");
                            }
                        }
                    }
                }
            }
        }
    })
} else { //local  log // env  developpement
    logger = pino({
        browser: {
            serialize: true
        }
    })
}
