import { action, makeAutoObservable, observable } from 'mobx';

class NotificationsStore {
	@observable menuVisible: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}
	/* Setter and getter menuVisible */
	@action
	setMenuVisible(value: boolean) {
		this.menuVisible = value;
	}
}

export default new NotificationsStore();
