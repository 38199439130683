import { useState,useEffect } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Row, Col, Select, Spin } from "antd";
import MobileTab from "../../../../components/mobileTab/mobileTab";
import CollectionHeader from "../../components/collection header/collectionHeader";
import ProfileSider from "../../components/profile sider/profileSider";
import ProfileTab from "../../components/profile tab/profileTab";
import "./collectionProfile.less";
import RecentItem from "../../../home/components/recentItem/recentItem";
import instanceOfAxios from "../../../../../configAxios";
import { useContext } from "../../../../context";
import ScrollToTop from "../../../explore/components/scrollToTop";
import addIcon from "../../../../../assets/images/add.svg";
import Nodata from "../../../../components/noData/nodata";
import { Helmet } from "react-helmet";
import { useGlobalContext } from "../../../../context/globalContext";

const CollectionProfile = observer((props: any) => {
  const { t } = useTranslation();
  const params = useParams();
  const { address } = useContext();
  const item = t("Items");
  const nft = t("Nft");
  const sft = t("Sft");
  const auction = t("On-auction");
  const notAuction = t("Not-on-auction");
  const [privateCollection, setCollectionPrivate] = useState(false);
  const [privateAccess, setPrivateAccess] = useState([]);
  const [collections, setCollection] = useState<any>([]);
  const [localBanner, setLocalBanner] = useState(collections?.banner_image);
  const [extbanner, setExtbanner] = useState(null);
  const [collectionDenided, setCollectionDenided] = useState<any>([]);
  const { Option } = Select;
  const [totalCountContributer, setTotalCountContributer] = useState<any>();
  const [totalCountAccessPrivate, setTotalCountAccessPrivate] = useState<any>();
  const [itemTab, setItemTab] = useState(false);
  const [activeTab, setActiveTab] = useState("items");
  const [contributers, setContribters] = useState<any>([]);
  const  navigate = useNavigate();
  const [isCreator, setIsCreator] = useState<any>();
  const [notAuctionLoading, setNotAuctionLoading] = useState(false);
  const [auctionLoading, setAuctionLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [nftLoading, setNFTLoading] = useState(false);
  const {likesStore}=useGlobalContext();

  const sortBy = [
    {
      label: t("Recently-Created"),
      value: "created_at",
    },
    {
      label: t("Recently-Sold"),
      value: "recently_sold",
    },
    {
      label: t("Auction-ends-soon"),
      value: "auction_soon",
    },
    {
      label: t("Price-Low-to-High"),
      value: "price",
    },
    {
      label: t("Price-High-to-Low"),
      value: "priceHigh",
    },
    {
      label: t("hightest-Last-Sale"),
      value: "highest_last_sale",
    },
    {
      label: t("Most-viewed"),
      value: "views",
    },
    {
      label: t("Most-Favorited"),
      value: "likes",
    },
    {
      label: t("Oldest"),
      value: "oldest",
    },
  ];

  const [visibleInvitations, setVisibleInvitations] = useState(false);
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const [objectTotals, setObjectTotal] = useState<any>({
    countItems: 0,
    countSfts: 0,
    countNfts: 0,
    countAuctions: 0,
    countNotAuctions: 0,
  });

  const menuCollection = [
    { label: item, count: objectTotals.countItems, value: "items" },
    { label: nft, count: objectTotals.countNfts, value: "Nft" },
    { label: auction, count: objectTotals.countAuctions, value: "On-auction" },
    {
      label: notAuction,
      count: objectTotals.countNotAuctions,
      value: "Not-on-auction",
    },
  ];

  const SortedByitems = [
    {
      label: t("Recently-Created"),
      value: "created_at",
    },
    {
      label: t("Most-viewed"),
      value: "views",
    },
    {
      label: t("Most-Favorited"),
      value: "likes",
    },
    {
      label: t("Oldest"),
      value: "oldest",
    },
  ];

  useEffect(() => {
    if (
      window.location.hash !== "" &&
      menuCollection.filter((menuItem) =>
        window.location.hash?.includes(menuItem?.value)
      )?.length > 0
    ) {
      setActiveTab(
        menuCollection.filter((menuItem) =>
          window.location.hash?.includes(menuItem?.value)
        )[0]?.value
      );
    }
  }, []);

  useEffect(() => {
    if (params.id && window.location.pathname.includes("collection-profile")) {
      instanceOfAxios
        .get("/collections/" + params.id)
        .then((res) => {
          setCollection(res?.data);
          setLocalBanner(res?.data?.banner_image)
          setExtbanner(res?.data?.banner_image_ext)
          setIsCreator(user?.id === res?.data?.creator?.id);
        })
        .catch((err) => {});
    } else if (params.slug) {
      instanceOfAxios
        .get("/collections/by-slug/" + params.slug)
        .then((res) => {
          setCollection(res?.data);
          setLocalBanner(res?.data?.banner_image)
          setExtbanner(res?.data?.banner_image_ext)
          setIsCreator(user?.id === res?.data?.creator?.id);
        })
        .catch((err) => {});
    } else {
      instanceOfAxios
        .get("/collections/by-slug/" + window.location.pathname.substring(1))
        .then((res) => {
          setCollection(res?.data);
          setLocalBanner(res?.data?.banner_image)
          setExtbanner(res?.data?.banner_image_ext)
          setIsCreator(user?.id === res?.data?.creator?.id);
        })
        .catch((err) => {});
    }
  }, [params.id]);

  useEffect(() => {
    setBody("");
    setSelectVal("Recently Created");
    filterData(false, "test");
  }, [collections, activeTab]);

  const fetchCollection = async () => {
    if (collections) {
      try {
        if (user && address) {
          if (collections?.collectionTypeId !== 3) {
            if (address === collections?.creator?.erd_address) {
              setItemTab(true);
              setVisibleInvitations(false);
              setCollectionPrivate(false);
            } else if (address !== collections.creator?.erd_address) {
              setVisibleInvitations(true);
            }
          }

          if (collections?.collectionTypeId === 3) {
            setCollectionPrivate(true);

            if (user?.erd_address !== collections?.creator?.erd_address) {
              const invited = localStorage.getItem("invite");
              if (!invited) {
                const resp = await instanceOfAxios.get(
                  `collection_permission?sortBy=private_access&collection_id=${collections?.id}&user_id=${user?.id}`
                );
              } else if (invited) {
                setVisibleInvitations(true);
              }
            } else if (
              user?.erd_address === collections?.creator?.erd_address
            ) {
              setItemTab(true);
            }
          }
        } else if (address !== collections?.creator?.erd_address) {
        }
        if (address === null) {
          if (collections?.collectionTypeId !== 3) {
            setItemTab(false);
            setCollectionPrivate(false);
          }
        }
      } catch (er) {}
    }
  };
  useEffect(() => {
    if (user?.role === "admin" || user?.role === "community-admin") {
      setItemTab(true);
    }
    if (collections?.id) {
      fetchCollection();
    }
  }, [collections]);

  ///****************** Get Items Infinity scroll**************** */

  useEffect(() => {
    if (collections?.id) {
      instanceOfAxios
        .get(
          `collection_permission?sortBy=private_access&collection_id=${collections?.id}`
        )
        .then((res) => {
          setPrivateAccess(res?.data?.nodes);
          setTotalCountAccessPrivate(res?.data?.pageInfo?.totalCount);
          setCollectionDenided(res?.data?.pageInfo?.totalCount);
        })
        .catch((err) => {});

      //************************ Get User ************************ */

      if (user) {
        const getProifle = async () => {
          try {
            const permissionContribute = await instanceOfAxios.get(
              `collection_permission?first=10&sortBy=contributor_invitation&&collection_id=${collections?.id}&user_id=${user?.id}`
            );

            if (permissionContribute?.data?.pageInfo?.totalCount > 0) {
              setItemTab(true);
            }
          } catch (e) {
            // return (window.location.pathname = "/error/404");
          }
        };
        getProifle();
      }
    }
  }, [privateCollection]);

  useEffect(() => {
    if (collections?.id) {
      instanceOfAxios
        .get(
          `collection_permission?first=10&sortBy=contributor_invitation&&collection_id=${collections?.id}`
        )
        .then((res) => {
          setContribters(res?.data?.nodes);
          setTotalCountContributer(res?.data?.pageInfo?.totalCount);
        })
        .catch((err) => {});
    }    
  }, [collections]);

  //************************ NEW fnc data structure ************************ */

  const filterData = (filterBool: boolean, etat: any) => {
    switch (etat !== "") {
      case etat === "items":
        handleGetItems(filterBool, body);
        break;
      case etat === "Nft":
        handleGetNFT(filterBool, body);
        break;
      case etat === "Sft":
        handleGetSFT(filterBool, body);
        break;
      case etat === "On-auction":
        handleGetAuction(filterBool, body);
        break;
      case etat === "Not-on-auction":
        handleGetNotauction(filterBool, body);
        break;
      default:
        handleGetItems(filterBool, "");
        handleGetNFT(filterBool, "");
        handleGetSFT(filterBool, "");
        handleGetAuction(filterBool, "");
        handleGetNotauction(filterBool, "");
        break;
    }
  };

  const filterbySort = (sort: any) => {
    setSelectVal(sort);
    switch (sort) {
      case "created_at":
        if (sort.length !== 0) {
          let valueSortBy = sort;
          setBody(`&sortBy=${valueSortBy}&sortOrder=DESC`);
        }
        break;
      case "oldest":
        if (sort.length !== 0) {
          setBody(`&sortBy=created_at&sortOrder=ASC`);
        }
        break;
      case "price":
        if (sort.length !== 0) {
          let valueSortBy = sort;
          setBody(`&sortBy=${valueSortBy}&sortOrder=ASC`);
        }
        break;
      case "priceHigh":
        if (sort.length !== 0) {
          setBody(`&sortBy=price&sortOrder=DESC`);
        }
        break;
      case "recently_sold":
        if (sort.length !== 0) {
          let valueSortBy = sort;
          setBody(`&sortBy=${valueSortBy}`);
        }
        break;
      case "auction_soon":
        if (sort.length !== 0) {
          let valueSortBy = sort;
          setBody(`&sortBy=${valueSortBy}`);
        }
        break;
      case "highest_last_sale":
        if (sort.length !== 0) {
          let valueSortBy = sort;
          setBody(`&sortBy=${valueSortBy}`);
        }
        break;
      case "views":
        if (sort.length !== 0) {
          let valueSortBy = sort;
          setBody(`&sortBy=${valueSortBy}&sortOrder=DESC`);
        }
        break;
      case "likes":
        if (sort.length !== 0) {
          let valueSortBy = sort;
          setBody(`&sortBy=${valueSortBy}&sortOrder=DESC`);
        }
        break;
      default:
        setBody(`&sortBy=created_at&sortOrder=DESC`);
        break;
    }
  };

  //************************ data items ************************ */
  const [body, setBody] = useState<any>("");
  const [selectVal, setSelectVal] = useState<any>("");

  const [offset, setOffset] = useState<number>(0);
  const [dataItem, setDataitem] = useState<any[]>([]);
  const [dataloadItem, setDataloadItem] = useState<boolean>(false);

  const handleGetItems = (filter: boolean, request: any) => {
    if (collections?.id) {
      setItemLoading(true);
      instanceOfAxios
        .get(
          `/collection-profile${address ? `?erd_address=${address}&` : ""}${
            address ? `current_user=${address}&` : "?"
          }collection=${collections?.id}&first=20&offset=${
            filter ? offset : 0
          }${request}`
        )
        .then(function(res) {
          setObjectTotal((prev: any) => {
            return { ...prev, countItems: res?.data?.pageInfo?.totalCount };
          });

          let result = res.data.nodes;

          let objData = dataItem;
          result.forEach((element: any) => {
            objData.push(element);
          });

          if (filter === false) {
            // setDataitem(result)
            setDataitem((prev: any) => {
              return result;
            });
          } else {
            setDataitem([...objData]);
          }

          setDataloadItem(res.data.pageInfo.hasNextPage);

          if (res.data.pageInfo.hasNextPage === true && filter === true) {
            setOffset(offset + 20);
          } else {
            setOffset(20);
          }
          setItemLoading(false);
        })
        .catch((e) => {
          setItemLoading(false);
        });
    }
  };

  //************************ data NFT ************************ */
  const [dataNFT, setDataNFT] = useState<any[]>([]);
  const [dataloadNft, setDataloadNft] = useState<boolean>(false);

  const handleGetNFT = (filter: boolean, request: any) => {
    if (collections?.id) {
      setNFTLoading(true);
      instanceOfAxios
        .get(
          `/collection-profile${address ? `?erd_address=${address}&` : ""}${
            address ? `current_user=${address}&` : "?"
          }collection=${collections?.id}&first=20&offset=${
            filter ? offset : 0
          }&type=nft${request}`
        )
        .then(function(res) {
          setObjectTotal((prev: any) => {
            return { ...prev, countNfts: res?.data?.pageInfo?.totalCount };
          });
          let result = res.data.nodes;

          let objData = dataNFT;
          result.forEach((element: any) => {
            objData.push(element);
          });

          if (filter === false) {
            // setDataNFT(result)
            setDataNFT((prev: any) => {
              return result;
            });
          } else {
            setDataNFT([...objData]);
          }
          setDataloadNft(res.data.pageInfo.hasNextPage);
          if (res.data.pageInfo.hasNextPage === true && filter === true) {
            setOffset(offset + 20);
          } else {
            setOffset(20);
          }
          setNFTLoading(false);
        })
        .catch((e) => {
          setNFTLoading(false);
        });
    }
  };

  //************************ data SFT ************************ */
  const [dataSFT, setDataSFT] = useState<any[]>([]);
  const [dataloadSft, setDataloadSft] = useState<boolean>(false);

  const handleGetSFT = (filter: boolean, request: any) => {
    if (collections?.id) {
      instanceOfAxios
        .get(
          `/collection-profile${address ? `?erd_address=${address}&` : ""}${
            address ? `current_user=${address}&` : "?"
          }collection=${collections?.id}&first=20&offset=${
            filter ? offset : 0
          }&type=sft${request}`
        )
        .then(function(res) {
          setObjectTotal((prev: any) => {
            return { ...prev, countSfts: res?.data?.pageInfo?.totalCount };
          });
          let result = res.data.nodes;

          let objData = dataSFT;
          result.forEach((element: any) => {
            objData.push(element);
          });

          if (filter === false) {
            // setDataSFT(result)
            setDataSFT((prev: any) => {
              return result;
            });
          } else {
            setDataSFT([...objData]);
          }

          setDataloadSft(res.data.pageInfo.hasNextPage);
          if (res.data.pageInfo.hasNextPage === true && filter === true) {
            setOffset(offset + 20);
          } else {
            setOffset(20);
          }
        });
    }
  };

  //************************ data Auction ************************ */
  const [dataAuction, setDataAuction] = useState<any[]>([]);
  const [dataloadAuction, setDataloadAuction] = useState<boolean>(false);

  const handleGetAuction = (filter: boolean, request: any) => {
    if (collections?.id) {
      setAuctionLoading(true);
      instanceOfAxios
        .get(
          `/collection-profile${address ? `?erd_address=${address}&` : ""}${
            address ? `current_user=${address}&` : "?"
          }collection=${collections?.id}&first=20&offset=${
            filter ? offset : 0
          }&statusAuction=onAuction${request}`
        )
        .then(function(res) {
          setObjectTotal((prev: any) => {
            return { ...prev, countAuctions: res?.data?.pageInfo?.totalCount };
          });
          let result = res.data.nodes;

          let objData = dataAuction;
          result.forEach((element: any) => {
            objData.push(element);
          });

          if (filter === false) {
            // setDataAuction(result)
            setDataAuction((prev: any) => {
              return result;
            });
          } else {
            setDataAuction([...objData]);
          }

          setDataloadAuction(res.data.pageInfo.hasNextPage);

          if (res.data.pageInfo.hasNextPage === true && filter === true) {
            setOffset(offset + 20);
          } else {
            setOffset(20);
          }
          setAuctionLoading(false);
        })
        .catch((e) => {
          setAuctionLoading(false);
        });
    }
  };

  //************************ data Not auction ************************ */
  const [dataNotauction, setDataNotauction] = useState<any[]>([]);
  const [dataloadNotAuction, setDataloadNotAuction] = useState<boolean>(false);

  const handleGetNotauction = (filter: boolean, request: any) => {
    if (collections?.id) {
      setNotAuctionLoading(true);
      instanceOfAxios
        .get(
          `/collection-profile${address ? `?erd_address=${address}&` : ""}${
            address ? `current_user=${address}&` : "?"
          }collection=${collections?.id}&first=20&offset=${
            filter ? offset : 0
          }&statusAuction=notOnAuction${request}`
        )
        .then(function(res) {
          setObjectTotal((prev: any) => {
            return {
              ...prev,
              countNotAuctions: res?.data?.pageInfo?.totalCount,
            };
          });
          let result = res.data.nodes;

          let objData = dataNotauction;
          result.forEach((element: any) => {
            objData.push(element);
          });

          if (filter === false) {
            // setDataNotauction(result)
            setDataNotauction((prev: any) => {
              return result;
            });
          } else {
            setDataNotauction([...objData]);
          }

          setDataloadNotAuction(res.data.pageInfo.hasNextPage);
          if (res.data.pageInfo.hasNextPage === true && filter === true) {
            setOffset(offset + 20);
          } else {
            setOffset(20);
          }
          setNotAuctionLoading(false);
        })
        .catch((e) => {
          setNotAuctionLoading(false);
        });
    }
  };

  useEffect(() => {
    filterData(false, activeTab);
  }, [body,likesStore.isLiked]);

  //************************ end source ************************ */

  return (
    <div className="collection-show-page">
      <Helmet>
        <title>
          Rarity.market | {t("collection")} {`${collections?.collection_name}`}
        </title>
        <meta
          name="keywords"
          content="NFT colloction, item, bids, creation, auction, trading volume, offer, art, digital, elrond"
        />
         <meta name="description" content={collections?.description} />
         <meta content={collections?.collection_name} property="og:title" />
         <meta content={collections?.description} property="og:description" />
         <meta
          property="og:url"
          content={`https://rarity.market/${collections?.homepage}`}
        />
        <meta content={collections?.collection_name} property="twitter:title" />
        <meta
          content={collections?.description}
          property="twitter:description"
        />
          <meta
          property="twitter:url"
          content={`https://rarity.market/${collections?.homepage}`}
        />
      </Helmet>
      <div className="collection-show-header">
      {extbanner!==null&&  <CollectionHeader
          collection={collections}
          profile={false}
          creator={address === collections?.creator?.erd_address}
          colDetails={collections?.collectionType?.collection_type_name_i18ntag}
          owner={address === collections?.creator?.erd_address}
          idCol={collections?.id}
          localBanner={localBanner}
          setLocalBanner={setLocalBanner}
          extbanner={extbanner}
          setExtbanner={setExtbanner}
        />}
      </div>

      <div className="collection-show-c">
        <ProfileSider
          isCreator={isCreator}
          privateAccess={privateAccess}
          totalCountAccessPrivate={totalCountAccessPrivate}
          totalCountContributer={totalCountContributer}
          contributers={contributers}
          collection={collections}
          profile={false}
          idCol={collections?.id}
          stat={false}
          creator={address === collections?.creator?.erd_address}
          lenghtNft={objectTotals.countNfts}
          lenghSft={objectTotals.countSfts}
          collectionType={
            collections?.collectionType?.collection_type_name_i18ntag
          }
          creatorId={collections?.creator?.id}
          colDetails={collections?.collectionType?.collection_type_name_i18ntag}
        />

        <div className="collection-profile-contenu">
          <div className="profile-tab-responsive">
            <ProfileTab
              profile={false}
              elementsCount={[]}
              menu={menuCollection}
              activeTab={activeTab}
              setActiveTab={(tab) => setActiveTab(tab)}
            />
          </div>
          <div style={{ marginBottom: "30px" }}>
            <MobileTab
              tabs={menuCollection}
              activeTab={t(`${activeTab}`)}
              customHeaderClass="collection-profile-mobile-tab"
              onChangeTab={(tab) => setActiveTab(tab)}
            />
          </div>
          {(activeTab === "items" ||
            activeTab === "Nft" ||
            activeTab === "On-auction" ||
            activeTab === "Not-on-auction") && (
            <div className="sort-by-collection">
              <Select
                className={"select-option"}
                style={{
                  fontWeight: "bold",
                  width: "221px",
                  display: "grid",
                }}
                onChange={(e) => filterbySort(e)}
                placeholder={t("Sort By")}
                showArrow={true}
                value={selectVal}
                size="middle"
              >
                {activeTab === "items"
                  ? SortedByitems.map((el: any, i: any) => (
                      <Option
                        style={{ fontSize: 12 }}
                        key={i}
                        value={el.value}
                        className="sortby_option"
                      >
                        {el.label}
                      </Option>
                    ))
                  : sortBy.map((el: any, i: any) => (
                      <Option
                        style={{ fontSize: 12 }}
                        key={i}
                        value={el.value}
                        className="sortby_option"
                      >
                        {el.label}
                      </Option>
                    ))}
              </Select>
            </div>
          )}

          <div className="desktop-tab-content-items-container-collection-tab">
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className="contentCollection"
            >
              {activeTab === "items" &&
                (itemLoading ? (
                  <div className="collection-tab-loading-content">
                    <Spin size="large" />
                  </div>
                ) : (
                  <>
                    {collections?.creator?.erd_address === address ||
                    contributers?.some(
                      (element: any) => element.profile.erd_address === address
                    ) ||
                    collections?.collectionType
                      ?.collection_type_name_i18ntag === "open" ||
                    collections?.collectionType
                      ?.collection_type_name_i18ntag === "charity" ? (
                      <Col
                        span={9}
                        className="col-style col-add"
                        onClick={() =>
                          navigate(`/create-item/${collections?.id}`)
                        }
                      >
                           <a
                          href={`/create-item/${collections?.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                        <div>
                          <h5>{t("collection-page.add-new-item")}</h5>
                          <img
                            alt="addIcon"
                            src={addIcon}
                            style={{ margin: "20px 0px" }}
                          ></img>
                        </div>
                        </a>
                      </Col>
                    ) : (
                      ""
                    )}{" "}
                    {dataItem?.length === 0 ? (
                      <div className="collection-profile-noData-holder">
                        <Nodata />
                      </div>
                    ) : (
                      dataItem.map((el: any,key:number) => {
                        return (
                          <Col span={9} className="col-style" key={key}>
                            <RecentItem item={el} />
                          </Col>
                        );
                      })
                    )}
                  </>
                ))}
              {activeTab === "Nft" &&
                (nftLoading ? (
                  <div className="collection-tab-loading-content">
                    <Spin size="large" />
                  </div>
                ) : dataNFT?.length === 0 ? (
                  <div className="collection-profile-noData-holder">
                    <Nodata />
                  </div>
                ) : (
                  dataNFT.map((el: any,key:number) => {
                    return (
                      <Col span={9} className="col-style" key={key}>
                        <RecentItem item={el} />
                      </Col>
                    );
                  })
                ))}
              {/* {activeTab === "Sft" &&
                dataSFT.map((el: any) => {
                  return (
                    <Col span={9} className="col-style">
                      <RecentItem item={el} />
                    </Col>
                  );
                })} */}
              {activeTab === "On-auction" &&
                (auctionLoading ? (
                  <div className="collection-tab-loading-content">
                    <Spin size="large" />
                  </div>
                ) : dataAuction?.length === 0 ? (
                  <div className="collection-profile-noData-holder">
                    <Nodata />
                  </div>
                ) : (
                  dataAuction.map((el: any,key:number) => {
                    return (
                      <Col span={9} className="col-style" key={key}>
                        <RecentItem item={el} />
                      </Col>
                    );
                  })
                ))}
              {activeTab === "Not-on-auction" &&
                (notAuctionLoading ? (
                  <div className="collection-tab-loading-content">
                    <Spin size="large" />
                  </div>
                ) : dataNotauction?.length === 0 ? (
                  <div className="collection-profile-noData-holder">
                    <Nodata />
                  </div>
                ) : (
                  dataNotauction.map((el: any,key:number) => {
                    return (
                      <Col span={9} className="col-style" key={key}>
                        <RecentItem item={el} />
                      </Col>
                    );
                  })
                ))}
              <ScrollToTop showBelow={250} />
            </Row>
            {dataloadItem && activeTab === "items" ? (
              <button
                className="button-seeMore ant-btn ant-btn-default collections-button"
                onClick={() => filterData(true, activeTab)}
              >
                {t("activity.see-more")}{" "}
              </button>
            ) : dataloadNft && activeTab === "Nft" ? (
              <button
                className="button-seeMore ant-btn ant-btn-default collections-button"
                onClick={() => filterData(true, activeTab)}
              >
                {t("activity.see-more")}{" "}
              </button>
            ) : dataloadSft && activeTab === "sft" ? (
              <button
                className="button-seeMore ant-btn ant-btn-default collections-button"
                onClick={() => filterData(true, activeTab)}
              >
                {t("activity.see-more")}{" "}
              </button>
            ) : dataloadAuction && activeTab === "On-auction" ? (
              <button
                className="button-seeMore ant-btn ant-btn-default collections-button"
                onClick={() => filterData(true, activeTab)}
              >
                {t("activity.see-more")}{" "}
              </button>
            ) : dataloadNotAuction && activeTab === "Not-on-auction" ? (
              <button
                className="button-seeMore ant-btn ant-btn-default collections-button"
                onClick={() => filterData(true, activeTab)}
              >
                {t("activity.see-more")}{" "}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default CollectionProfile;
