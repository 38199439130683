import { Button } from 'antd';
import React from 'react';
import './orangeCard.less';

export interface OrangeCardProps {
	image: string;
	title: string;
	description: string;
	buttonTitle: string;
	firstImage: boolean;
	onClick: () => void;
}

const OrangeCard = ({ image, title, description, buttonTitle, firstImage, onClick }: OrangeCardProps) => {
	return (
		<div className="orange-3d-card">
			<div className="description-card-become-Creator">
				<h3 className="orange-card-text">{title}</h3>
				<h5 className="orange-card-text text-regular-font" style={{ color: '#fff' }}>
					{description}
				</h5>
				<Button className="card-white-button" onClick={onClick}>
					<span style={{ color: '#EC6321' }}>{buttonTitle}</span>
				</Button>
			</div>
			<div className={firstImage ? 'illustration-image-container-1' : 'illustration-image-container-2'}>
				<img alt="illustration" src={image} className="illustration-image" />
			</div>
		</div>
	);
};

export default OrangeCard;
