import { createContext, useContext } from 'react';
import ActivityStore from '../store/activity.store';
import ExploreStore from '../store/explore.store';
import GenesisCollection from '../store/genesisCollection';
import ItemDetailsStore from '../store/itemDetailsStore.store';
import ItemFormStore from '../store/itemFormStore.store';
import LikesStore from '../store/likes.store';
import NotificationsStore from '../store/notifications.store';
import ProfieInfoStore from '../store/profieInfo.store';

const globalStore = {
	likesStore: LikesStore,
	exploreStore: ExploreStore,
	activityStore: ActivityStore,
	itemDetailsStore: ItemDetailsStore,
	genesisCollection: GenesisCollection,
	profieInfoStore: ProfieInfoStore,
	itemFormStore: ItemFormStore,
	notificationsStore: NotificationsStore
};

export const GlobalContext = createContext(globalStore);

export function useGlobalContext() {
	return useContext(GlobalContext);
}

export const GlobalContextProvider = ({ children }: any) => {
	return <GlobalContext.Provider value={globalStore}>{children}</GlobalContext.Provider>;
};
