import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import instanceOfAxios from "../../../../../configAxios";
import { OpenErrorNotification } from "../../../../components/toasts/openToast";
import { useContext } from "../../../../context";
import {  setItem } from "../../../../storage/session";
import "./emailConfirmed.less";

const EmailConfirmed = (props: any) => {
  const { t } = useTranslation();
  const params = useParams();
  const { address } = useContext();
  const  navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (!address && params.status === "profile") {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    } else
      instanceOfAxios
        .get(`confirm_email_profile/${params.code}/${params.status}`)
        .then((res: any) => {
          localStorage.setItem("user", JSON.stringify(res?.data[0]));
          setConfirmed(true);
        })
        .catch((err: any) => {
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-email-desc"),
            3
          );
          navigate("/my-profile");
        });
  }, []);

  const redirectToHome = (subscribe: boolean) => {
    if (subscribe && JSON.parse(localStorage.getItem("user") || "{}")?.id) {
      instanceOfAxios
        .get(
          `subscribe_newsletter/${
            JSON.parse(localStorage.getItem("user") || "{}")?.id
          }/${params.code}`
        )
        .then((res: any) => {
          let updatedUser = {
            ...JSON.parse(localStorage.getItem("user") || "{}"),
            ...{
              newsletter_subscription: true,
              opt_in: res?.data?.opt_in,
            },
          };
          localStorage.setItem("user", JSON.stringify(updatedUser));
          navigate("/");
        })
        .catch((err: any) => {
          OpenErrorNotification(
            t("profile.error"),
            t("profile.error-email-desc"),
            3
          );
          navigate("/");
        });
    } else navigate("/");
  };

  return (
    <div className="email-confirmation-page-container">
      {confirmed ? (
        <>
          <h2 className="primary-color">{t("profile.congratulations")}</h2>
          <h4 className="middle-description">
            {t("profile.email-confirmed-message")}
          </h4>
          {params.status === "profile" && (
            <div className="checkbox-container">
              {`${t("profile.subscribe-newsletter")} ?`}
            </div>
          )}
          {params.status === "profile" ? (
            <div>
              <Button
                className="not-intereseted-button"
                onClick={() => redirectToHome(false)}
              >
                {t("profile.not-interested")}
              </Button>
              <Button type="primary" onClick={() => redirectToHome(true)}>
                {t("profile.subscribe-button")}
              </Button>
            </div>
          ) : (
            <div>
              <Button type="primary" onClick={() => redirectToHome(false)}>
                {t("errors.redirect-home")}
              </Button>
            </div>
          )}
        </>
      ) : (
        <Spin size="large" />
      )}
    </div>
  );
};

export default EmailConfirmed;
