import React from "react";
import { Modal } from "antd";
import closeIcon from "../../../assets/images/close.svg";

interface Props {
  children: any;
  width: string;
  visible: boolean;
  closable: boolean;
  handleOk: (event: React.MouseEvent<HTMLElement>) => void;
  handleCancel: (event: React.MouseEvent<HTMLElement>) => void;
}

const CustomModal = ({
  children,
  visible,
  width,
  closable,
  handleOk,
  handleCancel,
}: Props) => {
  return (
    <Modal
      centered={true}
      title={null}
      visible={visible}
      footer={null}
      width={width}
      onOk={handleOk}
      onCancel={handleCancel}
      closeIcon={
        <img alt="Close Modal" src={closeIcon} style={{ width: "15px" }} />
      }
      closable={closable}
      maskClosable={closable}
      destroyOnClose
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
