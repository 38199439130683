import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuctionModal from "../../auction modal/auctionModal";
import egldIcon from "../../../../../../assets/images/EGLD.svg";
import "./display.less";
import instanceOfAxios from "../../../../../../configAxios";
import NumberDisplayi18n from "../../../../../helpers/numbersi18n";
import { getItem, setItem } from "../../../../../storage/session";
import moment from "moment";
import { useContext } from "../../../../../context";
import { verifyNFTonExpectedAddress } from "../../../../../helpers/communFunctions";
import { OpenErrorNotification } from "../../../../../components/toasts/openToast";
import { useGlobalContext } from "../../../../../context/globalContext";
import { observer } from "mobx-react-lite";

interface AuctionDisplayProps {
  item: any;
  gasLimit: number;
}

const AuctionDisplay = observer(({ item, gasLimit }: AuctionDisplayProps) => {
  const { t } = useTranslation();
  const { itemDetailsStore } = useGlobalContext()
  const { address, marketPlaceAddress } = useContext();
  
  const  navigate = useNavigate();
  const [order, setOrder] = useState("place-bid");
  const [visible, setVisible] = useState(false);
  const [currentConverted, setCurrentConverted] = useState<any>(0);
  const [maxConverted, setMaxConverted] = useState<any>(0);
  const [restTime, setRestTime] = useState<any>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [auctionData, setAuctionData] = useState<any>({});
  console.log("auctionData")
  console.log(process.env.REACT_APP_AUCTION_MINIMUM_BID_STEP,"fuzziuehfuezfezohfofe")
  const bidStep =  Number(process.env.REACT_APP_AUCTION_MINIMUM_BID_STEP);
  const [auctionDeadline, setAuctionDeadline] = useState(0);
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);
  const customDate = moment(new Date(auctionDeadline * 1000));
  var now = moment(new Date());
  let timestampNow = parseInt(moment(new Date()).format("X"));


  const changeStatusAutcion=async()=>{
    instanceOfAxios
    .post(`change_auction_status`, {item_id:item.id})
    .then((res: any) => {
      getActionStatus()
      itemDetailsStore.setIsAuctionBoxStatusChange(true)
    })
    .catch((err: any) => {
      OpenErrorNotification(
        t("profile.error"),
        t("profile.error-desc"),
        3
      );
    });
  }

  const getActionStatus = () => {
    instanceOfAxios
      .get(
        `item_box_action/${item?.id}${address ? `?erd_address=${address}` : ""}`
      )
      .then((res) => {
        setIsApiCalled(true)
        if (res?.data?.data?.auction) {   
          setAuctionData({
            bid: Number(res?.data?.data?.auction?.bid),
            min_bid: Number(res?.data?.data?.auction?.min_bid),
            max_bid: Number(res?.data?.data?.auction?.max_bid),
          });
          setAuctionDeadline(Number(res?.data?.data?.auction?.deadline));
        }
      })
      .catch((error) => {
        console.log("err", error);
        return error;
      });
  };

  const redirectNotConnected = () => {
    if (!address) {
      setItem("goToUrl", window.location.href);
      window.scrollTo(0, 0);
      navigate("/auth");
    }
  };

  const getCountdown = () => {
    if (auctionDeadline !== 0) {
   //todays date
      var duration = moment.duration(customDate.diff(now));
        setRestTime({
          years: duration.years(),
          months: duration.months(),
          days: duration.days(),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
    }
  };

  useEffect(() => { 
    if(!isApiCalled){
      getActionStatus();
      setIsApiCalled(false)
    }
    if(auctionData?.bid ||auctionData?.min_bid){
      instanceOfAxios
        .get(
          `currency/exchange_rates/egld/${
            auctionData?.bid === 0 ? auctionData?.min_bid : auctionData?.bid
          }/eur`
        )
        .then((res: any) => {
          setCurrentConverted(res?.data?.rate);
        })
        .catch((err: any) => {});
    }
    auctionData?.max_bid > 0 &&
      instanceOfAxios
        .get(`currency/exchange_rates/egld/${auctionData?.max_bid}/eur`)
        .then((res: any) => {
          setMaxConverted(res?.data?.rate);
        })
        .catch((err: any) => {});
  }, [auctionData?.bid]);

  useEffect(() => {
    if(now<=customDate){
      getCountdown();
    }
  }, [auctionDeadline]);  

  useEffect(() => {  
    if(auctionDeadline!==0 && timestampNow!==0){
      if(timestampNow<=auctionDeadline){
        const interval = setInterval(() => {
          getCountdown();
        }, 1000);
        return () => clearInterval(interval);      
      }else {
          changeStatusAutcion()
  
      }
    }
  }, [restTime,auctionDeadline]);

  const blockchainCurrency = "EGLD";
  const userCurrency = "€";

  const showSendBid = (status: string) => {
    redirectNotConnected();
    instanceOfAxios
      .get(
        `item_trading_history/item/${item?.id}?${
          address ? `erd_address=${address}&` : ""
        }first=${1}&offset=${0}`
      )
      .then((res: any) => {
        if (
          res?.data?.nodes[0]?.fromAddress?.erd_address === address &&
          res?.data?.nodes[0]?.status === "sent"
        ) {
          message.error(
            t("action-box.cant-outbid-yourself", {
              bid: Number(res?.data?.nodes[0]?.price) / 1000000000000000000,
            })
          );
        } else {
          switch (status) {
            case "place bid":
              getActionStatus();
              setOrder("place bid");
              setVisible(true);
              break;

            case "purchase":
              getActionStatus();
              setOrder("purchase");
              setVisible(true);
              break;

            default:
              break;
          }
        }
      })
      .catch((err: any) => {
        return "failed call for get trading history";
      });
  };

  const checkBeforeAction = (actionCase: any) => {
    verifyNFTonExpectedAddress(
      marketPlaceAddress,
      item?.token_identifier,
    ).then((res: any) => {
      if (res === true) {
        showSendBid(actionCase);
      }else {
        message.warning(t("action-box.nft-not-on-auction"))
      }
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="place-bid-content">
        <div className="place-bid-price-container">
          <h5
            className="text-regular-font primary-color"
            style={{ marginBottom: "10px" }}>
            {t("collection-page.current-bid")}
          </h5>
          <h4>
            <NumberDisplayi18n
              value={Number(
                (auctionData?.bid === 0
                  ? auctionData?.min_bid /1000000000000000000

                  : auctionData?.bid /1000000000000000000
                )?.toFixed(6)
              )}
              currency=""
              crypto={false}
            />
            <img alt="EGLD" src={egldIcon} className="egld-icon" />
          </h4>
          <h6 className="approximative-price">
            ≈
            <NumberDisplayi18n
              value={Number((currentConverted / 1000000000000000000)?.toFixed(2) )}
              currency={userCurrency}
              crypto={false}
            />
          </h6>
        </div>
        <div className="place-bid-price-container">
          <h5
            className="text-regular-font primary-color"
            style={{ marginBottom: "10px" }}>
            {t("collection-page.immediate-winnig-bid")}
          </h5>
          <h4>
            <NumberDisplayi18n
              value={Number(auctionData?.max_bid?.toFixed(6))/1000000000000000000 }
              currency=""
              crypto={false}
            />
            <img alt="EGLD" src={egldIcon} className="egld-icon" />
          </h4>
          <h6 className="approximative-price">
            ≈ 
            <NumberDisplayi18n
              value={Number((maxConverted / 1000000000000000000)?.toFixed(2))  }
              currency={userCurrency}
              crypto={false}
            />
          </h6>
        </div>
        <div>
          <h5
            className="text-regular-font primary-color"
            style={{ marginBottom: "10px" }}>
            {t("collection-page.auction-end")}
          </h5>
          <div className="end-date-container">
            {restTime?.years > 0 && (
              <div className="date-number-part">
                <div className="date-number-container">
                  <h5>{restTime.years}</h5>
                </div>
                <h6 className="text-regular-font">
                  {restTime?.years > 1
                    ? t("collection-page.Years")
                    : t("collection-page.year")}
                </h6>
              </div>
            )}
            {restTime?.months > 0 && (
              <div className="date-number-part">
                <div className="date-number-container">
                  <h5>{restTime.months}</h5>
                </div>
                <h6 className="text-regular-font">
                  {restTime?.months > 1
                    ? t("collection-page.months")
                    : t("collection-page.month")}
                </h6>
              </div>
            )}
            <div className="date-number-part">
              <div className="date-number-container">
                <h5>{restTime.days}</h5>
              </div>
              <h6 className="text-regular-font">{t("collection-page.days")}</h6>
            </div>
            <div className="date-number-part">
              <div className="date-number-container">
                <h5>{restTime.hours}</h5>
              </div>
              <h6 className="text-regular-font">
                {t("collection-page.hours")}
              </h6>
            </div>
            <p className="points-timer">:</p>
            <div className="date-number-part">
              <div className="date-number-container">
                <h5>{restTime.minutes}</h5>
              </div>
              <h6 className="text-regular-font">{t("collection-page.mins")}</h6>
            </div>
            <p className="points-timer">:</p>
            <div className="date-number-part">
              <div className="date-number-container">
                <h5>{restTime.seconds}</h5>
              </div>
              <h6 className="text-regular-font">{t("collection-page.secs")}</h6>
            </div>
          </div>
        </div>
      </div>
      {item?.owner?.erd_address === address ? (
        <h5 className="primary-color">{t("collection-page.your-auction")}</h5>
      ) : (
        <div className="place-bid-buttons-holder">
          <div className="place-bid-button-holder">
            <Button
              type="default"
              block
              onClick={() => checkBeforeAction("place bid")}
              disabled={
                item?.owner?.id ===
                JSON.parse(localStorage.getItem("user") || "{}")?.id
              }>
              {t("collection-page.place-bid-button")}
            </Button>
          </div>
          <Button
            type="primary"
            block
            onClick={() => checkBeforeAction("purchase")}
            disabled={
              item?.owner?.id ===
              JSON.parse(localStorage.getItem("user") || "{}")?.id
            }>
            {t("collection-page.purchase-now-button")}
          </Button>
        </div>
      )}
      <AuctionModal
        visible={visible}
        setVisible={setVisible}
        order={order}
        price={
          order === "purchase"
            ? auctionData?.max_bid
            : auctionData?.bid === 0
            ? auctionData?.min_bid
            :parseFloat((auctionData?.bid + bidStep).toFixed(10)) > auctionData?.max_bid
            ? auctionData?.max_bid
            : parseFloat((auctionData?.bid + bidStep).toFixed(10))
        }
        blockchainCurrency={blockchainCurrency}
        userCurrency={userCurrency}
        royality={item?.royalties}
        item={item}
        gasLimit={gasLimit}
        minBid={
          auctionData?.bid === 0
            ? auctionData?.min_bid
            : parseFloat((auctionData?.bid + bidStep).toFixed(10)) > auctionData?.max_bid
            ? auctionData?.max_bid
            :parseFloat((auctionData?.bid + bidStep).toFixed(10))
        }
        maxBid={auctionData?.max_bid}
        bidStep={bidStep}
      />
    </div>
  );
});

export default AuctionDisplay;
