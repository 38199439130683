import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import heartIcon from "../../../../../../assets/images/heart.svg";
import priceLogo from "../../../../../../assets/images/EGLD.svg";
import useImageDisplay from "../../../../../helpers/useImageDisplay";
import useWorstDisplay from "../../../../../helpers/useWorstDisplay";
import { Tooltip } from "antd";

const AuctionList = ({ auction }: { auction: any }) => {
  const  navigate = useNavigate();
  const { t } = useTranslation();
  const collectionThumbnail = useImageDisplay(
    auction?.uri,
    "__t" + auction?.uri_ext
  );
  
  return (
    <div className="action-element-container">
      <div className="auction-element-first-part">
        <div className="thumbnail-container">
        <a
            href={auction.type==="nft"?`/nft/${auction?.token_identifier}`:`/item-details/${auction?.id}`}
            onClick={(e) => {
              e.preventDefault();
            }}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(auction.type==="nft"?`/nft/${auction?.token_identifier}`:`/item-details/${auction?.id}`);
              }}
              alt="thumbnailIcon"
              src={collectionThumbnail}
              className="thumbnail-image"
            />
          </a>
        </div>
        <div>
          <Tooltip title={auction?.title || ""}>
            <a
                href={auction.type==="nft"?`/nft/${auction?.token_identifier}`:`/item-details/${auction?.id}`}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <h5
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(auction.type==="nft"?`/nft/${auction?.token_identifier}`:`/item-details/${auction?.id}`);
                  }}
                  className="h5-auction-text">
                  {useWorstDisplay(auction?.title, 15)}
                  {/* {auction?.title} */}
                </h5>
              </a>
          </Tooltip>
            <a
              href={`${auction?.collection?.homepage}`}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
            <h5
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/${auction?.collection?.homepage}`);
              }}
              className="h5-auction-text text-regular-font">
              {useWorstDisplay(auction?.collection?.collection_name, 60)}
              {/* {auction?.collection?.collection_name} */}
            </h5>
          </a>
        </div>
      </div>
      <div className="auction-element-second-part">
        <div className="price-part">
          <h4 style={{ margin: 0 }}>{auction?.likes}</h4>
          <img alt="heartIcon" src={heartIcon} className="price-logo" />
        </div>
        {auction?.bidsNum ? (
          <h5 className="h5-auction-text text-regular-font">
            {`${auction?.bidsNum} ${t("home.navigation-bid")}`}
          </h5>
        ) : null}
      </div>
      {/* <div className="auction-element-second-part">
              <div className="price-part">
                <h4 style={{ margin: 0 }}>{auction?.price.toFixed(4)}</h4>
                <img alt="" src={priceLogo} className="price-logo" />
              </div>
              {auction?.bidsNum ? (
                <h5 className="h5-auction-text text-regular-font">
                  {`${auction?.bidsNum} ${t("home.navigation-bid")}`}
                </h5>
              ) : null}
            </div> */}
    </div>
  );
};

export default AuctionList;
