import * as React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import "./collectionBanner.less";

interface BannerPropositionProps {
  photo: string;
  displayPhoto: string;
  setImageDisplay: (img: string) => void;
}

const BannerProposition = ({
  photo,
  displayPhoto,
  setImageDisplay,
}: BannerPropositionProps) => {
  return (
    <div
      className={
        photo === displayPhoto
          ? "banner-proposition-container highlighted-proposition"
          : "banner-proposition-container"
      }
      onClick={() => {
        setImageDisplay(photo);
      }}
    >
      {/* if  upload  before save */}
      <img alt={`${photo + "?"+ new Date().getTime()}`} src={
        process.env.REACT_APP_S3_ENABLED === 'true' ?
          photo?.length > 50 && photo.includes("data") ?
            photo :  photo 
          : photo
      } className="banner-show-cover-photo" />
      {(photo === displayPhoto) && (
        <CheckCircleFilled
          className="checked-button-container"
          style={{ color: "white" }}
        />
      )}
    </div>
  );
};

export default BannerProposition;
