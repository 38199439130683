import { Avatar, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import CustomAvatar from "../../../components/avatar/customAvatar";

export interface CreatorsProps {
  creators: any[];
}

const Creators = ({ creators }: CreatorsProps) => {
  const navigate = useNavigate();

  return (
    <Avatar.Group
      maxCount={3}
      size="large"
      maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
    >
      {creators.map((creator: any, index) => {
        if (index === creators.length)
          return (
            <Tooltip title="Ant User" placement="top" key={index}>
              <div
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(`/profile-details/${creator.id}`);
                }}
                style={{ cursor: "pointer" }}
              >
                <CustomAvatar
                  avatarImage={creator?.avatar}
                  extensionImage={creator?.avatar_ext}
                />
              </div>
            </Tooltip>
          );
        else
          return (
            <div
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/profile-details/${creator.id}`);
              }}
              style={{ cursor: "pointer" }}
            >
              <CustomAvatar
                avatarImage={creator?.avatar}
                extensionImage={creator?.avatar_ext}
              />
            </div>
          );
      })}
    </Avatar.Group>
  );
};

export default Creators;
