import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RegisterBrand from "../boxes/registerBrand";
import "./display.less";

interface SuccessRegisterProps {
  type: "nft" | "sft";
  createNFTorSFT: (ok: boolean) => void;
  loading: boolean;
}

const SuccessRegisterDisplay = ({
  type,
  createNFTorSFT,
  loading,
}: SuccessRegisterProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className="primary-color">
        {t("collection-page.auction-box-success")}
      </h4>
      <h4>{t("action-box.register-congrats-success")}</h4>
      <h5 className="action-description-text">
        {t(`action-box.congrats-success-${type}-desc`)}
      </h5>
      <Button
        type="primary"
        loading={loading}
        block
        onClick={() => createNFTorSFT(true)}
      >
        {t(`collection-page.create-${type}-title`)}
      </Button>
    </div>
  );
};

export default SuccessRegisterDisplay;
