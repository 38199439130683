import React, { useEffect, useState } from 'react';
import lightIcon from '../../../../assets/images/light.svg';
import darkIcon from '../../../../assets/images/dark.svg';

const SwitchMode = () => {
	const [ light, setLight ] = useState(
		localStorage.getItem('theme') === 'dark'
			? false
			: localStorage.getItem('theme') === 'light'
				? true
				: window.matchMedia('(prefers-color-scheme: light)').matches
	);

	useEffect(
		() => {
			// console.log("navigator", window.matchMedia('(prefers-color-scheme: light)'));

			localStorage.setItem('theme', `${light ? 'light' : 'dark'}`);
			if (light) {
				document.body.classList.add('light');
				document.body.classList.remove('dark');
			} else {
				document.body.classList.remove('light');
				document.body.classList.add('dark');
			}
		},
		[ light ]
	);

	const changeTheme = (theme: any) => {
		setLight(!theme);
		// window.location.reload();
	};

	return (
		<div
			className={light ? 'switch-button switch-button-white' : 'switch-button switch-button-black'}
			onClick={() => changeTheme(light)}>
			<img alt="switchIcon" className="switch-icon" src={light ? lightIcon : darkIcon} />
		</div>
	);
};

export default SwitchMode;
