import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import useImageDisplay from "../../helpers/useImageDisplay";
import useUserNaming from "../../helpers/useUserNaming";
import "./userDisplay.less";

interface UserDisplayProps {
  user: any;
  sizeAvatar: any;
}

const UserDisplay = ({ user, sizeAvatar }: UserDisplayProps) => {
  const avatarDispaly = useImageDisplay(user?.avatar, "__t" + user?.avatar_ext);
  const userNaming = useUserNaming(user);
  const  navigate = useNavigate();

  const redirectToProfile = () => {
    navigate(`/profile-details/${user?.id}`);
  };

  return (
    <>
      {user?.id ? (
        <div className="user-display-container" >
            <a href={`/profile-details/${user?.id}`}
            onClick={(e) => {
              e.preventDefault();
            }}
            className="user-display-container"
            >
            <div onClick={redirectToProfile}>
              <Avatar size={sizeAvatar} src={avatarDispaly} />
            </div>
            <h6
            onClick={redirectToProfile}
              className={
                sizeAvatar === "small"
                  ? "text-regular-font display-title"
                  : " display-title"
              }
            >
              {userNaming}
            </h6>

        </a>
        </div>
      ) : null}
    </>
  );
};

export default UserDisplay;
